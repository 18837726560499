<template>
  <v-layout class="page-layout">
    <v-card class="col-12 transparent">
      <v-card-title :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1 pr-0']" class="col-12 pagetitle"
        >{{$t('employee_score_card')}}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-container v-if="employeeinfo" fluid>
        <v-col>
          <v-row class="justify-space-between driver-info">
            <v-card elevation="0" class="transparent pl-0 col-xl-5 col-lg-5 col-md-7 col-sm-12 col-xs-12">
              <v-card-title :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1 pr-0']" class="employee__name pl-0">
                {{ employeeinfo.firstname }} {{ employeeinfo.lastname }}
              </v-card-title>
              <v-card-text class="pl-0">
                <p :class="[$vuetify.breakpoint.mdAndUp ? 'h-6' : 'body-1']" class="employee__username">{{ employeeinfo.username }}</p>
                <p :class="[$vuetify.breakpoint.mdAndUp ? 'h-6' : 'body-1']" class="employee__division">{{ employeeinfo.division }}</p>
                <p :class="[$vuetify.breakpoint.mdAndUp ? 'h-6' : 'body-1']" class="employee__location">{{ employeeinfo.location }}</p>
              </v-card-text>
              <v-card-text class="pl-0">
                <h2 class="h-6 mb-7">USER SNAPSHOT</h2>
                <p :class="[$vuetify.breakpoint.mdAndUp ? 'h-6' : 'body-1']" class="employee__username">{{ $t('score')}}: {{ employeeinfo.currentData.score }}</p>
                <p :class="[$vuetify.breakpoint.mdAndUp ? 'h-6' : 'body-1']" class="employee__division">{{ $t('coins')}}: {{ employeeinfo.currentData.coins }}</p>
              </v-card-text>

            </v-card>
            <v-card elevation="0" class="transparent pl-0 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-xs-12">
              <v-img
                v-if="employeeinfo.avatar && employeeinfo.avatar.length > 0"
                :lazy-src="employeeinfo.avatar"
                max-height="auto"
                :src="employeeinfo.avatar"
              >
              </v-img>
              <v-img
                v-else
                lazy-src="~@/assets/avatar.jpg"
                max-height="270"
                src="~@/assets/avatar.jpg"
              ></v-img>
            </v-card>
          </v-row>
        </v-col>
        <v-col ref="tabsRef" class="px-0">
          <v-tabs
                  v-model="tab"
                  align-with-title
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab class="mx-0">{{ $t('badges') }}</v-tab>
            <v-tab>{{ $t('performance_history' )}}</v-tab>
            <v-tab>{{ $t('complete_badge_history') }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
                <template v-if="tab==0">
                  <v-col class="px-0">
                    <v-toolbar flat class="py-2">
                      <v-toolbar-title>{{ $t('badges') }}</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <div class=""><v-dialog
                              ref="menu"
                              v-model="menu"
                              :return-value.sync="selectedDates"
                              persistent
                              width="430px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                                  :value="dateRangeText"
                                  :label="$t('date_range')"
                                  hide-details
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="selectedDates"
                                no-title
                                scrollable
                                range
                                width="auto"
                                show-adjacent-months
                                :max="new Date().toISOString().substr(0, 10)"
                        >
                          <v-col
                          >
                            <v-col class="calendar-btn--wrapper" cols="12">
                              <v-btn
                                      :class="{ 'active primary': activePeriodBadges == 'week' }"
                                      @click="setDatesBadges('week')"
                                      class="interval-btn"
                              >{{ $t('week') }}</v-btn
                              >
                              <v-btn
                                      :class="{ 'active primary': activePeriodBadges == 'month' }"
                                      @click="setDatesBadges('month')"
                                      class="interval-btn"
                              >{{ $t('month') }}</v-btn
                              >
                              <v-btn
                                      :class="{ 'active primary': activePeriodBadges == 'year' }"
                                      @click="setDatesBadges('year')"
                                      class="interval-btn"
                              >{{ $t('yeear') }}</v-btn
                              >
                              <v-btn
                                      :class="{ 'active primary': activePeriodBadges == 'all' }"
                                      @click="setDatesBadges('all')"
                                      class="interval-btn"
                              >{{ $t('all_time') }}</v-btn
                              >
                            </v-col>
                            <v-col class="d-flex justify-center" cols="12">
                              <v-btn text color="primary" @click="menu = false">
                                {{ $t('cancel') }}
                              </v-btn>
                              <v-btn text color="primary" @click="saveDates(selectedDates)">
                                {{ $t('ok') }}
                              </v-btn>
                            </v-col></v-col
                          >
                        </v-date-picker>
                      </v-dialog></div>
                      <div class="d-flex">
                        <v-btn
                                class="primary ml-2 export-data-btn"
                                :outlined="false"
                                color="white"
                                :loading="loading"
                                @click="getCsvBadgesStatisticData"
                        >
                          <v-icon class="mr-1">mdi-file-export</v-icon>{{ $t('export_data') }}
                        </v-btn
                        >
                      </div>
                    </v-toolbar>
                    <v-simple-table>
                      <template v-slot:default>

                        <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('badge_name') }}
                          </th>
                          <th class="text-center">
                            {{ $t('earned') }}
                          </th>
                          <th class="text-center">
                            {{ $t('average') }}
                          </th>
                          <th class="text-center">
                            {{ $t('median') }}
                          </th>
                          <th class="text-center">
                            {{ $t('mode') }}
                          </th>
                          <th class="text-center">
                            {{ $t('low') }}
                          </th>
                          <th class="text-center">
                            {{ $t('high') }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="item in badgesList"
                                :key="item.id"
                        >
                          <td>{{ item.name }}</td>
                          <td class="text-center">{{ item.earned }}</td>
                          <td class="text-center">{{ item.average }}</td>
                          <td class="text-center">{{ item.median }}</td>
                          <td class="text-center">{{ item.mode }}</td>
                          <td class="text-center">{{ item.low }}</td>
                          <td class="text-center">{{ item.high }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </template>
            </v-tab-item>
            <v-tab-item>
                <template v-if="tab==1">
                  <v-col class="px-0">
                    <v-data-table
                            class="active-data-table elevation-0"

                            :headers="performanceTableHeaders"
                            :items="performanceDataForTable"
                            :loading="loading"
                            hide-default-footer
                            disable-pagination
                            disable-sort
                            fixed-header
                    >
                      <template v-slot:top>
                        <v-toolbar class="flex-columned-toolbar" height="auto" flat>
                          <template>
                            <v-toolbar-title>{{ $t('performance_history') }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </template>
                          <v-spacer></v-spacer>
                          <div class="py-4">
                            <v-select
                                    hide-details
                                    v-model="periodid"
                                    :items="periods"
                                    :label="$t('period')"
                                    item-text="name"
                                    item-value="id"
                                    @change="selectPeriod"
                            ></v-select>
                          </div>
                          <div class="d-flex">
                            <v-btn
                                    class="primary ml-2 export-data-btn"
                                    :outlined="false"
                                    color="white"
                                    :loading="loading"
                                    @click="getCsvPerformanceData"
                            >
                              <v-icon class="mr-1">mdi-file-export</v-icon>
                              {{ $t('export_data') }}
                            </v-btn
                            >
                          </div>
                        </v-toolbar>
                      </template>
                      <template  v-slot:item="props" v-if="performanceDataForTable.length > 0" >
                        <template v-if="props.item.title=='Score'">
                          <tr>
                            <td> {{ $t('score') }}</td>
                            <td class="text-center" :key="i" v-for="(val,i) in props.item.items" >{{val.score}}</td>
                          </tr>
                        </template>
                        <template v-if="props.item.title=='Coins'">
                          <tr>
                            <td>{{$t('coins') }}</td>
                            <td class="text-center" :key="i" v-for="(val,i) in props.item.items" >{{val.coins}}</td>
                          </tr>
                        </template>
                        <template v-if="props.item.title=='Ranking'">
                          <tr>
                            <td :colspan="performanceTableHeaders.length">{{ $t('ranking') }}</td>
                          </tr>
                          <tr>
                            <td class="text-right">{{ $t('company') }}</td>
                            <td  class="text-center" :key="i" v-for="(val,i) in props.item.items" >{{val.companyRank}}</td>
                          </tr>
                          <tr>
                            <td class="text-right">{{ $t('division') }}</td>
                            <td class="text-center" :key="i" v-for="(val,i) in props.item.items" >{{val.divisionRank}}</td>
                          </tr>
                          <tr>
                            <td class="text-right">{{ $t('location') }}</td>
                            <td class="text-center" :key="i" v-for="(val,i) in props.item.items" >{{val.locationRank}}</td>
                          </tr>
                        </template>
                        <template v-if="props.item.title=='Badges'">
                          <tr>
                            <td :colspan="performanceTableHeaders.length">{{$t('badges')}}</td>
                          </tr>
                          <tr :key="i" v-for="(bd, i) in props.item.items">
                            <td>{{bd.badgeName}}</td>
                            <td class="text-center" :key="i" v-for="(badgeData, i) in bd.badgeInfoList">{{badgeData.count}}</td>
                          </tr>
                        </template>

                      </template>

                    </v-data-table>
                  </v-col>
                </template>
            </v-tab-item>
            <v-tab-item>
                <template v-if="tab==2">
                  <v-col class="px-0">
                    <v-data-table
                            class="active-data-table elevation-0"
                            :mobile-breakpoint="1400"
                            :headers="completeHistoryTableHeaders"
                            :items="completeHistoryTableRows"
                            :loading="loading"
                            disable-sort
                            fixed-header
                            :hide-default-footer="true"
                            :items-per-page="completeHistoryTableItemsPerPage"
                            item-key="dd"
                            :item-class="getCompleteHistoryTableItemClass"
                    >
                      <template v-slot:footer>
                        <v-card>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="setcompleteHistoryTablePage(completeHistoryTablePage + 1)" :disabled="!completeHistoryTableHasNext">Load next 50 rows</v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                      <template v-slot:top>
                        <v-toolbar class="flex-columned-toolbar" height="auto" flat>
                          <template>
                            <v-toolbar-title>{{ $t('complete_badge_history') }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </template>
                          <v-spacer></v-spacer>
                          <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="completeHistoryTableFilterBadgesValue"
                                    :items="completeHistoryTableFilterBadges"
                                    :label="$t('badge_name')"
                                    item-text="value"
                                    item-value="id"
                                    @change="selectCompleteHistoryTableFilterBadgeValue"
                            ></v-select>
                          </v-col>
                          <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="completeHistoryTableFilterRewardedValue"
                                    :items="completeHistoryTableFilterRewarded"
                                    :label="$t('rewardedby')"
                                    item-text="value"
                                    item-value="id"
                                    @change="selectCompleteHistoryTableFilterRewardedValue"
                            ></v-select>
                          </v-col>
                          <v-col class="d-flex col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 col-12"><v-dialog
                                  ref="menu1"
                                  v-model="menu1"
                                  :return-value.sync="completeHistoryTableFilterDates"
                                  persistent
                                  width="430px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                      :value="dateRangeText1"
                                      :label="$t('date_range')"
                                      hide-details
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="completeHistoryTableFilterDates"
                                    no-title
                                    scrollable
                                    range
                                    width="auto"
                                    show-adjacent-months
                                    :max="new Date().toISOString().substr(0, 10)"
                            >
                              <v-col
                              >
                                <v-col class="calendar-btn--wrapper" cols="12">
                                  <v-btn
                                          :class="{ 'active primary': activePeriodBadges1 == 'week' }"
                                          @click="setDatesBadges1('week')"
                                          class="interval-btn"
                                  >{{ $t('week') }}</v-btn
                                  >
                                  <v-btn
                                          :class="{ 'active primary': activePeriodBadges1 == 'month' }"
                                          @click="setDatesBadges1('month')"
                                          class="interval-btn"
                                  >{{ $t('month')}}</v-btn
                                  >
                                  <v-btn
                                          :class="{ 'active primary': activePeriodBadges1 == 'year' }"
                                          @click="setDatesBadges1('year')"
                                          class="interval-btn"
                                  >{{$t('year')}}</v-btn
                                  >
                                  <v-btn
                                          :class="{ 'active primary': activePeriodBadges1 == 'all' }"
                                          @click="setDatesBadges1('all')"
                                          class="interval-btn"
                                  >{{$t('alltime')}}</v-btn
                                  >
                                </v-col>
                                <v-col class="d-flex justify-center" cols="12">
                                  <v-btn text color="primary" @click="menu1 = false">
                                    {{$t('cancel')}}
                                  </v-btn>
                                  <v-btn text color="primary" @click="saveDates1(completeHistoryTableFilterDates)">
                                    {{$t('ok')}}
                                  </v-btn>
                                </v-col></v-col
                              >
                            </v-date-picker>
                          </v-dialog></v-col>
                          <div class="d-flex">
                            <v-btn
                                    class="primary ml-2 export-data-btn"
                                    :outlined="false"
                                    color="white"
                                    :loading="loading"
                                    @click="getCsvCompleteBadgeHistoryData"
                            >
                              <v-icon class="mr-1">mdi-file-export</v-icon>
                              {{$t('export_data')}}
                            </v-btn
                            >
                          </div>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </v-col>
                </template>
            </v-tab-item>
          </v-tabs-items>

        </v-col>
      </v-container>
      <v-container>
      </v-container>
    </v-card>
  </v-layout>
</template>
<script>
import { apiCall } from "@/utils/api.js";
import { mapActions } from "vuex";
export default {
  name: "Driverinfo",
  props: {
    driverid: {
      type: [String,Number],
      require: true,
    },
  },
  data(context) {
    return {
      activePeriodBadges1: null,
      activePeriodBadges: null,
      tab: null,
      loading: true,
      employeeinfo: null,
      selectedDate: new Date().toISOString().substr(0, 10),
      minDate: "2020-01-01",
      maxDate: new Date().toISOString().substr(0, 10),
      badgesList: [],
      visibleBadgesList: false,
      selectedDates: [],
      menu: false,
      menu1: false,
      performanceDataForTable: [],
      performanceTableHeaders: [
        {
          text: "",
          value:"section",
        }
      ],
      periodid: 0,
      periods: [
        {
          name: context.$t('prev_three_months'),
          id: 0,
        },
        {
          name: context.$t('prev_sixst_months'),
          id: 1,
        },
        {
          name: context.$t('prev_twelw_months'),
          id: 2,
        },
        // {
        //   name: "Previous years",
        //   id: 3,
        // }
      ],
      completeHistoryTableHeaders: [
        {
          text: context.$t('date'),
          value:"date",
          align: 'center',
        },
        {
          text: context.$t('name'),
          value:"name",
          align: 'center',
        },
        {
          text: context.$t('score'),
          value:"score",
          align: 'center',
        },
        {
          text: context.$t('coin'),
          value:"coin",
          align: 'center',
        },
        {
          text: context.$t('awarded_by'),
          value:"awardedby",
          align: 'center',
        },
        {
          text: context.$t('remark'),
          value:"remark",
          align: 'center',
        },
      ],
      completeHistoryTableRows: [],
      completeHistoryTableHasNext: false,
      completeHistoryTablePage: 0,
      completeHistoryTableFilterBadges: [],
      completeHistoryTableFilterBadgesValue: 0,
      completeHistoryTableFilterRewarded: [],
      completeHistoryTableFilterRewardedValue: 0,
      completeHistoryTableFilterDates: [],
      completeHistoryTableFilterDate: new Date().toISOString().substr(0, 10),
      completeHistoryTableFilterDateMinDate: "2020-01-01",
      completeHistoryTableFilterDateMaxDate: new Date().toISOString().substr(0, 10),
      completeHistoryTableItemsPerPage: 50
    };
  },
  mounted() {
    this.getDataFromApi();

    const curDate = new Date();
    let end = curDate.setDate(0);
    let start = new Date(end).setDate(1);

    this.selectedDates = [new Date(start).toLocaleDateString('en-GB').split("/").reverse().join("-"), new Date(end).toLocaleDateString('en-GB').split("/").reverse().join("-") ];
    this.completeHistoryTableFilterDates = [...this.selectedDates];
    this.getBadges();
    },
  watch: {
    selectedDate(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getBadges();
      }
    },
    tab(newVal) {
      switch (newVal) {
        case 0:
          this.selectedDate = new Date().toISOString().substr(0, 10);
          this.minDate = "2020-01-01";
          this.maxDate = new Date().toISOString().substr(0, 10);

          // eslint-disable-next-line no-case-declarations
          const curDate = new Date();
          // eslint-disable-next-line no-case-declarations
          let end = curDate.setDate(0);
          // eslint-disable-next-line no-case-declarations
          let start = new Date(end).setDate(1);

          this.selectedDates = [new Date(start).toLocaleDateString('en-GB').split("/").reverse().join("-"), new Date(end).toLocaleDateString('en-GB').split("/").reverse().join("-") ];
          this.getBadges();
          break;
        case 1:
          this.periodid = 0;
          this.getPerformance();
          break;
        case 2:
          this.completeHistoryTableFilterBadgesValue = 0;
          this.completeHistoryTableFilterRewardedValue = 0;
          this.completeHistoryTablePage = 0;
          this.completeHistoryTableFilterDate = new Date().toISOString().substr(0, 10);
          this.completeHistoryTableFilterDateMinDate = "2020-01-01";
          this.completeHistoryTableFilterDateMaxDate = new Date().toISOString().substr(0, 10);

          // eslint-disable-next-line no-case-declarations
          const curDate1 = new Date();
          // eslint-disable-next-line no-case-declarations
          let end1 = curDate1.setDate(0);
          // eslint-disable-next-line no-case-declarations
          let start1 = new Date(end1).setDate(1);

          this.selectedDates = [new Date(start1).toLocaleDateString('en-GB').split("/").reverse().join("-"), new Date(end1).toLocaleDateString('en-GB').split("/").reverse().join("-") ];

          this.getCompleteBadgeHistoryFiltersWithData();
          break;
      }

    }
  },
  computed: {
    datetitle() {
      return `${this.selectedDate.split("-")[1]}/${
        this.selectedDate.split("-")[2]
      }/${this.selectedDate.split("-")[0]}`;
    },
    dateRangeText() {
      const endDate = new Date();
      const firstDate = new Date(2020, 0, 1);
      const arr  = [
        firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
        endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
      ];

      if (this.selectedDates[0] == arr[0] && this.selectedDates[1] == arr[1]) {
        return this.$t('alltime');
      } else {
        return this.selectedDates.join(" ~ ");
      }
    },
    dateRangeText1() {
      const endDate = new Date();
      const firstDate = new Date(2020, 0, 1);
      const arr  = [
        firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
        endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
      ];

      if (this.completeHistoryTableFilterDates[0] == arr[0] && this.completeHistoryTableFilterDates[1] == arr[1]) {
        return this.$t('alltime');
      } else {
        return this.completeHistoryTableFilterDates.join(" ~ ");
      }
    },
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    selectCompleteHistoryTableFilterRewardedValue(){
      this.completeHistoryTablePage = 0;
      this.completeHistoryTableItemsPerPage = 50;
      this.getCompleteBadgeHistoryData();
    },
    selectCompleteHistoryTableFilterBadgeValue(){
      this.completeHistoryTablePage = 0;
      this.completeHistoryTableItemsPerPage = 50;
      this.getCompleteBadgeHistoryData();
    },
    setcompleteHistoryTablePage(val){
      this.completeHistoryTablePage = val;
      this.completeHistoryTableItemsPerPage = 50 * (val+1);
      this.getCompleteBadgeHistoryData(true);
    },
    selectPeriod() {
      this.getPerformance();
    },
    clearDates() {
      this.selectedDates = [...[]];
      this.activePeriodBadges = 'all';
    },
    saveDates(dates) {
      if (dates.length != 2) {
        this.$refs.menu.save([]);
      } else {
        this.$refs.menu.save(this.selectedDates);
        this.getBadges();
      }
    },
    saveDates1(dates) {
      if (dates.length != 2) {
        this.$refs.menu1.save([]);
      } else {
        this.$refs.menu1.save(this.completeHistoryTableFilterDates);
        this.completeHistoryTablePage = 0;
        this.getCompleteBadgeHistoryData();
      }
    },
    setDatesBadges(period) {
      this.activePeriodBadges = period == this.activePeriodBadges ? null : period;

      const endDate = new Date();

      let temp = new Date();

      let firstDate = null;

      switch (period) {
        case "week":
          firstDate = new Date(temp.setDate(temp.getDate() - temp.getDay()));
          this.selectedDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        case "month":
          firstDate = new Date(temp.getFullYear(), temp.getMonth());
          this.selectedDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        case "year":
          firstDate = new Date(temp.getFullYear(), 0);
          this.selectedDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        default:
          firstDate = new Date(2020, 0, 1);
          this.selectedDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
      }
    },
    setDatesBadges1(period) {
      this.activePeriodBadges1 = period == this.activePeriodBadges1 ? null : period;

      const endDate = new Date();

      let temp = new Date();

      let firstDate = null;

      switch (period) {
        case "week":
          firstDate = new Date(temp.setDate(temp.getDate() - temp.getDay()));
          this.completeHistoryTableFilterDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        case "month":
          firstDate = new Date(temp.getFullYear(), temp.getMonth());
          this.completeHistoryTableFilterDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        case "year":
          firstDate = new Date(temp.getFullYear(), 0);
          this.completeHistoryTableFilterDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        default:
          firstDate = new Date(2020, 0, 1);
          this.completeHistoryTableFilterDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
      }
    },
    getDataFromApi() {
      apiCall("get", `drivers/getdriver?driverid=${this.driverid}`, true).then(
        (response) => {
          this.employeeinfo = response.data;
          this.maxDate = response.data.currentdate;
          this.selectedDate = response.data.currentdate;
        }
      );
    },
    getBadges() {
      this.loading = true;

      const params = {
        driverid: this.driverid,
        selectedDates: this.selectedDates,
      };

      apiCall(
        "post",
        `drivers/getbadgesstatistic`,
              {
                data: params,
              },
        true
      ).then((response) => {
        this.badgesList = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },

    getPerformance() {
      this.loading = true;

      apiCall("get", `drivers/getperformance?driverid=${this.driverid}&periodid=${this.periodid}`, true).then((response) => {
        this.performanceTableHeaders = [
          {
            text: "",
            value:"section",
          }
        ];
        response.data.result.tableHeaderList.forEach(e => {
          let val = {
            text: this.$t(e.title.toLowerCase()),
            value: "",
            align: "center"
          };
          this.performanceTableHeaders.push(val);
        });

        this.performanceDataForTable = [
          {
            value: "section",
            title: this.$t('score'),
            items: response.data.result.scores,
          },
          {
            value: "section",
            title: this.$t('coins'),
            items: response.data.result.scores,
          },
          {
            value: "section",
            title: this.$t('ranking'),
            items: response.data.result.rankings,
          },
          {
            value: "section",
            title: this.$t('badges'),
            items: response.data.result.badgesItems,
          },
        ];

      }).finally(() => {
        this.loading = false;
      });
    },
    getCompleteBadgeHistoryFiltersWithData(){
      this.loading = true;

      const params = {
        driverid: this.driverid,
      };

      apiCall(
              "post",
              `drivers/getcompletebadgehistoryfilters`,
              {
                data: params,
              },
              true
      ).then((response) => {

        this.completeHistoryTableRows = response.data.result.data.content || [];
        this.completeHistoryTableFilterBadges = response.data.result.badges;
        this.completeHistoryTableFilterBadgesValue = response.data.result.badges[0].id;

        this.completeHistoryTableFilterRewarded = response.data.result.users;
        this.completeHistoryTableFilterRewardedValue = response.data.result.users[0].id;


        this.completeHistoryTableHasNext = response.data.result.data.last !== true;
      }).finally(() => {
        this.loading = false;
        // this.completeHistoryTableScrollTo();
      });
    },
    getCompleteBadgeHistoryData(addDataToCurrent = false){
      this.loading = true;

      const params = {
        driverid: this.driverid,
        badgeId: this.completeHistoryTableFilterBadgesValue,
        awardedById: this.completeHistoryTableFilterRewardedValue,
        page: this.completeHistoryTablePage,
        selectedDates: this.completeHistoryTableFilterDates
      };

      apiCall(
              "post",
              `drivers/getcompletebadgehistorydata`,
              {
                data: params,
              },
              true
      ).then((response) => {
        if (addDataToCurrent) {
          this.completeHistoryTableRows = [...this.completeHistoryTableRows, ...response.data.result.content];
        }else {
          this.completeHistoryTableRows = response.data.result.content;
        }


        this.completeHistoryTableHasNext = response.data.result.last !== true;
      }).finally(() => {
        this.loading = false;
        this.completeHistoryTableScrollTo();
      });
    },
    getCompleteHistoryTableItemClass() {
      return "complete-history-table-row-class";
    },
    completeHistoryTableScrollTo() {
      const rows  = document.getElementsByClassName("complete-history-table-row-class");
      if (rows.length && this.completeHistoryTablePage  != 0 ) {
        const index = (this.completeHistoryTablePage * 50);
        rows[index].scrollIntoView({ behavior: 'smooth' });
      }
    },
    getCsvCompleteBadgeHistoryData() {
      this.loading = true;

      const params = {
        driverid: this.driverid,
        badgeId: this.completeHistoryTableFilterBadgesValue,
        awardedById: this.completeHistoryTableFilterRewardedValue,
        page: this.completeHistoryTablePage,
        selectedDates: this.completeHistoryTableFilterDates
      };

      apiCall(
              "post",
              `drivers/exportcompletebadgehistorydata`,
              {
                data: params,
              },
              true,
              "blob"
      )
              .then((response) => {
                const url = window.URL.createObjectURL(response.data);

                const dates = this.period =='Alltime' ? 'Alltime' : this.dateRangeText1;

                const filename = dates.concat(" - ", `${this.employeeinfo.firstname}_${this.employeeinfo.lastname}`,".csv");

                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                this.loading = false;
              })
              .catch((e) => {
                console.log(e);
                this.loading = false;
                this.setSnackbar({
                  color: "error",
                  text: this.$t('unknown_error'),
                  toggle: true,
                });
              });
    },
    getCsvBadgesStatisticData() {
      this.loading = true;

      const params = {
        driverid: this.driverid,
        selectedDates: this.selectedDates,
      };

      apiCall(
              "post",
              `drivers/exportbadgestatistic`,
              {
                data: params,
              },
              true,
              "blob"
      )
              .then((response) => {
                const url = window.URL.createObjectURL(response.data);

                const dates = this.period =='Alltime' ? 'Alltime' : this.dateRangeText;

                const filename = dates.concat(" - ", `${this.employeeinfo.firstname}_${this.employeeinfo.lastname}`,".csv");

                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                this.loading = false;
              })
              .catch((e) => {
                console.log(e);
                this.loading = false;
                this.setSnackbar({
                  color: "error",
                  text: this.$t('unknown_error'),
                  toggle: true,
                });
              });
    },
    getCsvPerformanceData() {
      this.loading = true;

      const params = {
        driverid: this.driverid,
        periodid: this.periodid,
      };

      apiCall(
              "post",
              `drivers/exportperformancehistory`,
              {
                params: params,
              },
              true,
              "blob"
      )
              .then((response) => {
                const url = window.URL.createObjectURL(response.data);

                const filename = "Performance".concat(" - ", `${this.employeeinfo.firstname}_${this.employeeinfo.lastname}`,".csv");

                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                this.loading = false;
              })
              .catch((e) => {
                console.log(e);
                this.loading = false;
                this.setSnackbar({
                  color: "error",
                  text: this.$t('unknown_error'),
                  toggle: true,
                });
              });
    }
  },
};
</script>
<style scoped>
  .calendar-btn--wrapper{
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  .dialog-card{
    background-color: white !important;
  }
  @media (max-width: 960px) {
    .badges-list{
      display: none;
    }
    
  }
  .no-arrows-tabs >>> .v-slide-group__prev, .no-arrows-tabs >>> .v-slide-group__next{
    display: none !important;
  }
.divider {
  border-width: 1.5px;
  border-color: black;
}
.score-card {
  font-size: 48px;
  height: 60px;
}
.points-span {
  font-size: 24px;
}
.location {
  font-size: 24px;
}
.employee__name {
  word-break: break-word;
  line-height: initial;
}
.employee__username, .employee__division, .employee__location{
    font-size: 22px;
    color: black;
}
.pagetitle{
    word-break: break-word;
    line-height: 3rem;
    color: var(--v-primary-base);
}

.page-layout /deep/ .v-tabs-bar, .page-layout /deep/ .v-tabs-items{
   background: transparent !important; 
}
</style>