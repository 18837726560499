<template>
  <v-layout>
    <div class="col-12 badges">
      <v-card-title class="page-title"
        >{{ $t('badges') }}
        <v-spacer></v-spacer>
        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
          <v-select
            v-model="selectedDivision"
            :items="divisions"
            label="Division"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-dialog v-model="dialog" max-width="540px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isAdmin" color="primary" dark v-bind="attrs" v-on="on" class="add-badge-btn">
              {{ $t('new badge') }}
            </v-btn>
          </template>
          <v-card>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form @submit.prevent="save">
                <v-card-title class="dialog-title">
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-tabs
                        v-model="badgeItemStep"
                        align-tabs="center"
                >
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab>General info</v-tab>
                  <v-tab :disabled="!isFinishedFirstStep || !validfile">{{ $t('additional_settings') }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="badgeItemStep">
                  <v-tab-item>
                    <v-card-text>
                      <v-container>
                        <div class="mb-5"><validation-provider
                                v-if="renderedfiledrop"
                                ref="fileref"
                                v-slot="{ errors }"
                                :name="$t('image')"
                                rules="required|ext1:jpeg,jpg,png|size:1024"
                        >
                          <v-row class="justify-center">
                            <v-col class="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-8 col-xs-9">

                              <file-drop
                                      ref="filedrop"
                                      @fileselected="logFiles"
                                      :fileurl="editedItem.smallimage || null"
                                      :clear="clear"
                                      :errors="errors"
                              ></file-drop>
                            </v-col>
                          </v-row>
                          <v-row class="justify-center">
                            <v-btn class="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-8 col-xs-9 primary px-3" text @click="()=> libraryDialog = true">
                              {{ $t('choose_from_library') }}</v-btn>
                          </v-row>
                        </validation-provider></div>
                        <v-row>
                          <v-col class="pt-0" cols="12">
                            <validation-provider
                                    v-slot="{ errors }"
                                    :name="$t('badge_name')"
                                    ref="badge"
                                    rules="required"
                            >
                              <v-text-field
                                      :error-messages="errors"
                                      v-model="editedItem.name"
                                      :label="$t('badge_name')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12">
                            <validation-provider
                                    v-slot="{ errors }"
                                    ref="description"
                                    :name="$t('description')"
                                    rules="required|max:200"
                            >
                              <v-textarea
                                      :error-messages="errors"
                                      :label="$t('description')"
                                      v-model="editedItem.description"
                                      no-resize
                                      counter="200"
                                      height="100"
                              ></v-textarea>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12">
                            <validation-provider
                                    v-slot="{ errors }"
                                    ref="points"
                                    :name="$t('points')"
                                    rules="required|integer|min_value:0"
                            >
                              <v-text-field
                                      :error-messages="errors"
                                      type="number"
                                      v-model.number="editedItem.points"
                                      min="0"
                                      :label="$t('points')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="cancel-button px-3" text @click="close">
                        {{ $t('cancel') }}
                      </v-btn>
                      <v-btn
                              :disabled="!isFinishedFirstStep || !validfile"
                              class="confirm-button px-5"
                              text
                              @click="badgeItemStep = 1"
                      >
                        {{ $t('continue') }}
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col v-if="hasBadgesFeed" cols="12">
                              <validation-provider
                                      v-slot="{ errors }"
                                      :name="$t('feed_type')"
                                      rules="required"
                              >
                                <v-select
                                        :error-messages="errors"
                                        v-model="editedItem.feedType"
                                        :items="feedTypes"
                                        item-text="text"
                                        item-value="value"
                                        :label="$t('allow_on_recognition')"
                                        hide-details
                                ></v-select>
                              </validation-provider>
                            </v-col>
                            <v-col v-if="editedItem.type==='MANAGEMENT' || editedIndex === -1" cols="12" class="d-flex">
                              <v-col cols="6">
                                <v-checkbox
                                        class="mt-0"
                                        hide-details
                                        v-model="editedItem.activeMonthlyLimit"
                                        :label="$t('monthly_limit_per_division_is_enabled')"
                                        @change="changeIsActiveMonthlyLimit"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="6">
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Monthly/division limit"
                                        rules="required|min_value:0|integer"
                                >
                                  <v-text-field
                                          :disabled="!editedItem.activeMonthlyLimit"
                                          :error-messages="errors"
                                          v-model="editedItem.monthlyLimit"
                                          :label="$t('monthly_division_limit')"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-col>
                            <v-col v-if="editedItem.type==='MANAGEMENT' || editedIndex === -1" cols="12" class="d-flex">
                              <v-col cols="6">
                                <v-checkbox
                                        class="mt-0"
                                        hide-details
                                        v-model="editedItem.activeDriverLimit"
                                        :label="$t('monthly_limit_per_driver_is_enabled')"
                                        @change="changeIsDriverLimit"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="6">
                                <validation-provider
                                        v-slot="{ errors }"
                                        :name="$t('monthly_driver_limit')"
                                        rules="required|min_value:0|integer"
                                >
                                  <v-text-field
                                          :disabled="!editedItem.activeDriverLimit"
                                          :error-messages="errors"
                                          v-model="editedItem.driverLimit"
                                          :label="$t('monthly_limit_per_driver')"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="blue darken-1" text @click="badgeItemStep = 0"
                        >{{ $t('back') }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn class="cancel-button px-3" text @click="close">
                          {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                                :class="{
                      'confirm-button px-5': !invalid,
                      'disabled px-5': invalid,
                    }"
                                text
                                type="submit"
                                :disabled="invalid"
                                :loading="inuse"
                        >
                          {{ $t('save') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-form>
            </validation-observer>
          </v-card>
        </v-dialog>
        <v-divider class="mx-4 hidden-sm-and-down transparent-divider" inset vertical></v-divider>
        <v-dialog v-model="assigndialog" max-width="920px" height="90%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              raised
              :disabled="
                badges.length === 0 ||
                (activeDivision.drivers.length === 0 && activeDivision.users.length === 0)
              "
              color="primary"
              light
              v-bind="attrs"
              v-on="on"
              class="ml-1 assign-badge-btn"
            >
              {{ $t('assign_badges') }}
            </v-btn>
          </template>
          <v-card>
            <v-stepper v-model="assignstep">
              <v-stepper-header>
                <v-stepper-step :complete="assignstep > 1" step="1">
                  {{ $t('badges_data') }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="assignstep > 2" step="2">
                  {{ $t('users_data') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="assignstep > 3" step="3">
                  {{ $t('info') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="assignstep > 4" step="4">
                  {{ $t('confirmation') }}
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card elevation="0">
                    <v-list dense>
                      <v-subheader>{{ $t('badges') }}</v-subheader>
                      <v-divider></v-divider>
                      <v-list-item-group v-model="assignbadge" color="primary">
                        <v-list-item
                          v-for="item in managementBadges(badges)"
                          :key="item.id"
                          :value="item.id"
                          :disabled="isLimitEnds(item)"
                        >
                          <v-list-item-avatar>
                            <v-img :src="item.smallimage"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-col class="py-0 px-0 d-flex">
                              <v-col class="px-0 py-0">
                                <v-list-item-title
                                        v-text="item.name"
                                ></v-list-item-title>

                                <v-list-item-subtitle
                                        class="text--primary"
                                        :v-text="$t('badgetype', [item.type])"
                                ></v-list-item-subtitle>

                                <v-list-item-subtitle
                                        :v-text="$t('badgepoints', [item.points])"
                                ></v-list-item-subtitle>
                                <v-list-item-subtitle
                                        :v-text="$t('badgecoins', [item.coins.toFixed(2)])"
                                ></v-list-item-subtitle>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col v-if="isLimitEnds(item)" class="p-0 d-flex align-center justify-end">
                                <div>{{ $t('reached_monthly_limit') }}</div>
                              </v-col>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cancel-button px-3"
                      text
                      @click="assigndialog = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      :disabled="assignbadge == null"
                      class="confirm-button px-5"
                      text
                      @click="assignstep = 2"
                    >
                      {{ $t('continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card elevation="0">
                    <v-list dense>
                      <div class="d-flex align-center my-3 flex-wrap">
                        <v-subheader>{{ $t('users') }}</v-subheader>
                        <v-btn @click="changeSort">
                          <v-icon>mdi-arrow-up-down</v-icon>
                          {{ this.sortableDesc ? 'A-Z' : 'Z-A'}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-col  class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 py-0 my-2">
                          <v-select
                                  class="pt-0"
                                  v-model="selectedLocation"
                                  :items="locations"
                                  item-text="name"
                                  item-value="id"
                                  hide-details
                          ></v-select>
                        </v-col>
                        <v-col  class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 py-0 my-2">
                          <v-text-field
                            v-model="search"
                            class="search-field"
                            prepend-icon="mdi-magnify"
                            :label="$t('search')"
                            single-line
                            hide-details
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-btn color="primary" v-if="chooseAllIsAvailable()" @click="chooseAllDrivers"
                          >{{ $t('select_all') }}</v-btn
                        >
                      </div>
                      <v-divider></v-divider>
                      <v-list-item-group
                        :max="maxAvailableLimit"
                        :value="assigndrivers"
                        color="primary"
                        multiple
                      >
                        <v-list-item
                          :disabled="isLimitMonthly() || isLimitDriver(item)"
                          v-for="item in filteredDrivers"
                          :key="item.id"
                          :value="item.id"
                          class="border-bottom"
                          :class="{'v-list-item--active' : isDriverSelected(item.id)}"
                          @click="addDriver(item)"
                        >
                          <v-list-item-avatar>
                            <v-img
                              v-if="
                                item.profilepic != null && item.profilepic != ''
                              "
                              :src="item.profilepic"
                            ></v-img>
                            <v-img v-else src="~@/assets/avatar.jpg"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-col class="d-flex px-0 py-0">
                              <v-col class="p-0 d-flex">
                                <v-list-item-title
                                        v-text="`${item.firstname} ${item.lastname}`"
                                ></v-list-item-title>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col v-if="isLimitMonthly() || isLimitDriver(item)" class="p-0 d-flex align-center justify-end">
                                <div>{{ $t('reached_montly_limit') }}</div>
                              </v-col>
                            </v-col>

                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="toSelectBadge"
                      >{{ $t('back') }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cancel-button px-3"
                      text
                      @click="assigndialog = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      :disabled="assigndrivers.length == 0"
                      class="confirm-button px-5"
                      text
                      @click="assignstep = 3"
                    >
                      {{ $t('continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-card elevation="0">
                    <v-col>
                      <v-row justify="space-around">
                        <v-date-picker
                          class="mx-5"
                          v-model="awardedfor"
                          :max="new Date().toISOString().substr(0, 10)"
                          color="primary"
                          header-color="primary"
                        ></v-date-picker>
                        <v-textarea
                          class="mx-5"
                          :name="$t('comment')"
                          :label="$t('comment')"
                          v-model.trim="commentary"
                          counter="200"
                          height="200"
                          no-resize
                        ></v-textarea>
                      </v-row>
                    </v-col>
                  </v-card>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="assignstep = 2"
                      >{{ $t('back') }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cancel-button px-3"
                      text
                      @click="assigndialog = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      :disabled="awardedfor == null || commentary.length>200"
                      class="confirm-button px-5"
                      text
                      @click="commentaryConfirm"
                      :loading="inuse"
                    >
                      {{ $t('continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="4">
                    <v-card elevation="0">
                      <v-card-text v-if="getAssignedBadge(assignbadge) != undefined"><b>{{ $t('badge')}}:</b> {{getAssignedBadge(assignbadge).name}}</v-card-text>
                    </v-card>
                  <v-divider class="my-2"></v-divider>
                  <v-card elevation="0" v-if="assigndrivers.length>0">
                    <v-card-text><b>{{$t('users')}}:</b> {{getSelectedDrivers()}}</v-card-text>
                  </v-card>
                  <v-divider class="my-2"></v-divider>
                  <v-card elevation="0">
                    <v-card-text><b>{{ $t('date') }}:</b> {{awardedfor|formatted}} </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text><b>{{$t('comment')}}:</b> {{commentary || $t('no_comment') }} </v-card-text>
                  </v-card>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="assignstep = 3"
                    >{{ $t('back') }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cancel-button px-3"
                            text
                            @click="assigndialog = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                            :disabled="awardedfor == null || commentary.length>200"
                            class="confirm-button px-5"
                            text
                            @click="assignbadges"
                            :loading="inuse"
                    >
                      {{ $t('assign') }}
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-container v-bind="{ [`grid-list-md`]: true }" fluid>
        <v-layout row wrap>
          <v-flex
                  v-for="(item, i) in badges" :key="i"
                  lg2 badgecard
          >
            <v-card class="d-flex flex-column pa-2" height="100%">
              <v-img
                      :src="item.smallimage"
                      class="white--text align-end badgecard__image"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      aspect-ratio="1"
                      contain
                      height="150px"
              >
                <template v-slot:placeholder>
                  <v-card
                          color="rgb(0, 0, 0, 0)"
                          class="d-flex justify-center"
                          height="inherit"
                  >
                    <v-icon class="">mdi-image</v-icon>
                  </v-card>
                </template>
              </v-img>

              <v-card-title class="text-break">{{ item.name }}</v-card-title>
              <v-card-subtitle class="pb-0">{{$t('points')}}: {{ item.points }}</v-card-subtitle>
              <v-card-subtitle class="pt-0">{{ $t('coins')}}: {{ item.coins.toFixed(2) }}</v-card-subtitle>
              <v-card-text>{{ item.description }}</v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions
                      v-if="isAdmin"
                      class="card-actions d-flex justify-space-between"
              >
                <v-btn color="primary" @click="editItem(item)" text
                ><v-icon>mdi-pencil</v-icon>
                  {{ $t('edit') }}</v-btn
                >
                <v-btn color="error" @click="deleteItem(item)" text
                ><v-icon>mdi-delete</v-icon>
                  {{ $t('delete') }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <ConfirmationDialog
                :visible="dialogDelete"
                @confirmationdialog:cancelClick="closeDelete"
                @confirmationdialog:confirmClick="deleteItemConfirm"
        >
          <template v-slot:cardText>{{ $t('are_you_sure_delete') }}</template>
        </ConfirmationDialog>
        <ConfirmationDialog
                :visible="showConfirmation"
                @confirmationdialog:cancelClick="showConfirmation = !showConfirmation"
                @confirmationdialog:confirmClick="emptyCommentConfirm"
        >
          <template v-slot:cardText>{{ $t('assign_without_comment') }}</template>
        </ConfirmationDialog>
      </v-container>
      <v-dialog v-model="libraryDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h4 lighten-2"> {{ $t('select_icon') }} </v-card-title>
          <v-card-text class="mt-4 overflow-y-auto" style="min-height: 80%; max-height: 640px">
            <v-list-item-group active-class="green--text" v-model="selectedLibrayFile">
              <v-row>
                <v-col
                        v-for="(item, index) in libraryFiles"
                        :key="index"
                        class="d-flex child-flex"
                        cols="3"
                >
                  <v-list-item class="no-padding select-library-file" :key="item.name" :value="item.url">
                    <v-img
                            :src="item.url"
                            :lazy-src="item.url"
                    >
                    </v-img>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list-item-group>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeLibraryDialog">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" text :disabled="selectedLibrayFile==null" @click="selectFileFromLibrary"> {{ $t('ok') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>
<script>
import { apiCall } from "@/utils/api.js";
import FileDrop from "@/components/FileDrop.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { mapGetters, mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {getFileFromUrl} from "../utils/requests/requests";

export default {
  name: "Badges",
  components: { FileDrop, ValidationObserver, ValidationProvider, ConfirmationDialog },
  data() {
    return {
      inuse: false,
      renderedfiledrop: true,
      validfile: false,
      sortableDesc: true,
      search: null,
      dialog: false,
      assigndialog: false,
      dialogDelete: false,
      loading: false,
      badges: [],
      divisions: [],
      selectedDivision: 0,
      editedIndex: -1,
      clear: false,
      assignstep: 1,
      badgeItemStep: 0,
      assignbadge: null,
      assigndrivers: [],
      locations: [],
      selectedLocation: 0,
      commentary: "",
      awardedfor: new Date().toISOString().substr(0, 10),
      feedTypes: [],
      editedItem: {
        id: 0,
        name: null,
        points: 0,
        description: null,
        divisionid: 0,
        file: null,
        feedType: null,
        monthlyLimit: 0,
        driverLimit: 0,
        activeMonthlyLimit: false,
        activeDriverLimit: false
      },
      defaultItem: {
        id: 0,
        name: null,
        points: 0,
        description: null,
        divisionid: 0,
        file: null,
        feedType: null,
        monthlyLimit: 0,
        driverLimit: 0,
        activeMonthlyLimit: false,
        activeDriverLimit: false
      },
      showConfirmation: false,
      libraryFiles: [],
      selectedLibrayFile: null,
      libraryDialog: false,
    };
  },
  computed: {
    maxAvailableLimit() {
      if (!this.selectedBadge){
        return 0;
      }
      let max = 0;

      if (this.selectedBadge.activeMonthlyLimit) {
        max = this.selectedBadge.assignmentInfo ? this.selectedBadge.monthlyLimit - this.selectedBadge.assignmentInfo.currentvalue : this.selectedBadge.monthlyLimit;
      }else {
        max = this.filteredDrivers.length;
      }
      return max;
    },
    selectedBadge() {
      return this.managementBadges(this.badges).filter(b => b.id === this.assignbadge)[0] || null;
    },
    isFinishedFirstStep() {
      const result = (this.editedItem.name !== null && this.editedItem.name !== '')
              && this.editedItem.description?.length< 200
              && ((parseInt(this.editedItem.points).toString().length === this.editedItem.points.toString().length) || this.editedItem.points === 0);
      return result;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t("new_badge") : this.$t("edit_badge");
    },
    ...mapGetters("auth", ["getProfile"]),
    token() {
      return this.getProfile.token;
    },
    isAdmin() {
      return this.getProfile?.roles.includes("CompanyAdmin");
    },
    hasBadgesFeed() {
      return this.getProfile?.hasBadgesFeed;
    },
    activeDivision() {
      return this.divisions.length > 0
        ? this.divisions.filter(
            (division) => division.id == this.selectedDivision
          )[0]
        : [];
    },
    filteredDrivers(){
      let drivers = this.activeDivision.drivers || [];
      let users = this.activeDivision.users || [];
      let result =  drivers.concat(users);

      if (this.search != null && this.search != "" && this.activeDivision.length!=0){
        if (this.selectedLocation == 0) {
          return this.sortableDesc ? result.filter(item =>
                  item.firstname.toUpperCase().includes(this.search.toUpperCase()) || item.lastname.toUpperCase().includes(this.search.toUpperCase()))
                          .sort((a, b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0)) :
                  result.filter(item =>
                          item.firstname.toUpperCase().includes(this.search.toUpperCase()) || item.lastname.toUpperCase().includes(this.search.toUpperCase()))
                          .sort((a, b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                          .reverse()
        }else {
          return this.sortableDesc ? result.filter(item =>
                  item.locationid == this.selectedLocation &&
                  (item.firstname.toUpperCase().includes(this.search.toUpperCase()) || item.lastname.toUpperCase().includes(this.search.toUpperCase())))
                          .sort((a, b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0)) :
                  result.filter(item =>
                          item.locationid == this.selectedLocation &&
                          (item.firstname.toUpperCase().includes(this.search.toUpperCase()) || item.lastname.toUpperCase().includes(this.search.toUpperCase())))
                          .sort((a, b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                          .reverse()
        }
      }
        
      if (result){
        if (this.selectedLocation == 0) {
          return this.sortableDesc ? result
                          .sort((a,b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                  : result
                          .sort((a,b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                          .reverse()
        }else {
          return this.sortableDesc ? result
                          .filter(e => e.locationid == this.selectedLocation)
                          .sort((a,b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                  : result
                          .filter(e => e.locationid == this.selectedLocation)
                          .sort((a,b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                          .reverse()
        }

      }
       
      return []
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
      this.clear = !this.clear;
    },
    assigndialog(val) {
      val || this.closeAssign();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    selectedDivision() {
      this.getLocations();
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDivisions();
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    changeSort(){
      this.sortableDesc = !this.sortableDesc
    },
    closeLibraryDialog(){
      this.selectedLibrayFile = null;
      this.libraryDialog = false;
    },
    async selectFileFromLibrary() {
      if (this.selectedLibrayFile) {
        const file = await getFileFromUrl(this.selectedLibrayFile, "name.png");
        await this.logFiles([file], this.selectedLibrayFile);
        this.libraryDialog = false;
      }else {
        this.setSnackbar({
          color: "error",
          text: this.$t('unable_to_fetch_icon'),
          toggle: true,
        });
      }
    },
    managementBadges(badges) {
      const management = "MANAGEMENT";
      return badges.filter((badge) => badge.type == management);
    },
    chooseAllDrivers() {
      this.assigndrivers.length != this.filteredDrivers.length
        ? (this.assigndrivers = this.filteredDrivers.map(
            (driver) => driver.id
          ))
        : (this.assigndrivers = []);
    },
    commentaryConfirm() {
      if (this.commentary.length === 0) {
        this.showConfirmation = true;
      }else {
        this.assignstep = 4;
      }
    },
    assignbadges() {
      this.inuse = true;
      let params = {
        badgeid: this.assignbadge,
        drivers: this.assigndrivers,
        awardedfor: new Date(this.awardedfor),
        commentary: this.commentary,
      };

      apiCall(
        "post",
        "badges/assign",
        {
          data: params,
        },
        true
      )
        .then(() => {
          this.setSnackbar({
            color: "success",
            text: this.$t("badge_assigned_successfully"),
            toggle: true,
          });
          this.assigndialog = false;
          this.inuse = false;

          this.getDivisions();
          this.getDataFromApi();
        })
        .catch((error) => {
          this.inuse = false;
          if (error?.response?.data?.message) {
            this.setSnackbar({
              color: "error",
              text: error.response.data,
              toggle: true,
            });
          } else {
            this.setSnackbar({
              color: "error",
              text: this.$t('unknown_error'),
              toggle: true,
            });
          }
        });
    },
    closeAssign() {
      this.$nextTick(() => {
        this.assignstep = 1;
        this.assignbadge = null;
        this.assigndrivers = [];
        this.awardedfor = new Date().toISOString().substr(0, 10);
        this.commentary = "";
        this.sortableDesc = true;
        this.search = null;
        this.selectedLocation = 0;
        this.showConfirmation = false;
      });
    },
    async logFiles(files, selectedFile = null) {

       const result = await this.$refs.fileref.validate(files ? files[0] : null);
      
      this.validfile = result.valid;

      
      if (files && this.validfile) {
        this.editedItem.file = files[0];
        if (selectedFile) {
          this.editedItem.smallimage = selectedFile;
          this.$refs.fileref.reset();
          this.$refs.observer.reset();
          this.clear = true;
          this.renderedfiledrop = false;

          this.$nextTick(() => {
            this.renderedfiledrop = true;
            this.clear = false;
          });
        }
      } else {
        this.editedItem.file = null;
      }
    },
    getDivisions() {
      apiCall("get", `divisions/getcompanydivisions`, true).then((response) => {
        this.divisions = response.data;
        this.selectedDivision = this.divisions[0].id;
        // this.getDataFromApi();
      });
    },
    getLocations() {
      apiCall(
              "get",
              `locations/get?divisionid=${this.selectedDivision}`,
              true
      ).then((response) => {
        this.locations = [{ id: 0, name: this.$t('all_locations') }, ...response.data];
        this.selectedLocation = 0;
      });
    },
    getDataFromApi() {
      this.loading = true;

      apiCall(
        "get",
        `badges/get?divisionid=${this.selectedDivision}`,
        true
      ).then((response) => {
        this.badges = response.data.badges;
        this.libraryFiles = response.data.libraryIcons;
        const feedTypes = response.data.feedTypes;

        feedTypes.forEach(e => {
          switch (e) {
            case "NoneType":
              this.feedTypes.push({
                value: e,
                text: this.$t('none'),
              });
              break;
            case "GlobalType":
              this.feedTypes.push({
                value: e,
                text: this.$t('global'),
              });
              break;
            case "DivisionType":
              this.feedTypes.push({
                value: e,
                text: this.$t('within_division'),
              });
              break;
            case "LocationType":
              this.feedTypes.push({
                value: e,
                text: this.$t('within_home_location'),
              });
              break;
          }
        });

        this.loading = false;
      });
    },
    close() {
      this.dialog = false;
      this.badgeItemStep = 0;
      // this.clear = true;
        this.renderedfiledrop = false;
      this.$nextTick(() => {
        this.clear = true;
        this.$refs.observer.reset();
                this.renderedfiledrop = true;
        this.validfile = false;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.selectedLibrayFile = null;
      });
    },
    deleteItem(item) {

      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // api remove
      this.deleteBadge();
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.editedItem.divisionid = this.selectedDivision;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.editedItem.feedType = item.feedType;
      this.editedItem.driverLimit = item.driverLimit ||  0;
      this.editedItem.monthlyLimit = item.monthlyLimit ||  0;


      this.validfile = item.smallimage != null ? true : false;
      
    },
    save() {
      this.editedItem.divisionid = this.selectedDivision;
      if (this.editedIndex > -1) {
        // edit
        this.editedItem.id = this.editedIndex;
        this.defaultItem.id = this.editedIndex;
        this.updateBadge();
      } else {
        // create
        this.createBadge();
      }
      this.close();
    },
    createBadge() {
      this.loading = true;
      this.inuse = true;
      const params = this.mapItemToPayload(this.editedItem);

      let formData = new FormData();

      for (let key in params) {
        formData.append(key, params[key]);
      }

      const headers = {
        "Content-Type": `multipart/form-data`
      };

      apiCall(
              "post",
              "badges/create",
              formData,
              true,
              null,
              headers,
              false
      )
              .then((response) => {
                this.badges = response.data;
                this.loading = false;
                this.inuse = false;
              })
              .catch((error) => {
                this.inuse = false;
                if (error?.response?.data?.message) {
                  this.setSnackbar({
                    color: "error",
                    text: error.response.data.message,
                    toggle: true,
                  });
                } else {
                  this.setSnackbar({
                    color: "error",
                    text: this.$t("unknown_error"),
                    toggle: true,
                  });
                }
              });
    },
    updateBadge() {
      this.loading = true;
      const params = this.mapItemToPayload(this.editedItem);

      let formData = new FormData();

      for (let key in params) {
        formData.append(key, params[key]);
      }

      const headers = {
        "Content-Type": "multipart/form-data"
      };

      apiCall(
              "post",
              "badges/update",
              formData,
              true,
              null,
              headers,
              false
      )
        .then(() => {
          this.getDataFromApi();
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            this.setSnackbar({
              color: "error",
              text: error.response.data.message,
              toggle: true,
            });
          } else {
            this.setSnackbar({
              color: "error",
              text: this.$t('unknown_error'),
              toggle: true,
            });
          }
        });
    },
    deleteBadge() {
      this.loading = true;
      const params = this.editedItem;

      apiCall("post", "badges/delete", { data: params }, true).then(
        (response) => {
          this.badges = response.data;
          this.loading = false;
        }
      );
    },
    addDriver(item) {
      if (this.isDriverSelected(item.id)) {
        this.assigndrivers = [...this.assigndrivers.filter(el => el !== item.id)];
      }else {
        if (this.assigndrivers.length < this.maxAvailableLimit) {
          this.assigndrivers = [...this.assigndrivers, item.id];
        }
      }
    },
    isDriverSelected(id) {
      return this.assigndrivers.includes(id);
    },
    getAssignedBadge(assignbadge) {
      const badges = this.managementBadges(this.badges);

      return badges.filter(item => item.id == assignbadge)[0];

    },
    getSelectedDrivers() {
      let drivers = this.activeDivision.drivers || [];
      let users = this.activeDivision.users || [];
      return drivers.concat(users)
              .filter(item => this.assigndrivers.includes(item.id))
              .map(e => e.firstname.concat(" ").concat(e.lastname))
              .join(", ");
    },
    emptyCommentConfirm() {
      this.assignstep = 4;
      this.showConfirmation = false;
    },
    changeIsActiveMonthlyLimit(value) {
      if (!value) {
        this.editedItem.monthlyLimit = 0;
      }
    },
    changeIsDriverLimit(value) {
      if (!value) {
        this.editedItem.driverLimit = 0;
      }
    },
    mapItemToPayload(editedItem) {
      let value = {};

      value.id = editedItem.id;
      value.name = editedItem.name;
      value.description = editedItem.description;
      value.points = editedItem.points;
      value.feedType = editedItem.feedType;
      value.divisionid = editedItem.divisionid;
      value.feedType = editedItem.feedType;
      value.activeMonthlyLimit = editedItem.activeMonthlyLimit;
      value.activeDriverLimit = editedItem.activeDriverLimit;

      if (editedItem.file) {
        value.file = editedItem.file;
      }

      if (editedItem.activeMonthlyLimit) {
        value.monthlyLimit = editedItem.monthlyLimit;
      }

      if (editedItem.activeDriverLimit) {
        value.driverLimit = editedItem.driverLimit;
      }

      return value;
    },
    isLimitEnds(item) {
      if (!item){
        return true;
      }

      let result = true;
      if (item.assignmentInfo){
        result = item.assignmentInfo.hasMonthlyLimit === true ? item.assignmentInfo.currentvalue >= item.assignmentInfo.monthlylimit : false;
      }else {
        result = item.monthlylimit > 0;
      }
      return result;
    },
    isLimitMonthly(){
      if (!this.selectedBadge){
        return true;
      }
      let result = true;

      if (this.selectedBadge.assignmentInfo) {
        if ((this.selectedBadge.activeMonthlyLimit && this.selectedBadge.assignmentInfo.currentvalue >= this.selectedBadge.monthlyLimit)){
          result = true;
        }else{
          result = false;
        }
      }else {

        result = this.selectedBadge.monthlyLimit === 0 || this.selectedBadge.driverLimit === 0;
      }

      return result;
    },
    isLimitDriver(item) {
       const assignedBadgesDto = item.assignedBadgesDto;
      if (!assignedBadgesDto){
        return false;
      }
      const record = item.assignedBadgesDto.filter(r => r.badgeid === this.selectedBadge.id)[0] || null ;
      // console.log(record);
      if (!record) {
        return false;
      }

      const badgeCountForUser = record.count;
      // console.log(record.count);
      if (this.selectedBadge.activeDriverLimit && badgeCountForUser >= this.selectedBadge.driverLimit){
        // console.log(444);
        return true;
      }
    },
    toSelectBadge() {
      this.assigndrivers = [];
      this.assignstep = 1;

    },
    chooseAllIsAvailable() {
      if (this.selectedBadge && this.filteredDrivers.length > 0) {
        const availableByDivision = this.selectedBadge.monthlyLimit ? this.selectedBadge.assignmentInfo ? this.selectedBadge.monthlyLimit - this.selectedBadge.assignmentInfo.currentvalue : this.selectedBadge.monthlyLimit : null;

        const availableByDriversLength = this.filteredDrivers.filter( driver => !this.isLimitDriver(driver)).length;

        return availableByDivision ? (availableByDivision > 0 && availableByDivision >= availableByDriversLength && availableByDriversLength == this.filteredDrivers.length) : availableByDriversLength == this.filteredDrivers.length;
      }
      return false;
    }
  },
  filters: {
    formatted(value) {
        let [year, month, day] = value.split("-");
        return `${month}/${day}/${year}`
    }
  }
};
</script>
<style scoped>
  @media (max-width: 400px) {
    .add-badge-btn,.assign-badge-btn{
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
    }

  }

  .page-title{
    justify-content: flex-end;
  }

.transparent-divider {
  border-color: transparent;
}
.search-field{
  margin-top: 0;
  padding-top: 0;
}
.v-list-item-group .v-list-item--active {
  color: deeppink;
  border: 1px solid #ff1493;
}
</style>
<style scoped>
  upload-input >>> .v-text-field__slot {
    min-height: 35px;
  }
  .no-padding {
    padding: 0 !important;
  }
  .select-library-file.v-list-item--active::before,.select-library-file.v-list-item--active:hover::before {
    opacity: 0.45;
    border: 1px solid #ff1493;
  }

  @media (max-width: 1679px) {
    .badges /deep/ .layout .badgecard {
      flex-basis: 25%;
      flex-grow: 0;
      max-width: 25%;
    }
  }

  @media (max-width: 1280px) {
    .badges /deep/ .layout .badgecard {
      flex-basis: 33.3333333333%;
      flex-grow: 0;
      max-width: 33.3333333333%;
    }
  }

  @media (max-width: 1024px) {
    .badges /deep/ .layout .badgecard {
      flex-basis: 33.3333333333%;
      flex-grow: 0;
      max-width: 33.3333333333%;
    }
  }

  @media (max-width: 968px) {
    .badges /deep/ .layout .badgecard {
      flex-basis: 50%;
      flex-grow: 0;
      max-width: 50%;
    }
  }

  @media (max-width: 720px) {
    .badges /deep/ .layout .badgecard {
      flex-basis: 100%;
      flex-grow: 0;
      max-width: 100%;
    }
  }

  .badgecard__image{
    flex-basis: 150px;
    flex-grow: 0;
    max-height: 150px;
  }
  .active-disabled-text >>> textarea {
    color: rgba(0,0,0, 0.87);
  }
</style>
