<template>
  <v-sheet
    id="dropzone"
    ref="dzone"
    tabindex="0"
    color="white"
    width="100%"
    style="cursor: pointer"
    height="200"
    class="pa-2 fileupload--wrapper"
  >
    <validation-provider
      ref="observerfile"
      :name="$t('image')"
      rules="required|ext1:jpeg,jpg,png|size:1024"
      v-slot="{ errors, validate }"
      vid="file"
    >
      <input
        class="fileupload--file"
        ref="upload"
        id="fileUpload"
        type="file"
        @change="validate"
        accept="image/jpeg,image/png,image/jpg"
        :title="filename"
        v-if="rendered"
      />
      <span class="error--text" style="text-align: center">{{
        errors[0]
      }}</span>
    </validation-provider>
    <v-col v-if="!filedata">
      <v-row justify="center">
        <v-icon v-if="!dragover" color="primary" size="75"
          >mdi-cloud-upload-outline</v-icon
        >
        <v-icon v-if="dragover" color="primary " size="75"
          >mdi-book-plus</v-icon
        >
      </v-row>
      <v-row justify="center">
        <span style="text-align: center" class="title primary--text"
          >{{ $t('dragndrop') }}</span
        >
      </v-row>
    </v-col>
    <div v-else class="preview" :style="stylePreview"></div>
  </v-sheet>
</template>
<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    clear: {
      type: Boolean,
      default: false,
    },
    fileurl: {
      type: String,
    },
  },
  data: () => ({
    formUpload: false,
    dragover: false,
    file: null,
    filedata: null,
    filename: null,
    rendered: true,
    mimes: ["image/png", "image/jpg", "image/jpeg"],
    ext: ["png", "jpg", "jpeg"],
  }),
  created() {
    if (this.fileurl != null) {
      this.filedata = this.fileurl;
      this.filename = this.fileurl.split("/").reverse()[0];
    }
    this.file = null;
  },
  mounted() {
    // to register listeners, we have to know the
    // html elements

    const dropzone = this.$el;
    const fileupload = this.$el.firstElementChild;

    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener("dragenter", (e) => {
         
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener("dragleave", (e) => {
        e.preventDefault();
        this.dragover = false;
      });
      dropzone.addEventListener("dragover", (e) => {
        e.preventDefault();
        this.dragover = true;
        this.$refs.observerfile.reset();
      });
      dropzone.addEventListener("drop", (e) => {
        e.preventDefault();
        const dragevent = e;

         this.$refs.observerfile.validate(dragevent.dataTransfer.files[0]);
        if (dragevent.dataTransfer) {
          if (this.validatedata(dragevent.dataTransfer.files)) {
            this.filesSelected(dragevent.dataTransfer.files);
            this.file = dragevent.dataTransfer.files[0];
            this.filename = this.file.name;
            this.readFile(this.file);
          } else {
            this.file = null;
            this.filedata = null;
            this.filename=null;
            this.filesSelected(null);
          }
        }
      });

      // register listeners on the file input
      if (fileupload) {
        fileupload.addEventListener("change", (e) => {
          const target = e.target;

          if (this.validatedata(target.files)) {
            this.filesSelected(target.files);
            this.file = target.files[0];
            this.readFile(this.file);
          } else {
            this.file = null;
            this.filedata = null;
            this.filename=null;
            this.filesSelected(null);
          }
        });
      }
    }
  },
  watch: {
    fileurl(newVal){
      this.filedata = newVal;
    },
    clear(newVal) {
      if (newVal == true) {
        this.filedata = null;
        this.file = null;
        this.filename = null;
        this.rendered = false;

        this.$nextTick(() => {
          this.rendered = true;
        });
      }
    },
  },
  computed: {
    stylePreview() {
      return `background: url(${this.filedata})`;
    },
  },
  methods: {
    validatedata(files) {

      const fileslength = files.length;

      if (fileslength === 1) {
        const size = files[0].size / 1048576;

        if (
          this.mimes.includes(files[0].type) &&
          this.ext.includes(files[0].name.split(".").pop()) &&
          size < 1
        ) {
          return true;
        }
      } else {
        return false;
      }
    },

    filesSelected(fileList) {
      this.$emit("fileselected", fileList);
    },
    readFile(file) {
      const app = this;
      const reader = new FileReader();
      reader.onload = function (event) {
        // app.$refs.myid.attr('src', event.target.result);
        app.filedata = event.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
<style scoped>
.fileupload--wrapper {
  position: relative;
  cursor: pointer;
  z-index: 0;
  border: 1px dashed grey !important;
}
.fileupload--file {
  position: absolute;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  z-index: 1;
  opacity: 0;
}
.preview {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 100%;
}
</style>