<template>
  <v-layout>
    <div class="col-12 leaderboard">
      <v-card-title>{{ $t('leaderboard') }} </v-card-title>
      <v-card>
        <v-card-title>
          <v-dialog
            ref="menu"
            v-model="menu"
            :return-value.sync="selectedDates"
            persistent
            width="430px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                :label="$t('date_range')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearDates"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedDates"
              no-title
              scrollable
              range
              width="auto"
              show-adjacent-months
              :max="new Date().toISOString().substr(0, 10)"
            >
              <v-col
                ><v-col class="leaderboard-calendar-btn--wrapper" cols="12">
                  <v-btn
                    :class="{ 'active primary': activePeriod == 'week' }"
                    @click="setDates('week')"
                    class="interval-btn"
                    >{{$t('week')}}</v-btn
                  >
                  <v-btn
                    :class="{ 'active primary': activePeriod == 'month' }"
                    @click="setDates('month')"
                    class="interval-btn"
                    >{{$t('month')}}</v-btn
                  >
                  <v-btn
                    :class="{ 'active primary': activePeriod == 'year' }"
                    @click="setDates('year')"
                    class="interval-btn"
                    >{{$t('year')}}</v-btn
                  >
                  <v-btn
                    :class="{ 'active primary': activePeriod == 'all' }"
                    @click="setDates('all')"
                    class="interval-btn"
                    >{{$t('alltime')}}</v-btn
                  >
                </v-col>

                <v-col class="d-flex justify-center" cols="12">
                  <v-btn text color="primary" @click="menu = false">
                    {{$t('cancel')}}
                  </v-btn>
                  <v-btn text color="primary" @click="saveDates(selectedDates)">
                    {{$t('ok')}}
                  </v-btn>
                </v-col></v-col
              >
            </v-date-picker>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
            <v-select
              v-if="superadmin"
              v-model="selectedCompany"
              :items="companies"
              :label="$t('company')"
              item-text="name"
              item-value="id"
              hide-details
            ></v-select>
          </v-col>
          <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
            <v-select
              v-model="selectedDivision"
              :items="divisions"
              :label="$t('division')"
              item-text="name"
              item-value="id"
              hide-details
            ></v-select>
          </v-col>
          <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
            <v-select
              v-model="selectedLocation"
              :items="locations"
              :label="$t('location')"
              item-text="name"
              item-value="id"
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" class="d-flex justify-end leaderboard-btn--wrapper">
            <v-btn
                  class="primary show-data-btn"
                  :outlined="false"
                  color="white"
                  :loading="loading"
                  @click="getDataFromApi"
          >{{ $t('show_board_data') }}</v-btn
          >
            <v-btn
                    class="primary ml-2 export-data-btn"
                    :outlined="false"
                    color="white"
                    :loading="loading"
                    @click="getCsvData"
            >
              <v-icon class="mr-1">mdi-file-export</v-icon>{{ $t('export_data') }}
            </v-btn
            ></v-col>
        </v-card-title>
        <v-card v-resize="resizeWindow"  v-if="boardData.length > 0">
          <div class="d-flex" v-if="isMobileSize">
            <v-col cols="12">
              <v-list-item class="heading--item hidden-sm-and-down">
                <div class="col-1 text-center heading--item__position">№</div>
                <div class="col-6 heading--item__name">{{$t('driver')}}</div>
                <div class="col-3 heading--item__name">{{$t('badge_count')}}</div>
                <div class="col-2 heading--item__name">{{ $t('score') }}</div>
              </v-list-item>
              <v-expansion-panels>
                <v-expansion-panel
                        v-for="item in boardData"
                        :key="item.id"
                >
                  <v-expansion-panel-header :hide-actions="true" >
                    <v-list-item
                            class="pl-0 hidden-sm-and-down"
                            :class="{
                      'item--active': selectedDriver == item.driver.id,
                    }"
                            :key="item.id"
                            @click="getDriverData(item)"
                    >
                      <div class="position">{{ item.position }}</div>
                      <v-list-item-avatar>
                        <v-img
                                v-if="item.driver.profilePhotoThumbnail != null"
                                :alt="`${item.name} avatar`"
                                :src="
                          item.driver.profilePhotoThumbnail != null
                            ? item.driver.profilePhotoThumbnail
                            : '~@/assets/avatar.jpg'
                        "
                        ></v-img>
                        <v-img
                                v-else
                                :alt="`${item.name} avatar`"
                                src="~@/assets/avatar.jpg"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content
                              style="min-width: 50%"
                              class="name--wrapper"
                      >
                        <v-list-item-title
                                :title="item.driver.name"
                                class="name--content text-h6"
                                v-text="item.driver.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content class="earnedbadge--wrapper">
                        <v-list-item-title class="earnedbadge--content text-h6">{{
                          item.count
                          }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content class="currentscore--wrapper primary">
                        <v-list-item-title
                                class="currentscore--content text-h6"
                        >{{ item.points }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="hidden-md-and-up"  :key="item.id"
                                 @click="getDriverData(item)">
                      <v-col>
                        <div class="d-flex flex-row justify-space-between mobile-user-info">
                          <div class="font-weight-bold text-h6">№</div>
                          <div class="font-weight-bold text-h6">{{item.position}}</div>
                        </div>
                        <div class="d-flex flex-row justify-space-between mobile-user-info">
                          <div class="font-weight-bold text-subtitle-1 mr-3">{{$t('driver')}}: </div>
                          <div class="text-subtitle-1">{{item.driver.name}}</div>
                        </div>
                        <div class="d-flex flex-row justify-space-between mobile-user-info">
                          <div class="font-weight-bold text-subtitle-1 mr-3">{{$t('badge_count')}}: </div>
                          <div class="text-subtitle-1">{{item.count}}</div>
                        </div>
                        <div class="d-flex flex-row justify-space-between mobile-user-info">
                          <div class="font-weight-bold text-subtitle-1 mr-3">{{$t('score')}}: </div>
                          <div class="text-subtitle-1">{{item.points}}</div>
                        </div>
                      </v-col>
                    </v-list-item>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card v-if="driverData != null" elevation="0">
                      <v-card-text
                              v-if="driverData.badges.length > 0"
                              class="d-flex flex-wrap align-stretch badge-list-items-card"
                      >
                        <v-list-item class="badge-list-items" v-for="item in driverData.badges" :key="item.id">
                          <v-list-item-avatar class="my-0 mr-0 hidden-sm-and-down">
                            <v-img :src="item.smallimage"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content class="name--wrapper"
                          ><v-list-item-title class="name--content">{{
                            item.name
                            }}</v-list-item-title></v-list-item-content
                          >
                          <v-list-item-content class="earnedbadge--wrapper"
                          ><v-list-item-title class="earnedbadge--content"
                          ><span class="hidden-sm-and-down">{{$t('badge_count')}}: </span>{{ item.count }}</v-list-item-title
                          ></v-list-item-content
                          >
                        </v-list-item>
                      </v-card-text>
                      <v-card-text v-else class="pa-5 text-center text-h6">
                        {{ $t('no_data_available') }}
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </div>
          <div class="d-flex" v-else>
            <v-col :cols="selectedDriver == 0 ? 12 : 6">
              <v-list-item class="heading--item">
                <div class="col-1 text-center heading--item__position">№</div>
                <div class="col-6 heading--item__name">{{$t('driver')}}</div>
                <div class="col-3 heading--item__name">{{ $t('badge_count')}}</div>
                <div class="col-2 heading--item__name">{{ $t('score')}}</div>
              </v-list-item>
              <v-virtual-scroll
                      height="560"
                      item-height="56"
                      :items="boardData"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:default="{ item }">
                  <v-list-item
                          class="pl-0"
                          :class="{
                      'item--active': selectedDriver == item.driver.id,
                    }"
                          :key="item.id"
                          @click="getDriverData(item)"
                  >
                    <div class="position">{{ item.position }}</div>
                    <v-list-item-avatar>
                      <v-img
                              v-if="item.driver.profilePhotoThumbnail != null"
                              :alt="`${item.name} avatar`"
                              :src="
                          item.driver.profilePhotoThumbnail != null
                            ? item.driver.profilePhotoThumbnail
                            : '~@/assets/avatar.jpg'
                        "
                      ></v-img>
                      <v-img
                              v-else
                              :alt="`${item.name} avatar`"
                              src="~@/assets/avatar.jpg"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content
                            style="min-width: 50%"
                            class="name--wrapper"
                    >
                      <v-list-item-title
                              :title="item.driver.name"
                              class="name--content text-h6"
                              v-text="item.driver.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content class="earnedbadge--wrapper">
                      <v-list-item-title class="earnedbadge--content text-h6">{{
                        item.count
                        }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content class="currentscore--wrapper primary">
                      <v-list-item-title
                              class="currentscore--content text-h6"
                      >{{ item.points }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-col>
            <v-col elevation-0 cols="6" v-if="driverData != null">
              <v-card elevation="0">
                <v-card-title elevation-0>{{ driverData.driver }}</v-card-title>
                <v-card-text
                        v-if="driverData.badges.length > 0"
                        class="d-flex flex-wrap align-stretch"
                >
                  <v-list-item v-for="item in driverData.badges" :key="item.id">
                    <v-list-item-avatar class="my-0 mr-0">
                      <v-img :src="item.smallimage"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="name--wrapper"
                    ><v-list-item-title class="name--content">{{
                      item.name
                      }}</v-list-item-title></v-list-item-content
                    >
                    <v-list-item-content class="earnedbadge--wrapper"
                    ><v-list-item-title class="earnedbadge--content"
                    >{{$t('badge_count')}}: {{ item.count }}</v-list-item-title
                    ></v-list-item-content
                    >
                  </v-list-item>
                </v-card-text>
                <v-card-text v-else class="pa-5 text-center text-h6">
                  {{$t('no_data_available')}}
                </v-card-text>
              </v-card>
            </v-col>
          </div>
        </v-card>
        <v-card v-else>
          <div class="pa-3" v-if="loading">
            <v-layout align-center justify-center column fill-height>
              <v-progress-circular :size="210" color="primary" indeterminate>
                <v-img
                  alt="RocketStart Logo"
                  class="shrink mr-2"
                  contain
                  src="~@/assets/Logo.png"
                  transition="scale-transition"
                  width="200"
                  position="center -10px"
                />
              </v-progress-circular>
            </v-layout>
          </div>
          <div v-else class="pa-5 text-center text-h6">{{$t('no_data_available')}}</div>
        </v-card>
      </v-card>
    </div>
  </v-layout>
</template>
<script>
import { apiCall } from "@/utils/api.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LeaderBoard",
  data: () => ({
    activePeriod: null,
    companies: [],
    divisions: [],
    locations: [],
    selectedDates: [],
    menu: false,
    selectedCompany: 0,
    selectedDivision: 0,
    selectedLocation: 0,
    selectedDriver: 0,
    loading: false,
    boardData: [],
    driverData: null,
    isMobileSize: false,
    activePanel: null
  }),
  async created() {

    this.resizeWindow();

    this.setDates('month');

    if (this.superadmin) {
      await this.getCompanies();
      this.getDataFromApi();
    } else {
      await this.getCompanyDivisions();
      this.getDataFromApi();
    }
  },
  watch: {
    selectedCompany(newVal) {
        if (newVal != 0) {
            this.getDivisions();
        }else{
            this.selectedDivision = 0;
        }
    },
    selectedDivision(newVal) {
      if(newVal != 0){
         this.getLocations();
      }else{

        this.locations = [{ id: 0, name: "All" }];
        this.selectedLocation = 0;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getProfile"]),
    superadmin() {
      return this.getProfile.roles.includes("Admin");
    },
    dateRangeText() {
       if (this.selectedDates.length == 0) {
        return this.$t('alltime');
      } else {
        return this.selectedDates.join(" ~ ");
      }
    },
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    clearDates() {
      this.selectedDates = [...[]];
      this.activePeriod = "all";
    },
    saveDates(dates) {
      if (dates.length != 2) {
        this.$refs.menu.save([]);
      } else {
        this.$refs.menu.save(this.selectedDates);
      }
    },
    setDates(period) {
      this.activePeriod = period == this.activePeriod ? null : period;

      const endDate = new Date();

      let temp = new Date();

      let firstDate = null;

      switch (period) {
        case "week":
          firstDate = new Date(temp.setDate(temp.getDate() - temp.getDay()));
          this.selectedDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        case "month":
          firstDate = new Date(temp.getFullYear(), temp.getMonth());
          this.selectedDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        case "year":
          firstDate = new Date(temp.getFullYear(), 0);
          this.selectedDates = [
            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
          ];
          break;
        default:
          this.selectedDates = [...[]];
          break;
      }
    },
    getDriverData(item) {
      this.selectedDriver =
        this.selectedDriver == item.driver.id ? 0 : item.driver.id;
      this.selectedDriver != 0 && this.getDriverBadgeData();
    },
    getDriverBadgeData() {
      this.loading = true;

      let params = {
        selectedDates: this.selectedDates,
        selectedDriver: this.selectedDriver,
      };

      apiCall(
        "post",
        "users/leaderboard/user",
        {
          data: params,
        },
        true
      )
        .then((response) => {
          if (response.data.status == true) {
            this.driverData = response.data.result;
            const array = this.driverData.badges;
            array.sort( (a, b) => a.name>b.name);
          } else {
            this.setSnackbar({
              color: "error",
              text: response?.data?.result
                ? response.data.result
                : this.$t('unknown_error'),
              toggle: true,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.setSnackbar({
            color: "error",
            text: this.$t('unknown_error'),
            toggle: true,
          });
        });
    },
    getDataFromApi() {
      this.boardData = [];
      this.loading = true;

      let params = {
        companyid: this.selectedCompany,
        divisionid: this.selectedDivision,
        locationid: this.selectedLocation,
        selectedDates: this.selectedDates,
      };

      apiCall(
        "post",
        "users/leaderboard",
        {
          data: params,
        },
        true
      )
        .then((response) => {
          if (response.data.status == true) {
            let i = 1;
            this.boardData = [];
            this.selectedDriver = 0;
            this.driverData = null;
            response.data.result.forEach((e) => {
              this.boardData.push({
                position: i,
                points: e.points,
                count: e.count,
                driver: e.driver,
              });
              i++;
            });
          } else {
            this.setSnackbar({
              color: "error",
              text: this.$t('unknown_error'),
              toggle: true,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.setSnackbar({
            color: "error",
            text: this.$t('unknown_error'),
            toggle: true,
          });
        });
    },
    getCsvData() {
      this.loading = true;

      let params = {
        companyid: this.selectedCompany,
        divisionid: this.selectedDivision,
        locationid: this.selectedLocation,
        selectedDates: this.selectedDates,
      };

      apiCall(
        "post",
        "users/leaderboard/export",
        {
          data: params,
        },
        true,
        "blob"
      )
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          
          const dates = this.period =='Alltime' ? 'Alltime' : this.dateRangeText;

          const company = this.superadmin ? this.companies.filter(company => company.id == this.selectedCompany)[0].name : this.getProfile.companyname;

          const filename = dates.concat(" - ", company,".csv");

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.setSnackbar({
            color: "error",
            text: this.$t('unknown_error'),
            toggle: true,
          });
        });
    },
    async getCompanies() {
      await apiCall("get", "companies/get", true).then((response) => {
        this.companies = response.data;
        this.selectedCompany =
          this.companies.length > 0 ? this.companies[0].id : 0;
      });
    },
    async getCompanyDivisions() {
      await apiCall("get", `divisions/getcompanydivisions?exclude=leaderboard`, true).then(
        (response) => {
          this.divisions = [{ id: 0, name: "All" }, ...response.data];
          this.selectedDivision = 0;
        }
      );
    },
    getDivisions() {
      apiCall(
        "get",
        `divisions/get?exclude=leaderboard&companyid=${this.selectedCompany}`,
        true
      ).then((response) => {
        this.divisions = [{ id: 0, name: this.$t('all') }, ...response.data];
        this.locations = [{ id: 0, name: this.$t('all')}];
        this.selectedDivision = 0;
      });
    },
    getLocations() {
      apiCall(
        "get",
        `locations/get?divisionid=${this.selectedDivision}`,
        true
      ).then((response) => {
        this.locations = [{ id: 0, name: this.$t('all') }, ...response.data];
        this.selectedLocation = 0;
      });
    },
    resizeWindow() {
      const width = window.innerWidth;
      if (width < 1024){
        this.isMobileSize = true;
      }else {
        this.isMobileSize = false;
      }
    }
  },
};
</script>
<style scoped>
  .leaderboard-calendar-btn--wrapper{
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 480px) {
    .leaderboard-calendar-btn--wrapper{
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      justify-content: space-between;
    }
    .leaderboard-btn--wrapper{
      flex-direction: column;
    }
    .show-data-btn,.export-data-btn{
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
    }

  }
  .v-expansion-panel-header{
    padding: 0;
  }
  @media only screen and (max-width: 959px) {
    .badge-list-items-card{
      padding: 0;
    }
    .badge-list-items{
      padding: 0;
    }
    .badge-list-items .name--wrapper,
    .badge-list-items .name--content,
    .badge-list-items .earnedbadge--wrapper,
    .badge-list-items .earnedbadge--content{
      transform: none;
      border: none;
    }
  }
.heading--item {
  padding-left: 0;
}
.position {
  font-size: 24px;
  font-weight: 500;
  min-width: 50px;
  width: auto;
  padding: 0;
  text-align: center;
}
.v-list-item__avatar {
  z-index: 2;
}
.name--wrapper {
  background: white;
  border: 1px solid gray;
  transform: skew(-30deg);
  padding-left: 30px;
  margin-left: -20px !important;
  z-index: 1;
}
.name--content {
  transform: skew(30deg);
}
.earnedbadge--wrapper {
  background-color: #a0ce5f;
  transform: skew(-30deg);
  display: flex;
  /* padding: 0; */
  justify-content: center;
  /* height: 48px; */
}
.earnedbadge--content {
  text-align: center;
  /* font-size: 24px; */
  transform: skew(30deg);
}
.currentscore--wrapper {
  display: flex;
  /* padding: 0; */
  justify-content: center;
  transform: skew(-30deg);
  /* height: 48px; */
}
.currentscore--content {
  color: white;
  text-align: center;
  transform: skew(30deg);
}
.leaderboard /deep/ .v-virtual-scroll__item:hover {
  background: #ff1493;
  cursor: pointer;
}
.leaderboard /deep/ .v-virtual-scroll__item.item--active {
  background: #ff1493;
}
.v-list-item.item--active .name--wrapper,
.v-list-item.item--active .earnedbadge--wrapper,
.v-list-item.item--active .currentscore--wrapper {
  border-top: 2px solid #ff1493 !important;
  border-bottom: 2px solid #ff1493 !important;
}
</style>