const service = {
    namespaced: true,
    state:{
        snackbar: {
            color: "undefined",
            text: "",
            toggle: false,
            timeout: 2500
          },
    },
    mutations:{
        setSnackbar(state,payload){
            Object.assign(state.snackbar, payload);
        }
    },
    getters:{

    },
    actions:{
        setSnackbar (context,payload){
            context.commit('setSnackbar',payload)
        }
    }
}
export default service