<template>
    <v-layout>
        <div class="col-12">
            <v-data-table
                    mobile-breakpoint="980"
                    :headers="headers"
                    :items="users"
                    :server-items-length="totalUsers"
                    :loading="loading"
                    :footer-props="footerProps"
                    :page="page"
                    :options.sync="options"
                    class="users-table"
            >
                <template v-slot:top>
                    <v-toolbar class="flex-columned-toolbar" height="auto" flat>
                        <v-spacer></v-spacer>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-text-field
                                    v-model="search"
                                    prepend-icon="mdi-magnify"
                                    :label="$t('search')"
                                    single-line
                                    hide-details
                                    clearable
                                    @keyup.enter.prevent="setSearch()"
                                    @click:clear="clearSearch()"
                            ></v-text-field>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="filteredcompany"
                                    :items="allcompanies(companies)"
                                    :label="$t('company')"
                                    item-text="name"
                                    item-value="id"
                                    @change="selectCompany"
                            >
                            </v-select>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="filteredDivision"
                                    :items="filteredDivisions"
                                    :label="$t('division')"
                                    item-text="name"
                                    item-value="id"
                                    @change="selectDivision"
                            ></v-select>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="filteredrole"
                                    :items="allroles(roles)"
                                    :label="$t('role')"
                                    item-text="roletext"
                                    item-value="id"
                                    @change="selectRole"
                            >
                            </v-select>
                        </v-col>

                        <div v-if="userStatus ==='Active' || userStatus ==='Inactive'">
                            <EditUserDialog
                                    :visible="dialog"
                                    :editedIndex="editedIndex"
                                    :user="editedItem"
                                    :selectableroles="selectableroles"
                                    :companies="companies"
                                    :inuse="inuse"
                                    @edituserdialog:showError="showError"
                                    @edituserdialog:cancel="close"
                                    @edituserdialog:create="getDataFromApi"
                            >
                            </EditUserDialog>
                            <v-btn
                                    color="primary"
                                    @click="dialog = true"
                            >
                                {{ $t('new_user') }}
                            </v-btn>
                            <v-dialog v-model="dialogBatch" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                            color="primary"
                                            class="ml-1 upload-users-btn"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                    >
                                        {{ $t('batch_user_import') }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <!-- eslint-disable-next-line -->
                                    <validation-observer ref="observer1" v-slot="{ invalid }">
                                        <v-form @submit.prevent="uploadUsers">
                                            <v-card-title class="dialog-title">
                                                <span class="headline">{{ $t('upload_users') }}</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12" class="d-flex">
                                                            <v-col cols="10" class="pl-0 sample-wrapper">
                              <span class="format-title"
                              >{{ $t('expected_csv')}} :</span
                              >
                                                                <span class="format-text"
                                                                >{{ $t('csv_headers') }}</span
                                                                >
                                                            </v-col>
                                                        </v-col>
                                                        <v-col cols="12">`
                                                            <!-- eslint-disable-next-line -->
                                                            <validation-provider ref="provider" v-slot="{ errors, validate }"
                                                                                 :name="$t('file')"
                                                                                 rules="required|ext:csv"
                                                            >
                                                                <v-file-input
                                                                        v-if="fileinput"
                                                                        required
                                                                        accept=".csv"
                                                                        @change="selectFile"
                                                                        :error-messages="errors"
                                                                        ref="filedata"
                                                                        truncate-length="15"
                                                                ></v-file-input>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider
                                                                    v-slot="{ errors }"
                                                                    :name="$t('company')"
                                                                    rules="required|min_value:1"
                                                            >
                                                                <v-select
                                                                        :error-messages="errors"
                                                                        v-model="selectedBatchCompany"
                                                                        :items="companies"
                                                                        :label="$t('company')"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                ></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider
                                                                    v-slot="{ errors }"
                                                                    :name="$t('division')"
                                                                    rules="required|min_value:1"
                                                            >
                                                                <v-select
                                                                        v-model="selectedBatchDivision"
                                                                        :items="batchDivisions"
                                                                        :label="$t('division')"
                                                                        :error-messages="errors"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                ></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider
                                                                    v-slot="{ errors }"
                                                                    :name="$t('role')"
                                                                    rules="required|min_value:1"
                                                            >
                                                                <v-select
                                                                        v-model="selectedBatchRole"
                                                                        :items="rolesForUpload"
                                                                        :label="$t('division')"
                                                                        :error-messages="errors"
                                                                        item-text="roletext"
                                                                        item-value="id"
                                                                ></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider
                                                                    v-slot="{ errors }"
                                                                    :name="$t('lang')"
                                                                    rules="required"
                                                            >
                                                                <v-select
                                                                        v-model="selectedBatchLang"
                                                                        :items="languages"
                                                                        :label="$t('lang')"
                                                                        :error-messages="errors"
                                                                        item-text="text"
                                                                        item-value="value"
                                                                ></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-checkbox
                                                                    v-model="scheduledInvitation"
                                                                    :label="$t('sheduled_invitation')"
                                                            ></v-checkbox>
                                                        </v-col>
                                                        <template v-if="scheduledInvitation">
                                                            <v-col cols="12">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        :name="$t('campaign_name')"
                                                                        rules="required"
                                                                >
                                                                    <v-text-field
                                                                            :error-messages="errors"
                                                                            v-model="campaignName"
                                                                            :label="$t('campaign_name')"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        :name="$t('scheduled_at')"
                                                                        rules="required"
                                                                >
                                                                    <v-datetime-picker class="scheduled-picker"
                                                                                       :label="$t('select_datetime')"
                                                                                       v-model="scheduledAt"
                                                                                       :aria-errormessage="errors"
                                                                                       :date-picker-props="datePickerProps"
                                                                                       @input="validateScheduledDate"
                                                                                       ref="datePicker"
                                                                                       v-if="visibleDatePicker"
                                                                    >
                                                                        <template v-slot:dateIcon>
                                                                            <v-icon>mdi-calendar</v-icon>
                                                                        </template>
                                                                        <template v-slot:timeIcon>
                                                                            <v-icon>mdi-timer</v-icon>
                                                                        </template>

                                                                    </v-datetime-picker>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        :name="$t('template_id')"
                                                                        rules="numeric|min_value:0"
                                                                >
                                                                    <v-text-field
                                                                            :error-messages="errors"
                                                                            v-model="letterTemplateId"
                                                                            :label="$t('template_id')"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                        class="cancel-button px-3"
                                                        text
                                                        @click="closeBatch"
                                                >
                                                    {{ $t('cancel') }}
                                                </v-btn>
                                                <v-btn
                                                        v-if="scheduledInvitation"
                                                        :class="{
                              'confirm-button px-5': !invalidForm && !invalid && scheduledAt != null,
                              'disabled px-5': invalidForm || invalid ||  scheduledAt == null,
                            }"
                                                        text
                                                        type="submit"
                                                        :disabled="invalidForm || invalid || scheduledAt == null"
                                                        :loading="inuse"
                                                >
                                                    {{ $t('upload') }}
                                                </v-btn>
                                                <v-btn
                                                        v-else
                                                        :class="{
                              'confirm-button px-5 dd': !invalidForm && !invalid,
                              'disabled px-5 f': invalidForm || invalid,
                            }"
                                                        text
                                                        type="submit"
                                                        :disabled="invalidForm || invalid"
                                                        :loading="inuse"
                                                >
                                                    {{ $t('upload') }}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </validation-observer>
                                </v-card>
                            </v-dialog>
                            <v-menu left bottom :offset-y="true">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="user-menu-button" icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-btn
                                                class="rounded-0"
                                                color="secondary"
                                                depressed
                                                @click.prevent="dialogUpload = true"
                                                :disabled="loading"
                                                :loading="loading"
                                        >{{ $t('upload_users_to_awardco') }}
                                        </v-btn
                                        >
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <Confirmationdialog
                                    :visible="dialogUpload"
                                    @confirmationdialog:cancelClick="closeUpload"
                                    @confirmationdialog:confirmClick="uploadConfirm"
                            >
                                <template v-slot:cardText>{{ $t('are_you_sure_upload_to_awardco') }}</template>
                            </Confirmationdialog>
                        </div>

                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline"
                                >{{ $t('are_you_sure_delete') }}
                                </v-card-title
                                >
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete"
                                    >{{ $t('cancel') }}
                                    </v-btn
                                    >
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                                    >{{ $t('ok') }}
                                    </v-btn
                                    >
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.newhire="{ item }">
                    <v-icon v-if="item.newhire" color="#ffb100" class="mr-2">mdi-account-clock</v-icon>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <div v-if="userStatus ==='Active' || userStatus ==='Inactive'">
                        <div v-if="userStatus ==='Active'" class="icon__wrapper mr-2">
                            <v-icon title="Login as" :disabled="inuse" size="19" v-if="hasLoginAs(item)"
                                    @click="loginas(item)">
                                mdi-account-switch-outline
                            </v-icon>
                        </div>
                        <v-menu left bottom :offset-y="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="user-menu-button" icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="editItem(item)">
                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                </v-list-item>
                              <V-list-item v-if="userStatus ==='Active'" @click="requestPasswordReset(item)">
                                <v-list-item-title>{{ $t('request_password_reset') }}</v-list-item-title>
                              </V-list-item>
                              <V-list-item v-if="userStatus ==='Active'" @click="requestPasswordChange(item)">
                                <v-list-item-title>{{ $t('change_password') }}</v-list-item-title>
                              </V-list-item>
                                <v-list-item @click="changeStatus(item)">
                                    <v-list-item-title>{{ userStatus ==='Active' ? $t('make_inactive') : $t('make_active') }}</v-list-item-title>
                                </v-list-item>
                              <v-list-item v-if="item.code !== null" @click="showCode(item.code)">
                                <v-list-item-title>{{ $t('show_code') }}</v-list-item-title>
                              </v-list-item>
                                <v-list-item @click="deleteItem(item)">
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div v-else>
                        <div class="icon__wrapper">
                            <v-icon :title="$t('restore')" :disabled="inuse" size="19"
                                    @click="restoreUser(item)">
                                mdi-backup-restore
                            </v-icon>
                        </div>
                        <div class="icon__wrapper">
                            <v-icon v-if="item.roles!=='Admin'" :title="$t('permanent_delete')" size="19" class="mr-2"
                                    @click="permanentDeleteUser(item)">
                                mdi-delete
                            </v-icon>
                        </div>
                    </div>
                </template>
            </v-data-table>
            <Informationdialog
                    :title="$t('oops')"
                    :text="loginAsErrorText"
                    :button-text="$t('confirm')"
                    :visible="loginAsError"
                    @informationdialog:buttonClick="loginAsError = false"
            />
            <Informationdialog
                    :title="$t('oops')"
                    :text="informationdialogText"
                    :button-text="$t('confirm')"
                    :visible="informationdialogVisible"
                    @informationdialog:buttonClick="informationdialogVisible = !informationdialogVisible"
            />
            <Confirmationdialog
                    :visible="showRestoreConfirmation"
                    @confirmationdialog:cancelClick="showRestoreConfirmation = !showRestoreConfirmation"
                    @confirmationdialog:confirmClick="restoreUserConfirm"
            >
                <template v-slot:cardText>{{ $t('restore_user')}}</template>
            </Confirmationdialog>
            <Confirmationdialog
                    :visible="showPermanentDeleteConfirmation"
                    @confirmationdialog:cancelClick="showPermanentDeleteConfirmation = !showPermanentDeleteConfirmation"
                    @confirmationdialog:confirmClick="permanentDeleteUserConfirm"
            >
                <template v-slot:cardText>{{ $t('delete_user_permanently') }}</template>
            </Confirmationdialog>
            <Confirmationdialog
                    :visible="dialogDelete"
                    @confirmationdialog:cancelClick="closeDelete"
                    @confirmationdialog:confirmClick="deleteItemConfirm"
            >
                <template v-slot:cardText>{{ $t('are_you_sure_delete_user') }}</template>
            </Confirmationdialog>
            <Confirmationdialog
                    :visible="dialogChangeStatus"
                    @confirmationdialog:cancelClick="closeChangeStatus"
                    @confirmationdialog:confirmClick="changeStatusConfirm"
            >
                <template v-slot:cardText>{{ $t('are_you_sure_change_status') }}</template>
            </Confirmationdialog>
          <Confirmationdialog
              :visible="dialogRequestPasswordReset"
              @confirmationdialog:cancelClick="closeRequestPasswordReset"
              @confirmationdialog:confirmClick="requestPasswordResetConfirm"
          >
            <template v-slot:cardText>{{ $t('are_you_sure_request_password_reset') }}</template>
          </Confirmationdialog>
          <Confirmationdialog
              :visible="dialogRequestPasswordChange"
              @confirmationdialog:cancelClick="closeRequestPasswordChange"
              @confirmationdialog:confirmClick="requestPasswordChangeSend"
          >
            <template v-slot:cardText>{{ $t('are_you_sure_password_change') }}</template>
          </Confirmationdialog>
          <v-dialog v-model="dialogPassword" max-width="500px">
            <v-card>
              <validation-observer ref="dialogPassword" v-slot="{ invalid }">
              <v-card-title class="headline primary white--text"
              >{{ $t('change_password') }}
              </v-card-title
              >
              <v-card-text class="pt-4">

                <validation-provider
                    v-slot="{ errors }"
                    :name="$t('new_password')"
                    rules="required"
                >
                <v-text-field
                    v-model="passwordChageData.password"
                    :error-messages="errors"
                    :label="$t('new_password')"
                    required
                ></v-text-field>
                </validation-provider>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="cancel-button px-5" text @click="closeDialogRequestPassword"
                >{{ $t('cancel') }}
                </v-btn
                >
                <v-btn class="confirm-button px-5" :disabled="invalid || loading" text @click="dialogRequestPasswordChange = true"
                >{{ $t('ok') }}
                </v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <Informationdialog
              :title="$t('code')"
              :text="codedialogtText"
              :button-text="$t('ok')"
              :visible="codedialogVisible"
              @informationdialog:buttonClick="codedialogVisible = false"
          />
        </div>
    </v-layout>
</template>
<script>

    import {apiCall} from "@/utils/api.js";
    import {mapGetters, mapActions} from "vuex";
    import {ValidationObserver, ValidationProvider } from "vee-validate";
    import Informationdialog from "@/components/InformationDialog";
    import Confirmationdialog from "@/components/ConfirmationDialog";
    import EditUserDialog from "@/components/EditUserDialog";

    export default {
        name: "Users",
        components: {
            ValidationObserver,
            ValidationProvider,
            Informationdialog,
            Confirmationdialog,
            EditUserDialog,
        },
        props: {
            userStatus: {
                type: String,
                required: true,
                default: "Active"
            }
        },
        data(context) {
            return {
                footerProps: {
                    "items-per-page-options": [10, 20, 50],
                },
                informationdialogVisible: false,
                loginAsErrorText: context.$t('loginas_error'),
                informationdialogText: '',
                showRestoreConfirmation: false,
                showPermanentDeleteConfirmation: false,
                restoredUser: null,
                permanentDeletedUser: null,
                inuse: false,
                loginAsError: false,
                dialog: false,
                dialogBatch: false,
                dialogDelete: false,
                invalidForm: true,
                totalUsers: 0,
                users: [],
                roles: [],
                selectableroles: [],
                companies: [],
                divisions: [],
                batchDivisions: [],
                loading: true,
                options: {
                    sortBy: ["firstname"],
                    sortDesc: [true]
                },
                selectedBatchCompany: 0,
                selectedBatchDivision: 0,
                selectedBatchRole: 4,
                selectedBatchLang: null,
                scheduledInvitation: false,
                campaignName: "",
                scheduledAt: null,
                filteredrole: 0,
                filteredcompany: 0,
                file: null,
                fileinput: true,
                search: null,
                page: 1,
                headers: [
                    {text: "", value: context.$t('newhire'), sortable: false},
                    {
                        text: context.$t('employeeid'),
                        align: "start",
                        value: "employeeid",
                        sortable: true,
                    },
                    {text: context.$t('firstname'), value: "firstname", sortable: true},
                    {text: context.$t('lastname'), value: "lastname", sortable: true},
                    {text: context.$t('email'), value: "username", sortable: true},
                    {text: context.$t('company'), value: "company", sortable: true},
                    {text: context.$t('roles'), value: "roletext", sortable: false},
                    {text: context.$t('date_created'), value: "createddate", sortable: false},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                editedIndex: -1,
                editedItem: {
                    id: -1,
                    username: "",
                    firstname: "",
                    lastname: "",
                    employeeid: "",
                    secondaryid: "",
                    role: 0,
                    companyid: 0,
                    divisionid: 0,
                    locationid: 0,
                    selectedDivisions: [],
                    hireDate: null,
                    birthDate: null,
                    activenewhire: false,
                    newhire: false
                },
                defaultItem: {
                    username: "",
                    firstname: "",
                    lastname: "",
                    employeeid: "",
                    secondaryid: "",
                    role: 0,
                    companyid: 0,
                    divisionid: 0,
                    locationid: 0,
                    selectedDivisions: [],
                    birthDate: null,
                    hireDate: null,
                    activenewhire: false,
                    newhire: false
                },
                datePickerProps: {
                    min: new Date().toISOString().substr(0, 10)
                },
                visibleDatePicker: true,
                letterTemplateId: null,
                searchValue: null,
                filteredDivision: 0,
                filteredDivisions: [{id: 0, name: context.$t('all')},],
                hireDate: null,
                birthDate: null,
                dialogUpload: false,
                dialogChangeStatus: false,
                dialogRequestPasswordReset: false,
                dialogRequestPasswordChange: false,
                dialogPassword: false,
                languages: [
                    {
                        id: 1,
                        value: "en",
                        text: "English"
                    },
                    {
                        id: 2,
                        value: "fr",
                        text: "Français"
                    }
                ],
              passwordChageData: {
                  userId: null,
                  password: ""
              },
              codedialogVisible: false,
              codedialogtText: null,
            };
        },
        computed: {
            rolesForUpload() {
                let result =  [];
                this.roles
                    .filter(e => e.id == 4 || e.id == 6)
                    .forEach((elem) => {
                        switch (elem.name) {
                            case "User":
                                elem.roletext = "Non-Driver user";
                                break;
                            default:
                                elem.roletext = elem.name;
                                break;
                        }
                        result.push(elem);
                    });
               return result;
            },
            ...mapGetters("auth", ["getProfile"]),
            token() {
                return this.getProfile.token;
            },
            rolesforEdit() {
                if (this.editedIndex === -1) {
                    return this.selectableroles
                } else {
                    return this.getProfile.roles.includes("Admin") ?
                        this.selectableroles.filter(item => item.id != 4 && item.id != 6) :
                        this.selectableroles.filter(item => item.id != 4 && item.id != 6 && item.id != 1)
                }

            }
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            selectedBatchCompany(newVal) {
                if (newVal != 0) {
                    this.getBatchDivisions();
                }
            },
            selectedBatchDivision(newVal) {
                if (newVal != 0 && this.file != null) {
                    this.invalidForm = false
                }
            },
            dialogBatch(val) {
                val || this.closeBatch();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        mounted() {
            // this.getDataFromApi();
            this.getCompanies();
            this.getRoles();
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            ...mapActions("auth", ["loginAs"]),
            hasLoginAs(item) {
                let id = item.permissions[0].role.id;

                return id != 1 && id != 4 && id!=6;
            },
            async loginas(item) {
                if (item.iscomplete == false) {
                    this.loginAsError = true;
                    return;
                }

                this.inuse = true;
                let params = {
                    id: item.id
                }

                const {permissions, result} = await this.loginAs(params);

                if (permissions) {
                    const id = permissions[0].role.id;

                    if (id == 2 || id == 3) {
                        this.$router.push("/drivers");
                    } else if (id == 5) {
                        const hasGpsDataPage = this.getProfile?.hasGpsDataPage;
                        const hasLeaderboard = this.getProfile?.hasLeaderboard;
                        if (hasGpsDataPage) {
                            this.$router.push("/gps-data");
                        }
                        if (hasLeaderboard) {
                            this.$router.push("/leaderboard");
                        }
                        this.$router.push("/quizzes");
                    }
                }
                if(result) {
                    this.setSnackbar({
                        color: "error",
                        text: result,
                        toggle: true,
                    });
                }
            },
            clearSearch() {
                this.search = null;
                this.searchValue = this.search;

                this.$nextTick(() => {
                    this.options = {...this.options, page: 1}
                });
            },
            async selectFile(file) {

                const validated = await this.$refs.provider.validate(file);

                if (file != undefined && validated.valid) {
                    this.file = file;
                    const res = await this.$refs.observer1.validate();
                    this.$refs.observer1.reset();
                    if (res)
                        this.invalidForm = false
                    // this.uploadFile();
                } else {
                    this.file = null;
                    this.invalidForm = true
                }
            },
            allroles(roles) {
                let arr = [];
                arr = [
                    {
                        id: 0,
                        name: "All",
                        roletext: this.$t('all'),
                    },
                    ...roles.filter((role) => role.id != 1),
                ];
                return arr;
            },
            allcompanies(companies) {
                let arr = [];
                arr = [
                    {
                        id: 0,
                        name: this.$t('all'),
                    },
                    ...companies,
                ];
                return arr;
            },
            async selectCompany() {
                await this.getFilteredDivisions();
                this.options = {...this.options, page: 1}
            },
            selectDivision() {
                this.options = {...this.options, page: 1}
            },
            selectRole() {
                this.options = {...this.options, page: 1}
            },
            restoreData() {
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.dialog = false;
                });
            },
          showCode(code) {
            this.codedialogVisible = true;
            this.codedialogtText = code;
          },
            deleteUser() {
                this.loading = true;
                const params = {...this.editedItem, status: this.userStatus};

                apiCall(
                    "post",
                    "users/delete",
                    {
                        data: params,
                    },
                    true
                ).then(() => {
                    if (this.users.length == 1 && this.page != 1) {
                        this.page = this.page - 1;
                    }
                    this.getDataFromApi();
                    this.loading = false;
                });
            },
            changeUserStatus() {
                this.loading = true;
                const params = {...this.editedItem, status: this.userStatus};

                apiCall(
                    "post",
                    "users/changestatus",
                    {
                        data: params,
                    },
                    true
                ).then(() => {
                    if (this.users.length == 1 && this.page != 1) {
                        this.page = this.page - 1;
                    }
                    this.setSnackbar({
                        color: "success",
                        text: this.$t('status_changed'),
                        toggle: true,
                    });
                    this.getDataFromApi();
                    this.loading = false;
                });
            },
            getCompanies() {
                apiCall("get", "companies/get", true).then((response) => {
                    this.companies = response.data;
                });
            },
            getRoles() {
                apiCall("get", "roles/get", true).then((response) => {
                    this.roles = response.data;
                    this.selectableroles = response.data;

                    this.selectableroles.forEach((elem) => {
                        switch (elem.name) {
                            case "Admin":
                                elem.roletext = this.$t('super_admin');
                                break;
                            case "CompanyAdmin":
                                elem.roletext = this.$t('company_admin');
                                break;
                            case "User":
                                elem.roletext = this.$t("non_driver_user");
                                break;
                            case "Driver":
                                elem.roletext = this.$t("driver");
                                break;
                            case "Manager":
                                elem.roletext = this.$t("manager");
                                break;
                            case "Supervisor":
                                elem.roletext = this.$t("supervisor");
                                break;
                            default:
                                elem.roletext = elem.name;
                                break;
                        }
                    });
                });
            },
            getDataFromApi() {
                this.loading = true;

                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "firstname",
                    perpage: this.options.itemsPerPage,
                    companyid: this.filteredcompany,
                    divisionid: this.filteredDivision,
                    roleid: this.filteredrole,
                    search: this.searchValue,
                    status: this.userStatus,
                };

                apiCall(
                    "get",
                    "users",
                    {
                        params: params,
                    },
                    true
                ).then((response) => {
                    this.users = response.data.content;

                    this.users.forEach((elem) => {
                        switch (elem.roles) {
                            case "Admin":
                                elem.roletext = this.$t('super_admin');
                                break;
                            case "CompanyAdmin":
                                elem.roletext = this.$t('company_admin');
                                break;
                            case "User":
                                elem.roletext = this.$t("non_driver_user");
                                break;
                            case "Driver":
                                elem.roletext = this.$t("driver");
                                break;
                            case "Manager":
                                elem.roletext = this.$t("manager");
                                break;
                            case "Supervisor":
                                elem.roletext = this.$t("supervisor");
                                break;
                            default:
                                elem.roletext = elem.name;
                                break;
                        }
                    });

                    this.totalUsers = response.data.totalElements;
                    this.loading = false;
                });
            },

            editItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },

            deleteItemConfirm() {
                // api remove
                this.deleteUser();
                this.closeDelete();
            },
            changeStatus(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogChangeStatus = true;
            },
            changeStatusConfirm() {
                this.changeUserStatus();
                this.closeChangeStatus();
            },
            close() {
                this.restoreData();
            },
            closeBatch() {
                this.dialogBatch = false;
                this.fileinput = false;
                this.$refs.filedata.clearableCallback();
                // this.file=null;
                this.$refs.observer1.reset();
                this.$nextTick(() => {
                    this.selectedBatchCompany = 0;
                    this.selectedBatchDivision = 0;
                    this.batchDivisions = [];
                    this.fileinput = true;
                    this.scheduledAt = null;
                    this.scheduledInvitation = false;
                    this.letterTemplateId = null;
                    this.campaignName = "";
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeChangeStatus() {
                this.dialogChangeStatus = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            getBatchDivisions() {
                apiCall(
                    "get",
                    `divisions/get?companyid=${this.selectedBatchCompany}`,
                    true
                ).then((response) => {
                    this.batchDivisions = response.data;
                });
            },
            async getFilteredDivisions() {
                this.filteredDivision = 0;
                apiCall(
                    "get",
                    `divisions/get?companyid=${this.filteredcompany}`,
                    true
                ).then((response) => {

                    this.filteredDivisions = [{id: 0, name: this.$t('all')}, ...response.data];
                });
            },
            uploadUsers() {
                this.inuse = true;

                let params = {};

                if (this.scheduledInvitation) {
                    params = {
                        companyid: this.selectedBatchCompany,
                        divisionid: this.selectedBatchDivision,
                        roleId : this.selectedBatchRole,
                        lang: this.selectedBatchLang,
                        scheduledInvitation: this.scheduledInvitation || false,
                        campaignName: this.campaignName,
                        scheduledAt: this.$refs.datePicker.formattedDatetime,
                        letterTemplateId: this.letterTemplateId || 0
                    }
                } else {
                    params = {
                        companyid: this.selectedBatchCompany,
                        divisionid: this.selectedBatchDivision,
                        roleId : this.selectedBatchRole,
                        lang: this.selectedBatchLang,
                    };
                }

                let formData = new FormData();

                for (let key in params) {
                    formData.append(key, params[key]);
                }

                const headers = {
                    "Content-Type": "multipart/form-data"
                };
                formData.append("file", this.file);

                apiCall(
                    "post",
                    "users/import",
                    formData,
                    true,
                    null,
                    headers,
                    false
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,
                            });
                            this.closeBatch();

                            this.getDataFromApi();
                        } else {
                            this.informationdialogVisible = true;
                            this.informationdialogText = response.data.result;
                        }
                    })
                    .catch(() => {
                        this.inuse = false;
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    });
            },
            validateScheduledDate() {
                if (new Date() > this.scheduledAt) {
                    this.scheduledAt = null;
                    this.visibleDatePicker = false;
                    this.$nextTick(() => {
                        this.visibleDatePicker = true;
                    })
                }
            },
            setSearch() {
                this.searchValue = this.search;
                this.options = {...this.options, page: 1}
            },
            restoreUser(item) {
                this.restoredUser = item;
                this.showRestoreConfirmation = true;
            },
            permanentDeleteUser(item) {
                this.permanentDeletedUser = item;
                this.showPermanentDeleteConfirmation = true;
            },
            restoreUserConfirm() {
                const params = {id: this.restoredUser.id};
                this.inuse = true;

                apiCall(
                    "post",
                    "users/restore",
                    {params: params},
                    true
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,
                            });
                            this.getDataFromApi();
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: response.data.result,
                                toggle: true,
                            });
                        }
                    })
                    .catch(() => {
                        this.inuse = false;
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    })
                    .finally(() => {
                        this.restoredUser = null;
                        this.showRestoreConfirmation = false;
                    });
            },
            permanentDeleteUserConfirm() {
                const params = {id: this.permanentDeletedUser.id};
                this.inuse = true;

                apiCall(
                    "post",
                    "users/permanentdelete",
                    {params: params},
                    true
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,
                            });
                            this.getDataFromApi();
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: response.data.result,
                                toggle: true,
                            });
                        }
                    })
                    .catch(() => {
                        this.inuse = false;
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    })
                    .finally(() => {
                        this.permanentDeletedUser = null;
                        this.showPermanentDeleteConfirmation = false;
                    });
            },
            closeUpload() {
                this.dialogUpload = false;
            },
            uploadConfirm() {
                this.loading = true;

                apiCall("post", "service/awardcoexport", {}, true)
                    .then(response => {
                        if (response.data.status === true) {
                            this.setSnackbar({
                                color: "success",
                                text: this.$t('users_are_uploaded_to_awardco'),
                                toggle: true,
                            })
                            this.dialogUpload = false;
                        }

                    }).catch(e => {
                    console.log(e);
                    this.setSnackbar({
                        color: "error",
                        text: this.$t("something_went_wrong"),
                        toggle: true,
                    })
                }).finally(() => {
                    this.loading = false;
                });
            },
            showError(text) {
                this.informationdialogText = text;
                this.informationdialogVisible = true;
            },
          requestPasswordReset(item) {
            this.editedIndex = item.id;
            this.editedItem = Object.assign({}, item);
            this.dialogRequestPasswordReset = true;
          },
          closeRequestPasswordReset() {
            this.dialogRequestPasswordReset = false;
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem);
              this.editedIndex = -1;
            });
          },
          requestPasswordResetConfirm() {
            this.inuse = true;

            const params = {userId: this.editedItem.id};

            apiCall("post", "users/reset-password-admin", {data: params}, true)
                .then(response => {
                  if (response.data.status === true) {
                    this.setSnackbar({
                      color: "success",
                      text: this.$t(response.data.result),
                      toggle: true,
                    })
                    this.dialogRequestPasswordReset = false;
                    this.getDataFromApi();
                  }

                }).catch(e => {
              console.log(e);
              this.setSnackbar({
                color: "error",
                text: this.$t("something_went_wrong"),
                toggle: true,
              })
            }).finally(() => {
              this.loading = false;
            });
          },
          requestPasswordChange(item) {
            this.passwordChageData.userId = item.id;
            this.dialogPassword = true;
          },
          closeDialogRequestPassword() {
            this.passwordChageData.password = "";
            this.passwordChageData.userId = null;
            this.dialogPassword = false;
            this.$refs.dialogPassword.reset();
          },
          closeRequestPasswordChange() {
            this.dialogRequestPasswordChange = false;
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem);
              this.editedIndex = -1;
            });
          },
          requestPasswordChangeSend() {
            this.inuse = true;

            const params = Object.assign({},this.passwordChageData);

            apiCall("post", "users/set-password-admin", {data: params}, true)
                .then(response => {
                  if (response.data.status === true) {
                    this.setSnackbar({
                      color: "success",
                      text: this.$t(response.data.result),
                      toggle: true,
                    })
                    this.$refs.dialogPassword.reset();
                    this.passwordChageData.password = "";
                    this.passwordChageData.userId = null;
                    this.dialogRequestPasswordChange = false;
                    this.dialogPassword = false;
                    this.getDataFromApi();

                  }

                }).catch(e => {
              console.log(e);
              this.setSnackbar({
                color: "error",
                text: this.$t("something_went_wrong"),
                toggle: true,
              })
            }).finally(() => {
              this.loading = false;
            });
          }
        },
    };
</script>
<style scoped>
    @media (max-width: 400px) {
        .add-user-btn,.upload-users-btn{
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100%;
            margin-top: 4px;
            margin-bottom: 4px;
        }

    }
    @media (max-width: 480px) {
        .users-table /deep/ .v-data-footer{
            justify-content: center;
            padding-bottom: 8px;
        }
        .users-table /deep/ .v-data-footer__pagination{
            display: none;
        }
        .users-table /deep/ .v-data-table__mobile-row__cell{
            overflow-wrap: anywhere;
        }
        .users-table /deep/ .v-data-footer__select .v-input{
            margin: 0 10px !important;
        }

    }

    @media (max-width: 1280px) {
        .flex-columned-toolbar >>> .v-toolbar__content {
            flex-wrap: wrap;
            justify-content: right;
        }

    }

    .icon__wrapper {
        width: 19px;
        display: inline-flex;
        vertical-align: middle;
    }

    .v-data-footer__select {
        display: none !important;
    }

    .format-text {
        font-size: 10px;
    }

    .format-title,
    .format-text {
        display: block;
        color: #000;
    }

    .scheduled-picker /deep/ .v-picker__title {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
</style>
