<template>
  <v-layout v-if="$i18n.locale == 'en'">
    <div class="col-12">
      <v-card class="pb-9">
        <v-card-title class="page-title">ROCKET START PRIVACY POLICY</v-card-title>
        <v-card-subtitle>Last updated November 01, 2023</v-card-subtitle>
        <v-card-text>This privacy notice for Rocket Start LLC ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</v-card-text>
        <ul class="ml-4">
          <li>Visit our website at <a href="http://www.gorocketstart.com">http://www.gorocketstart.com</a> , or any website of ours that links to this privacy notice</li>
          <li>Download and use our mobile application (Rocket Start), or any other application of ours that links to this privacy notice</li>
          <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
        <v-card-text><strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at manny@gorocketstart.com.</v-card-text>

        <v-card elevation="0" id="contact">
          <v-card-title class="mb-0 pb-1">SUMMARY OF KEY POINTS</v-card-title>
          <v-card-text>
            <strong>
              This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <router-link to="/privacy#toc">table of contents</router-link> below to find the section you are looking for.
            </strong>
          </v-card-text>
          <v-card-text>
            <strong>What personal information do we process? </strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <router-link to="/privacy#personal">personal information you disclose to us.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
          </v-card-text>
          <v-card-text>
            <strong>Do we receive any information from third parties?</strong> We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about <router-link to="/privacy/#icfos">information collected from other sources.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <router-link to="/privacy#hwpyi">how we process your information.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Learn more about <router-link to="/privacy#4">when and with whom we share your personal information.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <router-link to="/privacy#8">how we keep your information safe.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your <router-link to="/privacy#10">privacy rights.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a <a href="https://app.termly.io/notify/b14cbc0a-f1f4-43bd-90a3-0a42e2f9b64f">data subject access request</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
          </v-card-text>
          <v-card-text>Want to learn more about what we do with any information we collect? <router-link to="/privacy#table">Review the privacy notice in full.</router-link></v-card-text>
        </v-card>
          <v-card elevation="0" id="table">
            <v-card-title>TABLE OF CONTENTS</v-card-title>

            <ul class="ml-4 mb-8 nav">
              <router-link  class="hashlink mt-2" color="white"  to="#1" tag="li">WHAT INFORMATION DO WE COLLECT?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#2" tag="li">HOW DO WE PROCESS YOUR INFORMATION?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#3" tag="li">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#4" tag="li">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#5" tag="li">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#6" tag="li">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#7" tag="li">HOW LONG DO WE KEEP YOUR INFORMATION?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#8" tag="li">HOW DO WE KEEP YOUR INFORMATION SAFE?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#9" tag="li">DO WE COLLECT INFORMATION FROM MINORS?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#10" tag="li">WHAT ARE YOUR PRIVACY RIGHTS?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#11" tag="li">CONTROLS FOR DO-NOT-TRACK FEATURES</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#12" tag="li">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#13" tag="li">DO WE MAKE UPDATES TO THIS NOTICE?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#14" tag="li">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</router-link>
              <router-link  class="hashlink mt-2" color="white" to="#15" tag="li">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</router-link>
            </ul>
          </v-card>

            <v-card elevation="0" id="1">
              <v-card-title class="mb-0 pb-1">1. WHAT INFORMATION DO WE COLLECT?</v-card-title>
              <v-card-title class="my-0 pt-1" id="personal">Personal information you disclose to us</v-card-title>
              <v-card-text>
                <strong>In Short:</strong> We collect personal information that you provide to us.
              </v-card-text>
              <v-card-text>
                <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                <ul class="pl-5">
                  <li>app usage</li>
                  <li>purchase history</li>
                  <li>passwords</li>
                  <li>financial information (credit card number, purchase history, invoices)</li>
                  <li>names</li>
                  <li>email addresses</li>
                  <li>passwords</li>
                </ul>
              </v-card-text>
              <v-card-text>
                <strong>Sensitive Information.</strong> We do not process sensitive information.
              </v-card-text>
              <v-card-text>
                <strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
                <ul>
                  <li><i>Mobile Device Access.</i> We may request access or permission to certain features from your mobile device, including your mobile device's bluetooth, calendar, camera, contacts, microphone, reminders, sensors, sms messages, social media accounts, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</li>
                  <li><i>Mobile Device Data</i> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.</li>
                  <li><i>Push Notifications.</i> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
                </ul>
              </v-card-text>
              <v-card-text>
                This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
              </v-card-text>
              <v-card-text>
                All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
              </v-card-text>

              <v-card-title class="my-0 pt-1" id="icfos">Information collected from other sources</v-card-title>
              <v-card-text>
                <strong>In Short:</strong> We may collect limited data from public databases, marketing partners, and other outside sources.
              </v-card-text>
              <v-card-text>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.</v-card-text>

            </v-card>

            <v-card elevation="0" id="2">
              <v-card-title>2. HOW DO WE PROCESS YOUR INFORMATION?</v-card-title>
              <v-card-text>
                <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              </v-card-text>
              <v-card-text>
                <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
                <ul>
                  <li><strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                  <li><strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
                </ul>
              </v-card-text>
            </v-card>

            <v-card elevation="0" id="3">
              <v-card-title>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</v-card-title>
              <v-card-text>
                <strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
              </v-card-text>
              <v-card-text>
                <strong><u>If you are located in the EU or UK, this section applies to you.
                </u></strong>
              </v-card-text>
              <v-card-text>
                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                <ul>
                  <li><strong>Consent.</strong> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <router-link to="/privacy#withdrawconsent">withdrawing your consent.</router-link></li>
                  <li><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
                  <li><strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
                </ul>
              </v-card-text>
              <v-card-text><strong><u>If you are located in Canada, this section applies to you.
              </u></strong></v-card-text>
              <v-card-text>
                We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can <router-link to="/privacy#withdrawconsent">withdraw your consent</router-link> at any time.
              </v-card-text>
              <v-card-text>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
                <ul>
                  <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
                  </li>
                  <li>For investigations and fraud detection and prevention
                  </li>
                  <li>For business transactions provided certain conditions are met
                  </li>
                  <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
                  </li>
                  <li>For identifying injured, ill, or deceased persons and communicating with next of kin
                  </li>
                  <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
                  </li>
                  <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
                  </li>
                  <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
                  </li>
                  <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
                  </li>
                  <li>If the collection is solely for journalistic, artistic, or literary purposes
                  </li>
                  <li>If the information is publicly available and is specified by the regulations
                  </li>
                </ul>
              </v-card-text>
            </v-card>

            <v-card elevation="0" id="4">
              <v-card-title>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</v-card-title>
              <v-card-text>
                <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties. </v-card-text>
              <v-card-text>
                We may need to share your personal information in the following situations:
                <ul>
                  <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                  </li>
                  <li><strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests. This includes: driving history, driver delivery locations, driver location, and corresponding dates and times.; __________; and other similar information. A full list of what we use information for can be found in this section and in the previous section titled "<router-link to="/privacy#2">HOW DO WE PROCESS YOUR INFORMATION?</router-link>" We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document. The Google Maps Platform APIs that we use store and access cookies and other information on your devices. If you are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a look at our Cookie Notice.</li>
                  <li><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                  <li><strong>Other Users.</strong> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li>
                  <li><strong>Offer Wall.</strong> Our application(s) may display a third-party hosted "offer wall." Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.</li>
                </ul>
              </v-card-text>
            </v-card>

        <v-card elevation="0" id="5">
          <v-card-title>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</v-card-text>
        </v-card>

        <v-card elevation="0" id="6">
          <v-card-title>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong> We may transfer, store, and process your information in countries other than your own.</v-card-text>
          <v-card-text>
            Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "<router-link to='/privacy#4'>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</router-link>" above), in and other countries.
          </v-card-text>
          <v-card-text>If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="7">
          <v-card-title>7. HOW LONG DO WE KEEP YOUR INFORMATION?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</v-card-text>
          <v-card-text>
            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
          </v-card-text>
          <v-card-text>
            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="8">
          <v-card-title>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</v-card-text>
          <v-card-text>
            We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="9">
          <v-card-title>9. DO WE COLLECT INFORMATION FROM MINORS?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</v-card-text>
          <v-card-text>
            We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="10">
          <v-card-title>10. WHAT ARE YOUR PRIVACY RIGHTS?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</v-card-text>
          <v-card-text>
            In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<router-link to="/privacy#14">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</router-link>" below.
          </v-card-text>
          <v-card-text>We will consider and act upon any request in accordance with applicable data protection laws.</v-card-text>
          <v-card-text>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <a href="https://ec.europa.eu/newsroom/article29/items/612080">Member State data protection authority</a> or <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">UK data protection authority</a>.
          </v-card-text>
          <v-card-text>If you are located in Switzerland, you may contact the <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal Data Protection and Information Commissioner</a>.</v-card-text>
          <v-card-text><strong>Withdrawing your consent</strong>: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "<router-link to="/privacy#14">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</router-link>" below.</v-card-text>
          <v-card-text>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</v-card-text>
          <v-card-title class="my-0 pt-1">Account Information</v-card-title>
          <v-card-text>
            If you would at any time like to review or change the information in your account or terminate your account, you can:
            <ul>
              <li>Contact us using the contact information provided.</li>
              <li>Notify your employer</li>
            </ul>
          </v-card-text>
          <v-card-text>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
          </v-card-text>
          <v-card-text>
            <strong>Cookies and similar technologies</strong>: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
          </v-card-text>
          <v-card-text>If you have questions or comments about your privacy rights, you may email us at manny@gorocketstart.com.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="11">
          <v-card-title >11. CONTROLS FOR DO-NOT-TRACK FEATURES</v-card-title>
          <v-card-text>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="12">
          <v-card-title >12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong>If you are a resident of California, you are granted specific rights regarding access to your personal information.</v-card-text>
            <v-card-text>
              <strong>What categories of personal information do we collect?
              </strong>
            </v-card-text>
          <v-card-text>We have collected the following categories of personal information in the past twelve (12) months:</v-card-text>
          <table class="ml-9">
            <tr>
              <th><strong>Category</strong></th>
              <th><strong>Examples</strong></th>
              <th class="center"><strong>Collected</strong></th>
            </tr>
            <tr>
              <td>A. Identifiers</td>
              <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
              </td>
              <td class="center">YES
              </td>
            </tr>
            <tr>
              <td>
                B. Personal information as defined in the California Customer Records statute</td>
              <td>Name, contact information, education, employment, employment history, and financial information
              </td>
              <td class="center">YES
              </td>
            </tr>
            <tr>
              <td>
                C. Protected classification characteristics under state or federal law</td>
              <td>Gender and date of birth
              </td>
              <td class="center">YES
              </td>
            </tr>
            <tr>
              <td>
                D. Commercial information</td>
              <td>Transaction information, purchase history, financial details, and payment information

              </td>
              <td class="center">NO
              </td>
            </tr>
            <tr>
              <td>
                E. Biometric information</td>
              <td>Fingerprints and voiceprints
              </td>
              <td class="center">NO
              </td>
            </tr>
            <tr>
              <td>
                F. Internet or other similar network activity
              </td>
              <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
              </td>
              <td class="center">NO
              </td>
            </tr>
            <tr>
              <td>
                G. Geolocation data
              </td>
              <td>Device location
              </td>
              <td class="center">NO
              </td>
            </tr>
            <tr>
              <td>
                H. Audio, electronic, visual, thermal, olfactory, or similar information
              </td>
              <td>Images and audio, video or call recordings created in connection with our business activities
              </td>
              <td class="center">NO
              </td>
            </tr>
            <tr>
              <td>
                I. Professional or employment-related information
              </td>
              <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
              </td>
              <td class="center">YES
              </td>
            </tr>
            <tr>
              <td>
                J. Education Information
              </td>
              <td>Student records and directory information
              </td>
              <td class="center">NO
              </td>
            </tr>
            <tr>
              <td>
                K. Inferences drawn from collected personal information
              </td>
              <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
              </td>
              <td class="center">NO
              </td>
            </tr>
            <tr>
              <td>
                L. Sensitive personal Information
              </td>
              <td></td>
              <td class="center">NO
              </td>
            </tr>
          </table>

          <v-card-text class="mt-6">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
            <ul class="ml-2">
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
            </ul>
          </v-card-text>
          <v-card-text><strong>How do we use and share your personal information?
          </strong></v-card-text>
          <v-card-text>Learn about how we use your personal information in the section, "<router-link to="/privacy#2">HOW DO WE PROCESS YOUR INFORMATION?</router-link>"
          </v-card-text>
          <v-card-text><strong>Will your information be shared with anyone else?</strong>
          </v-card-text>
          <v-card-text>
            We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "<router-link to="/privacy#4">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION</router-link>?"
          </v-card-text>
          <v-card-text>
            We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
          </v-card-text>
          <v-card-text>
            We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
          </v-card-text>
          <v-card-title class="my-0 pt-1 pb-1">California Residents</v-card-title>
          <v-card-text>California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
          </v-card-text>
          <v-card-text>
            If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
          </v-card-text>
          <v-card-title class="my-0 pt-1 pb-1">CCPA Privacy Notice</v-card-title>
          <v-card-text>This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.</v-card-text>
          <v-card-text>
            The California Code of Regulations defines a "residents" as:
            <ul class="pl-2" style="list-style-type: none">
              <li>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</li>
              <li>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
            </ul>
          </v-card-text>
          <v-card-text>All other individuals are defined as "non-residents."</v-card-text>
          <v-card-text>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</v-card-text>
          <v-card-title class="my-0 pt-1 pb-1">Your rights with respect to your personal data</v-card-title>
          <v-card-text><u>Right to request deletion of the data — Request to delete
          </u></v-card-text>
          <v-card-text>
            You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
          </v-card-text>
          <v-card-text><u>Right to be informed — Request to know
          </u></v-card-text>
          <v-card-text>
            Depending on the circumstances, you have a right to know:
            <ul>
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>the purposes for which the collected personal information is used;</li>
              <li>whether we sell or share personal information to third parties;
              </li>
              <li>the categories of personal information that we sold, shared, or disclosed for a business purpose;
              </li>
              <li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;
              </li>
              <li>the business or commercial purpose for collecting, selling, or sharing personal information; and
              </li>
              <li>the specific pieces of personal information we collected about you.
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
          </v-card-text>
          <v-card-text>
            <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
            </u>
          </v-card-text>
          <v-card-text>
            We will not discriminate against you if you exercise your privacy rights.
          </v-card-text>
          <v-card-text>
            <u>Right to Limit Use and Disclosure of Sensitive Personal Information
            </u>
          </v-card-text>
          <v-card-text>
            We do not process consumer's sensitive personal information.
          </v-card-text>
          <v-card-text>
            <u>
              Verification process
            </u>
          </v-card-text>
          <v-card-text>
            Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
          </v-card-text>
          <v-card-text>
            We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
          </v-card-text>
          <v-card-text>
            <u>Other privacy rights
            </u>
            <ul>
              <li>You may object to the processing of your personal information.
              </li>
              <li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
              </li>
              <li>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
              </li>
              <li>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            To exercise these rights, you can contact us by submitting a <a href="https://app.termly.io/notify/b14cbc0a-f1f4-43bd-90a3-0a42e2f9b64f">data subject access request</a>, by email at support@gorocketstart.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="13">
          <v-card-title >13. DO WE MAKE UPDATES TO THIS NOTICE?</v-card-title>
          <v-card-text>
            <strong>In Short:</strong> <i>Yes, we will update this notice as necessary to stay compliant with relevant laws.
          </i>
          </v-card-text>
          <v-card-text>
            We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="14">
          <v-card-title >14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</v-card-title>
          <v-card-text>
            If you have questions or comments about this notice, you may email us at manny@gorocketstart.com or contact us by post at:
            <strong class="address">
              <v-card-text>Rocket Start LLC</v-card-text>
              <v-card-text>200 Horizon Dr Suite 100</v-card-text>
              <v-card-text>Raleigh, NC 27614</v-card-text>
              <v-card-text> United States</v-card-text>
              <v-card-text>Phone: 9194382022</v-card-text>
              <v-card-text>manny@gorocketstart.com</v-card-text>
            </strong>
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="15">
          <v-card-title >15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</v-card-title>
          <v-card-text>
            Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a <a href="https://app.termly.io/notify/b14cbc0a-f1f4-43bd-90a3-0a42e2f9b64f">data subject access request</a>.
          </v-card-text>
        </v-card>

      </v-card>
    </div>
  </v-layout>
  <v-layout v-else>
    <div class="col-12">
      <v-card class="pb-9">
        <v-card-title class="page-title">POLITIQUE DE CONFIDENTIALITÉ DE ROCKET START</v-card-title>
        <v-card-subtitle>Dernière mise à jour le 01 novembre 2023</v-card-subtitle>
        <v-card-text>Cet avis de confidentialité pour Rocket Start LLC (« nous », « notre » ou « notre ») décrit comment et pourquoi nous pouvons collecter, stocker, utiliser et/ou partager (« traiter ») vos informations lorsque vous utilisez nos services. (« Services »), par exemple lorsque vous :</v-card-text>
        <ul class="ml-4">
          <li>Visitez notre site Web à l' adresse <a href="http://www.gorocketstart.com">http://www.gorocketstart.com</a>, ou tout autre site Web qui renvoie à cet avis de confidentialité.</li>
          <li>Téléchargez et utilisez notre application mobile (Rocket Start) ou toute autre de nos applications qui renvoie à cet avis de confidentialité.
          </li>
          <li>Interagissez avec nous par d'autres moyens connexes, y compris par des ventes, du marketing ou des événements.
          </li>
        </ul>
        <v-card-text><strong>Questions ou préoccupations?</strong> La lecture de cet avis de confidentialité vous aidera à comprendre vos droits et vos choix en matière de confidentialité. Si vous n'êtes pas d'accord avec nos politiques et pratiques, veuillez ne pas utiliser nos services. Si vous avez encore des questions ou des préoccupations, veuillez nous contacter à manny@gorocketstart.com.</v-card-text>

        <v-card elevation="0" id="contact">
          <v-card-title class="mb-0 pb-1">RÉSUMÉ DES POINTS CLÉS
          </v-card-title>
          <v-card-text>
            <strong>
              Ce résumé fournit les points clés de notre avis de confidentialité, mais vous pouvez trouver plus de détails sur l'un de ces sujets en cliquant sur le lien suivant chaque point clé ou en utilisant notre <router-link to="/privacy#toc">table des matières</router-link> ci-dessous pour trouver la section que vous recherchez.
            </strong>
          </v-card-text>
          <v-card-text>
            <strong>Quelles informations personnelles traitons-nous?  </strong>
            Lorsque vous visitez, utilisez ou naviguez sur nos Services, nous pouvons traiter des informations personnelles en fonction de la manière dont vous interagissez avec nous et les Services, des choix que vous faites et des produits et fonctionnalités que vous utilisez. Apprenez-en davantage sur <router-link to="/privacy#personal">les informations personnelles que vous nous divulguez.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>Traitons-nous des informations personnelles sensibles?</strong> Nous ne traitons pas d'informations personnelles sensibles.
          </v-card-text>
          <v-card-text>
            <strong>Recevons-nous des informations de tiers?</strong>
            Nous pouvons recevoir des informations provenant de bases de données publiques, de partenaires marketing, de plateformes de réseaux sociaux et d'autres sources externes. Apprenez-en davantage sur <router-link to="/privacy/#icfos">les informations collectées à partir d’autres sources</router-link>.
          </v-card-text>
          <v-card-text>
            <strong>Comment traitons-nous vos informations?</strong>
            Nous traitons vos informations pour fournir, améliorer et administrer nos services, communiquer avec vous, pour des raisons de sécurité et de prévention de la fraude, et pour nous conformer à la loi. Nous pouvons également traiter vos informations à d'autres fins avec votre consentement. Nous traitons vos informations uniquement lorsque nous avons une raison légale valable de le faire. Apprenez-en davantage sur <router-link to="/privacy#hwpyi">la manière dont nous traitons vos informations</router-link>.
          </v-card-text>
          <v-card-text>
            <strong>Dans quelles situations et avec quelles parties partageons-nous des informations personnelles?</strong>
            Nous pouvons partager des informations dans des situations spécifiques et avec des tiers spécifiques. Apprenez-en davantage sur <router-link to="/privacy#4">quand et avec qui nous partageons vos informations personnelles.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>Comment protégeons-nous vos informations?</strong>
            Nous avons mis en place des processus et procédures organisationnels et techniques pour protéger vos informations personnelles. Cependant, aucune transmission électronique sur Internet ou aucune technologie de stockage d'informations ne peut être garantie comme étant sécurisée à 100 %. Nous ne pouvons donc pas promettre ou garantir que les pirates informatiques, les cybercriminels ou d'autres tiers non autorisés ne seront pas en mesure de mettre en échec notre sécurité et de collecter, d'accéder de manière inappropriée. , voler ou modifier vos informations. Apprenez-en davantage sur <router-link to="/privacy#8">la façon dont nous protégeons vos informations</router-link>.
          </v-card-text>
          <v-card-text>
            <strong>Quels sont vos droits?</strong>
            Selon l'endroit où vous vous trouvez géographiquement, la loi applicable en matière de confidentialité peut signifier que vous disposez de certains droits concernant vos informations personnelles. Apprenez-en davantage sur vos <router-link to="/privacy#10">droits en matière de confidentialité.</router-link>
          </v-card-text>
          <v-card-text>
            <strong>Comment exercer vos droits?</strong>
            Le moyen le plus simple d'exercer vos droits est de soumettre une <a href="https://app.termly.io/notify/b14cbc0a-f1f4-43bd-90a3-0a42e2f9b64f">demande d'accès à la personne concernée</a> ou de nous contacter. Nous examinerons et répondrons à toute demande conformément aux lois applicables en matière de protection des données.
          </v-card-text>
          <v-card-text>Vous souhaitez en savoir plus sur ce que nous faisons avec les informations que nous collectons? <router-link to="/privacy#table">Consultez l’avis de confidentialité dans son intégralité.</router-link></v-card-text>
        </v-card>
        <v-card elevation="0" id="table">
          <v-card-title>TABLE DES MATIÈRES</v-card-title>

          <ul class="ml-4 mb-8 nav">
            <router-link  class="hashlink mt-2" color="white"  to="#1" tag="li">QUELLES INFORMATIONS COLLECTONS-NOUS?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#2" tag="li">COMMENT TRAITONS-NOUS VOS INFORMATIONS?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#3" tag="li">SUR QUELLES BASES JURIDIQUES NOUS APPUYONS POUR TRAITER VOS INFORMATIONS?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#4" tag="li">QUAND ET AVEC QUI PARTAGEONS-NOUS VOS INFORMATIONS PERSONNELLES?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#5" tag="li">UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#6" tag="li">VOS INFORMATIONS SONT-ELLES TRANSFÉRÉES À L'INTERNATIONAL?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#7" tag="li">COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#8" tag="li">COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#9" tag="li">COLLECTONS-NOUS DES INFORMATIONS SUR LES MINEURS?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#10" tag="li">QUELS SONT VOS DROITS À LA VIE PRIVÉE?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#11" tag="li">COMMANDES POUR LES FONCTIONS NE PAS SUIVI</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#12" tag="li">LES RÉSIDENTS DES ÉTATS-UNIS ONT-ILS DES DROITS SPÉCIFIQUES À LA VIE PRIVÉE?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#13" tag="li">FAISONS-NOUS DES MISES À JOUR À CET AVIS?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#14" tag="li">COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CET AVIS?</router-link>
            <router-link  class="hashlink mt-2" color="white" to="#15" tag="li">COMMENT POUVEZ-VOUS CONSULTER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE NOUS COLLECTONS CHEZ VOUS ?</router-link>
          </ul>
        </v-card>

        <v-card elevation="0" id="1">
          <v-card-title class="mb-0 pb-1">1. QUELLES INFORMATIONS COLLECTONS-NOUS?</v-card-title>
          <v-card-title class="my-0 pt-1" id="personal">Informations personnelles que vous nous divulguez</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> Nous collectons les informations personnelles que vous nous fournissez.
          </v-card-text>
          <v-card-text>
            <strong>Informations personnelles fournies par vous.</strong> Les informations personnelles que nous collectons dépendent du contexte de vos interactions avec nous et les Services, des choix que vous faites et des produits et fonctionnalités que vous utilisez. Les informations personnelles que nous collectons peuvent inclure les éléments suivants:
            <ul class="pl-5">
              <li>utilisation de l'application</li>
              <li>historique d'achat</li>
              <li>mots de passe</li>
              <li>informations financières (numéro de carte de crédit, historique des achats, factures)</li>
              <li>des noms</li>
              <li>adresses mail</li>
              <li>mots de passe</li>
            </ul>
          </v-card-text>
          <v-card-text>
            <strong>Information sensible.</strong> Nous ne traitons pas d'informations sensibles.
          </v-card-text>
          <v-card-text>
            <strong>Application Data.</strong> Si vous utilisez nos applications, nous pouvons également collecter les informations suivantes si vous choisissez de nous fournir un accès ou une autorisation:
            <ul>
              <li><i>Accès aux appareils mobiles.</i> Nous pouvons demander l'accès ou l'autorisation à certaines fonctionnalités de votre appareil mobile, notamment le Bluetooth, le calendrier, l'appareil photo, les contacts, le microphone, les rappels, les capteurs, les messages SMS, les comptes de réseaux sociaux, le stockage et d'autres fonctionnalités. Si vous souhaitez modifier notre accès ou nos autorisations, vous pouvez le faire dans les paramètres de votre appareil.</li>
              <li><i>Données sur les appareils mobiles.</i> Nous collectons automatiquement des informations sur l'appareil (telles que l'identifiant de votre appareil mobile, le modèle et le fabricant), le système d'exploitation, les informations sur la version et les informations de configuration du système, les numéros d'identification de l'appareil et de l'application, le type et la version du navigateur, le modèle de matériel, le fournisseur de services Internet et/ ou opérateur de téléphonie mobile et adresse IP (Internet Protocol) (ou serveur proxy). Si vous utilisez nos applications, nous pouvons également collecter des informations sur le réseau téléphonique associé à votre appareil mobile, le système d'exploitation ou la plate-forme de votre appareil mobile, le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile et des informations. sur les fonctionnalités de nos applications auxquelles vous avez accédé.</li>
              <li><i>Notifications push.</i> Nous pouvons demander de vous envoyer des notifications push concernant votre compte ou certaines fonctionnalités de la ou des applications. Si vous souhaitez refuser de recevoir ces types de communications, vous pouvez les désactiver dans les paramètres de votre appareil.</li>
            </ul>
          </v-card-text>
          <v-card-text>
            Ces informations sont principalement nécessaires pour maintenir la sécurité et le fonctionnement de nos applications, pour le dépannage et à des fins d'analyse et de reporting internes.
          </v-card-text>
          <v-card-text>
            Toutes les informations personnelles que vous nous fournissez doivent être véridiques, complètes et exactes, et vous devez nous informer de toute modification apportée à ces informations personnelles.
          </v-card-text>

          <v-card-title class="my-0 pt-1" id="icfos">Informations collectées à partir d'autres sources</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> nous pouvons collecter des données limitées à partir de bases de données publiques, de partenaires marketing et d'autres sources externes.
          </v-card-text>
          <v-card-text>Afin d'améliorer notre capacité à vous fournir du marketing, des offres et des services pertinents et à mettre à jour nos dossiers, nous pouvons obtenir des informations vous concernant à partir d'autres sources, telles que des bases de données publiques, des partenaires marketing conjoints, des programmes d'affiliation, des fournisseurs de données et d'autres sources. des tiers. Ces informations comprennent les adresses postales, les titres de poste, les adresses e-mail, les numéros de téléphone, les données d'intention (ou les données de comportement des utilisateurs), les adresses IP (Internet Protocol), les profils de réseaux sociaux, les URL de réseaux sociaux et les profils personnalisés, à des fins de publicité ciblée et d'événements. promotion.</v-card-text>
        </v-card>

        <v-card elevation="0" id="2">
          <v-card-title>2. COMMENT TRAITONS-NOUS VOS INFORMATIONS?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> nous traitons vos informations pour fournir, améliorer et administrer nos services, communiquer avec vous, pour des raisons de sécurité et de prévention de la fraude, et pour nous conformer à la loi. Nous pouvons également traiter vos informations à d'autres fins avec votre consentement.
          </v-card-text>
          <v-card-text>
            <strong>Nous traitons vos informations personnelles pour diverses raisons, en fonction de la manière dont vous interagissez avec nos Services, notamment:</strong>
            <ul>
              <li><strong>Pour faciliter la création et l'authentification de comptes et autrement gérer les comptes d'utilisateurs.</strong> Nous pouvons traiter vos informations afin que vous puissiez créer et vous connecter à votre compte, ainsi que maintenir votre compte en état de fonctionnement.</li>
              <li><strong>TSauver ou protéger les intérêts vitaux d’un individu.</strong> Nous pouvons traiter vos informations lorsque cela est nécessaire pour sauvegarder ou protéger les intérêts vitaux d'un individu, par exemple pour éviter tout préjudice.</li>
            </ul>
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="3">
          <v-card-title>3. SUR QUELLES BASES JURIDIQUES NOUS APPUYONS POUR TRAITER VOS INFORMATIONS?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> Nous traitons vos informations personnelles uniquement lorsque nous pensons que cela est nécessaire et que nous avons une raison juridique valable (c'est-à-dire une base juridique) pour le faire en vertu de la loi applicable, comme avec votre consentement, pour nous conformer aux lois, pour vous fournir des services. pour conclure ou remplir nos obligations contractuelles, pour protéger vos droits ou pour répondre à nos intérêts commerciaux légitimes.
          </v-card-text>
          <v-card-text>
            <strong><u>Si vous résidez dans l'UE ou au Royaume-Uni, cette section s'applique à vous.
            </u></strong>
          </v-card-text>
          <v-card-text>
            Le Règlement général sur la protection des données (RGPD) et le RGPD britannique nous obligent à expliquer les bases juridiques valides sur lesquelles nous nous appuyons pour traiter vos informations personnelles. À ce titre, nous pouvons nous appuyer sur les bases juridiques suivantes pour traiter vos informations personnelles:
            <ul>
              <li><strong>Consentement.</strong> Nous pouvons traiter vos informations si vous nous avez donné la permission (c'est-à-dire votre consentement) d'utiliser vos informations personnelles dans un but spécifique. Vous pouvez retirer votre consentement à tout moment. Apprenez-en davantage sur <router-link to="/privacy#withdrawconsent">le retrait de votre consentement.</router-link></li>
              <li><strong>Obligations légales.</strong> Nous pouvons traiter vos informations lorsque nous pensons que cela est nécessaire au respect de nos obligations légales, par exemple pour coopérer avec un organisme chargé de l'application de la loi ou un organisme de réglementation, exercer ou défendre nos droits légaux, ou divulguer vos informations comme preuve dans un litige dans lequel nous sommes. impliqué.</li>
              <li><strong>Intérêts vitaux.</strong> Nous pouvons traiter vos informations lorsque nous estimons que cela est nécessaire pour protéger vos intérêts vitaux ou les intérêts vitaux d'un tiers, par exemple dans des situations impliquant des menaces potentielles pour la sécurité de toute personne.</li>
            </ul>
          </v-card-text>
          <v-card-text><strong><u>Si vous résidez au Canada, cette section s'applique à vous.
          </u></strong></v-card-text>
          <v-card-text>
            Nous pouvons traiter vos informations si vous nous avez donné une autorisation spécifique (c'est-à-dire un consentement exprès) pour utiliser vos informations personnelles dans un but spécifique, ou dans des situations où votre autorisation peut être déduite (c'est-à-dire un consentement implicite). Vous pouvez <router-link to="/privacy#withdrawconsent">retirer votre consentement</router-link> à tout moment.
          </v-card-text>
          <v-card-text>Dans certains cas exceptionnels, nous pouvons être légalement autorisés, en vertu de la loi applicable, à traiter vos informations sans votre consentement, notamment:
            <ul>
              <li>Si la collecte est clairement dans l’intérêt d’une personne et que le consentement ne peut être obtenu en temps opportun
              </li>
              <li>Pour les enquêtes, la détection et la prévention des fraudes
              </li>
              <li>Pour les transactions commerciales sous certaines conditions
              </li>
              <li>S'il est contenu dans une déclaration de témoin et que la collecte est nécessaire pour évaluer, traiter ou régler une réclamation d'assurance
              </li>
              <li>Pour identifier les personnes blessées, malades ou décédées et communiquer avec leurs plus proches parents
              </li>
              <li>Si nous avons des motifs raisonnables de croire qu'une personne a été, est ou pourrait être victime d'exploitation financière
              </li>
              <li>S'il est raisonnable de s'attendre à ce que la collecte et l'utilisation avec consentement compromettent la disponibilité ou l'exactitude des renseignements et que la collecte est raisonnable à des fins liées à une enquête sur une violation d'un accord ou une contravention aux lois du Canada ou d'une province.
              </li>
              <li>Si la divulgation est requise pour se conformer à une assignation à comparaître, un mandat, une ordonnance du tribunal ou des règles du tribunal relatives à la production de dossiers
              </li>
              <li>Si elles ont été produites par un individu dans le cadre de son emploi, de son entreprise ou de sa profession et que la collecte est conforme aux fins pour lesquelles les informations ont été produites
              </li>
              <li>Si la collection est uniquement à des fins journalistiques, artistiques ou littéraires
              </li>
              <li>Si les informations sont accessibles au public et sont spécifiées par la réglementation
              </li>
            </ul>
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="4">
          <v-card-title>4. QUAND ET AVEC QUI PARTAGEONS-NOUS VOS INFORMATIONS PERSONNELLES?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> nous pouvons partager des informations dans des situations spécifiques décrites dans cette section et/ou avec les tiers suivants. </v-card-text>
          <v-card-text>
            Nous pouvons être amenés à partager vos informations personnelles dans les situations suivantes:
            <ul>
              <li><strong>Transferts d'entreprises.</strong> Nous pouvons partager ou transférer vos informations dans le cadre ou pendant les négociations de toute fusion, vente d'actifs de l'entreprise, financement ou acquisition de tout ou partie de notre entreprise à une autre société.
              </li>
              <li><strong>Lorsque nous utilisons les API de Google Maps Platform.</strong>
                Nous pouvons partager vos informations avec certaines API de Google Maps Platform (par exemple, l'API Google Maps, l'API Places). Nous utilisons certaines API de Google Maps Platform pour récupérer certaines informations lorsque vous effectuez des demandes spécifiques à un emplacement. Cela comprend : l'historique de conduite, les lieux de livraison du chauffeur, l'emplacement du chauffeur et les dates et heures correspondantes ; __________ ; et d'autres informations similaires. Une liste complète de la raison pour laquelle nous utilisons les informations peut être trouvée dans cette section et dans la section précédente intitulée «<router-link to="/privacy#2">COMMENT TRAITONS-NOUS VOS INFORMATIONS?</router-link> » Nous obtenons et stockons sur votre appareil (« cache ») votre emplacement. Vous pouvez révoquer votre consentement à tout moment en nous contactant aux coordonnées fournies à la fin de ce document. Les API de Google Maps Platform que nous utilisons stockent et accèdent aux cookies et autres informations sur vos appareils. Si vous êtes actuellement un utilisateur dans l'Espace économique européen (pays de l'UE, Islande, Liechtenstein et Norvège) ou au Royaume-Uni, veuillez consulter notre Avis relatif aux cookies.
              </li>
              <li><strong>Partenaires d'affaires.</strong> Nous pouvons partager vos informations avec nos partenaires commerciaux pour vous proposer certains produits, services ou promotions.</li>
              <li><strong>Autres utilisateurs.</strong>Lorsque vous partagez des informations personnelles (par exemple, en publiant des commentaires, des contributions ou tout autre contenu sur les Services) ou que vous interagissez de toute autre manière avec les zones publiques des Services, ces informations personnelles peuvent être consultées par tous les utilisateurs et peuvent être rendues publiques en dehors des Services. à perpétuité. De même, les autres utilisateurs pourront consulter les descriptions de votre activité, communiquer avec vous au sein de nos Services et consulter votre profil.</li>
              <li><strong>Mur d'offres.</strong> Nos applications peuvent afficher un « mur d'offres » hébergé par un tiers. Un tel mur d'offres permet à des annonceurs tiers d'offrir de la monnaie virtuelle, des cadeaux ou d'autres articles aux utilisateurs en échange de l'acceptation et de la réalisation d'une offre publicitaire. Un tel mur d'offres peut apparaître dans notre (nos) application (s) et vous être affiché en fonction de certaines données, telles que votre zone géographique ou des informations démographiques. Lorsque vous cliquez sur un mur d'offres, vous serez redirigé vers un site Web externe appartenant à d'autres personnes et quitterez notre (nos) application (s). Un identifiant unique, tel que votre identifiant d'utilisateur, sera partagé avec le fournisseur du mur d'offres afin de prévenir la fraude et de créditer correctement votre compte avec la récompense correspondante</li>
            </ul>
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="5">
          <v-card-title>5. UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> nous pouvons utiliser des cookies et d'autres technologies de suivi pour collecter et stocker vos informations.</v-card-text>
        </v-card>

        <v-card elevation="0" id="6">
          <v-card-title>6. VOS INFORMATIONS SONT-ELLES TRANSFÉRÉES À L'INTERNATIONAL?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> nous pouvons transférer, stocker et traiter vos informations dans des pays autres que le vôtre.</v-card-text>
          <v-card-text>
            Nos serveurs sont situés. Si vous accédez à nos services de l'extérieur, sachez que vos informations peuvent être transférées, stockées et traitées par nous dans nos installations et par les tiers avec lesquels nous pouvons partager vos informations personnelles (voir "<router-link to='/privacy#4'>QUAND ET AVEC QUI PARTAGONS-NOUS VOS INFORMATIONS PERSONNELLES?</router-link>" ci-dessus), dans et dans d'autres pays.
          </v-card-text>
          <v-card-text>Si vous résidez dans l'Espace économique européen (EEE), au Royaume-Uni (Royaume-Uni) ou en Suisse, ces pays ne disposent pas nécessairement de lois sur la protection des données ou d'autres lois similaires aussi complètes que celles de votre pays. Cependant, nous prendrons toutes les mesures nécessaires pour protéger vos informations personnelles conformément au présent avis de confidentialité et à la loi applicable.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="7">
          <v-card-title>7. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> nous conservons vos informations aussi longtemps que nécessaire pour atteindre les objectifs décrits dans le présent avis de confidentialité, sauf disposition contraire de la loi.</v-card-text>
          <v-card-text>
            Nous ne conserverons vos informations personnelles que le temps nécessaire aux fins énoncées dans le présent avis de confidentialité, à moins qu'une période de conservation plus longue ne soit requise ou autorisée par la loi (telle que la fiscalité, la comptabilité ou d'autres exigences légales). Aucun objectif de cet avis ne nous obligera à conserver vos informations personnelles plus longtemps que la période pendant laquelle les utilisateurs ont un compte chez nous.
          </v-card-text>
          <v-card-text>
            Lorsque nous n'avons aucun besoin professionnel légitime de traiter vos informations personnelles, nous supprimerons ou anonymiserons ces informations, ou, si cela n'est pas possible (par exemple, parce que vos informations personnelles ont été stockées dans des archives de sauvegarde), nous le ferons en toute sécurité. stocker vos informations personnelles et les isoler de tout traitement ultérieur jusqu'à ce que leur suppression soit possible. .
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="8">
          <v-card-title>8. COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> Nous visons à protéger vos informations personnelles grâce à un système de mesures de sécurité organisationnelles et techniques.</v-card-text>
          <v-card-text>
            Nous avons mis en œuvre des mesures de sécurité techniques et organisationnelles appropriées et raisonnables conçues pour protéger la sécurité de toutes les informations personnelles que nous traitons. Cependant, malgré nos mesures de protection et nos efforts pour sécuriser vos informations, aucune transmission électronique sur Internet ni aucune technologie de stockage d'informations ne peuvent être garanties comme étant sécurisées à 100 %. Nous ne pouvons donc pas promettre ou garantir que les pirates informatiques, les cybercriminels ou d'autres tiers non autorisés ne seront pas en mesure de mettre en péril notre sécurité et de collecter, accéder, voler ou modifier de manière inappropriée vos informations. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, la transmission des informations personnelles vers et depuis nos Services se fait à vos propres risques. Vous ne devez accéder aux Services que dans un environnement sécurisé.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="9">
          <v-card-title>9. COLLECTONS-NOUS DES INFORMATIONS SUR LES MINEURS?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> nous ne collectons pas sciemment de données auprès d'enfants de moins de 18 ans et ne les commercialisons pas auprès d'eux.</v-card-text>
          <v-card-text>
            Nous avons mis en œuvre des mesures de sécurité techniques et organisationnelles appropriées et raisonnables conçues pour protéger la sécurité de toutes les informations personnelles que nous traitons. Cependant, malgré nos mesures de protection et nos efforts pour sécuriser vos informations, aucune transmission électronique sur Internet ni aucune technologie de stockage d'informations ne peuvent être garanties comme étant sécurisées à 100 %. Nous ne pouvons donc pas promettre ou garantir que les pirates informatiques, les cybercriminels ou d'autres tiers non autorisés ne seront pas en mesure de mettre en péril notre sécurité et de collecter, accéder, voler ou modifier de manière inappropriée vos informations. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, la transmission des informations personnelles vers et depuis nos Services se fait à vos propres risques. Vous ne devez accéder aux Services que dans un environnement sécurisé.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="10">
          <v-card-title>10. QUELS SONT VOS DROITS À LA VIE PRIVÉE?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> dans certaines régions, telles que l'Espace économique européen (EEE), le Royaume-Uni (UK), la Suisse et le Canada, vous disposez de droits qui vous permettent un meilleur accès et un meilleur contrôle sur vos informations personnelles. Vous pouvez consulter, modifier ou résilier votre compte à tout moment.</v-card-text>
          <v-card-text>
            Dans certaines régions (comme l'EEE, le Royaume-Uni, la Suisse et le Canada), vous disposez de certains droits en vertu des lois applicables en matière de protection des données. Ceux-ci peuvent inclure le droit (i) de demander l’accès et d’obtenir une copie de vos informations personnelles, (ii) de demander la rectification ou l’effacement ; (iii) pour restreindre le traitement de vos informations personnelles ; (iv) le cas échéant, à la portabilité des données ; et (v) ne pas être soumis à une prise de décision automatisée. Dans certaines circonstances, vous pouvez également avoir le droit de vous opposer au traitement de vos informations personnelles. Vous pouvez faire une telle demande en nous contactant en utilisant les coordonnées fournies dans la section «<router-link to="/privacy#14">COMMENT POUVEZ-VOUS NOUS CONTACTER À PROPOS DE CET AVIS?</router-link>» ci-dessous.
          </v-card-text>
          <v-card-text>Nous examinerons et répondrons à toute demande conformément aux lois applicables en matière de protection des données.</v-card-text>
          <v-card-text>
            Si vous résidez dans l'EEE ou au Royaume-Uni et que vous pensez que nous traitons illégalement vos informations personnelles, vous avez également le droit de porter plainte auprès de <a href="https://ec.europa.eu/newsroom/article29/items/612080">l'autorité de protection des données de votre État membre</a> ou de <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">l'autorité de protection des données du Royaume-Uni</a>.
          </v-card-text>
          <v-card-text>
            Si vous résidez en Suisse, vous pouvez contacter le <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">Préposé fédéral à la protection des données et à l'information</a>
          </v-card-text>
          <v-card-text><strong>Retrait de votre consentement</strong>:
            Si nous comptons sur votre consentement pour traiter vos informations personnelles, qui peut être un consentement exprès et/ou implicite selon la loi applicable, vous avez le droit de retirer votre consentement à tout moment. Vous pouvez retirer votre consentement à tout moment en nous contactant en utilisant les coordonnées fournies dans la section «<router-link to="/privacy#14">COMMENT POUVEZ-VOUS NOUS CONTACTER À PROPOS DE CET AVIS?</router-link>» ci-dessous.</v-card-text>
          <v-card-text>Cependant, veuillez noter que cela n’affectera pas la licéité du traitement avant son retrait ni, lorsque la loi applicable le permet, que cela n’affectera le traitement de vos informations personnelles effectué sur la base de motifs de traitement licites autres que le consentement.
          </v-card-text>

          <v-card-title class="my-0 pt-1">Information sur le compte</v-card-title>
          <v-card-text>
            Si vous souhaitez à tout moment consulter ou modifier les informations de votre compte ou résilier votre compte, vous pouvez:
            <ul>
              <li>Contactez-nous en utilisant les coordonnées fournies.</li>
              <li>Informez votre employeur</li>
            </ul>
          </v-card-text>
          <v-card-text>À votre demande de résiliation de votre compte, nous désactiverons ou supprimerons votre compte et vos informations de nos bases de données actives. Cependant, nous pouvons conserver certaines informations dans nos fichiers pour prévenir la fraude, résoudre des problèmes, faciliter toute enquête, faire respecter nos conditions légales et/ou nous conformer aux exigences légales applicables.
          </v-card-text>
          <v-card-text>
            <strong>Cookies et technologies similaires</strong>: La plupart des navigateurs Web sont configurés pour accepter les cookies par défaut. Si vous préférez, vous pouvez généralement choisir de configurer votre navigateur pour supprimer les cookies et les rejeter. Si vous choisissez de supprimer les cookies ou de refuser les cookies, cela pourrait affecter certaines fonctionnalités ou services de nos Services.
          </v-card-text>
          <v-card-text>Si vous avez des questions ou des commentaires sur vos droits à la vie privée, vous pouvez nous envoyer un e-mail à manny@gorocketstart.com.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="11">
          <v-card-title >11. COMMANDES POUR LES FONCTIONS NE PAS SUIVI</v-card-title>
          <v-card-text>La plupart des navigateurs Web et certains systèmes d'exploitation mobiles et applications mobiles incluent une fonctionnalité ou un paramètre Do-Not-Track (« DNT ») que vous pouvez activer pour signaler votre préférence en matière de confidentialité afin que les données sur vos activités de navigation en ligne ne soient pas surveillées et collectées. À ce stade, aucune norme technologique uniforme pour la reconnaissance et la mise en œuvre des signaux DNT n’a été finalisée. En tant que tel, nous ne répondons pas actuellement aux signaux du navigateur DNT ou à tout autre mécanisme communiquant automatiquement votre choix de ne pas être suivi en ligne. Si une norme de suivi en ligne que nous devons suivre à l'avenir est adoptée, nous vous informerons de cette pratique dans une version révisée de cet avis de confidentialité.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="12">
          <v-card-title >12. LES RÉSIDENTS DES ÉTATS-UNIS ONT-ILS DES DROITS SPÉCIFIQUES À LA VIE PRIVÉE?</v-card-title>
          <v-card-text>
            <strong>En bref:</strong>si vous résidez en Californie, vous bénéficiez de droits spécifiques concernant l'accès à vos informations personnelles.</v-card-text>
          <v-card-text>
            <strong>Quelles catégories d’informations personnelles collectons-nous?
            </strong>
          </v-card-text>
          <v-card-text>Nous avons collecté les catégories de renseignements personnels suivantes au cours des douze (12) derniers mois:</v-card-text>
          <table class="ml-9">
            <tr>
              <th><strong>Catégorie</strong></th>
              <th><strong>Exemples</strong></th>
              <th class="center"><strong>Collecté</strong></th>
            </tr>
            <tr>
              <td>A. Identifiants</td>
              <td>Coordonnées, telles que nom réel, pseudonyme, adresse postale, numéro de téléphone ou de mobile, identifiant personnel unique, identifiant en ligne, adresse de protocole Internet, adresse e-mail et nom de compte
              </td>
              <td class="center">OUI
              </td>
            </tr>
            <tr>
              <td>
                B. Informations personnelles telles que définies dans la loi californienne sur les dossiers clients</td>
              <td>Nom, coordonnées, formation, emploi, antécédents professionnels et informations financières
              </td>
              <td class="center">OUI
              </td>
            </tr>
            <tr>
              <td>
                C. Caractéristiques de classification protégées en vertu de la loi nationale ou fédérale</td>
              <td>Sexe et date de naissance
              </td>
              <td class="center">OUI
              </td>
            </tr>
            <tr>
              <td>
                D. Informations commerciales</td>
              <td>Informations sur les transactions, historique des achats, détails financiers et informations de paiement

              </td>
              <td class="center">NON
              </td>
            </tr>
            <tr>
              <td>
                E. Informations biométriques</td>
              <td>Empreintes digitales et empreintes vocales
              </td>
              <td class="center">NON
              </td>
            </tr>
            <tr>
              <td>
                F. Internet ou autre activité réseau similaire
              </td>
              <td>Historique de navigation, historique de recherche, comportement en ligne, données d'intérêt et interactions avec nos sites Web, applications, systèmes et publicités ainsi qu'avec d'autres.
              </td>
              <td class="center">NON
              </td>
            </tr>
            <tr>
              <td>
                G. Données de géolocalisation
              </td>
              <td>Emplacement de l'appareil
              </td>
              <td class="center">NON
              </td>
            </tr>
            <tr>
              <td>
                H. Informations audio, électroniques, visuelles, thermiques, olfactives ou similaires
              </td>
              <td>Images et enregistrements audio, vidéo ou d'appels créés dans le cadre de nos activités commerciales
              </td>
              <td class="center">NON
              </td>
            </tr>
            <tr>
              <td>
                I. Informations professionnelles ou liées à l'emploi
              </td>
              <td>Coordonnées professionnelles afin de vous fournir nos services au niveau de l'entreprise ou titre du poste, antécédents professionnels et qualifications professionnelles si vous postulez pour un emploi chez nous.
              </td>
              <td class="center">OUI
              </td>
            </tr>
            <tr>
              <td>
                J. Informations sur l'éducation
              </td>
              <td>Dossiers des étudiants et informations d'annuaire
              </td>
              <td class="center">NON
              </td>
            </tr>
            <tr>
              <td>
                K. Inférences tirées des informations personnelles collectées
              </td>
              <td>Inférences tirées de l'une des informations personnelles collectées répertoriées ci-dessus pour créer un profil ou un résumé sur, par exemple, les préférences et les caractéristiques d'un individu
              </td>
              <td class="center">NON
              </td>
            </tr>
            <tr>
              <td>
                L. Informations personnelles sensibles
              </td>
              <td></td>
              <td class="center">NON
              </td>
            </tr>
          </table>

          <v-card-text class="mt-6">Nous pouvons également collecter d'autres informations personnelles en dehors de ces catégories dans les cas où vous interagissez avec nous en personne, en ligne, par téléphone ou par courrier dans le contexte de:
            <ul class="ml-2">
              <li>Recevoir de l'aide via nos canaux de support client;</li>
              <li>Participation à des enquêtes clients ou à des concours; et</li>
              <li>Facilitation dans la prestation de nos Services et pour répondre à vos demandes.</li>
            </ul>
          </v-card-text>
          <v-card-text><strong>Comment utilisons-nous et partageons-nous vos informations personnelles?
          </strong></v-card-text>
          <v-card-text>
            Découvrez comment nous utilisons vos informations personnelles dans la section «<router-link to="/privacy#2">COMMENT TRAITONS-NOUS VOS INFORMATIONS?</router-link>»
          </v-card-text>
          <v-card-text><strong>Vos informations seront-elles partagées avec quelqu'un d'autre?</strong>
          </v-card-text>
          <v-card-text>
            Nous pouvons divulguer vos informations personnelles à nos prestataires de services conformément à un contrat écrit entre nous et chaque prestataire de services. Apprenez-en davantage sur la manière dont nous divulguons vos informations personnelles dans la section «<router-link to="/privacy#4">QUAND ET AVEC QUI PARTAGEONS-NOUS VOS INFORMATIONS PERSONNELLES</router-link>?»
          </v-card-text>
          <v-card-text>
            Nous pouvons utiliser vos informations personnelles à nos propres fins commerciales, par exemple pour entreprendre des recherches internes à des fins de développement et de démonstration technologiques. Ceci n’est pas considéré comme une « vente » de vos informations personnelles.
          </v-card-text>
          <v-card-text>
            Nous n'avons divulgué, vendu ou partagé aucune information personnelle à des tiers à des fins commerciales au cours des douze (12) mois précédents. Nous ne vendrons ni ne partagerons à l’avenir des informations personnelles appartenant aux visiteurs du site Web, aux utilisateurs et à d’autres consommateurs.
          </v-card-text>
          <v-card-title class="my-0 pt-1 pb-1">Résidents de Californie
          </v-card-title>
          <v-card-text>L'article 1798.83 du Code civil de Californie, également connu sous le nom de loi « Shine The Light », permet à nos utilisateurs résidant en Californie de demander et d'obtenir de notre part, une fois par an et gratuitement, des informations sur les catégories d'informations personnelles (le cas échéant) que nous avons divulguées. à des tiers à des fins de marketing direct ainsi que les noms et adresses de tous les tiers avec lesquels nous avons partagé des informations personnelles au cours de l'année civile précédente. Si vous résidez en Californie et souhaitez faire une telle demande, veuillez nous soumettre votre demande par écrit en utilisant les coordonnées fournies ci-dessous.
          </v-card-text>
          <v-card-text>
            Si vous avez moins de 18 ans, résidez en Californie et disposez d'un compte enregistré auprès des Services, vous avez le droit de demander la suppression des données indésirables que vous publiez publiquement sur les Services. Pour demander la suppression de ces données, veuillez nous contacter en utilisant les informations de contact fournies ci-dessous et inclure l'adresse e-mail associée à votre compte et une déclaration indiquant que vous résidez en Californie. Nous veillerons à ce que les données ne soient pas affichées publiquement sur les Services, mais sachez que les données ne peuvent pas être complètement ou complètement supprimées de tous nos systèmes (par exemple, sauvegardes, etc.).
          </v-card-text>
          <v-card-title class="my-0 pt-1 pb-1">Avis de confidentialité du CCPA</v-card-title>
          <v-card-text>Cette section s'applique uniquement aux résidents de Californie. En vertu du California Consumer Privacy Act (CCPA), vous disposez des droits énumérés ci-dessous.</v-card-text>
          <v-card-text>
            Le Code de réglementation de la Californie définit un «résident» comme suit:
            <ul class="pl-2" style="list-style-type: none">
              <li>(1) toute personne qui se trouve dans l’État de Californie à des fins autres que temporaires ou transitoires et</li>
              <li>(2) toute personne physique domiciliée dans l'État de Californie qui se trouve en dehors de l'État de Californie à des fins temporaires ou transitoires</li>
            </ul>
          </v-card-text>
          <v-card-text>Toutes les autres personnes sont définies comme des «non-résidents».
          </v-card-text>
          <v-card-text>Si cette définition de «résident» s'applique à vous, nous devons respecter certains droits et obligations concernant vos informations personnelles.
          </v-card-text>

          <v-card-title class="my-0 pt-1 pb-1">Vos droits concernant vos données personnelles
          </v-card-title>
          <v-card-text><u>Droit de demander la suppression des données — Demande de suppression
          </u></v-card-text>
          <v-card-text>
            Vous pouvez demander la suppression de vos informations personnelles. Si vous nous demandez de supprimer vos informations personnelles, nous respecterons votre demande et supprimerons vos informations personnelles, sous réserve de certaines exceptions prévues par la loi, telles que (mais sans s'y limiter) l'exercice par un autre consommateur de son droit à la liberté d'expression. , nos exigences de conformité résultant d'une obligation légale, ou tout traitement pouvant être nécessaire pour se protéger contre les activités illégales.
          </v-card-text>
          <v-card-text><u>Droit d’être informé — Demander à savoir
          </u></v-card-text>
          <v-card-text>
            Selon les circonstances, vous avez le droit de savoir :
            <ul>
              <li>si nous collectons et utilisons vos informations personnelles;</li>
              <li>les catégories d'informations personnelles que nous collectons;</li>
              <li>les finalités pour lesquelles les informations personnelles collectées sont utilisées;</li>
              <li>si nous vendons ou partageons des informations personnelles avec des tiers;
              </li>
              <li>es catégories d'informations personnelles que nous avons vendues, partagées ou divulguées à des fins commerciales;
              </li>
              <li>les catégories de tiers auxquels les informations personnelles ont été vendues, partagées ou divulguées à des fins commerciales;
              </li>
              <li>l'objectif commercial ou commercial de la collecte, de la vente ou du partage d'informations personnelles ; et
              </li>
              <li>les informations personnelles spécifiques que nous avons collectées à votre sujet.
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            Conformément à la loi applicable, nous ne sommes pas obligés de fournir ou de supprimer des informations client anonymisées en réponse à une demande du consommateur ou de réidentifier les données individuelles pour vérifier une demande du consommateur.
          </v-card-text>
          <v-card-text>
            <u>Droit à la non-discrimination pour l'exercice du droit à la vie privée d'un consommateur
            </u>
          </v-card-text>
          <v-card-text>
            Nous ne ferons aucune discrimination à votre égard si vous exercez vos droits à la vie privée.
          </v-card-text>
          <v-card-text>
            <u>Droit de limiter l’utilisation et la divulgation d’informations personnelles sensibles
            </u>
          </v-card-text>
          <v-card-text>
            Nous ne traitons pas les informations personnelles sensibles des consommateurs.
          </v-card-text>
          <v-card-text>
            <u>
              Processus de vérification
            </u>
          </v-card-text>
          <v-card-text>
            Dès réception de votre demande, nous devrons vérifier votre identité pour déterminer que vous êtes la même personne au sujet de laquelle nous avons les informations dans notre système. Ces efforts de vérification nous obligent à vous demander de fournir des informations afin que nous puissions les faire correspondre aux informations que vous nous avez précédemment fournies. Par exemple, selon le type de demande que vous soumettez, nous pouvons vous demander de fournir certaines informations afin que nous puissions faire correspondre les informations que vous fournissez avec les informations que nous avons déjà dans nos dossiers, ou nous pouvons vous contacter via une méthode de communication (par exemple, téléphone ou email) que vous nous avez préalablement fourni. Nous pouvons également utiliser d'autres méthodes de vérification selon les circonstances.
          </v-card-text>
          <v-card-text>
            Nous utiliserons uniquement les informations personnelles fournies dans votre demande pour vérifier votre identité ou votre autorité pour faire la demande. Dans la mesure du possible, nous éviterons de vous demander des informations supplémentaires à des fins de vérification. Toutefois, si nous ne pouvons pas vérifier votre identité à partir des informations que nous conservons déjà, nous pouvons vous demander de fournir des informations supplémentaires afin de vérifier votre identité et à des fins de sécurité ou de prévention de la fraude. Nous supprimerons ces informations supplémentaires fournies dès que nous aurons fini de vous vérifier.
          </v-card-text>
          <v-card-text>
            <u>Autres droits à la vie privée
            </u>
            <ul>
              <li>Vous pouvez vous opposer au traitement de vos informations personnelles.
              </li>
              <li>Vous pouvez demander la rectification de vos données personnelles si elles sont incorrectes ou ne sont plus pertinentes, ou demander la limitation du traitement des informations.
              </li>
              <li>Vous pouvez désigner un agent autorisé pour faire une demande en vertu de la CCPA en votre nom. Nous pouvons refuser une demande d'un agent autorisé qui ne soumet pas la preuve qu'il a été valablement autorisé à agir en votre nom conformément au CCPA.
              </li>
              <li>Vous pouvez demander à vous désinscrire de la vente ou du partage futur de vos informations personnelles à des tiers. Dès réception d'une demande de désinscription, nous y donnerons suite dès que possible, mais au plus tard quinze (15) jours à compter de la date de soumission de la demande.
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            Pour exercer ces droits, vous pouvez nous contacter en soumettant une <a href="https://app.termly.io/notify/b14cbc0a-f1f4-43bd-90a3-0a42e2f9b64f">demande d'accès à la personne concernée</a> , par email à support@gorocketstart.com, ou en vous référant aux coordonnées au bas de ce document. Si vous avez une plainte concernant la façon dont nous traitons vos données, nous aimerions vous entendre.
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="13">
          <v-card-title >13. DEVONS-NOUS METTRE À JOUR CET AVIS</v-card-title>
          <v-card-text>
            <strong>En bref:</strong> <i>Oui, nous mettrons à jour cet avis si nécessaire pour rester en conformité avec les lois en vigueur.
          </i>
          </v-card-text>
          <v-card-text>
            Nous pouvons mettre à jour cet avis de confidentialité de temps à autre. La version mise à jour sera indiquée par une date de mise à jour « Révisée » et la version mise à jour entrera en vigueur dès qu'elle sera accessible. Si nous apportons des modifications importantes à cet avis de confidentialité, nous pouvons vous en informer soit en affichant bien en vue un avis de ces modifications, soit en vous envoyant directement une notification. Nous vous encourageons à consulter fréquemment cet avis de confidentialité pour être informé de la manière dont nous protégeons vos informations. .
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="14">
          <v-card-title >14. COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CET AVIS?</v-card-title>
          <v-card-text>
            Si vous avez des questions ou des commentaires concernant cet avis, vous pouvez nous envoyer un e-mail à manny@gorocketstart.com ou nous contacter par courrier à :
            <strong class="address">
              <v-card-text>Rocket Start LLC</v-card-text>
              <v-card-text>200, promenade Horizon, bureau 100</v-card-text>
              <v-card-text>Raleigh, Caroline du Nord 27614</v-card-text>
              <v-card-text>États-Unis</v-card-text>
              <v-card-text>Téléphone: 9194382022</v-card-text>
              <v-card-text>manny@gorocketstart.com</v-card-text>
            </strong>
          </v-card-text>
        </v-card>

        <v-card elevation="0" id="15">
          <v-card-title >15. COMMENT POUVEZ-VOUS CONSULTER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE NOUS COLLECTONS CHEZ VOUS?</v-card-title>
          <v-card-text>
            En fonction des lois applicables dans votre pays, vous pouvez avoir le droit de demander l'accès aux informations personnelles que nous collectons auprès de vous, de modifier ces informations ou de les supprimer. Pour demander l'examen, la mise à jour ou la suppression de vos informations personnelles, veuillez remplir et soumettre une <a href="https://app.termly.io/notify/b14cbc0a-f1f4-43bd-90a3-0a42e2f9b64f">demande d'accès à la personne concernée</a>
          </v-card-text>
        </v-card>

      </v-card>
    </div>
  </v-layout>
</template>
<script>

    export default {
      name: "Privacy",

      // eslint-disable-next-line no-unused-vars
      // beforeRouteEnter(to, from, next) {
      //   const { lang } = to.query;
      //   console.log(lang);
      //   switch (lang) {
      //     case "en":
      //       console.log("eng");
      //         break;
      //     case "fr":
      //       break;
      //     default:
      //       break;
      //   }
      //   next();
      // }
    }
</script>
<style scoped>

  .hashlink {
    list-style-type: decimal;
    text-decoration: underline;
    color: var(--v-anchor-base);
    cursor: pointer;
  }
  .address div.v-card__text {
  font-weight: normal;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
 .col-12 /deep/ div.v-card__text{
  padding-top: 8px;
   padding-bottom: 8px;
}
  table.ml-9{
    max-width: 60%;
  }
 table.ml-9, th, td {
   border: 1px solid black;
   border-collapse: collapse;
 }
 td {
   padding: 0.1rem;
 }
 .center {
   text-align: center;
 }

</style>