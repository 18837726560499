<template>
    <v-layout>
        <div class="col-12">
            <v-data-table
                    mobile-breakpoint="920"
                    :headers="headers"
                    :items="users"
                    :server-items-length="totalUsers"
                    :loading="loading"
                    class="elevation-0 users-table"
                    :footer-props="footerProps"
                    :options.sync="options"
            >
                <template v-slot:top>
                    <v-toolbar class="flex-columned-toolbar" height="auto" flat>
                        <template v-if="fullpage">
                            <v-toolbar-title>{{ $t('users') }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </template>
                        <v-spacer></v-spacer>
                        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-text-field
                                    v-model="search"
                                    prepend-icon="mdi-magnify"
                                    :label="$t('search')"
                                    single-line
                                    hide-details
                                    clearable
                                    @keyup.enter.prevent="setSearch()"
                                    @click:clear="clearSearch()"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="filteredDivision"
                                    :items="filteredDivisions"
                                    :label="$t('division')"
                                    item-text="name"
                                    item-value="id"
                                    @change="selectDivision"
                            ></v-select>
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12" v-if="!supervisor">
                            <v-select
                                    hide-details
                                    v-model="filteredrole"
                                    :items="allroles(roles)"
                                    :label="$t('role')"
                                    item-text="roletext"
                                    item-value="id"
                                    @change="selectRole"
                            >
                            </v-select>
                        </v-col>
                        <template v-if="userStatus ==='Active' || userStatus ==='Inactive'">
                            <EditUserDialog
                                    :visible="dialog"
                                    :editedIndex="editedIndex"
                                    :user="editedItem"
                                    :selectableroles="roles"
                                    :companies="[]"
                                    :inuse="inuse"
                                    @edituserdialog:showError="showError"
                                    @edituserdialog:cancel="close"
                                    @edituserdialog:create="getDataFromApi"
                            >
                            </EditUserDialog>
                            <v-btn
                                    color="primary"
                                    @click="dialog = true"
                            >
                                {{ $t('new_user') }}
                            </v-btn>
                            <v-dialog v-model="dialogBatch" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                            color="primary"
                                            class="ml-1 upload-users-btn"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                    >
                                        {{ $t('batch_user_import') }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <!-- eslint-disable-next-line -->
                                    <validation-observer ref="observer1" v-slot="{ invalid }">
                                        <v-form @submit.prevent="uploadUsers">
                                            <v-card-title class="dialog-title">
                                                <span class="headline">{{ $t('upload_users') }}</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>

                                                        <v-col cols="12" class="d-flex ">
                                                            <v-col cols="10" class="pl-0 sample-wrapper">
                                                                <span class="format-title">{{$t('expected_csv')}}:</span>
                                                                <span class="format-text">{{$t('csv_headers')}}</span>
                                                            </v-col>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <!-- eslint-disable-next-line -->
                                                            <validation-provider ref="provider"  v-slot="{ errors, validate }"
                                                                                 :name="$t('file')"
                                                                                 rules="required|ext:csv"
                                                            >
                                                                <v-file-input
                                                                        v-if="fileinput"
                                                                        required
                                                                        accept=".csv"
                                                                        @change="selectFile($event)"
                                                                        :error-messages="errors"
                                                                        ref="filedata"
                                                                        truncate-length="15"
                                                                ></v-file-input>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider
                                                                    v-slot="{ errors }"
                                                                    :name="$t('division')"
                                                                    rules="required|min_value:1"
                                                            >
                                                                <v-select
                                                                        v-model="selectedBatchDivision"
                                                                        :items="divisions"
                                                                        :label="$t('division')"
                                                                        :error-messages="errors"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                ></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider
                                                                    v-slot="{ errors }"
                                                                    :name="$t('role')"
                                                                    rules="required|min_value:1"
                                                            >
                                                                <v-select
                                                                        v-model="selectedBatchRole"
                                                                        :items="rolesForUpload"
                                                                        :label="$t('role')"
                                                                        :error-messages="errors"
                                                                        item-text="roletext"
                                                                        item-value="id"
                                                                ></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <validation-provider
                                                                    v-slot="{ errors }"
                                                                    :name="$t('lang')"
                                                                    rules="required"
                                                            >
                                                                <v-select
                                                                        v-model="selectedBatchLang"
                                                                        :items="languages"
                                                                        :label="$t('lang')"
                                                                        :error-messages="errors"
                                                                        item-text="text"
                                                                        item-value="value"
                                                                ></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-checkbox
                                                                    v-model="scheduledInvitation"
                                                                    :label="$t('sheduled_invitation')"
                                                            ></v-checkbox>
                                                        </v-col>
                                                        <template v-if="scheduledInvitation">
                                                            <v-col cols="12">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        name="Campaign name"
                                                                        rules="required"
                                                                >
                                                                    <v-text-field
                                                                            :error-messages="errors"
                                                                            v-model="campaignName"
                                                                            :label="$t('campaign_name')"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        name="Scheduled at"
                                                                        rules="required"
                                                                >
                                                                    <v-datetime-picker class="scheduled-picker"
                                                                                       :label="$t('select_datetime')"
                                                                                       v-model="scheduledAt"
                                                                                       :aria-errormessage="errors"
                                                                                       :date-picker-props="datePickerProps"
                                                                                       @input="validateScheduledDate"
                                                                                       ref="datePicker"
                                                                                       v-if="visibleDatePicker"

                                                                    >
                                                                        <template v-slot:dateIcon>
                                                                            <v-icon>mdi-calendar</v-icon>
                                                                        </template>
                                                                        <template v-slot:timeIcon>
                                                                            <v-icon>mdi-timer</v-icon>
                                                                        </template>

                                                                    </v-datetime-picker>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        :name="$t('template_id')"
                                                                        rules="numeric|min_value:0"
                                                                >
                                                                    <v-text-field
                                                                            :error-messages="errors"
                                                                            v-model="letterTemplateId"
                                                                            :label="$t('template_id')"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn class="cancel-button px-3" text @click="closeBatch">
                                                    {{ $t('cancel') }}
                                                </v-btn>
                                                <v-btn
                                                        v-if="scheduledInvitation"
                                                        :class="{
                              'confirm-button px-5': !invalidForm && !invalid && scheduledAt != null,
                              'disabled px-5': invalidForm || invalid ||  scheduledAt == null,
                            }"
                                                        text
                                                        type="submit"
                                                        :disabled="invalidForm || invalid || scheduledAt == null"
                                                        :loading="inuse"
                                                >
                                                    {{ $t('upload') }}
                                                </v-btn>
                                                <v-btn
                                                        v-else
                                                        :class="{
                              'confirm-button px-5 dd': !invalidForm && !invalid,
                              'disabled px-5 f': invalidForm || invalid,
                            }"
                                                        text
                                                        type="submit"
                                                        :disabled="invalidForm || invalid"
                                                        :loading="inuse"
                                                >
                                                    {{ $t('upload')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </validation-observer>
                                </v-card>
                            </v-dialog>
                        </template>
                        <Informationdialog
                                :title="$t('oops')"
                                :text="informationdialogText"
                                button-text="Confirm"
                                :visible="informationdialogVisible"
                                @informationdialog:buttonClick="informationdialogVisible = !informationdialogVisible"
                        />
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.newhire="{ item }">
                    <v-icon v-if="item.newhire" color="#ffb100" class="mr-2">mdi-account-clock</v-icon>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <div v-if="userStatus === 'Active' || userStatus === 'Inactive'" class="d-flex align-items-start">
                        <div class="mr-2 px-1 icon-wrap">
                            <v-icon
                                    title="View info"
                                    size="19"
                                    v-if="item.role == 'Driver'"
                                    @click="view(item)"
                            >
                                mdi-eye
                            </v-icon>
                        </div>
                        <v-menu left bottom :offset-y="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="user-menu-button" icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="editItem(item)">
                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeStatus(item)">
                                    <v-list-item-title>{{ userStatus ==='Active' ? $t('make_inactive') : $t('make_active') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="deleteItem(item)">
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div v-else class="d-flex align-items-start">
                        <div class="icon__wrapper">
                            <v-icon :title="$t('restore')" :disabled="inuse" size="19"
                                    @click="restoreUser(item)">
                                mdi-backup-restore
                            </v-icon>
                        </div>
                    </div>
                </template>
            </v-data-table>
            <Confirmationdialog
                    :visible="showRestoreConfirmation"
                    @confirmationdialog:cancelClick="showRestoreConfirmation = !showRestoreConfirmation"
                    @confirmationdialog:confirmClick="restoreUserConfirm"
            >
                <template v-slot:cardText>{{ $t('restore_user')}}</template>
            </Confirmationdialog>
          <Confirmationdialog
                  :visible="dialogDelete"
                  @confirmationdialog:cancelClick="closeDelete"
                  @confirmationdialog:confirmClick="deleteItemConfirm"
          >
              <template v-slot:cardText>{{ $t('are_you_sure_delete_user') }}</template>
          </Confirmationdialog>
            <Confirmationdialog
                    :visible="dialogChangeStatus"
                    @confirmationdialog:cancelClick="closeChangeStatus"
                    @confirmationdialog:confirmClick="changeStatusConfirm"
            >
                <template v-slot:cardText>{{ $t('are_you_sure_change_status') }}</template>
            </Confirmationdialog>
        </div>
    </v-layout>
</template>
<script>
    import {apiCall} from "@/utils/api.js";
    import {mapGetters, mapActions} from "vuex";
    import Confirmationdialog from "@/components/ConfirmationDialog";
    import Informationdialog from "@/components/InformationDialog";
    import EditUserDialog from "@/components/EditUserDialog";

    import {ValidationObserver, ValidationProvider } from "vee-validate";

    export default {
        name: "Drivers",
        props: {
            fullpage: {
                type: Boolean,
                required: true,
                default: true
            },
            userStatus: {
                type: String,
                required: true,
                default: "Active"
            }
        },
        components: {
            ValidationObserver,
            ValidationProvider,
            Informationdialog,
            Confirmationdialog,
            EditUserDialog,

        },
        data(context) {
            return {
                footerProps: {
                    "items-per-page-options": [10, 20, 50],
                },
                informationdialogVisible: false,
                informationdialogText: '',
                showRestoreConfirmation: false,
                restoredUser: null,
                inuse: false,
                dialog: false,
                roles: [],
                dialogBatch: false,
                invalidForm: true,
                dialogDelete: false,
                totalUsers: 0,
                users: [],
                locations: [],
                divisions: [],
                loading: true,
                options: {
                    sortBy: ["firstname"],
                    sortDesc: [true]
                },
                selectedDivision: 0,
                selectedDivisions: [],
                selectedLocation: 0,
                selectedRole: 0,
                page: 1,
                perpage: 10,
                search: null,
                searchValue: null,
                selectedBatchCompany: 0,
                selectedBatchDivision: 0,
                selectedBatchRole: 4,
                selectedBatchLang: null,
                file: null,
                fileinput: true,
                filteredrole: 0,
                headers: [
                    {text: "", value: context.$t('newhire'), sortable: false},
                    {
                        text: context.$t('employeeid'),
                        align: "start",
                        value: "employeeid",
                        sortable: true,
                    },
                    {text: context.$t('firstname'), value: "firstname", sortable: true},
                    {text: context.$t('lastname'), value: "lastname", sortable: true},
                    {text: context.$t('email'), value: "username", sortable: true},
                    {text: context.$t('company'), value: "company", sortable: true},
                    {text: context.$t('roles'), value: "roletext", sortable: false},
                    {text: context.$t('date_created'), value: "createddate", sortable: false},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                editedIndex: -1,
                editedItem: {
                    id: -1,
                    username: "",
                    firstname: "",
                    lastname: "",
                    employeeid: "",
                    secondaryid: "",
                    divisionid: 0,
                    locationid: 0,
                    selectedRole: 0,
                    divisions: [],
                    hireDate: null,
                    birthDate: null,
                    activenewhire: false,
                    newhire: false
                },
                defaultItem: {
                    username: "",
                    firstname: "",
                    lastname: "",
                    employeeid: "",
                    secondaryid: "",
                    divisionid: 0,
                    locationid: 0,
                    selectedRole: 0,
                    divisions: [],
                    hireDate: null,
                    birthDate: null,
                    activenewhire: false,
                    newhire: false
                },
                scheduledInvitation: false,
                campaignName: "",
                scheduledAt: null,
                datePickerProps: {
                    min: new Date().toISOString().substr(0, 10)
                },
                visibleDatePicker: true,
                letterTemplateId: null,
                filteredDivision: 0,
                filteredDivisions: [{id: 0, name: context.$t('all')}],
                hireDate: null,
                birthDate: null,
                dateDialog: false,
                dateDialog1: false,
                dialogChangeStatus: false,
                languages: [
                    {
                        id: 1,
                        value: "en",
                        text: "English"
                    },
                    {
                        id: 2,
                        value: "fr",
                        text: "Français"
                    }
                ],

            };
        },
        computed: {
            rolesForUpload() {
                return this.roles
                    .filter(e => e.id == 4 || e.id == 6);
            },
            ...mapGetters("auth", ["getProfile"]),
            token() {
                return this.getProfile.token;
            },
            supervisor() {
                return this.getProfile.roles.includes('Supervisor')
            },
            rolesforEdit() {
                return this.editedIndex === -1 ? this.roles :
                    this.roles.filter(item => item.id != 4 && item.id != 1 && item.id != 6);
            }
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            selectedDivision(newVal, oldVal) {

                if (oldVal != 0) {
                    this.selectedLocation = 0;
                    this.selectedLocations = [];
                }
                this.editedItem.divisionid = newVal;
                if (newVal != 0) {
                    this.getLocations();
                    this.$refs.observer?.reset();
                }


            },
            selectedRole() {
                if (this.editedIndex === -1) {
                    this.selectedDivision = 0;
                    this.selectedDivisions = [];
                }
            },
            selectedBatchDivision(newVal) {
                if (newVal != 0 && this.file != null) {
                    this.invalidForm = false
                }
            },
            selectedDivisions(newVal) {
                this.editedItem.selectedDivisions = newVal;
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            dialogBatch(val) {
                val || this.closeBatch();
            },
        },
        mounted() {
            this.getDivisions();
            this.getRoles();
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            clearSearch() {
                this.search = null;
                this.searchValue = this.search;

                this.$nextTick(() => {
                    this.options = {...this.options, page: 1}
                    // this.getDataFromApi();
                });
            },
            selectRole() {
                this.options = {...this.options, page: 1}
            },
            allroles(roles) {
                let arr = [];
                arr = [
                    {
                        id: 0,
                        name: "All",
                        roletext: this.$t('all'),
                    },
                    ...roles.filter((role) => role.id != 1),
                ];
                return arr;
            },
            view(item) {
                this.$router.push({
                    name: "Driverinfo",
                    params: {driverid: item.id},
                });
            },
            async selectFile(file) {

                const validated = await this.$refs.provider.validate(file);

                if (file != undefined && validated.valid) {
                    this.file = file;
                    const res = await this.$refs.observer1.validate();
                    this.$refs.observer1.reset();
                    if (res)
                        this.invalidForm = false
                    // this.uploadFile();
                } else {
                    this.file = null;
                    this.invalidForm = true
                }
            },
            restoreData() {
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.dialog = false;
                });
            },
            getRoles() {
                apiCall("get", "roles/get", true).then((response) => {
                    this.roles = response.data;

                    this.roles.forEach((elem) => {
                        switch (elem.name) {
                            case "Admin":
                                elem.roletext = this.$t('super_admin');
                                break;
                            case "CompanyAdmin":
                                elem.roletext = this.$t('company_admin');
                                break;
                            case "User":
                                elem.roletext = this.$t("non_driver_user");
                                break;
                            case "Driver":
                                elem.roletext = this.$t("driver");
                                break;
                            case "Manager":
                                elem.roletext = this.$t("manager");
                                break;
                            case "Supervisor":
                                elem.roletext = this.$t("supervisor");
                                break;
                            default:
                                elem.roletext = elem.name;
                                break;
                        }
                    });
                });
            },
            getLocations() {
                apiCall(
                    "get",
                    `locations/getlocations?divisionid=${this.selectedDivision}`,
                    true
                ).then((response) => {
                    this.locations = response.data;

                    if (this.editedItem.id === -1) {
                        this.selectedLocations = [];
                        this.selectedLocation = 0;
                    }
                });
            },
            getDivisions() {
                apiCall("get", `divisions/getcompanydivisions`, true).then((response) => {
                    this.divisions = response.data;

                    this.filteredDivisions = [{id: 0, name: this.$t('all')}, ...response.data];
                });
            },
            getDataFromApi() {
                this.loading = true;

                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "firstname",
                    perpage: this.options.itemsPerPage,
                    roleid: this.filteredrole,
                    divisionid: this.filteredDivision,
                    search: this.searchValue,
                    status: this.userStatus,
                };

                apiCall(
                    "get",
                    "drivers",
                    {
                        params: params,
                    },
                    true
                ).then((response) => {
                    this.users = response.data.content;
                    this.totalUsers = response.data.totalElements;
                    this.loading = false;

                    if (this.users != undefined) {
                        this.users.forEach((elem) => {
                            switch (elem.role) {
                                case "User":
                                    elem.roletext = "Non-Driver user";
                                    break;
                                default:
                                    elem.roletext = elem.role;
                                    break;
                            }
                        });
                    }
                });
            },
            deleteUser() {
                this.loading = true;
                const params = {...this.editedItem, status: this.userStatus};

                apiCall(
                    "post",
                    "drivers/delete",
                    {
                        data: params,
                    },
                    true
                ).then(() => {
                    this.getDataFromApi();
                    this.loading = false;
                });
            },
            changeUserStatus() {
                this.loading = true;
                const params = {...this.editedItem, status: this.userStatus};

                apiCall(
                    "post",
                    "users/changestatus",
                    {
                        data: params,
                    },
                    true
                ).then(() => {
                    if (this.users.length == 1 && this.page != 1) {
                        this.page = this.page - 1;
                    }
                    this.setSnackbar({
                        color: "success",
                        text: this.$t('status_changed'),
                        toggle: true,
                    });
                    this.getDataFromApi();
                    this.loading = false;
                });
            },
            editItem(item) {
                this.editedIndex = item.id;
                this.editedItem.locationid = item.locationid;
                this.editedItem.divisionid = item.divisionid;

                this.selectedDivision = item.divisionid;
                this.selectedLocation = item.locationid;
                this.birthDate = item.birthdate != null ? item.birthdate.split("-").reverse()[1].concat("/").concat(item.birthdate.split("-").reverse()[0]).concat("/").concat(item.birthdate.split("-").reverse()[2]): null;
                this.hireDate = item.hiredate != null ? item.hiredate.split("-").reverse()[1].concat("/").concat(item.hiredate.split("-").reverse()[0]).concat("/").concat(item.hiredate.split("-").reverse()[2]): null;

                this.editedItem = Object.assign({}, item);
                this.dialog = true;

                this.selectedRole = this.roles.filter(
                    (role) => role.name === item.permissions[0].role.name
                )[0].id;

                if (item.permissions[0].role.id === 3 || item.permissions[0].role.id === 5) {

                    const arr = [];
                    item.permissions.forEach(e => {
                        arr.push(e.division.id);
                    })

                    this.selectedDivisions = arr;
                    this.editedItem.selectedDivisions = arr;
                }

                if (item.permissions[0].role.id === 4) {

                    this.selectedDivision = item.permissions[0].division.id;
                    this.editedItem.selectedDivision = this.selectedDivision;

                    this.selectedLocation = item.permissions[0].location.id;
                    this.editedItem.selectedLocation = this.selectedLocation;
                }
            },

            deleteItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },

            deleteItemConfirm() {
                // api remove
                this.deleteUser();
                this.closeDelete();
            },
            changeStatus(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogChangeStatus = true;
            },
            changeStatusConfirm() {
                this.changeUserStatus();
                this.closeChangeStatus();
            },
            close() {
                this.restoreData();
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeChangeStatus() {
                this.dialogChangeStatus = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeBatch() {
                this.dialogBatch = false;
                this.fileinput = false;
                this.$refs.filedata.clearableCallback();
                // this.file=null;
                this.$refs.observer1.reset();
                this.$nextTick(() => {
                    this.selectedBatchCompany = 0;
                    this.selectedBatchDivision = 0;
                    this.batchDivisions = [];
                    this.fileinput = true;
                    this.scheduledAt = null;
                    this.scheduledInvitation = false;
                    this.letterTemplateId = null;
                    this.campaignName = "";
                });
            },
            uploadUsers() {
                this.inuse = true;

                let params = {}

                if (this.scheduledInvitation) {
                    params = {
                        companyid: this.selectedBatchCompany,
                        divisionid: this.selectedBatchDivision,
                        roleId : this.selectedBatchRole,
                        lang: this.selectedBatchLang,
                        scheduledInvitation: this.scheduledInvitation || false,
                        campaignName: this.campaignName,
                        scheduledAt: this.$refs.datePicker.formattedDatetime,
                        letterTemplateId: this.letterTemplateId || 0
                    }
                } else {
                    params = {
                        companyid: this.selectedBatchCompany,
                        divisionid: this.selectedBatchDivision,
                        roleId : this.selectedBatchRole,
                        lang: this.selectedBatchLang,
                    };
                }

                let formData = new FormData();

                for (let key in params) {
                    formData.append(key, params[key]);
                }

                const headers = {
                    "Content-Type": "multipart/form-data"
                };

                formData.append("file", this.file);

                apiCall(
                    "post",
                    "users/import",
                    formData,
                    true,
                    null,
                    headers,
                    false
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,
                            });
                            this.closeBatch();

                            this.getDataFromApi();
                        } else {
                            this.informationdialogVisible = true;
                            this.informationdialogText = response.data.result;
                        }
                    })
                    .catch(() => {
                        this.inuse = false;
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    });
            },
            validateScheduledDate() {
                if (new Date() > this.scheduledAt) {
                    this.scheduledAt = null;
                    this.visibleDatePicker = false;
                    this.$nextTick(() => {
                        this.visibleDatePicker = true;
                    })
                }
            },
            setSearch() {
                this.searchValue = this.search;
                this.options = {...this.options, page: 1}
            },
            selectDivision() {
                this.options = {...this.options, page: 1}
            },
            restoreUser(item) {
                this.restoredUser = item;
                this.showRestoreConfirmation = true;
            },
            restoreUserConfirm() {
                const params = {id: this.restoredUser.id};
                this.inuse = true;

                apiCall(
                    "post",
                    "users/restore",
                    {params: params},
                    true
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,
                            });
                            this.getDataFromApi();
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: response.data.result,
                                toggle: true,
                            });
                        }
                    })
                    .catch(() => {
                        this.inuse = false;
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    })
                    .finally(() => {
                        this.restoredUser = null;
                        this.showRestoreConfirmation = false;
                    });
            },
            showError(text) {
                this.informationdialogText = text;
                this.informationdialogVisible = true;
            },
        },
    };
</script>
<style scoped>
    @media (max-width: 400px) {
        .add-user-btn,.upload-users-btn{
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100%;
            margin-top: 4px;
            margin-bottom: 4px;
        }

    }
    @media (max-width: 480px) {
        .users-table /deep/ .v-data-footer{
            justify-content: center;
            padding-bottom: 8px;
        }
        .users-table /deep/ .v-data-footer__pagination{
            display: none;
        }
        .users-table /deep/ .v-data-table__mobile-row__cell{
            overflow-wrap: anywhere;
        }
        .users-table /deep/ .v-data-footer__select .v-input{
            margin: 0 10px !important;
        }

    }
    @media (max-width: 1280px) {
        .flex-columned-toolbar >>> .v-toolbar__content {
            flex-wrap: wrap;
            justify-content: right;
        }

    }

    .v-data-footer__select {
        display: none !important;
    }

    .icon-wrap {
        width: 24px;
        display: inline-flex;
        vertical-align: middle;
    }

    .format-text {
        font-size: 10px;
    }

    .format-title, .format-text {
        display: block;
        color: #000;
    }
</style>