import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify/lib/framework';
// import vuetify from './plugins/vuetify';
import { mapActions, mapGetters } from "vuex";
import DatetimePicker from 'vuetify-datetime-picker';
import VueI18n from 'vue-i18n';
import locales from './locales/locales';
import { configure, extend } from "vee-validate";
import {
  confirmed,
  email,
  ext, image,
  integer,
  max,
  max_value,
  min_value,
  numeric,
  required,
  size
} from "vee-validate/dist/rules";

Vue.use(Vuetify);
Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  fallbackLocale: 'en',
  messages: locales,
});

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#017894",
        secondary: "#A0CE5F",
        accent: "#03a9f4",
        error: "#f44336",
        warning: "#ff5722",
        info: "#00bcd4",
        success: "#4caf50"
      },
    },
  },
  lang: {
    current: localStorage.getItem('lang') || 'en',
    locales: {locales},
    t: (key, ...params) => i18n.t(key, params),
  }
});

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`${field}`);
    return i18n.t(`validations.${values._rule_}`, values);
  }
});

extend('required', {
  ...required,
  // the values param is the placeholders values
  message: (_, values) => i18n.t('validations.required', values)
});

extend("max",  {
  ...max,
  message: (_, values) => i18n.t('validations.max', values)
});

extend("ext", {
  ...ext,
  message: (_, values) => i18n.t('validations.ext', values),
});

extend("confirmed", {
  ...confirmed,
  message: (_, values) => i18n.t('validations.confirmed', values),
});

extend("size", {
  ...size,
  message: (_, values) => i18n.t('validations.size', values),
});

extend("ext1", {
  ...ext,
  message: (_, values) => i18n.t('validations.ext1', values),
});

extend("image", {
  ...image,
  message: (_, values) => i18n.t('validations.image', values),
});

extend("numeric", numeric);
extend("min_value", min_value);
extend("integer", integer);
extend("max_value", max_value);
extend("email", email);

Vue.config.productionTip = false;

store.dispatch('auth/login').then((response) => {
  if (response) {
    const currentLang = localStorage.getItem("lang");
    if (!response.lang || (currentLang !== null && currentLang !== response.lang)) {
      store.dispatch("auth/updatelang");
    }
  }
  router.beforeEach((to, from, next) => {
    if (!(store.state.auth && store.state.auth.profile && store.state.auth.profile.roles.includes("Admin")) && to.matched.some(m => m.meta.role === "Admin")) {
      next('/');
    } else if (!(store.state.auth && store.state.auth.profile && (store.state.auth.profile.roles.includes("CompanyAdmin") || store.state.auth.profile.roles.includes("Manager"))) && to.matched.some(m => m.meta.role === "Company")) {
      next('/');
    }
    else {
      next();
    }
    next();
  });

  router.beforeEach((to, from, next) => {
    const hasLeaderboard = store?.state?.auth?.profile?.hasLeaderboard;
    if(!hasLeaderboard && to.name==='LeaderBoard'){
      next("/404");
    }else {
      next();
    }
    next();
  })

  router.beforeEach((to, from, next) => {
    const hasGpsDataPage = store?.state?.auth?.profile?.hasGpsDataPage;
    if(!hasGpsDataPage && to.name==='GpsData'){
      next("/404");
    }else {
      next();
    }
    next();
  })

  Vue.use(DatetimePicker)

  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
    computed: {
      ...mapGetters('auth', ["getRolesArray"]),
  
      userHasRoleAdmin() {
        return this.getRolesArray?.includes("Admin");
      },
      userHasRoleCompany() {
        return this.getRolesArray?.includes("Manager") || this.getRolesArray?.includes("CompanyAdmin");
      },
      userHasRoleSupervisor() {
        return this.getRolesArray?.includes("Supervisor");
      }
    },
    methods: {
      ...mapActions("auth", ["login", "logout"]),
    },
  }).$mount('#app')

})
