<template>
    <v-dialog v-model="visible" persistent max-width="500px">
        <v-card>
            <v-card-title class="headline primary white--text"
            >{{$t('warning')}}
            </v-card-title
            >
            <v-card-text class="v-card__text mt-4">
                <slot name="cardText">{{$t('are_you_sure')}}</slot>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="cancel-button px-5" text @click="$emit('confirmationdialog:cancelClick')"
                >{{$t('cancel')}}
                </v-btn
                >
                <v-btn class="confirm-button px-5" text @click="$emit('confirmationdialog:confirmClick')"
                >{{$t('confirm')}}
                </v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "confirmationdialog",
        props: {
            visible: {
                type: Boolean,
                required: true,
                default: false
            }
        }
    }
</script>
<style>
    .headline{
        font-weight: 400;
    }
    .v-card__text {
        color: black !important;
    }
</style>
