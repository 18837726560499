<template>
  <v-layout>
    <div class="col-12">
      <v-card-title
              class="page-title"
        >{{ $t('gps') }}
        <v-spacer></v-spacer>
        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
          <v-select
            v-model="selectedDivision"
            :items="alldivisions(divisions)"
            :label="$t('division')"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ $t('upload_file')}}
            </v-btn>
          </template>
          <v-card>
               <!-- eslint-disable-next-line -->
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form ref="fileuploadform" @submit.prevent="save">
                <v-card-title class="dialog-title">
                  <span class="headline">{{ $t('upload_file') }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                            cols="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('division')"
                              rules="required|min_value:1"
                            >
                              <v-select
                                :error-messages="errors"
                                v-model="uploadedDivision"
                                :items="divisions"
                                :label="$t('division')"
                                item-text="name"
                                item-value="id"
                              ></v-select>
                            </validation-provider>
                          </v-col>
                      <template v-if="getProfile.userImportId==1 || getProfile.userImportId==4">
                        <v-col cols="12">
                          <!-- eslint-disable-next-line -->
                          <validation-provider ref="provider" v-slot="{ errors, validate }"
                                               :name="$t('file')"
                                               rules="required|ext:csv"
                          >
                            <v-file-input
                                    required
                                    accept=".csv"
                                    @change="selectFile($event, 'provider', 'file')"
                                    :error-messages="errors"
                                    ref="filedata"
                                    truncate-length="15"
                            ></v-file-input>
                          </validation-provider>
                        </v-col>
                      </template>
                      <template v-if="getProfile.userImportId==2">
                        <v-col cols="12">
                          <!-- eslint-disable-next-line -->
                          <validation-provider ref="provider1" v-slot="{ errors, validate }"
                                               :name="$t('file')"
                                               rules="required|ext:csv"
                          >
                            <v-file-input
                                    required
                                    accept=".csv"
                                    @change="selectFile($event, 'provider1', 'firstfile')"
                                    :error-messages="errors"
                                    ref="firstfiledata"
                                    truncate-length="15"
                                    label="Actual login file"
                            ></v-file-input>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12">
                          <!-- eslint-disable-next-line -->
                          <validation-provider ref="provider2" v-slot="{ errors, validate }"
                                               :name="$t('file')"
                                               rules="required|ext:csv"
                          >
                            <v-file-input
                                    required
                                    accept=".csv"
                                    @change="selectFile($event,'provider2', 'secondfile')"
                                    :error-messages="errors"
                                    ref="secondfiledata"
                                    truncate-length="15"
                                    :label="$t('sheduled_time_file')"
                            ></v-file-input>
                          </validation-provider>
                        </v-col>
                      </template>
                      <template v-if="getProfile.userImportId==3">
                        <v-col cols="12">
                          <!-- eslint-disable-next-line -->
                          <validation-provider ref="provider1" v-slot="{ errors, validate }"
                                               :name="$t('file')"
                                               rules="required|ext:csv"
                          >
                            <v-file-input
                                    required
                                    accept=".csv"
                                    @change="selectFile($event, 'provider1', 'firstfile')"
                                    :error-messages="errors"
                                    ref="firstfiledata"
                                    truncate-length="15"
                                    :label="$t('time_card_file')"
                            ></v-file-input>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12">
                          <!-- eslint-disable-next-line -->
                          <validation-provider ref="provider2" v-slot="{ errors, validate }"
                                               :name="$t('file')"
                                               rules="required|ext:csv"
                          >
                            <v-file-input
                                    required
                                    accept=".csv"
                                    @change="selectFile($event,'provider2', 'secondfile')"
                                    :error-messages="errors"
                                    ref="secondfiledata"
                                    truncate-length="15"
                                    :label="$t('report_shedule_access_file')"
                            ></v-file-input>
                          </validation-provider>
                        </v-col>
                      </template>
                      <template v-if="getProfile.userImportId==5">
                        <v-col cols="12">
                          <!-- eslint-disable-next-line -->
                          <validation-provider ref="provider1" v-slot="{ errors, validate }"
                                               :name="$t('file')"
                                               rules="required|ext:csv"
                          >
                            <v-file-input
                                    required
                                    accept=".csv"
                                    @change="selectFile($event, 'provider1', 'firstfile')"
                                    :error-messages="errors"
                                    ref="firstfiledata"
                                    truncate-length="15"
                                    label="CSV file from Digital Fleet"
                            ></v-file-input>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12">
                          <!-- eslint-disable-next-line -->
                          <validation-provider ref="provider2" v-slot="{ errors, validate }"
                                               :name="$t('file')"
                                               rules="required|ext:csv"
                          >
                            <v-file-input
                                    required
                                    accept=".csv"
                                    @change="selectFile($event,'provider2', 'secondfile')"
                                    :error-messages="errors"
                                    ref="secondfiledata"
                                    truncate-length="15"
                                    label="CSV File from Payroll System"
                            ></v-file-input>
                          </validation-provider>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="cancel-button px-3" text @click="close">
                    {{$t('cancel')}}
                  </v-btn>
                  <v-btn
                    :class="{
                              'confirm-button px-5': !invalidForm,
                              'disabled px-5': invalidForm,
                            }"
                            text
                            type="submit"
                            :disabled="invalidForm"
                            :loading="inuse"
                  >
                    {{ $t('upload') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-container fluid>
        <v-data-table
                mobile-breakpoint="992"
          :headers="headers"
          :footer-props="footerProps"
          :items="releases"
          :loading="loading"
          class="elevation-1 gpsdata-table"
          :options.sync="options"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-icon size="19" class="mr-2" @click="viewItem(item)">
              mdi-eye
            </v-icon>
            <v-icon size="19" v-if="item.releasedStatus==='Pending'" class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <Deletedialog
                :visible="deleteDialogVisible"
                @deletedialog:cancelClick="deleteDialogVisible = !deleteDialogVisible"
                @deletedialog:confirmClick="deleteItemConfirm"
        >
          <template v-slot:cardText>{{deleteTitle}}</template>
        </Deletedialog>
        <v-dialog
                v-model="responseDialog"
                max-width="800px"
                persistent
        >

        <v-card>
          <v-card-title class="dialog-title">
            <span class="headline">{{ $t('import_results')}}</span>
          </v-card-title>
          <v-card-text class="my-2 py-2 subtitle-1">
            {{ $t('trips_upload_successfully')}}: {{getSuccessfullyImportedTripsCount(responseData)}}
          </v-card-text>
          <v-divider v-if="responseData && responseData.length>1"></v-divider>
          <!--  eslint-disable-next-line -->
          <v-list-item v-for="(item, i) in responseData" :key="i" v-if="Object.keys(item)[0] !='trips'">
            <v-list-item-content class="px-2">
              <v-list-item-title>{{Object.keys(item)[0]}}</v-list-item-title>
              <v-list-item class="v-list-item__respone my-1" v-for="(it, b) in getData(item)"  :key="b">
                <div>{{it}}</div>
              </v-list-item>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" dark @click="closeResponseDialog"
            >{{ $t('acknowledged')}}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
        </v-dialog>
      </v-container>
    </div>
  </v-layout>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { apiCall } from "@/utils/api.js";
import { mapGetters, mapActions } from "vuex";
import Deletedialog from "@/components/DeleteDialog";


export default {
  name: "GpsData",
  components: {
    ValidationProvider,
    ValidationObserver,
    Deletedialog,
  },
  data(context) {
    return {
      footerProps: {
        "items-per-page-options": [10, 20, 50],
      },
      deleteTitle : "",
      inuse: false,
      dialog: false,
      loading: false,
      invalidForm: true,
      releases: [],
      divisions: [],
      selectedDivision: 0,
      uploadedDivision: 0,
      options: {},
      headers: [
        {
          text: context.$t('date_range'),
          align: "start",
          value: "dateRange",
          sortable: false,
        },
        { text: context.$t('division'), value: "division", sortable: false },
        { text: context.$t('released_status'), value: "releasedStatus", sortable: false },
        { text: context.$t('released_by'), value: "releasedBy", sortable: false },
        { text: context.$t('actions'), value: "actions", sortable: false },
      ],
      file: null,
      firstfile: null,
      secondfile: null,
      editeditem: {},
      deleteDialogVisible: false,
      responseDialog: false,
      responseData: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getProfile"]),
    token() {
      return this.getProfile.token;
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    selectedDivision() {
      this.options = {...this.options, page: 1}
    },
  },
  mounted() {
    this.getDivisions();
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    async selectFile(file, provider, target) {

      const valid = await this.$refs[provider].validate(file);

      let f = target == 'firstfile' ? 'secondfile' : 'firstfile';

      switch (this.getProfile.userImportId) {
        case 1:
        case 4:
          if (file != undefined && valid.valid) {
            this[target] = file;
            this.invalidForm = false
            // this.uploadFile();
          } else {
            this[target] = null;
            this.invalidForm = true
          }
          break;
        case 2:
        case 3:
        case 5:
          if (file != undefined && valid.valid) {
            this[target] = file;
          } else {
            this[target] = null;
            this.invalidForm = true
          }

          if (this[target] != null && this[f] != null) {
            this.invalidForm = false
          }
          break;
      }
    },
    viewItem(item) {
      this.$router.push({
        name: "Trips",
        params: { releaseid: item.id },
      });
    },
    alldivisions(divisions) {
      let arr = [];
      arr = [
        {
          id: 0,
          name: "All",
        },
        ...divisions,
      ];
      return arr;
    },
    getDataFromApi() {
      this.loading = true;

      const params = {
         page: this.options.page - 1,
        sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
        sortField: this.options.sortBy[0] || "id",
        perpage: this.options.itemsPerPage,
        divisionid: this.selectedDivision,
      };

      apiCall("get", `gpsdatacsv/getreleases`, {params: params}, true,).then((response) => {
        // this.releases = response.data;
        this.releases = [];

        response.data.forEach((elem) => {
          let a = {};
          a.dateRange = `${elem.startdate} - ${elem.enddate}`;
          a.id = elem.id;
          a.releasedStatus = elem.released ? this.$t('released') : this.$t('pending');
          a.releasedBy = elem.released ? elem.releasedby : "";
          a.division = elem.division.name;
          this.releases.push(a);
        });

        this.loading = false;
      });
    },
    close() {
      this.$refs.filedata?.clearableCallback();
      this.$refs.firstfiledata?.clearableCallback();
      this.$refs.secondfiledata?.clearableCallback();
      // this.file=null;
      this.$refs.observer.reset();
      this.dialog = false;
    },
    save() {
      this.uploadFile();
      this.close();
    },
    getDivisions() {
      apiCall("get", `divisions/getcompanydivisions?exclude=gps`, true).then((response) => {
        this.divisions = response.data;
        // this.selectedDivision = this.divisions[0].id;
        this.uploadedDivision = this.divisions[0].id;

        // this.getDataFromApi();
      });
    },
    uploadFile() {
      this.inuse = true;
      let formData = new FormData();
      const headers = {
        "Content-Type": "multipart/form-data"
      };

      const userImportId =  this.getProfile.userImportId;

      switch (userImportId) {
        case 1:
        case 4:
          formData.append("firstfile", this.file);
          formData.append("divisionid", this.uploadedDivision);
          break;
        case 2:
          formData.append("firstfile", this.firstfile);
          formData.append("secondfile", this.secondfile);
          formData.append("divisionid", this.uploadedDivision);
          break;
        case 3:
          formData.append("firstfile", this.firstfile);
          formData.append("secondfile", this.secondfile);
          formData.append("divisionid", this.uploadedDivision);
          break;
        case 5:
          formData.append("firstfile", this.firstfile);
          formData.append("secondfile", this.secondfile);
          formData.append("divisionid", this.uploadedDivision);
          break;
          default:
            this.setSnackbar({
              color: "error",
              text: this.$t('cant_find_import_system'),
              toggle: true,
            });
            return;
      }

      apiCall(
              "post",
              "gpsdatacsv/create",
              formData,
              true,
              null,
              headers,
              false
      ).then((response) => {
          this.inuse = false;
          if(response.data){
            if (!response.data.result){
              this.responseData = response.data;
              this.responseDialog = true;
            }else{
              this.setSnackbar({
                color: "error",
                text: response.data.result,
                toggle: true,
              });
            }
          }else{
            this.setSnackbar({
              color: "error",
              text: this.$t('unknown_error'),
              toggle: true,
            });
          }

          this.getDataFromApi();
        })
        .catch((error) => {
          this.inuse = false;
          if (error?.response?.data?.message) {
            this.setSnackbar({
              color: "error",
              text: error.response.data.message,
              toggle: true,
            });
          } else {
            console.log(error);
            this.setSnackbar({
              color: "error",
              text: this.$t('unknown_error'),
              toggle: true,
            });
          }
        });
    },
    uploadSplitFiles() {
      this.inuse = true;
      let formData = new FormData();
      const headers = {
        "Content-Type": "multipart/form-data"
      };
      formData.append("firstfile", this.firstfile);
      formData.append("secondfile", this.secondfile);
      formData.append("divisionid", this.uploadedDivision);

      apiCall(
              "post",
              "gpsdatacsv/splitcreate",
              formData,
              true,
              null,
              headers,
              false
      ).then((response) => {
                this.inuse = false;
                if(response.data){
                  this.setSnackbar({
                    color: response.data.status ? "success" : "error",
                    text: response.data.result,
                    toggle: true,
                  });

                }else{
                  this.setSnackbar({
                    color: "error",
                    text: this.$t('unknown_error'),
                    toggle: true,
                  });
                }

                this.getDataFromApi();
              })
              .catch((error) => {
                this.inuse = false;
                if (error?.response?.data?.message) {
                  this.setSnackbar({
                    color: "error",
                    text: error.response.data.message,
                    toggle: true,
                  });
                } else {
                  this.setSnackbar({
                    color: "error",
                    text: this.$t('unknown_error'),
                    toggle: true,
                  });
                }
              });
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.deleteTitle = this.$t('delete_gps_title', [item.dateRange]),
      this.deleteDialogVisible = true;
    },
    deleteItemConfirm() {
      // api remove
      this.deleteRelease();
      this.closeDelete();
    },
    closeDelete() {
      this.deleteDialogVisible = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    deleteRelease() {
      this.loading = true;
      const params = {
        releaseid : this.editedItem.id
      }

      apiCall(
              "post",
              "gpsdatacsv/delete",
              {
                params: params,
              },
              true
      ).then((response) => {
        if (response.data){
          this.setSnackbar({
            color: response.data.status ? "success" : "error",
            text: response.data.status ? this.$t('data_successfully_deleted') : response.data.result,
            toggle: true,
          });

          if (this.releases.length == 1 && this.page != 1) {
            this.page = this.page - 1;
          }
          this.getDataFromApi();
        }else{
          this.setSnackbar({
            color: "error",
            text: this.$t('unknown_error'),
            toggle: true,
          });
        }

      });
    },
    getData(item) {
      return Object.values(item)[0];
    },
    getSuccessfullyImportedTripsCount(responseData) {
      if (responseData) {
        let key = "trips";

        let res = responseData?.filter( elem => Object.keys(elem)[0] === key);

        return res[0][key][0]
      }else {
        return "";
      }

    },
    closeResponseDialog() {
      this.responseDialog = false;
      this.responseData = null;
    }
  },
};
</script>
<style>
.v-list-item__respone{
  min-height: auto;
}
</style>
<style scoped>
  .page-title{
    justify-content: flex-end;
  }
  @media (max-width: 480px) {
    .gpsdata-table /deep/ .v-data-footer{
      justify-content: center;
      padding-bottom: 8px;
    }
    .gpsdata-table /deep/ .v-data-footer__pagination{
      display: none;
    }
    .gpsdata-table /deep/ .v-data-footer__select .v-input{
      margin: 0 10px !important;
    }
  }
</style>
