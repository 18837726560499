<template>
    <v-layout>
        <div class="col-12">
            <v-toolbar
                    flat
            >
                <v-toolbar-title>{{ $t('programs') }}</v-toolbar-title>

                <template v-slot:extension>
                    <v-tabs
                            v-model="tab"
                            align-with-title
                    >
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab
                        >
                            Awardco
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-tabs-items v-model="tab">
                <v-tab-item
                >
                    <v-card class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6" elevation="0">
                        <v-form @submit.prevent="save">
                            <v-col>
                                <v-text-field
                                        hide-details
                                        class="text-h6 my-1"
                                        type="text"
                                        :label="$t('company_ids')"
                                        v-model.trim="companiesData"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                        hide-details
                                        class="text-h6 my-1"
                                        type="text"
                                        :label="$t('role_ids')"
                                        v-model.trim="rolesData"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                        hide-details
                                        class="text-h6 my-1"
                                        type="text"
                                        :label="$t('exclusion_user_id_list')"
                                        v-model.trim="exclusionData"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                        hide-details
                                        class="text-h6 my-1"
                                        type="text"
                                        :label="$t('inclusion_user_id_list')"
                                        v-model.trim="inclusionData"
                                />
                            </v-col>
                            <v-btn
                                    height="70"
                                    class="rounded-0 my-6 text-h6"
                                    block
                                    color="secondary"
                                    depressed
                                    type="submit"
                                    :disabled="loading"
                                    :loading="loading"
                            >{{ $t('save') }}
                            </v-btn
                            >
                            <v-btn
                                    height="70"
                                    class="rounded-0 my-6 text-h6"
                                    block
                                    color="secondary"
                                    depressed
                                    @click.prevent="dialogUpload = true"
                                    :disabled="loading"
                                    :loading="loading"
                            >{{ $t('upload_users_to_awardco') }}
                            </v-btn
                            >
                        </v-form>
                        <v-dialog
                                v-model="confirmationDialog"
                                max-width="800px"
                                persistent
                        >

                            <v-card>
                                <v-card-title class="dialog-title">
                                    <span class="headline"> {{ $t('confirmation') }} </span>
                                </v-card-title>
                                <v-card-text class="my-2 py-2 subtitle-1">
                                    {{ $t('you_are_about_save_data') }}
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-col class="d-flex flex-row flex-wrap">
                                    <p class="text-h6 my-1">{{ $t('companies_id')}} : {{companiesId.join(", ")}}</p>
                                </v-col>
                                <v-divider></v-divider>
                                <v-col class="d-flex flex-row flex-wrap">
                                    <p class="text-h6 my-1">{{ $t('roles_id')}} : {{rolesId.join(", ")}}</p>
                                </v-col>
                                <v-divider></v-divider>
                                <template v-if="exclusionId.length>0">
                                    <v-col class="d-flex flex-row flex-wrap">
                                        <p class="text-h6 my-1">{{ $t('exclusion_user_id')}} : {{exclusionId.join(", ")}}</p>
                                    </v-col>
                                    <v-divider></v-divider>
                                </template>
                                <template v-if="inclusionId.length>0">
                                    <v-col class="d-flex flex-row flex-wrap">
                                        <p class="text-h6 my-1">{{ $t('inclusion_user_id')}} : {{inclusionId.join(", ")}}</p>
                                    </v-col>
                                </template>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="cancel-button px-5" text @click="confirmationDialog = false"
                                    >{{ $t('cancel') }}
                                    </v-btn
                                    >
                                    <v-btn class="confirm-button px-5" text @click="confirmData"
                                    >{{ $t('confirm') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <ConfirmationDialog
                                :visible="dialogUpload"
                                @confirmationdialog:cancelClick="closeUpload"
                                @confirmationdialog:confirmClick="uploadConfirm"
                        >
                            <template v-slot:cardText>{{ $t('are_you_sure_upload_to_awardco') }}</template>
                        </ConfirmationDialog>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-layout>
</template>
<script>
    import {mapActions} from "vuex";
    import { apiCall } from "@/utils/api";
    import ConfirmationDialog from "@/components/ConfirmationDialog";

    export default {
        name: "Programs",
        components: {
            ConfirmationDialog,
        },
        data: () => ({
            tab: null,
            loading: false,
            companiesId: [],
            companiesData: "",
            rolesId: [],
            rolesData: "",
            exclusionId: [],
            exclusionData: "",
            inclusionId: [],
            inclusionData: "",
            scheduledAt: null,
            confirmationDialog: false,
            dialogUpload: false,
            timeModal: false
        }),
        created() {
            this.getDataFromApi();
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            closeUpload(){
                this.dialogUpload = false;
            },
            processingIds(data) {
                const iDs = data ? data.split(",") : [];

                let iDsArray = [];

                iDs.forEach(elem => {
                    if (String(parseInt(elem, 10)) === String(elem) && Number.parseInt(elem) > 0 && !iDsArray.includes(Number.parseInt(elem))) {
                        iDsArray.push(Number.parseInt(elem));
                    }
                })

                return iDsArray;
            },
            save() {

                this.companiesId = [...this.processingIds(this.companiesData)];

                this.rolesId = [...this.processingIds(this.rolesData)];

                this.exclusionId = [...this.processingIds(this.exclusionData)];

                this.inclusionId = [...this.processingIds(this.inclusionData)];


                if (this.companiesId.length == 0) {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('at_least_one_company_required'),
                        toggle: true,
                    });
                    return;
                }

                if (this.rolesId.length == 0) {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('at_least_one_role_required'),
                        toggle: true,
                    });
                    return;
                }

                // if (this.scheduledAt == null) {
                //     this.setSnackbar({
                //         color: "error",
                //         text: "Scheduled export time is required",
                //         toggle: true,
                //     });
                //     return;
                // }
                this.confirmationDialog = true;

            },
            confirmData() {

                this.loading = true;

                const params = {
                    companyIDs: this.companiesId.join(", "),
                    roleIDs: this.rolesId.join(", "),
                    exclusionUserID: this.exclusionId.join(", "),
                    inclusionUserID: this.inclusionId.join(", "),
                }

                apiCall("post", "export/settings", {
                    data: params
                }, true)
                    .then(response => {
                        if (response.data.status == true) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,
                            })
                        }
                        this.confirmationDialog = false;
                    }).catch(e => {
                    console.log(e);
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('something_went_wrong'),
                        toggle: true,
                    })
                }).finally(() => {
                    this.loading = false;
                });

            },
            getDataFromApi() {
                this.loading = true;

                apiCall("get", "export/settings", {}, true)
                    .then(response => {
                        if (response.data.status === true) {
                            this.companiesData = response.data.result.companyIDs;
                            this.rolesData = response.data.result.roleIDs;
                            this.exclusionData = response.data.result.exclusionUserID;
                            this.inclusionData = response.data.result.inclusionUserID;
                            this.scheduledAt = response.data.result.scheduledAt;
                        }

                        // eslint-disable-next-line no-unused-vars
                    }).catch(e => {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('something_went_wrong'),
                        toggle: true,
                    })
                }).finally(() => {
                    this.loading = false;
                });
            },
            uploadConfirm() {
                this.loading = true;

                apiCall("post", "service/awardcoexport", {}, true)
                    .then(response => {
                        if (response.data.status === true) {
                            this.setSnackbar({
                                color: "success",
                                text: this.$t('users_are_uploaded_to_awardco'),
                                toggle: true,
                            })
                            this.dialogUpload = false;
                        }
                        // eslint-disable-next-line no-unused-vars
                    }).catch(e => {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('something_went_wrong'),
                        toggle: true,
                    })
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
<style scoped>
    .scheduled-picker /deep/ .v-label {
        font-weight: 500;
    }
</style>
