<template>
  <v-layout>
    <div class="col-12">
      <v-card class="fill-height">
        <v-card-title>
          <v-toolbar-title style="font-weight: normal">
            {{ $t('profile') }}</v-toolbar-title
          >
        </v-card-title>
        <v-card-text>
          <!-- eslint-disable-next-line -->

          <v-row d-flex class="flex-wrapper">
            <validation-observer
              class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-12"
              ref="observer"
              v-slot="{ invalid }"
            >
              <v-form @submit.prevent="savedata">
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('first_name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="firstName"
                      :error-messages="errors"
                      :label="$t('first_name')"
                      required
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('last_name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="lastName"
                      :error-messages="errors"
                      :label="$t('last_name')"
                      required
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider
                          v-slot="{ errors }"
                          :name="$t('email')"
                          rules="required|email"
                  >
                    <v-text-field
                            v-model="email"
                            :error-messages="errors"
                            :label="$t('email')"
                            required
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider v-if="isCompanyAdmin"
                  v-slot="{ errors }"
                    :name="$t('company_name')"
                    rules="required">
                      <v-text-field
                      v-model="companyname"
                      :error-messages="errors"
                      :label="$t('company_name')"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    :disabled="invalid"
                    :loading="inuse"
                  >
                    {{ $t('submit') }}
                  </v-btn>
                </v-col>
              </v-form>
            </validation-observer>

            <v-col offset-xl="2" offset-lg="2" offset-md="0" offset-sm="0" offset-xs="0" class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-12">
              <file-drop
                @fileselected="fileselected"
                :clear="clear"
                :fileurl="getProfile.profilephotourl"
              ></file-drop>

              <v-btn
                v-if="file"
                @click="uploadFile"
                color="primary"
                block
                type="submit"
                :disabled="!file"
                :loading="inuse"
              >
                {{ $t('upload') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="flex-wrapper">
            <v-col class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-12">
              <v-col class="d-flex justify-center pb-6">
                <v-dialog v-model="dialog" max-width="500px">
                  <template justify-center v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="align-self-center"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('change_password') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <validation-observer ref="observer1" v-slot="{ invalid }">
                      <v-form @submit.prevent="save" ref="passwordform">
                        <v-card-title class="dialog-title">
                          <span class="headline">{{ $t('change_password') }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-col cols="12">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="$t('old_password')"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="oldpassword"
                                  :error-messages="errors"
                                  :label="$t('old_password')"
                                  required
                                  :append-icon="
                                    invisibleoldpassword
                                      ? 'mdi-eye-off'
                                      : 'mdi-eye'
                                  "
                                  @click:append="
                                    () =>
                                      (invisibleoldpassword = !invisibleoldpassword)
                                  "
                                  :type="
                                    invisibleoldpassword ? 'password' : 'text'
                                  "
                                ></v-text-field>
                              </validation-provider>
                              <validation-provider
                                v-slot="{ errors }"
                                :name="$t('new_password')"
                                rules="required"
                                vid="confirmation"
                              >
                                <v-text-field
                                  v-model="password"
                                  :error-messages="errors"
                                  :label="$t('new_password')"
                                  required
                                  :append-icon="
                                    invisiblenewpassword
                                      ? 'mdi-eye-off'
                                      : 'mdi-eye'
                                  "
                                  @click:append="
                                    () =>
                                      (invisiblenewpassword = !invisiblenewpassword)
                                  "
                                  :type="
                                    invisiblenewpassword ? 'password' : 'text'
                                  "
                                ></v-text-field>
                              </validation-provider>
                              <validation-provider
                                v-slot="{ errors }"
                                :name="$t('confirm_password')"
                                rules="required|confirmed:confirmation"
                              >
                                <v-text-field
                                  v-model="repeatedpassword"
                                  :error-messages="errors"
                                  :label="$t('confirm_new_password')"
                                  required
                                  :append-icon="
                                    invisiblerepeatedpassword
                                      ? 'mdi-eye-off'
                                      : 'mdi-eye'
                                  "
                                  @click:append="
                                    () =>
                                      (invisiblerepeatedpassword = !invisiblerepeatedpassword)
                                  "
                                  :type="
                                    invisiblerepeatedpassword
                                      ? 'password'
                                      : 'text'
                                  "
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn class="cancel-button px-3" text @click="close">
                            {{ $t('cancel') }}
                          </v-btn>
                          <v-btn
                            :class="{
                              'confirm-button px-5': !invalid,
                              'disabled px-5': invalid,
                            }"
                            text
                            type="submit"
                            :disabled="invalid"
                            :loading="inuse"
                          >
                            {{ $t('save') }}
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-layout>
</template>
<script>
import FileDrop from "@/components/FileDrop.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { apiCall } from "@/utils/api.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Profile",
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line
    FileDrop,
  },
  data: () => ({
    inuse: false,
    loading: false,
    firstName: null,
    lastName: null,
    email: null,
    companyname: null,
    clear: false,
    dialog: false,
    oldpassword: null,
    password: null,
    repeatedpassword: null,
    file: null,
    invisibleoldpassword: true,
    invisiblenewpassword: true,
    invisiblerepeatedpassword: true,
  }),
  created() {
    this.firstName = this.getProfile.firstname;
    this.lastName = this.getProfile.lastname;
    this.companyname = this.getProfile.companyname;
    this.email = this.getProfile.username;
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    ...mapGetters("auth", ["getProfile"]),
    token() {
      return this.getProfile.token;
    },
    isCompanyAdmin(){
        return this.getProfile.roles.includes("CompanyAdmin");
    }
  },
  methods: {
    ...mapActions("auth", ["loadProfile"]),
    ...mapActions("service", ["setSnackbar"]),
    fileselected(files) {
      if (files){
          this.file = files[0];
      }else{
          this.file = null;
      }
    },
    close() {
        this.dialog = false;
      this.$nextTick(() => {
          this.oldpassword = null;
        this.password = null;
        this.repeatedpassword = null;
        
          this.$refs.observer1.reset();
      });
    },
    savedata() {
      this.inuse = true;
      this.loading = true;
      const params = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
      };

      if (this.isCompanyAdmin){
          params.companyname = this.companyname;
      }

      apiCall(
        "post",
        "users/updatedata",
        {
          data: params,
        },
        true
      ).then((response) => {
        this.loadProfile();
        this.setSnackbar({
          color: response.data.status == true ? "success" : "error",
          text: response.data.result,
          toggle: true,
        });
        this.inuse = false;
      }).catch(() =>{
          this.setSnackbar({
              color: "error",
              text: this.$t('something_went_wrong'),
              toggle: true,
            });
        this.inuse = false;
      });
    },
    uploadFile() {
      this.inuse = true;
      let formData = new FormData();
      const headers = {
        "Content-Type": "multipart/form-data"
      };
      formData.append("file", this.file);

      apiCall(
              "post",
              "users/uploadimage",
              formData,
              true,
              null,
              headers,
              false
      ).then(() => {
            this.setSnackbar({
              color: "success",
              text: this.$t('image_successfully_updated'),
              toggle: true,
            });
            this.file = null;
          this.loadProfile();
          this.inuse = false;
        })
        .catch((error) => {
          this.inuse = false;
          if (error?.response?.data?.message) {
            this.setSnackbar({
              color: "error",
              text: error.response.data.message,
              toggle: true,
            });
          } else {
            this.setSnackbar({
              color: "error",
              text: this.$t('unknown_error'),
              toggle: true,
            });
          }
        });
    },
    save() {
      this.inuse = true;
      const params = {
        oldpassword: this.oldpassword,
        newpassword: this.password,
        repeatedpassword: this.repeatedpassword,
      };

      apiCall(
        "post",
        "users/updatepassword",
        {
          data: params,
        },
        true
      ).then(() => {
        this.inuse = false;
          this.setSnackbar({
            color: "success",
            text: this.$t('password_updated'),
            toggle: true,
          });
          this.close();
        }).catch(error => {
          this.inuse = false;
            this.setSnackbar({
                color: "error",
                text: error?.data || this.$t('unknown_error'),
                toggle: true
            })
        });
    },
  },
};
</script>
<style scoped>
  @media (max-width: 1263px) {
    .flex-wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }

  }
</style>