<template>
    <v-layout>
        <div class="col-12">
            <v-data-table
                    v-model="selectedItems"
                    mobile-breakpoint="980"
                    :headers="headers"
                    :items="users"
                    :server-items-length="totalUsers"
                    :loading="loading"
                    :footer-props="footerProps"
                    :page="page"
                    :options.sync="options"
                    class="users-table"
                    must-sort
            >
                <template v-slot:top>
                    <v-toolbar class="flex-columned-toolbar" height="auto" flat>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-text-field
                                    v-model="search"
                                    prepend-icon="mdi-magnify"
                                    :label="$t('search')"
                                    single-line
                                    hide-details
                                    clearable
                                    @keyup.enter.prevent="setSearch()"
                                    @click:clear="clearSearch()"
                            ></v-text-field>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="filteredcompany"
                                    :items="allcompanies(companies)"
                                    :label="$t('company')"
                                    item-text="name"
                                    item-value="id"
                                    @change="selectCompany"
                            >
                            </v-select>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="filteredDivision"
                                    :items="filteredDivisions"
                                    :label="$t('division')"
                                    item-text="name"
                                    item-value="id"
                                    @change="selectFilter"
                            ></v-select>
                        </v-col>
                      <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                        <v-select
                            hide-details
                            v-model="filteredStatus"
                            :items="filteredStatuses"
                            item-text="value"
                            item-value="key"
                            :label="$t('status')"
                            @change="selectFilter"
                        ></v-select>
                      </v-col>
                        <v-spacer></v-spacer>
                        <v-card-actions v-if="queueStopped" class="px-0 gaped-actions">
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="primary"
                                    :disabled="loading"
                                    @click="restartQueue"
                            >
                                {{ $t('restart_queue') }}
                            </v-btn>
                        </v-card-actions>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.status="{ item }">
                    <v-chip
                            :color="item.color"
                            dark
                    >
                        <strong>{{ item.status }}</strong>
                    </v-chip>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-btn
                            :class="item.class"
                            color="primary"
                            :disabled="item.status != 'Pending' && item.status !='Error'"
                            @click="showRemove(item)"
                    >
                        {{ $t('delete') }}
                    </v-btn>
                </template>
            </v-data-table>
            <Informationdialog
                    title="OOPS"
                    :text="informationdialogText"
                    :button-text="$t('confirm')"
                    :visible="informationdialogVisible"
                    @informationdialog:buttonClick="informationdialogVisible = !informationdialogVisible"
            />
            <Confirmationdialog
                    :visible="showRemoveConfirmation"
                    @confirmationdialog:cancelClick="cancelRemove"
                    @confirmationdialog:confirmClick="confirmRemove"
            >
                <template v-slot:cardText>{{$t('remove_user_from_queue')}}</template>
            </Confirmationdialog>
        </div>
    </v-layout>
</template>
<script>

    import {apiCall} from "@/utils/api.js";
    import {mapGetters, mapActions} from "vuex";
    import Informationdialog from "@/components/InformationDialog";
    import Confirmationdialog from "@/components/ConfirmationDialog";
    import { queueStatuses } from "@/utils/enums";


    export default {
        name: "Redeemsqueue",
        components: {
            Informationdialog,
            Confirmationdialog,
        },
        data(context) {
            return {
                footerProps: {
                    "items-per-page-options": [10, 20, 50],
                },
                inuse: false,
                informationdialogVisible: false,
                informationdialogText: '',
                showRemoveConfirmation: false,
                totalUsers: 0,
                users: [],
                companies: [],
                divisions: [],
                loading: true,
                options: {
                    sortBy: ["firstname"],
                    sortDesc: [true]
                },
                filteredcompany: 0,
                search: null,
                page: 1,
                headers: [
                    {text: context.$t('employee_name'), value: "fullname", sortable: false},
                    {
                        text: context.$t('employee_id'),
                        align: "start",
                        value: "employeeid",
                        sortable: true,
                    },
                    {text: context.$t('company'), value: "company", sortable: true},
                    {text: context.$t('transfer_am'), value: "coins", sortable: false},
                    {text: context.$t('status'), value: "status", sortable: false},
                    {text: context.$t('scheduled_date'), value: "queueDate", sortable: true},
                    {text: context.$t('processed_date'), value: "processedDate", sortable: false},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                searchValue: null,
                filteredDivision: 0,
                filteredStatus: 'all',
                filteredStatuses: [ ...this.getQueueStatuses(), {key: 'all', value: this.$t('all')} ],
                filteredDivisions: [{id: 0, name: this.$t('all')},],
                selectedItem: null,
                selectedItems: [],
                redeemSelected: null,
                queueStopped: false,
            };
        },
        computed: {
            ...mapGetters("auth", ["getProfile"]),
            token() {
                return this.getProfile.token;
            },
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            dialogBatch(val) {
                val || this.closeBatch();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        mounted() {
            // this.getDataFromApi();
            this.getCompanies();
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            getQueueStatuses() {
              return Object.keys(queueStatuses).map(key => {
                return { "key" : key, value: this.getQueueStatus(key) }
              })
            },
            getQueueStatus(status) {
              return this.$t(`queueStatuses.${status}`);
            },
            clearSearch() {
                this.search = null;
                this.searchValue = this.search;

                this.$nextTick(() => {
                    this.options = {...this.options, page: 1}
                });
            },
            allcompanies(companies) {
                let arr = [];
                arr = [
                    {
                        id: 0,
                        name: this.$t('all'),
                    },
                    ...companies,
                ];
                return arr;
            },
            async selectCompany() {
                await this.getFilteredDivisions();
                this.options = {...this.options, page: 1}
            },
            selectFilter() {
                this.options = {...this.options, page: 1}
            },
            restoreData() {
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.dialog = false;
                });
            },
            getCompanies() {
                apiCall("get", "companies/get", true).then((response) => {
                    this.companies = response.data;
                });
            },
            getDataFromApi() {
                this.loading = true;

                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "firstname",
                    perpage: this.options.itemsPerPage,
                    companyid: this.filteredcompany,
                    divisionid: this.filteredDivision,
                    search: this.searchValue,
                };

                if (this.filteredStatus !== 'all') {
                  params.status = this.filteredStatus;
                }

                apiCall(
                    "get",
                    "users/redeemsqueue",
                    {
                        params: params,
                    },
                    true
                ).then((response) => {
                    this.queueStopped = response.data.queueStopped;
                    this.users = response.data.values.content;
                    this.users.forEach((elem) => {
                        switch (elem.status) {
                            case "Pending":
                                elem.color = "#fda80e";
                                break;
                            case "Success":
                                elem.color = "#70ad47";
                                break;
                            case "Error":
                            case "Cancelled":
                                elem.color = "#f00";
                                break;
                            default:
                                elem.color = "transparent";
                                break;
                        }
                    });

                    this.totalUsers = response.data.values.totalElements;
                    this.loading = false;
                });
            },
            async getFilteredDivisions() {
                this.filteredDivision = 0;
                apiCall(
                    "get",
                    `divisions/get?companyid=${this.filteredcompany}`,
                    true
                ).then((response) => {

                    this.filteredDivisions = [{id: 0, name: this.$t('all')}, ...response.data];
                });
            },
            setSearch() {
                this.searchValue = this.search;
                this.options = {...this.options, page: 1}
            },
            showError(text) {
                this.informationdialogText = text;
                this.informationdialogVisible = true;
            },
            showRemove(item) {
                this.selectedItem = item;
                this.showRemoveConfirmation = true;
            },
            cancelRemove() {
                this.showRemoveConfirmation = false;
                this.selectedItem = null;
            },
            confirmRemove() {
                const params = {
                    id: this.selectedItem.id,
                };
                this.inuse = true;
                apiCall(
                    "post",
                    `users/redeem/delete`,
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    this.setSnackbar({
                        color: response.data.status == true ? "success" : "error",
                        text: response.data.result,
                        toggle: true,
                    });
                }).catch(e => {
                    this.setSnackbar({
                        color: "error",
                        text: e?.data?.result || this.$t('unknown_error'),
                        toggle: true,
                    });
                }).finally(() => {
                    this.getDataFromApi();
                    this.inuse = false;
                    this.showRemoveConfirmation = false;
                    this.editedItem = null;
                });
            },
            restartQueue() {
                this.loading = true;
                apiCall(
                    "post",
                    `users/redeems/restart`,
                    true
                ).then((response) => {
                    this.setSnackbar({
                        color: response.data.status == true ? "success" : "error",
                        text: response.data.result,
                        toggle: true,
                    });
                }).catch(e => {
                    this.setSnackbar({
                        color: "error",
                        text: e?.data?.result || this.$t('unknown_error'),
                        toggle: true,
                    });
                }).finally(() => {
                    this.getDataFromApi();
                    this.loading = false;
                });
            }
        },
    };
</script>
<style scoped>

    @media (max-width: 480px) {
        .users-table /deep/ .v-data-footer {
            justify-content: center;
            padding-bottom: 8px;
        }

        .users-table /deep/ .v-data-footer__pagination {
            display: none;
        }

        .users-table /deep/ .v-data-table__mobile-row__cell {
            overflow-wrap: anywhere;
        }

        .users-table /deep/ .v-data-footer__select .v-input {
            margin: 0 10px !important;
        }

    }

    @media (max-width: 1280px) {
        .flex-columned-toolbar >>> .v-toolbar__content {
            flex-wrap: wrap;
            justify-content: right;
        }

    }
</style>
