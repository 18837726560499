<template>
    <v-layout>
        <div class="col-12">
            <v-data-table
                    mobile-breakpoint="768"
                    :headers="headers"
                    :items="companies"
                    :server-items-length="totalCompanies"
                    :loading="loading"
                    class="elevation-1 companies-table"
                    :footer-props="footerProps"
                    :options.sync="options"
                    :page="page"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('companies') }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                      <CompanyDialog :visible="dialog" :edited-index="editedIndex" :company-data="editedItem" @companydialog:cancel="close" :userimport-list="userImportList"  @companydialog:create="getDataFromApi"/>
                      <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="dialog = true"
                      >
                        {{ $t('new_company') }}
                      </v-btn>
                        <Confirmationdialog
                                :visible="dialogDelete"
                                @confirmationdialog:cancelClick="closeDelete"
                                @confirmationdialog:confirmClick="deleteItemConfirm"
                        >
                            <template v-slot:cardText>{{ $t('are_you_sure_delete') }}</template>
                        </Confirmationdialog>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon size="19" class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon size="19" @click="deleteItem(item)"> mdi-delete</v-icon>
                </template>
            </v-data-table>
        </div>
    </v-layout>
</template>
<script>

    const companyUpdatePayload = {
        id: 0,
        name: "",
        driverlimit: 0,
        userlimit: 0,
        companykey: "",
        activesuggestions: false,
        userImportTypeid: 1,
        activetab: false,
        activeurl: false,
        latebuffer: 0,
        tcmenable: false,
    };

    import {apiCall} from "@/utils/api.js";
    import {mapActions} from "vuex";
    import Confirmationdialog from "@/components/ConfirmationDialog";
    import CompanyDialog from "@/components/CompanyDialog.vue";

    export default {
        name: "Companies",
        components: {
          // eslint-disable-next-line vue/no-unused-components
           CompanyDialog,
            Confirmationdialog,
        },
        data(context) {
            return {
                footerProps: {
                    "items-per-page-options": [10, 20, 50],
                },
                inuse: false,
                dialog: false,
                dialogDelete: false,
                totalCompanies: 0,
                companies: [],
                loading: true,
                options: {},
                page: 1,
                perpage: 10,
                headers: [
                    {
                        text: context.$t('name'),
                        align: "start",
                        sortable: false,
                        value: "name",
                    },
                    {
                        text: context.$t('company_key'),
                        sortable: false,
                        value: "companykey",
                    },
                    {text: context.$t('driver_limit'), value: "driverlimit", sortable: false},
                    {text: context.$t('driver_count'), value: "drivercount", sortable: false},
                    {text: context.$t('non_driver_limit'), value: "userlimit", sortable: false},
                    {text: context.$t('non_driver_count'), value: "usercount", sortable: false},
                    {text: context.$t('date_created'), value: "createddate"},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                editedIndex: -1,
                editedItem: {
                    id: 0,
                    name: "",
                    companykey: "",
                    driverlimit: 0,
                    userlimit: 0,
                    activetab: false,
                    activeurl: false,
                    activesuggestions: false,
                    activelatebuffer: false,
                    activabadgefeed: false,
                    latebuffer: 0,
                    userImportList: [],
                    activenewhirebuffer: false,
                    newhirebuffer: 0,
                    tcmenable: false,
                },
                defaultItem: {
                    id: 0,
                    name: "",
                    companykey: "",
                    driverlimit: 0,
                    userlimit: 0,
                    activetab: false,
                    activeurl: false,
                    activesuggestions: false,
                    latebuffer: 0,
                    activelatebuffer: false,
                    activebadgefeed: false,
                    userImportList: [],
                    activenewhirebuffer: false,
                    newhirebuffer: 0,
                    tcmenable: false,
                },
                userImportTypeid: 1,
                userImportList:[]
            };
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t("new_company") : this.$t("edit_company");
            },
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        mounted() {

        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            changeIsHireBufferActive(value) {
                if (!value) {
                    this.editedItem.newhirebuffer = 0;
                }
            },
            getDataFromApi() {
                this.loading = true;
                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "id",
                    perpage: this.options.itemsPerPage,
                };

                apiCall(
                    "get",
                    "companies",
                    {
                        params: params,
                    },
                    true
                ).then((response) => {
                    this.companies = response.data.content;
                    this.totalCompanies = response.data.totalElements;

                    this.editedItem.userImportList = response.data.content.length > 0 ? response.data.content[0].userImportList : [];
                    this.userImportList = response.data.content.length > 0 ? response.data.content[0].userImportList : [];
                    this.defaultItem.userImportList = this.editedItem.userImportList;

                  this.userImportList.forEach((elem) => {
                    switch (elem.type) {
                      case "NormalImport":
                        elem.text = "Normal import";
                        break;
                      case "HeslepSplitImport":
                        elem.text = "Heslep Split import";
                        break;
                      case "OhioSplitImport":
                        elem.text = "Ohio Split import";
                        break;
                      case "PreCastImport":
                        elem.text = "PreCast import";
                        break;
                      case "HawkeyeImport":
                        elem.text = "Hawkeye import";
                        break;
                      default:
                        elem.text = elem.type;
                        break;
                    }
                  });

                    this.loading = false;
                });
            },
            updateCompany() {
                this.inuse = true;
                this.loading = true;
                const params = this.mapItemToPayload(this.editedItem);

                apiCall(
                    "post",
                    "companies/update",
                    {
                        data: params,
                    },
                    true
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: "Company updated",
                                toggle: true,
                            });
                            this.getDataFromApi();
                            this.close();
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: response.data.result,
                                toggle: true,
                            });
                        }
                    })
                    .catch((error) => {
                        this.inuse = false;
                        if (error?.data?.message) {
                            this.setSnackbar({
                                color: "error",
                                text: error.data.message,
                                toggle: true,
                            });
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: "Unknown error",
                                toggle: true,
                            });
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            createCompany() {
                this.inuse = true;
                this.loading = true;
                const params = this.mapItemToPayload(this.editedItem);

                apiCall(
                    "post",
                    "companies/create",
                    {
                        data: params,
                    },
                    true
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: "Company created",
                                toggle: true,
                            });
                            this.getDataFromApi();
                            this.close();
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: response.data.result,
                                toggle: true,
                            });
                        }
                    })
                    .catch((error) => {
                        this.inuse = false;
                        if (error?.data?.message) {
                            this.setSnackbar({
                                color: "error",
                                text: error.data.message,
                                toggle: true,
                            });
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: "Unknown error",
                                toggle: true,
                            });
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            deleteCompany() {
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "companies/delete",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    if (response.data) {
                        this.setSnackbar({
                            color: response.data.status ? "success" : "error",
                            text: response.data.status ? "Company successfully deleted" : response.data.result,
                            toggle: true,
                        });

                        if (this.companies.length == 1 && this.page != 1) {
                            this.page = this.page - 1;
                        }
                        this.getDataFromApi();
                    } else {
                        this.setSnackbar({
                            color: "error",
                            text: "Unknown error",
                            toggle: true,
                        });
                    }

                });
            },
            editItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.userImportTypeid = item.userImport.id;
                this.dialog = true;
            },

            deleteItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },

            deleteItemConfirm() {
                // api remove
                this.deleteCompany();
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.userImportTypeid = 1;
                });
                // this.$refs.observer.reset();
            },

            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },

            save() {
                if (this.editedIndex > -1) {
                    // edit
                    this.editedItem.id = this.editedIndex;
                    this.defaultItem.id = this.editedIndex;
                    this.updateCompany();
                } else {
                    // create
                    this.createCompany();
                }
            },
            mapItemToPayload(editedItem) {
                let value = Object.assign({} , companyUpdatePayload);

                value.id = editedItem.id;
                value.name = editedItem.name;
                value.driverlimit = editedItem.driverlimit;
                value.userlimit = editedItem.userlimit;
                value.companykey = editedItem.companykey;
                value.activesuggestions = editedItem.activesuggestions;
                value.activebadgefeed = editedItem.activebadgefeed;
                value.userImportTypeid = this.userImportTypeid;
                value.activetab = editedItem.activetab;
                value.activeurl = editedItem.activeurl;
                value.latebuffer = editedItem.latebuffer;
                value.newhirebuffer = editedItem.newhirebuffer;
                value.activenewhirebuffer = editedItem.activenewhirebuffer;
                value.tcmenable = editedItem.tcmenable;

                return value;
            }
        },
    };
</script>
<style scoped>
    @media (max-width: 480px) {
        .companies-table /deep/ .v-data-footer{
            justify-content: center;
            padding-bottom: 8px;
        }
        .companies-table /deep/ .v-data-footer__pagination{
            display: none;
        }
        .companies-table /deep/ .v-data-footer__select .v-input{
            margin: 0 10px !important;
        }
        .companies-table /deep/ .v-data-table__mobile-row__cell{
            overflow-wrap: anywhere;
        }

    }
</style>
