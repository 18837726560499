<template>
    <v-layout>
        <div class="col-12">
            <v-data-table
                    v-model="selectedItems"
                    show-select
                    mobile-breakpoint="980"
                    :headers="headers"
                    :items="users"
                    :server-items-length="totalUsers"
                    :loading="loading"
                    :footer-props="footerProps"
                    :page="page"
                    :options.sync="options"
                    class="users-table"
            >
                <template v-slot:top>
                    <v-toolbar class="flex-columned-toolbar align-toolbar-start" height="auto" flat>
                        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 col-12">
                            <v-text-field
                                    v-model="search"
                                    prepend-icon="mdi-magnify"
                                    :label="$t('search')"
                                    single-line
                                    hide-details
                                    clearable
                                    @keyup.enter.prevent="setSearch()"
                                    @click:clear="clearSearch()"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="filteredDivision"
                                    :items="filteredDivisions"
                                    :label="$t('division')"
                                    item-text="name"
                                    item-value="id"
                                    @change="selectDivision"
                            ></v-select>
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 col-12">
                            <v-select
                                    hide-details
                                    v-model="status"
                                    :items="statuses"
                                   :label="$t('status')"
                                    item-text="key"
                                    item-value="value"
                                    @change="selectStatus"
                            ></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col>
                            <v-row>
                                <v-col class="d-flex"><v-dialog
                                        ref="menu"
                                        v-model="menu"
                                        :return-value.sync="selectedDates"
                                        persistent
                                        width="430px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                :value="dateRangeText"
                                                :label="$t('date_range')"
                                                hide-details
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                                @click:clear="clearDates"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="selectedDates"
                                            no-title
                                            scrollable
                                            range
                                            width="auto"
                                            show-adjacent-months
                                            :max="new Date().toISOString().substr(0, 10)"
                                    >
                                        <v-col
                                        >
                                            <v-col class="d-flex justify-center" cols="12">
                                                <v-btn text color="primary" @click="menu = false">
                                                    {{ $t('cancel') }}
                                                </v-btn>
                                                <v-btn text color="primary" @click="saveDates(selectedDates)">
                                                    {{ $t('ok') }}
                                                </v-btn>
                                            </v-col></v-col
                                        >
                                    </v-date-picker>
                                </v-dialog></v-col>
                                <v-col class="d-flex">
                                    <v-btn
                                            color="primary"
                                            @click="prepareData"
                                            class="mr-1"
                                    >
                                        {{ $t('transfer_current')}}
                                    </v-btn>
                                    <v-btn
                                            color="primary"
                                            @click="prepareDateTransferData"
                                            class="mr-1"
                                    >
                                        {{ $t('transfer_by_date') }}
                                    </v-btn>
                                    <v-btn
                                            color="error"
                                            :disabled="checkSelected()"
                                            @click="resetData"
                                            class="mr-1"
                                    >
                                        {{ $t('reset') }}
                                    </v-btn></v-col>
                            </v-row>
                        </v-col>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.amount="{ item }">
                    <v-text-field
                            :prepend-icon=" item.amount > item.coins ? 'mdi-alert-circle' : ''"
                            :error="item.amount > item.coins"
                            class="amount-input"
                            v-model="item.amount"
                            :label="$t('amount')"
                            single-line
                            hide-details
                            type="number"
                            min="0"
                            :max="item.coins"
                            @change="parseAmount(item)"
                    ></v-text-field>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                            v-if="checkSelected()"
                            color="primary"
                            :disabled="checkAmount(item)"
                            @click="showRedeem(item)"
                    >
                        {{ $t('redeem') }}
                    </v-btn>
                </template>
            </v-data-table>
            <v-card elevation="0" v-if="!checkSelected()" class="my-3 transparent">
                <v-spacer></v-spacer>
                <v-card-actions class="px-0 gaped-actions">
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            :disabled="checkSelected() || selectedHasErrors()"
                            @click="showRedeemSelected"
                    >
                        {{ $t('confirm_transfer') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <Informationdialog
                    title="OOPS"
                    :text="informationdialogText"
                    button-text="Confirm"
                    :visible="informationdialogVisible"
                    @informationdialog:buttonClick="informationdialogVisible = !informationdialogVisible"
            />
            <v-dialog v-model="showConfirmation" persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline primary white--text" >{{ $t('select_transfer_date') }}</v-card-title>
                    <v-card-text class="v-card__text mt-4 d-flex justify-center">
                        <v-date-picker landscape v-model="queueDate" :min="new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()"></v-date-picker>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="cancel-button px-5" text @click="cancelRedeem"
                        >{{ $t('cancel')}}
                        </v-btn
                        >
                        <v-btn class="confirm-button px-5" text @click="confirmRedeem"
                        >{{ $t('confirm') }}
                        </v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showConfirmationSelected" persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline primary white--text" >{{ $t('select_transfer_date') }}</v-card-title>
                        <v-card-text class="v-card__text mt-4 d-flex justify-center">
                            <v-date-picker landscape v-model="queueDate" :min="new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()"></v-date-picker>
                        </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="cancel-button px-5" text @click="cancelRedeemSelected"
                        >{{ $t('cancel')}}
                        </v-btn
                        >
                        <v-btn class="confirm-button px-5" text @click="confirmRedeemSelected"
                        >{{ $t('confirm') }}
                        </v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-layout>
</template>
<script>

    import {apiCall} from "@/utils/api.js";
    import {mapGetters, mapActions} from "vuex";
    import Informationdialog from "@/components/InformationDialog";


    export default {
        name: "CompanyRedeems",
        components: {
            Informationdialog,
        },
        data(context) {
            return {
                queueDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
                footerProps: {
                    "items-per-page-options": [10, 20, 50, -1],
                },
                statuses: [
                    {
                        key: 'Active',
                        value: 'Active'
                    },
                    {
                        key: 'Inactive',
                        value: 'Inactive'
                    },
                    {
                        key: 'New hire',
                        value: 'Newhire'
                    }
                ],
                inuse: false,
                informationdialogVisible: false,
                informationdialogText: '',
                showConfirmation: false,
                showConfirmationSelected: false,
                totalUsers: 0,
                users: [],
                companies: [],
                divisions: [],
                loading: true,
                options: {
                    sortBy: ["firstname"],
                    sortDesc: [true]
                },
                filteredcompany: 0,
                search: null,
                page: 1,
                headers: [
                    {
                        text: context.$t("employeeid"),
                        align: "start",
                        value: "employeeid",
                        sortable: true,
                    },
                    {text: context.$t('fullname'), value: "fullname", sortable: false},
                    {text: context.$t('email'), value: "username", sortable: true},
                    {text: context.$t('current_coins'), value: "coins", sortable: false},
                    {text: context.$t('coins_by_date_range'), value: "rangedCoins", sortable: false},
                    {text: context.$t('pending_transfer'), value: "pendingCoins", sortable: false},
                    {text: context.$t('transfer_amount'), value: "amount", sortable: false},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                searchValue: null,
                filteredDivision: 0,
                filteredDivisions: [{id: 0, name: context.$t('all')},],
                selectedItem: null,
                selectedItems: [],
                redeemSelected: null,
                status: "Active",
                selectedDates: [],
                menu: false,
            };
        },
        computed: {
            ...mapGetters("auth", ["getProfile"]),
            token() {
                return this.getProfile.token;
            },
            dateRangeText() {
                if (this.selectedDates.length == 0) {
                    return this.$t('alltime');
                } else {
                    return this.selectedDates.join(" ~ ");
                }
            },
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            dialogBatch(val) {
                val || this.closeBatch();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        mounted() {
            this.getDivisions();
            const curDate = new Date();
            let end = curDate.setDate(0);
            let start = new Date(end).setDate(1);

            this.selectedDates = [new Date(start).toLocaleDateString('en-GB').split("/").reverse().join("-"), new Date(end).toLocaleDateString('en-GB').split("/").reverse().join("-") ];
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            clearDates() {
                this.selectedDates = [...[]];
                this.activePeriod = this.$t('all');
            },
            saveDates(dates) {
                if (dates.length != 2) {
                    this.$refs.menu.save([]);
                } else {
                    this.$refs.menu.save(this.selectedDates);
                    this.getDataFromApi();
                }
            },
            clearSearch() {
                this.search = null;
                this.searchValue = this.search;

                this.$nextTick(() => {
                    this.options = {...this.options, page: 1}
                });
            },
            allcompanies(companies) {
                let arr = [];
                arr = [
                    {
                        id: 0,
                        name: this.$t('all'),
                    },
                    ...companies,
                ];
                return arr;
            },
            selectDivision() {
                this.options = {...this.options, page: 1}
            },
            selectStatus() {
                this.options = {...this.options, page: 1}
            },
            restoreData() {
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.dialog = false;
                });
            },
            getDataFromApi() {
                this.loading = true;

                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "firstname",
                    pagesize: this.options.itemsPerPage,
                    divisionid: this.filteredDivision,
                    search: this.searchValue,
                    status: this.status,
                    selectedDates: this.selectedDates,
                };
                apiCall(
                    "post",
                    "users/redeems",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    this.users = response.data.content;
                    this.users.forEach((elem) => {
                        switch (elem.name) {
                            case "Admin":
                                elem.roletext = this.$t('super_admin');
                                break;
                            case "CompanyAdmin":
                                elem.roletext = this.$t('company_admin');
                                break;
                            case "User":
                                elem.roletext = this.$t("non_driver_user");
                                break;
                            case "Driver":
                                elem.roletext = this.$t("driver");
                                break;
                            case "Manager":
                                elem.roletext = this.$t("manager");
                                break;
                            case "Supervisor":
                                elem.roletext = this.$t("supervisor");
                                break;
                            default:
                                elem.roletext = elem.name;
                                break;
                        }
                    });

                    this.totalUsers = response.data.totalElements;
                    this.loading = false;
                });
            },
            getDivisions() {
                apiCall("get", `divisions/getcompanydivisions`, true).then((response) => {
                    this.divisions = response.data;

                    this.filteredDivisions = [{id: 0, name: this.$t('all')}, ...response.data];
                });
            },
            setSearch() {
                this.searchValue = this.search;
                this.options = {...this.options, page: 1}
            },
            showError(text) {
                this.informationdialogText = text;
                this.informationdialogVisible = true;
            },
            confirmClick() {

            },
            parseAmount(item) {
                const res = parseInt(item.amount);
                if (res && res <= item.coins && res > 0) {
                    item.amount = res;
                }else {
                    item.amount = 0;
                }
            },
            checkAmount(item) {
                const res = parseInt(item.amount);
                return !(!this.inuse && this.selectedItems.length === 0 && res && res <= item.coins && res > 0);
            },
            showRedeem(item) {
                this.selectedItem = item;
                this.showConfirmation = true;
            },
            cancelRedeem() {
                this.showConfirmation = false;
                this.selectedItem = null;
                this.queueDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
            },
            confirmRedeem() {
                const params = {
                    id: this.selectedItem.id,
                    amount: this.selectedItem.amount
                };
                this.selectedItems = [params];
                this.confirmRedeemSelected();

            },
            showRedeemSelected() {
                this.showConfirmationSelected = true;
            },
            cancelRedeemSelected() {
                this.resetData();
                this.showConfirmationSelected = false;
                this.queueDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
            },
            confirmRedeemSelected() {
                const values = [...this.selectedItems.map(e => {
                    return {
                        userid: e.id,
                        coins: e.amount
                    }
                })];
                this.inuse = true;

                apiCall(
                    "post",
                    `users/redeemall`,
                    {data: {values, queueDate: this.queueDate}},
                    true
                ).then((response) => {
                    this.setSnackbar({
                        color: response.data.status == true ? "success" : "error",
                        text: response.data.result,
                        toggle: true,
                    });
                }).catch(e => {
                    this.setSnackbar({
                        color: "error",
                        text: e?.data?.result || this.$t('unknown_error'),
                        toggle: true,
                    });
                }).finally(() => {
                    this.getDataFromApi();
                    this.inuse = false;
                    this.showConfirmationSelected = false;
                    this.showConfirmation = false;
                    this.selectedItems = [];
                    this.queueDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
                });


            },
            prepareData() {
                this.users.filter(e => e.coins >= 1).forEach(e => e.amount = parseInt(e.coins));
                this.selectedItems = [...this.users.filter(e => e.coins >= 1)];
            },
            prepareDateTransferData() {
                this.users.filter(e => e.coins >= 1).forEach(e => e.amount = parseInt(e.rangedCoins));
                this.selectedItems = [...this.users.filter(e => e.rangedCoins >= 1)];
            },
            resetData() {
                this.users.filter(e => e.coins >= 1).forEach(e => e.amount = 0);
                this.selectedItems = [];
            },
            selectedHasErrors() {
                return this.selectedItems.filter(e => e.amount > e.coins).length > 0;
            },
            checkSelected() {
                return this.selectedItems.filter(e => e.amount >= 1).length === 0;
            }
        },
    };
</script>
<style scoped>
    .align-toolbar-start /deep/ .v-toolbar__content {
        align-items: flex-start !important;
    }

    .amount-input{
        margin-top: 0;
        padding-top: 0;
    }
    @media (max-width: 400px) {
        .add-user-btn,.upload-users-btn{
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100%;
            margin-top: 4px;
            margin-bottom: 4px;
        }

    }
    @media (max-width: 480px) {
        .users-table /deep/ .v-data-footer{
            justify-content: center;
            padding-bottom: 8px;
        }
        .users-table /deep/ .v-data-footer__pagination{
            display: none;
        }
        .users-table /deep/ .v-data-table__mobile-row__cell{
            overflow-wrap: anywhere;
        }
        .users-table /deep/ .v-data-footer__select .v-input{
            margin: 0 10px !important;
        }

    }

    @media (max-width: 1280px) {
        .flex-columned-toolbar >>> .v-toolbar__content {
            flex-wrap: wrap;
            justify-content: right;
        }

    }

    .scheduled-picker /deep/ .v-picker__title {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
</style>
