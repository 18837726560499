<template>
    <v-layout>
        <div class="col-12">
            <v-data-table
                    mobile-breakpoint="768"
                    :headers="headers"
                    :items="locations"
                    :server-items-length="totalLocations"
                    :loading="loading"
                    class="elevation-1 locations-table"
                    :footer-props="footerProps"
                    :options.sync="options"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('home_locations') }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    {{ $t('new_location')}}
                                </v-btn>
                            </template>
                            <v-card>
                                <validation-observer ref="observer" v-slot="{ invalid }">
                                    <v-form @submit.prevent="save">
                                        <v-card-title class="dialog-title">
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('name')"
                                                                rules="required"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    v-model="editedItem.name"
                                                                    :label="$t('name')"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('country')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-select
                                                                    :error-messages="errors"
                                                                    :items="countries"
                                                                    :label="$t('country')"
                                                                    v-model="editedItem.country"
                                                            ></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('address')"
                                                                rules="required"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    v-model="editedItem.address"
                                                                    :label="$t('address')"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('city')"
                                                                rules="required"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    v-model="editedItem.city"
                                                                    :label="$t('city')"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('state')"
                                                                rules="required"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    v-model="editedItem.state"
                                                                    :label="$t('state')"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('zipcode')"
                                                                rules="required"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    v-model="editedItem.zipcode"
                                                                    :label="$t('zipcode')"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('division')"
                                                                rules="required"
                                                        >
                                                            <v-select
                                                                    :error-messages="errors"
                                                                    v-model="selectedDivisions"
                                                                    :items="divisions"
                                                                    :label="$t('division')"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    multiple
                                                            ></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn class="cancel-button px-3" text @click="close">
                                                {{ $t('cancel') }}
                                            </v-btn>
                                            <v-btn
                                                    :class="{
                          'confirm-button px-5': !invalid,
                          'disabled px-5': invalid,
                        }"
                                                    text
                                                    type="submit"
                                                    :disabled="invalid"
                                                    :loading="inuse"
                                            >
                                                {{ $t('save') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </validation-observer>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon size="19" class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon size="19" v-if="item.canDelete" @click="deleteItem(item)"> mdi-delete</v-icon>
                </template>
            </v-data-table>
            <Confirmationdialog
                    :visible="dialogDelete"
                    @confirmationdialog:cancelClick="closeDelete"
                    @confirmationdialog:confirmClick="deleteItemConfirm"
            >
                <template v-slot:cardText>{{ $t('are_you_sure_delete') }}</template>
            </Confirmationdialog>
        </div>
    </v-layout>
</template>
<script>
    import {apiCall} from "@/utils/api.js";
    import {mapActions} from "vuex";
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import Confirmationdialog from "@/components/ConfirmationDialog";

    export default {
        name: "Locations",
        components: {
            ValidationObserver,
            ValidationProvider,
            Confirmationdialog,
        },
        data(context) {
            return {
                footerProps: {
                    "items-per-page-options": [10, 20, 50],
                },
                inuse: false,
                dialog: false,
                dialogDelete: false,
                totalLocations: 0,
                locations: [],
                divisions: [],
                loading: true,
                options: {},
                selectedDivisions: [],
                page: 1,
                perpage: 10,
                countries: [],
                headers: [
                    {text: context.$t('name'), value: "name", sortable: false},
                    {text: context.$t('address'), value: "address", sortable: false},
                    {text: context.$t('city'), value: "city", sortable: false},
                    {text: context.$t('state'), value: "state", sortable: false},
                    {text: context.$t('division'), value: "divisionnames", sortable: false},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                editedIndex: -1,
                editedItem: {
                    id: -1,
                    name: "",
                    country: "",
                    address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    divisionsid: [],
                },
                defaultItem: {
                    id: -1,
                    name: "",
                    country: "",
                    address: "",
                    city: "",
                    state: "",
                    zipcide: "",
                    divisionsid: [],
                },
            };
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('new_location') : this.$t('edit_location');
            },
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        mounted() {
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            getDivisions() {
                apiCall("get", `divisions/getcompanydivisions`, true).then((response) => {
                    this.divisions = response.data;
                });
            },
            getDataFromApi() {
                this.getDivisions();
                this.loading = true;

                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "id",
                    perpage: this.options.itemsPerPage,
                };

                apiCall(
                    "get",
                    "locations",
                    {
                        params: params,
                    },
                    true
                ).then((response) => {
                    this.locations = response.data.locations.content;
                    this.totalLocations = response.data.locations.totalElements;
                    this.countries = response.data.countryList;
                    this.loading = false;
                });
            },
            updateLocation() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "locations/update",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    if (response.data) {
                        this.setSnackbar({
                            color: response.data.status ? "success" : "error",
                            text: response.data.status
                                ? this.$t('location_successfully_updated')
                                : response.data.result,
                            toggle: true,
                        });

                        this.getDataFromApi();
                    } else {

                        if (response.data) {
                            this.setSnackbar({
                                color: "error",
                                text: this.$t('unknown_error'),
                                toggle: true,
                            });
                        }
                    }
                })
                    .catch(() => {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    }).finally(() => {
                    this.inuse = false;
                    this.loading = false;
                });
            },
            createLocation() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "locations/create",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    if (response.data) {
                        this.setSnackbar({
                            color: response.data.status ? "success" : "error",
                            text: response.data.status
                                ? this.$t('location_successfully_created')
                                : response.data.result,
                            toggle: true,
                        });

                        this.getDataFromApi();
                    } else {

                        if (response.data) {
                            this.setSnackbar({
                                color: "error",
                                text: this.$t('unknown_error'),
                                toggle: true,
                            });
                        }
                    }
                })
                    .catch(() => {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        })
                    }).finally(() => {
                    this.inuse = false;
                    this.loading = false;
                });

            },
            editItem(item) {
                this.editedIndex = item.id;
                this.editedItem.divisionsid = item.divisionsid;
                this.selectedDivisions = item.divisionsid;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },

            deleteItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },

            deleteItemConfirm() {
                // api remove
                this.deleteLocation();
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.selectedDivisions = [];
                });
                this.$refs.observer.reset();
            },

            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            deleteLocation() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "locations/delete",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    if (response.data) {
                        this.setSnackbar({
                            color: response.data.status ? "success" : "error",
                            text: response.data.status
                                ? this.$t('location_successfully_deleted')
                                : response.data.result,
                            toggle: true,
                        });

                        this.getDataFromApi();
                    } else {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    }
                }).catch(() => {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('unknown_error'),
                        toggle: true,
                    });
                }).finally(() => {
                    this.inuse = false;
                    this.loading = false;
                });
            },
            save() {
                if (this.editedIndex > -1) {
                    // edit
                    this.editedItem.id = this.editedIndex;
                    this.defaultItem.id = this.editedIndex;
                    this.editedItem.divisionsid = this.selectedDivisions;
                    this.updateLocation();
                } else {
                    // create
                    this.editedItem.divisionsid = this.selectedDivisions;
                    this.createLocation();
                }
                this.close();
            },
        },
    };
</script>
<style scoped>
    @media (max-width: 480px) {
        .locations-table /deep/ .v-data-footer{
            justify-content: center;
            padding-bottom: 8px;
        }
        .locations-table /deep/ .v-data-footer__pagination{
            display: none;
        }
        .locations-table /deep/ .v-data-footer__select .v-input{
            margin: 0 10px !important;
        }
    }
</style>
