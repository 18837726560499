<script>
import {mapActions, mapGetters} from "vuex";
import {apiCall} from "@/utils/api";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "NotificationsCenter",
  components: {ValidationObserver, ValidationProvider },
  data(context) {
    return {
      footerProps: {
        "items-per-page-options": [10, 20, 50],
      },
      inuse: false,
      invalidForm: true,
      totalUsers: 0,
      users: [],
      roles: [],
      selectableroles: [],
      companies: [],
      divisions: [],
      locations: [],
      loading: true,
      options: {
        sortBy: ["firstname"],
        sortDesc: [true]
      },
      filteredrole: 4,
      filteredcompany: 0,

      search: null,
      page: 1,
      headers: [
        {
          text: context.$t('employeeid'),
          align: "start",
          value: "employeeid",
          sortable: true,
        },
        {text: context.$t('firstname'), value: "firstname", sortable: true},
        {text: context.$t('lastname'), value: "lastname", sortable: true},
        {text: context.$t('email'), value: "username", sortable: true},
        {text: context.$t('company'), value: "company", sortable: true},
        {text: context.$t('date_created'), value: "createddate", sortable: false},
      ],
      searchValue: null,
      filteredDivision: 0,
      filteredLocation: 0,
      filteredDivisions: [{id: 0, name: context.$t('all')},],
      filteredLocations: [{id: 0, name: context.$t('all')},],
      selectedUsers: [],
      notification: {
        users: [],
        title: null,
        body: null
      },
      dialog: false,

    }
  },
  computed: {
    ...mapGetters("auth", ["getProfile"]),
    token() {
      return this.getProfile.token;
    },
    superadmin() {
      return this.getProfile.roles.includes("Admin");
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    selectedUsers(val) {
      let arr = [];

      console.log(val);
      val.forEach(e => arr.push(e.username));
      this.notification.users = [...arr];

    }
  },
  async mounted() {
    // this.getCompanies();

    if (this.superadmin) {
      await this.getCompanies();
      // this.getDataFromApi();
    } else {
      await this.getCompanyDivisions();
      // this.getDataFromApi();
    }
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    isSendDisabled() {
      return this.selectedUsers.length === 0;
    },
    setSearch() {
      this.searchValue = this.search;
      this.options = {...this.options, page: 1}
    },
    clearSearch() {
      this.search = null;
      this.searchValue = this.search;

      this.$nextTick(() => {
        this.options = {...this.options, page: 1}
      });
    },
    allcompanies(companies) {
      let arr = [];
      arr = [
        {
          id: 0,
          name: this.$t('all'),
        },
        ...companies,
      ];
      return arr;
    },
    async selectCompany() {
      await this.getFilteredDivisions();
      this.options = {...this.options, page: 1}
    },
    async selectDivision() {
      if(this.filteredDivision != 0){
        await this.getFilteredLocations();
      }else{
        this.locations = [{ id: 0, name: "All" }];
        this.selectedLocation = 0;
      }
      this.options = {...this.options, page: 1}
    },
    selectLocation() {
      this.options = {...this.options, page: 1}
    },
    async getCompanies() {
      apiCall("get", "companies/get", true).then((response) => {
        this.companies = response.data;
      });
    },
    async getCompanyDivisions() {
      await apiCall("get", `divisions/getcompanydivisions?exclude=leaderboard`, true).then(
          (response) => {
            this.filteredDivisions = [{ id: 0, name: "All" }, ...response.data];
            this.filteredDivision = response.data[0].id;
          }
      );
    },
    async getFilteredDivisions() {
      this.filteredDivision = 0;

      this.locations = [{ id: 0, name: "All" }];
      this.selectedLocation = 0;
      apiCall(
          "get",
          `divisions/get?companyid=${this.filteredcompany}`,
          true
      ).then((response) => {
        this.filteredDivisions = [{id: 0, name: this.$t('all')}, ...response.data];
      });
    },
    async getFilteredLocations() {
      this.filteredLocation = 0;
      apiCall(
          "get",
          `locations/get?divisionid=${this.filteredDivision}`,
          true
      ).then((response) => {

        this.filteredLocations = [{id: 0, name: this.$t('all')}, ...response.data];
      });
    },
    getDataFromApi() {
      this.loading = true;

      if (!this.superadmin) {
        this.filteredcompany = this.getProfile.companyid;
      }

      const params = {
        page: this.options.page - 1,
        sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
        sortField: this.options.sortBy[0] || "firstname",
        perpage: this.options.itemsPerPage,
        companyid: this.filteredcompany,
        divisionid: this.filteredDivision,
        locationid: this.filteredLocation,
        roleid: this.filteredrole,
        search: this.searchValue,
        status: "Active",
      };

      apiCall(
          "get",
          "users",
          {
            params: params,
          },
          true
      ).then((response) => {
        this.users = response.data.content;

        this.users.forEach((elem) => {
          switch (elem.name) {
            case "Admin":
              elem.roletext = this.$t('super_admin');
              break;
            case "CompanyAdmin":
              elem.roletext = this.$t('company_admin');
              break;
            case "User":
              elem.roletext = this.$t("non_driver_user");
              break;
            case "Driver":
              elem.roletext = this.$t("driver");
              break;
            case "Manager":
              elem.roletext = this.$t("manager");
              break;
            case "Supervisor":
              elem.roletext = this.$t("supervisor");
              break;
            default:
              elem.roletext = elem.name;
              break;
          }
        });

        this.totalUsers = response.data.totalElements;
        this.loading = false;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {

        this.notification = Object.assign({}, {
          users: [],
          title: null,
          body: null
        },);
      });
      this.$refs.observer.reset();
    },
      save() {
        this.sendNotification();
      },
      sendNotification() {
        this.inuse = true;
        this.loading = true;
        const params = Object.assign({}, this.notification);

        apiCall(
            "post",
            "notifications/create",
            {
              data: params,
            },
            true
        )
            .then((response) => {
              this.inuse = false;
              if (response?.data?.status) {
                this.setSnackbar({
                  color: "success",
                  text: "Notification created",
                  toggle: true,
                });
                this.close();
              } else {
                this.setSnackbar({
                  color: "error",
                  text: response.data.result,
                  toggle: true,
                });
              }
            })
            .catch((error) => {
              this.inuse = false;
              if (error?.data?.message) {
                this.setSnackbar({
                  color: "error",
                  text: error.data.message,
                  toggle: true,
                });
              } else {
                this.setSnackbar({
                  color: "error",
                  text: "Unknown error",
                  toggle: true,
                });
              }
            })
            .finally(() => {
              this.loading = false;
              this.selectedUsers = [];
            });
      },
    }
}
</script>

<template>
  <v-layout>
    <div class="col-12">
      <v-card>
        <v-card-title>{{ $t('notifications_center') }}</v-card-title>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form class="col-6" @submit.prevent="save">
            <v-card-text>
              <v-container>
                <v-row>
                  <!-- eslint-disable-next-line -->
                  <v-col cols="12">
                    <validation-provider
                        v-slot="{ errors }"
                        :name="$t('notification_title')"
                        rules="required"
                    >
                      <v-text-field
                          v-model="notification.title"
                          :label="$t('notification_title')"
                          :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                        v-slot="{ errors }"
                        :name="$t('notification_text')"
                        rules="required|max:150"
                    >
                      <v-textarea
                          :error-messages="errors"
                          :label="$t('notification_text')"
                          v-model="notification.body"
                          no-resize
                          counter="150"
                          height="100"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-dialog v-model="dialog">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      class="mb-2 confirm-button"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="invalid"
                  >
                    {{ $t('select_recipients') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-data-table
                      mobile-breakpoint="980"
                      :headers="headers"
                      :items="users"
                      :server-items-length="totalUsers"
                      :loading="loading"
                      :footer-props="footerProps"
                      :page="page"
                      :options.sync="options"
                      class="users-table"
                      show-select
                      v-model="selectedUsers"
                  >
                    <template v-slot:top>
                      <v-toolbar class="flex-columned-toolbar" height="auto" flat>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                          <v-text-field
                              v-model="search"
                              prepend-icon="mdi-magnify"
                              :label="$t('search')"
                              single-line
                              hide-details
                              clearable
                              @keyup.enter.prevent="setSearch()"
                              @click:clear="clearSearch()"
                          ></v-text-field>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                          <v-select
                              v-if="superadmin"
                              hide-details
                              v-model="filteredcompany"
                              :items="allcompanies(companies)"
                              :label="$t('company')"
                              item-text="name"
                              item-value="id"
                              @change="selectCompany"
                          >
                          </v-select>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
                          <v-select
                              hide-details
                              v-model="filteredDivision"
                              :items="filteredDivisions"
                              :label="$t('division')"
                              item-text="name"
                              item-value="id"
                              @change="selectDivision"
                          ></v-select>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
                          <v-select
                              v-model="filteredLocation"
                              :items="filteredLocations"
                              :label="$t('location')"
                              item-text="name"
                              item-value="id"
                              hide-details
                              @change="selectLocation"
                          ></v-select>
                        </v-col>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="cancel-button px-3" text @click="close">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="isSendDisabled()"
                        class="confirm-button px-5"
                        text
                        @click="sendNotification"
                    >
                      {{ $t('send_notification') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </div>
  </v-layout>
</template>

<style scoped>
</style>
