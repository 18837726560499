<template>
  <v-col>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="signin">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('email')"
          rules="required|email"
        >
          <v-text-field
            class="text-h6 my-2"
            type="text"
            :label="$t('email')"
            v-model="email"
            :error-messages="errors"
          />
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          :name="$t('password')"
          rules="required"
        >
          <v-text-field 
            :error-messages="errors" 
            class="text-h6 my-2"
            :label="$t('password')"
            v-model="password" 
            :append-icon="invisiblepassword ? 'mdi-eye-off' : 'mdi-eye' "
            @click:append=" () =>(invisiblepassword = !invisiblepassword) "
            :type="invisiblepassword ? 'password' : 'text' "/>
        </validation-provider>
        <v-btn
          height="70"
          class="rounded-0 my-6 text-h6"
          block
          color="secondary"
          depressed
          type="submit"
          :disabled="invalid"
          :loading="sending"
          >{{$t('login')}}</v-btn
        >
        <v-btn
          color="transparent"
          class="primary--text"
          depressed
          @click="$emit('changeform', 'reset')"
          >{{$t('password_forgot')}}</v-btn
        >
      </v-form>
    </validation-observer>
  </v-col>
</template>
<script>
// import { createSession } from "@/utils/session"
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    invisiblepassword: true,
    sending: false,
    valid: false,
    email: null,
    password: null,
  }),
  created() {},
  computed: {
    ...mapGetters("auth", ["getRolesArray"]),

    userHasRoleAdmin() {
      return this.getRolesArray?.includes("Admin");
    },
    userHasRoleCompany() {
      return (
        this.getRolesArray?.includes("Manager") ||
        this.getRolesArray?.includes("CompanyAdmin")
      );
    },
    userHasRoleSupervisor() {
      return this.getRolesArray?.includes("Supervisor");
    },
  },
  methods: {
    ...mapActions("auth", ["sendLoginData", "updatelang"]),
    ...mapActions("service", ["setSnackbar"]),
    validateForm() {
      if (this.$refs.form.validate()) {
        this.signin();
      } else {
        this.showValidationError();
      }
    },
    async signin() {
      this.sending = true;
      // this.loading = true;
      const data = {};
      (data.email = this.email), (data.password = this.password);

      this.sendLoginData(data)
        .then((response) => {
          const currentLang = localStorage.getItem("lang");
          if (!response.lang || currentLang !== response.lang) {
            this.updatelang();
          }

          if (this.userHasRoleAdmin) {
            this.$router.push("/companies");
          } else if (this.userHasRoleCompany) {
            this.$router.push("/drivers");
          } else if (this.userHasRoleSupervisor) {
            const hasGpsDataPage = this.getProfile?.hasGpsDataPage;
            const hasLeaderboard = this.getProfile?.hasLeaderboard;
            if (hasGpsDataPage) {
              this.$router.push("/gps-data");
            }
            if (hasLeaderboard) {
              this.$router.push("/leaderboard");
            }
            this.$router.push("/quizzes");
          }
        })
        .catch((error) => {
          this.sending = false;
          if (error.status == 401) {
            this.setSnackbar({
              color: "error",
              text: error.data.message,
              toggle: true,
            });
          } else if (error.status == 422) {
            this.setSnackbar({
              color: "error",
              text: error.data.result,
              toggle: true,
              timeout: 5000,
            });
          } else {
            this.setSnackbar({
              color: "error",
              text: "Unknown error",
              toggle: true,
            });
          }
        });
    },
  },
};
</script>