import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main'
import Companies from "@/views/Companies"
import DivisionsLocations from "@/views/Divisions_Locations"
import DefautBadges from "@/views/DefaultBadges"
import Locations from "@/views/Locations"
import Badges from "@/views/Badges"
import GpsData from "@/views/GpsData"
import Trips from "@/views/Trips"
import Profile from "@/views/Profile"
import DriverInfo from "@/views/DriverInfo"
import Quizzes from "@/views/Quizzes"
import LeaderBoard from "@/views/LeaderBoard"
import Reports from "@/views/Reports"
import Notifications from "@/views/Notifications";
import store  from '@/store'
import {apiCall} from "@/utils/api";
import SAUserListWrapper from "@/views/SAUserListWrapper";
import DriverListWrapper from "@/views/DriverListWrapper";
import Programs from "@/views/settings/Programs";
import SARedeemsWrapper from "@/views/SARedeemsWrapper";
import CompanyRedeemsWrapper from "@/views/CompanyRedeemsWrapper";
import Tos from "@/views/tos/Tos.vue";
import Privacy from "@/views/tos/Privacy.vue";
import Eula from "@/views/tos/Eula.vue";
import NotificationsCenter from "@/views/NotificationsCenter.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main,
    props: (route) => ({ token: route.query.token }),
    children: [
      {
        path: "/companies",
        name: "Companies",
        component: Companies,
        meta: {
          role: "Admin"
        }
      },
      {
        path: "/users",
        name: "Users",
        component: SAUserListWrapper,
        meta: {
          role: "Admin"
        }
      },
      {
        path: "/divisions",
        name: "Divisions",
        component: DivisionsLocations,
        meta: {
          role: "Admin"
        }
      },
      {
        path: "/defaultbadges",
        name: "DefautBadges",
        component: DefautBadges,
        meta: {
          role: "Admin"
        }
      },
      {
        path: "/settings-programs",
        name: "Programs",
        component: Programs,
        meta: {
          role: "Admin"
        }
      },
      {
        path: "/transfer-page",
        name: "SARedeems",
        component: SARedeemsWrapper,
        meta: {
          role: "Admin"
        }
      },
      {
        path: "/drivers",
        name: "Drivers",
        component: DriverListWrapper,
        meta: {
          role: "Company"
        }
      },
      {
        path: "/locations",
        name: "Locations",
        component: Locations,
        meta: {
          role: "Company"
        }
      },
      {
        path: "/badges",
        name: "Badges",
        component: Badges,
        meta: {
          role: "Company"
        }
      },
      {
        path: "/gps-data",
        name: "GpsData",
        component: GpsData,
        meta: {
          role: "Supervisor"
        }
      },
      {
        path: "/trips/:releaseid",
        name: "Trips",
        component: Trips,
        props: (route) => ({ releaseid: route.params.releaseid }),
        meta: {
          role: "Supervisor"
        }
      },
      {
        path: "/driver-data/:driverid",
        name: "Driverinfo",
        component: DriverInfo,
        props: (route) => ({ driverid: route.params.driverid }),
        meta: {
          role: "Supervisor"
        }
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/leaderboard",
        name: "LeaderBoard",
        component: LeaderBoard,
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
      },
      {
        path: "/quizzes",
        name: "Quizzes",
        component: Quizzes,
        meta: {
          role: "Supervisor"
        }

      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          role: "Company"
        }
      },
      {
        path: "/redeems",
        name: "Redeems",
        component: CompanyRedeemsWrapper,
        meta: {
          role: "Company"
        }
      },
      {
        path: "/emailconfirm",
        name: "EmailConfirm",
        component: Main,
        props: (route) => ({ token: route.query.token }),
      },
      {
        path: "/passwordreset",
        name: "PasswordResetConfirm",
        component: Main,
        props: (route) => ({ token: route.query.token }),
      },
      {
        path: "/notifications-center",
        name: "NotificationsCenter",
        component: NotificationsCenter,
        meta: {
          role: "Supervisor"
        }
      }
    ]
  },
  {
    path: '/tos',
    name: 'tos',
    component: Tos,
    props: (route) => ({ lang: route.query.lang }),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    props: (route) => ({ lang: route.query.lang }),
  },
  {
    path: '/eula',
    name: 'eula',
    component: Eula,
    props: (route) => ({ lang: route.query.lang }),
  },
  {
    path: '*',
    name: '404',
    component: () => import(/*webpackChunkName: "404"*/ "@/views/404.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function(to) {
    if (to.hash) {
      const element = document.getElementById(to.hash.replace(/#/, ''))
      if (element && element.scrollIntoView) {
        element.scrollIntoView({block: 'center', behavior: 'smooth'})
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const companyRoles = (store.state.auth && store.state.auth.profile && (store.state.auth.profile.roles.includes("CompanyAdmin") || store.state.auth.profile.roles.includes("Manager") || store.state.auth.profile.roles.includes("Supervisor")))
  if (companyRoles) {

    if (to.name === 'Redeems' && !store.state.auth.profile.tcmenable){
      next("/404");
    }

    apiCall("get", "notifications", {},true)
        .then(response => {
          try {
            const suggestions = response.data.result.suggestions;

            const newSuggestions = suggestions.filter(e =>
                !e.notificationseen && !e.completed
            );

            store.dispatch('auth/setSuggestions', newSuggestions.slice(0, 5))
            store.dispatch('auth/setSuggestionsCount', newSuggestions.length)

          }catch (e) {
            console.log(e);
          }
        })

    next();
  }else {
    next();
  }

})

export default router
