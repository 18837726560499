<template>
    <v-layout>
        <div class="col-12 badges">
            <v-card-title
            >{{ $t('badges') }}
                <v-spacer></v-spacer>
                <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
                    <v-select
                            v-model="selectedCompany"
                            :items="companies"
                            :label="$t('company')"
                            item-text="name"
                            item-value="id"
                            hide-details
                    ></v-select>
                </v-col>
                <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
                    <v-select
                            v-model="selectedDivision"
                            :items="divisions"
                            :label="$t('division')"
                            item-text="name"
                            item-value="id"
                            hide-details
                    ></v-select>
                </v-col>
                <v-dialog v-model="dialog" max-width="540px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on">
                            {{ $t('new_badge') }}
                        </v-btn>
                    </template>
                    <v-card>
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form @submit.prevent="save">
                                <v-card-title class="dialog-title">
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>
                                <v-tabs
                                        v-model="badgeItemStep"
                                        align-tabs="center"
                                >
                                    <v-tabs-slider color="primary"></v-tabs-slider>
                                    <v-tab>General info</v-tab>
                                    <v-tab :disabled="!isFinishedFirstStep || !validfile">{{ $t('additional_settings') }}</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="badgeItemStep">
                                    <v-tab-item>
                                        <v-card-text>
                                            <v-container>
                                                <div class="mb-5">
                                                    <validation-provider
                                                            v-if="renderedfiledrop"
                                                            ref="fileref"
                                                            v-slot="{ errors }"
                                                            :name="$t('image')"
                                                            rules="required|ext1:jpeg,jpg,png|size:1024"
                                                    >
                                                        <v-row class="justify-center">
                                                            <v-col class="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-8 col-xs-9">

                                                                <file-drop
                                                                        ref="filedrop"
                                                                        @fileselected="logFiles"
                                                                        :fileurl="editedItem.smallimage || null"
                                                                        :clear="clear"
                                                                        :errors="errors"
                                                                ></file-drop>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="justify-center">
                                                            <v-btn class="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-8 col-xs-9 primary px-3"
                                                                   text @click="()=> libraryDialog = true"> {{ $t('choose_from_library') }}
                                                            </v-btn>
                                                        </v-row>
                                                    </validation-provider>
                                                </div>
                                                <v-row>
                                                    <v-col class="py-0" cols="12">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('badge_name')"
                                                                rules="required"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    v-model="editedItem.name"
                                                                    :label="$t('badge_name')"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="6">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('points')"
                                                                rules="required|integer|min_value:0"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    type="number"
                                                                    v-model.number="editedItem.points"
                                                                    min="0"
                                                                    :label="$t('points')"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pt-0" cols="6">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('coins')"
                                                                rules="required|min_value:0"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    type="text"
                                                                    v-model.lazy="editedItem.coins"
                                                                    min="0"
                                                                    :label="$t('coins')"
                                                                    @change="changeCoins"

                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('description')"
                                                                rules="required"
                                                        >
                                                            <v-textarea
                                                                    :error-messages="errors"
                                                                    :label="$t('description')"
                                                                    v-model="editedItem.description"
                                                                    no-resize
                                                                    counter
                                                                    height="100"
                                                            ></v-textarea>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn class="cancel-button px-3" text @click="close">
                                                {{ $t('cancel') }}
                                            </v-btn>
                                            <v-btn
                                                    :disabled="!isFinishedFirstStep || !validfile"
                                                    class="confirm-button px-5"
                                                    text
                                                    @click="badgeItemStep = 1"
                                            >
                                                {{ $t('continue') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col class="py-0" cols="12">
                                                        <validation-provider
                                                                v-slot="{ errors }"
                                                                :name="$t('feed_type')"
                                                                rules="required"
                                                        >
                                                            <v-select
                                                                    :error-messages="errors"
                                                                    v-model="editedItem.feedType"
                                                                    :items="feedTypes"
                                                                    item-text="text"
                                                                    item-value="value"
                                                                    :label="$t('allow_on_recognition')"
                                                                    hide-details
                                                            ></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <template v-if="editedItem.type==='MANAGEMENT' || editedIndex === -1">
                                                        <v-col cols="12" class="d-flex">
                                                            <v-col cols="6">
                                                                <v-checkbox
                                                                        class="mt-0"
                                                                        hide-details
                                                                        v-model="editedItem.activeMonthlyLimit"
                                                                        :label="$t('monthly_limit_per_division_is_enabled')"
                                                                        @change="changeIsActiveMonthlyLimit"
                                                                ></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        :name="$t('monthly_division_limit')"
                                                                        rules="required|integer"
                                                                >
                                                                    <v-text-field
                                                                            :disabled="!editedItem.activeMonthlyLimit"
                                                                            :error-messages="errors"
                                                                            v-model="editedItem.monthlyLimit"
                                                                            :label="$t('monthly_limit_per_division')"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                        </v-col>
                                                        <v-col cols="12" class="d-flex">
                                                            <v-col cols="6">
                                                                <v-checkbox
                                                                        class="mt-0"
                                                                        hide-details
                                                                        v-model="editedItem.activeDriverLimit"
                                                                        :label="$t('monthly_limit_per_driver_is_enabled')"
                                                                        @change="changeIsDriverLimit"
                                                                ></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <validation-provider
                                                                        v-slot="{ errors }"
                                                                        :name="$t('monthly_driver_limit')"
                                                                        rules="required|integer"
                                                                >
                                                                    <v-text-field
                                                                            :disabled="!editedItem.activeDriverLimit"
                                                                            :error-messages="errors"
                                                                            v-model="editedItem.driverLimit"
                                                                            :label="$t('monthly_limit_per_driver')"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                        </v-col>
                                                    </template>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="blue darken-1" text @click="badgeItemStep = 0"
                                            >{{ $t('back') }}</v-btn
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn class="cancel-button px-3" text @click="close">
                                                {{ $t('cancel') }}
                                            </v-btn>
                                            <v-btn
                                                    :class="{
                      'confirm-button px-5': !invalid && validfile,
                      'disabled px-5': invalid || !validfile,
                    }"
                                                    text
                                                    type="submit"
                                                    :disabled="invalid || !validfile"
                                                    :loading="inuse"
                                            >
                                                {{ $t('save') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-form>
                        </validation-observer>
                    </v-card>
                </v-dialog>
            </v-card-title>
            <v-container v-bind="{ [`grid-list-md`]: true }" fluid>
                <v-layout row wrap>
                    <v-flex
                            v-for="(item, i) in badges" :key="i"
                            lg2 badgecard
                    >
                        <v-card class="d-flex flex-column pa-2" height="100%">
                            <v-img
                                    :src="item.smallimage"
                                    class="white--text align-end badgecard__image"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    aspect-ratio="1"
                                    contain
                                    height="150px"
                            >
                                <template v-slot:placeholder>
                                    <v-card
                                            color="rgb(0, 0, 0, 0)"
                                            class="d-flex justify-center"
                                            height="inherit"
                                    >
                                        <v-icon class="">mdi-image</v-icon>
                                    </v-card>
                                </template>
                            </v-img>

                            <v-card-title class="text-break">{{ item.name }}</v-card-title>
                            <v-card-subtitle class="pb-0">Points: {{ item.points }}</v-card-subtitle>
                            <v-card-subtitle class="pt-0">Coins: {{ item.coins.toFixed(2) }}</v-card-subtitle>
                            <v-card-text>{{ item.description }}</v-card-text>
                            <v-spacer></v-spacer>
                            <v-card-actions class="card-actions d-flex justify-space-between">
                                <v-btn color="primary" @click="editItem(item)" text
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                    Edit
                                </v-btn
                                >
                                <v-btn color="error" @click="deleteItem(item)" text
                                >
                                    <v-icon>mdi-delete</v-icon>
                                    Delete
                                </v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
                <Confirmationdialog
                        :visible="dialogDelete"
                        @confirmationdialog:cancelClick="closeDelete"
                        @confirmationdialog:confirmClick="deleteItemConfirm"
                >
                    <template v-slot:cardText>Are you sure you want to delete this item?</template>
                </Confirmationdialog>
            </v-container>
            <v-dialog v-model="libraryDialog" max-width="500px">
                <v-card>
                    <v-card-title class="text-h4 lighten-2">{{ $t('select_icon') }}</v-card-title>
                    <v-card-text class="mt-4 overflow-y-auto" style="min-height: 80%; max-height: 640px">
                        <v-list-item-group active-class="green--text" v-model="selectedLibrayFile">
                            <v-row>
                                <v-col
                                        v-for="(item, index) in libraryFiles"
                                        :key="index"
                                        class="d-flex child-flex"
                                        cols="3"
                                >
                                    <v-list-item class="no-padding select-library-file" :key="item.name"
                                                 :value="item.url">
                                        <v-img
                                                :src="item.url"
                                                :lazy-src="item.url"
                                        >
                                        </v-img>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-list-item-group>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeLibraryDialog">{{ $t('cancel') }}</v-btn>
                        <v-btn color="primary" text :disabled="selectedLibrayFile==null" @click="selectFileFromLibrary">
                            {{ $t('ok') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-layout>
</template>
<script>
    import {apiCall} from "@/utils/api.js";
    import FileDrop from "@/components/FileDrop.vue";
    import {mapGetters, mapActions} from "vuex";
    import {ValidationObserver, ValidationProvider } from "vee-validate";
    import Confirmationdialog from "@/components/ConfirmationDialog";
    import {mask} from 'vue-the-mask';
    import {getFileFromUrl} from "../utils/requests/requests";

    export default {
        components: {FileDrop, ValidationObserver, ValidationProvider, Confirmationdialog},
        name: "DefaultBadges",
        directives: {mask},
        data(context) {
            return {
                inuse: false,
                renderedfiledrop: true,
                validfile: false,
                dialog: false,
                dialogDelete: false,
                loading: false,
                badges: [],
                editedIndex: -1,
                clear: false,
                companies: [
                    {
                        name: context.$t('default'),
                        id: 0
                    }
                ],
                divisions: [
                    {
                        name: context.$t('default'),
                        id: 0
                    }
                ],
                feedTypes: [],
                selectedCompany: 0,
                selectedDivision: 0,
                badgeItemStep: 0,
                editedItem: {
                    id: 0,
                    name: null,
                    points: 0,
                    coins: 0,
                    description: null,
                    file: null,
                    divisionid: 0,
                    feedType: null,
                    monthlyLimit: 0,
                    driverLimit: 0,
                    activeMonthlyLimit: false,
                    activeDriverLimit: false
                },
                defaultItem: {
                    id: 0,
                    name: null,
                    points: 0,
                    coins: 0,
                    description: null,
                    file: null,
                    divisionid: 0,
                    feedType: null,
                    monthlyLimit: 0,
                    driverLimit: 0,
                    activeMonthlyLimit: false,
                    activeDriverLimit: false
                },
                libraryFiles: [],
                selectedLibrayFile: null,
                libraryDialog: false,
            };
        },
        computed: {
            isFinishedFirstStep() {
                const result = (this.editedItem.name !== null && this.editedItem.name !== '')
                    && this.editedItem.description?.length < 200
                    && ((parseInt(this.editedItem.points).toString().length === this.editedItem.points.toString().length) || this.editedItem.points === 0)
                    && ((parseFloat(this.editedItem.coins).toFixed(2).toString().length === this.editedItem.coins.toString().length) || this.editedItem.coins === 0);
                return result;
            },
            formTitle() {
                return this.editedIndex === -1 ? this.$t('new_badge') : this.$t('edit_badge');
            },
            ...mapGetters("auth", ["getProfile"]),
            token() {
                return this.getProfile.token;
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
                this.clear = !this.clear;
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            selectedCompany(newVal) {
                if (newVal != 0) {
                    this.getDivisions();
                } else {
                    this.divisions = [{
                        name: this.$t('default'),
                        id: 0
                    }];
                    this.selectedDivision = 0;
                }
            },
            selectedDivision() {
                this.getDataFromApi();
            }
        },
        mounted() {
            this.getCompanies();
            this.getDataFromApi();
        },
        methods: {
            closeLibraryDialog() {
                this.selectedLibrayFile = null;
                this.libraryDialog = false;
            },
            async selectFileFromLibrary() {
                if (this.selectedLibrayFile) {
                    const file = await getFileFromUrl(this.selectedLibrayFile, "name.png");

                    await this.logFiles([file], this.selectedLibrayFile);
                    this.libraryDialog = false;

                } else {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('unable_to_fetch_icon'),
                        toggle: true,
                    });
                }
            },
            async getCompanies() {
                await apiCall("get", "companies/get", true).then((response) => {
                    this.companies = [
                        {
                            name: this.$t('default'),
                            id: 0
                        },
                        ...response.data
                    ];
                });
            },
            getDivisions() {
                apiCall(
                    "get",
                    `divisions/get?companyid=${this.selectedCompany}`,
                    true
                ).then((response) => {
                    this.divisions = [...response.data];
                    this.selectedDivision = this.divisions[0].id;
                });
            },
            ...mapActions("service", ["setSnackbar"]),
            async logFiles(files, selectedFile = null) {
                const result = await this.$refs.fileref.validate(files ? files[0] : null);
                this.validfile = result.valid;
                if (files && this.validfile) {
                    if (selectedFile) {
                        this.editedItem.smallimage = selectedFile;
                        this.$refs.fileref.reset();
                        this.$refs.observer.reset();
                        this.clear = true;
                        this.renderedfiledrop = false;

                        this.$nextTick(() => {
                            this.renderedfiledrop = true;
                            this.clear = false;
                        });
                    }
                    this.editedItem.file = files[0];
                } else {
                    this.editedItem.file = null;
                }
            },
            getDataFromApi() {
                this.loading = true;

                apiCall(
                    "get",
                    `badges/get?divisionid=${this.selectedDivision}`,
                    true
                ).then((response) => {
                    this.badges = response.data.badges;

                    this.libraryFiles = response.data.libraryIcons;

                    const feedTypes = response.data.feedTypes;

                    feedTypes.forEach(e => {
                        switch (e) {
                            case "NoneType":
                                this.feedTypes.push({
                                    value: e,
                                    text: this.$t('none'),
                                });
                                break;
                            case "GlobalType":
                                this.feedTypes.push({
                                    value: e,
                                    text: this.$t('global'),
                                });
                                break;
                            case "DivisionType":
                                this.feedTypes.push({
                                    value: e,
                                    text: this.$t('within_division'),
                                });
                                break;
                            case "LocationType":
                                this.feedTypes.push({
                                    value: e,
                                    text: this.$t('within_home_location'),
                                });
                                break;
                        }
                    });

                    this.loading = false;
                });
            },
            close() {
                this.dialog = false;
                this.badgeItemStep = 0;
                this.renderedfiledrop = false;
                this.$nextTick(() => {
                    this.clear = true;
                    this.$refs.observer.reset();
                    this.renderedfiledrop = true;
                    this.validfile = false;
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.selectedLibrayFile = null;
                });

            },
            deleteItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                // api remove
                this.deleteBadge();
                this.closeDelete();
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            editItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.editedItem.coins = this.editedItem.coins.toFixed(2);
                this.editedItem.feedType = item.feedType;
                this.editedItem.driverLimit = item.driverLimit ||  0;
                this.editedItem.monthlyLimit = item.monthlyLimit ||  0;

                this.dialog = true;
                this.validfile = item.smallimage != null ? true : false;
            },
            save() {
                this.editedItem.divisionid = this.selectedDivision;
                if (this.editedIndex > -1) {
                    // edit
                    this.editedItem.id = this.editedIndex;
                    this.defaultItem.id = this.editedIndex;
                    this.updateBadge();
                } else {
                    // create
                    this.createBadge();
                }
                this.close();
            },
            createBadge() {
                this.inuse = true;
                this.loading = true;
                const params = this.mapItemToPayload(this.editedItem);

                let formData = new FormData();

                for (let key in params) {
                    formData.append(key, params[key]);
                }

                const headers = {
                    "Content-Type": "multipart/form-data"
                };

                if (this.file) {
                    formData.append("file", this.file);
                }
                apiCall(
                    "post",
                    "badges/create",
                    formData,
                    true,
                    null,
                    headers,
                    false
                )
                    .then((response) => {
                        this.badges = response.data;
                        this.loading = false;
                        this.inuse = false;
                    })
                    .catch((error) => {
                        this.inuse = false;
                        if (error?.response?.data?.message) {
                            this.setSnackbar({
                                color: "error",
                                text: error.response.data.message,
                                toggle: true,
                            });
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: this.$t('unknown_error'),
                                toggle: true,
                            });
                        }
                    });
            },
            updateBadge() {
                this.inuse = true;
                this.loading = true;
                const params = this.mapItemToPayload(this.editedItem);

                let formData = new FormData();

                for (let key in params) {
                    formData.append(key, params[key]);
                }

                const headers = {
                    "Content-Type": "multipart/form-data"
                };

                if (this.file) {
                    formData.append("file", this.file);
                }

                apiCall(
                    "post",
                    "badges/update",
                    formData,
                    true,
                    null,
                    headers,
                    false
                )
                    .then(() => {
                        this.inuse = false;
                        this.getDataFromApi();
                    })
                    .catch((error) => {
                        this.inuse = false;
                        if (error?.response?.data?.message) {
                            this.setSnackbar({
                                color: "error",
                                text: error.response.data.message,
                                toggle: true,
                            });
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: this.$t('unknown_error'),
                                toggle: true,
                            });
                        }
                    });
            },
            deleteBadge() {
                this.loading = true;
                const params = this.editedItem;

                apiCall("post", "badges/delete", {data: params}, true).then(
                    (response) => {
                        this.badges = response.data;
                        this.loading = false;
                    }
                );
            },
            changeCoins() {
                this.editedItem.coins = parseFloat(this.editedItem.coins) ? parseFloat(this.editedItem.coins).toFixed(2) : (0).toFixed(2);
            },
            changeIsActiveMonthlyLimit(value) {
                if (!value) {
                    this.editedItem.monthlyLimit = 0;
                }
            },
            changeIsDriverLimit(value) {
                if (!value) {
                    this.editedItem.driverLimit = 0;
                }
            },
            mapItemToPayload(editedItem) {
                let value = {};

                value.id = editedItem.id;
                value.name = editedItem.name;
                value.description = editedItem.description;
                value.points = editedItem.points;
                value.coins = editedItem.coins;
                value.feedType = editedItem.feedType;
                value.divisionid = editedItem.divisionid;
                value.feedType = editedItem.feedType;
                value.activeMonthlyLimit = editedItem.activeMonthlyLimit;
                value.activeDriverLimit = editedItem.activeDriverLimit;

                if (editedItem.file) {
                    value.file = editedItem.file;
                }

                if (editedItem.activeMonthlyLimit) {
                    value.monthlyLimit = editedItem.monthlyLimit;
                }

                if (editedItem.activeDriverLimit) {
                    value.driverLimit = editedItem.driverLimit;
                }

                return value;
            }
        },
    };
</script>
<style scoped>
    .upload-input >>> .v-text-field__slot {
        min-height: 35px;
    }

    .no-padding {
        padding: 0 !important;
    }

    .select-library-file.v-list-item--active::before, .select-library-file.v-list-item--active:hover::before {
        opacity: 0.45;
        border: 1px solid #ff1493;
    }

    @media (max-width: 1679px) {
        .badges /deep/ .layout .badgecard {
            flex-basis: 25%;
            flex-grow: 0;
            max-width: 25%;
        }
    }

    @media (max-width: 1280px) {
        .badges /deep/ .layout .badgecard {
            flex-basis: 33.3333333333%;
            flex-grow: 0;
            max-width: 33.3333333333%;
        }
    }

    @media (max-width: 1024px) {
        .badges /deep/ .layout .badgecard {
            flex-basis: 33.3333333333%;
            flex-grow: 0;
            max-width: 33.3333333333%;
        }
    }

    @media (max-width: 968px) {
        .badges /deep/ .layout .badgecard {
            flex-basis: 50%;
            flex-grow: 0;
            max-width: 50%;
        }
    }

    @media (max-width: 720px) {
        .badges /deep/ .layout .badgecard {
            flex-basis: 100%;
            flex-grow: 0;
            max-width: 100%;
        }
    }

    .badgecard__image {
        flex-basis: 150px;
        flex-grow: 0;
        max-height: 150px;
    }
</style>
