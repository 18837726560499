<template>
  <v-col>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="sendRestoreRequest">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('email')"
          rules="required|email"
        >
          <v-text-field
            class="text-h6 my-2"
            type="text"
            :label="$t('email')"
            v-model="email"
            :error-messages="errors"
          />
        </validation-provider>
        <v-btn
          height="70"
          class="rounded-0 my-6 text-h6"
          block
          color="secondary"
          depressed
          type="submit"
          :disabled="invalid"
          :loading="sending"
          >{{ $t('send_password_reset')}}</v-btn
        >
        <v-btn
          color="transparent"
          class="primary--text"
          depressed
          @click="$emit('changeform', 'login')"
          >{{ $t('to_login') }}</v-btn
        >
      </v-form>
    </validation-observer>
  </v-col>
</template>
<script>
import { apiCall } from "@/utils/api.js";
import { mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";


export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    sending: false,
    valid: false,
    email: null,
    requiredRule: [(v) => !!v || "Required field"],
  }),
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    validateForm() {
      if (this.$refs.form.validate()) {
        this.sendRestoreRequest();
      } else {
        this.showValidationError();
      }
    },
    sendRestoreRequest() {
      this.sending = true;
      const params = {
        email: this.email,
      };
      apiCall(
        "post",
        "auth/passwordreset",
        {
          data: params,
        },
        false
      ).then((response) => {
        this.sending = false;
           this.setSnackbar({
              color: response.data.status == true ? "success" : "error",
              text: response.data.message,
              toggle: true,
            });
      }).catch( e => {
        this.sending = false;
          console.log(e);
          this.setSnackbar({
              color: "error",
              text: this.$t('unknown_error'),
              toggle: true,
            });
      })

    },
  },
};
</script>