<script>
import {mapActions, mapGetters} from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {apiCall} from "@/utils/api";

const companyUpdatePayload = {
  id: 0,
  name: "",
  driverlimit: 0,
  userlimit: 0,
  companykey: "",
  activesuggestions: false,
  userImportTypeid: 1,
  activetab: false,
  activeurl: false,
  latebuffer: 0,
  tcmenable: false,
};

export default {
  name: "CompanyDialog",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    editedIndex: {
      default: -1,
      required: true
    },
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    userimportList: {
      type: [],
      default: [],
      required: true
    },
    companyData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      editedItem: {
        id: 0,
        name: "",
        companykey: "",
        driverlimit: 0,
        userlimit: 0,
        activetab: false,
        activeurl: false,
        activesuggestions: false,
        activelatebuffer: false,
        activabadgefeed: false,
        latebuffer: 0,
        userImportList: [],
        activenewhirebuffer: false,
        newhirebuffer: 0,
        tcmenable: false,
        companyAdminTemplateId: null,
        managerTemplateId: null,
        supervisorTemplateId: null,
        driverTemplateId: null,
        resetPasswordTemplateId: null,
        resetPasswordMobileTemplateId: null
      },
      defaultItem: {
        id: 0,
        name: "",
        companykey: "",
        driverlimit: 0,
        userlimit: 0,
        activetab: false,
        activeurl: false,
        activesuggestions: false,
        latebuffer: 0,
        activelatebuffer: false,
        activebadgefeed: false,
        userImportList: [],
        activenewhirebuffer: false,
        newhirebuffer: 0,
        tcmenable: false,
        companyAdminTemplateId: null,
        managerTemplateId: null,
        supervisorTemplateId: null,
        driverTemplateId: null,
        resetPasswordTemplateId: null,
        resetPasswordMobileTemplateId: null
      },
      userImportTypeid: 1
    }
  },
  mounted() {
    this.editedItem = Object.assign({}, this.companyData);
  },
  watch: {
    editedIndex(newVal){
      if (newVal != -1 ){
        this.editedItem = Object.assign({}, this.companyData);
        this.userImportTypeid = this.companyData.userImport.id;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getProfile"]),
    formTitle() {

      return this.editedIndex === -1 ? this.$t('new_company') : this.$t('edit_company');
    },
    isVisible : {
      get: function () { return this.visible; },
      set: function () { this.$emit('companydialog:cancel'); }
    },
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    changeIsHireBufferActive(value) {
      if (!value) {
        this.editedItem.newhirebuffer = 0;
      }
    },
    close() {
      this.$nextTick(() => {
        this.$refs.observer?.reset();
        this.$refs.observer1.reset();
        this.$refs.observer2.reset();
        this.step = 1;
        Object.assign( this.editedItem, this.defaultItem);
        this.userImportTypeid = 1;
        this.$emit('companydialog:cancel');
      });
    },
    mapItemToPayload(editedItem) {
      let value = Object.assign({} , companyUpdatePayload);

      value.id = editedItem.id;
      value.name = editedItem.name;
      value.driverlimit = editedItem.driverlimit;
      value.userlimit = editedItem.userlimit;
      value.companykey = editedItem.companykey;
      value.activesuggestions = editedItem.activesuggestions;
      value.activebadgefeed = editedItem.activebadgefeed;
      value.userImportTypeid = this.userImportTypeid;
      value.activetab = editedItem.activetab;
      value.activeurl = editedItem.activeurl;
      value.latebuffer = editedItem.latebuffer;
      value.newhirebuffer = editedItem.newhirebuffer;
      value.activenewhirebuffer = editedItem.activenewhirebuffer;
      value.tcmenable = editedItem.tcmenable;
      value.companyAdminTemplateId = editedItem.companyAdminTemplateId ;
      value.managerTemplateId = editedItem.managerTemplateId;
      value.supervisorTemplateId = editedItem.supervisorTemplateId;
      value.driverTemplateId = editedItem.driverTemplateId;
      value.resetPasswordTemplateId = editedItem.resetPasswordTemplateId;
      value.resetPasswordMobileTemplateId = editedItem.resetPasswordMobileTemplateId;
      console.log(value);

      return value;
    },
    submit() {
      if (this.editedIndex > -1) {
        // edit
        this.editedItem.id = this.editedIndex;
        this.defaultItem.id = this.editedIndex;
        this.updateCompany();
      } else {
        // create
        this.createCompany();
      }
    },
    createCompany() {
      this.inuse = true;
      this.loading = true;
      const params = this.mapItemToPayload(this.editedItem);

      apiCall(
          "post",
          "companies/create",
          {
            data: params,
          },
          true
      )
          .then((response) => {
            this.inuse = false;
            if (response?.data?.status) {
              this.setSnackbar({
                color: "success",
                text: "Company created",
                toggle: true,
              });
              this.$emit('companydialog:create');
              this.close();
            } else {
              this.setSnackbar({
                color: "error",
                text: response.data.result,
                toggle: true,
              });
            }
          })
          .catch((error) => {
            this.inuse = false;
            if (error?.data?.message) {
              this.setSnackbar({
                color: "error",
                text: error.data.message,
                toggle: true,
              });
            } else {
              this.setSnackbar({
                color: "error",
                text: "Unknown error",
                toggle: true,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    updateCompany() {
      this.inuse = true;
      this.loading = true;
      const params = this.mapItemToPayload(this.editedItem);

      apiCall(
          "post",
          "companies/update",
          {
            data: params,
          },
          true
      )
          .then((response) => {
            this.inuse = false;
            if (response?.data?.status) {
              this.setSnackbar({
                color: "success",
                text: "Company updated",
                toggle: true,
              });
              this.close();
              this.$emit('companydialog:create');
            } else {
              this.setSnackbar({
                color: "error",
                text: response.data.result,
                toggle: true,
              });
            }
          })
          .catch((error) => {
            this.inuse = false;
            if (error?.data?.message) {
              this.setSnackbar({
                color: "error",
                text: error.data.message,
                toggle: true,
              });
            } else {
              this.setSnackbar({
                color: "error",
                text: "Unknown error",
                toggle: true,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  }
}
</script>

<template>
  <v-dialog v-model="isVisible" max-width="720px" persistent>
    <v-card>
      <template v-if="editedIndex === -1">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              {{ $t('general_data') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              {{ $t('company_settings') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              {{ $t('email_settings') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="step = 2">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('company_name')"
                              rules="required"
                          >
                            <v-text-field
                                v-model="editedItem.name"
                                :label="$t('company_name')"
                                :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('company_key')"
                              rules="required"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.companykey"
                                :label="$t('company_key')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('driver_limit')"
                              rules="required|numeric|min_value:0"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.driverlimit"
                                :label="$t('driver_limit')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('non_driver_limit')"
                              rules="required|numeric|min_value:0"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.userlimit"
                                :label="$t('non_driver_limit')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" class="cancel-button px-3" text>
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="invalid"
                        class="confirm-button px-5"
                        text
                        type="submit"
                    >
                      {{ $t('continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-stepper-content>
            <v-stepper-content step="2">
              <validation-observer ref="observer1" v-slot="{ invalid }">
                <v-form @submit.prevent="step = 3">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activeurl"
                              :label="$t('show_awardco_link_in_app')"

                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activetab"
                              :label="$t('allow_user_point_redemption')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activesuggestions"
                              :label="$t('show_suggestion_box')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activebadgefeed"
                              :label="$t('show_badges_feed')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                              hide-details
                              v-model="userImportTypeid"
                              :items="userimportList"
                              :label="$t('user_import_type')"
                              item-text="text"
                              item-value="id"

                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('late_buffer')"
                              rules="required|min_value:0|max_value:99|integer"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.latebuffer"
                                :label="$t('late_buffer_min')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <v-col cols="6" class="pl-0">
                            <v-checkbox
                                class="mt-0"
                                hide-details
                                v-model="editedItem.activenewhirebuffer"
                                :label="$t('new_hire_buffer_is_enabled')"
                                @change="changeIsHireBufferActive"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                                v-slot="{ errors }"
                                :name="$t('new_hire')"
                                rules="required|min_value:0|integer"
                            >
                              <v-text-field
                                  :disabled="!editedItem.activenewhirebuffer"
                                  :error-messages="errors"
                                  v-model="editedItem.newhirebuffer"
                                  :label="$t('new_hire_buffer_days')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.tcmenable"
                              :label="$t('enable_managed_controller_transfer')"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 1" color="blue darken-1" text
                    >{{ $t('back') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" class="cancel-button px-3" text>
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="invalid"
                        class="confirm-button px-5"
                        text
                        type="submit"
                    >
                      {{ $t('continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </validation-observer>


            </v-stepper-content>
            <v-stepper-content step="3">
              <validation-observer ref="observer2" v-slot="{ invalid }">
              <v-form @submit.prevent="submit">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-card-text>{{ $t('please_leave_empty') }}</v-card-text>
                      <v-col cols="12" class="py-0">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('company_admin_template_id')"
                            rules="numeric|min_value:1"
                        >
                          <v-text-field
                              :error-messages="errors"
                              v-model="editedItem.companyAdminTemplateId"
                              :label="$t('company_admin_template_id')"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('manager_template_id')"
                            rules="numeric|min_value:1"
                        >
                          <v-text-field
                              :error-messages="errors"
                              v-model="editedItem.managerTemplateId"
                              :label="$t('manager_template_id')"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('supervisor_template_id')"
                            rules="numeric|min_value:1"
                        >
                          <v-text-field
                              :error-messages="errors"
                              v-model="editedItem.supervisorTemplateId"
                              :label="$t('supervisor_template_id')"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('driver_template_id')"
                            rules="numeric|min_value:1"
                        >
                          <v-text-field
                              :error-messages="errors"
                              v-model="editedItem.driverTemplateId"
                              :label="$t('driver_template_id')"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('reset_password_template_id')"
                            rules="numeric|min_value:1"
                        >
                          <v-text-field
                              :error-messages="errors"
                              v-model="editedItem.resetPasswordTemplateId"
                              :label="$t('reset_password_template_id')"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <validation-provider
                            v-slot="{ errors }"
                            :name="$t('reset_password_mobile_template_id')"
                            rules="numeric|min_value:1"
                        >
                          <v-text-field
                              :error-messages="errors"
                              v-model="editedItem.resetPasswordMobileTemplateId"
                              :label="$t('reset_password_mobile_template_id')"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="step = 2" color="blue darken-1" text
                  >{{ $t('back') }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="close" class="cancel-button px-3" text>
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      :disabled="invalid"
                      class="confirm-button px-5"
                      text
                      type="submit"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
              </validation-observer>
            </v-stepper-content>
          </v-stepper-items>

        </v-stepper>

      </template>
      <template v-else>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              {{ $t('general_data') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              {{ $t('company_settings') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              {{ $t('email_settings') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="step = 2">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('company_name')"
                              rules="required"
                          >
                            <v-text-field
                                v-model="editedItem.name"
                                :label="$t('company_name')"
                                :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('company_key')"
                              rules="required"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.companykey"
                                :label="$t('company_key')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('driver_limit')"
                              rules="required|numeric|min_value:0"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.driverlimit"
                                :label="$t('driver_limit')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('non_driver_limit')"
                              rules="required|numeric|min_value:0"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.userlimit"
                                :label="$t('non_driver_limit')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" class="cancel-button px-3" text>
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="invalid"
                        class="confirm-button px-5"
                        text
                        type="submit"
                    >
                      {{ $t('continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-stepper-content>
            <v-stepper-content step="2">
              <validation-observer ref="observer1" v-slot="{ invalid }">
                <v-form @submit.prevent="step = 3">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activeurl"
                              :label="$t('show_awardco_link_in_app')"

                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activetab"
                              :label="$t('allow_user_point_redemption')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activesuggestions"
                              :label="$t('show_suggestion_box')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.activebadgefeed"
                              :label="$t('show_badges_feed')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                              hide-details
                              v-model="userImportTypeid"
                              :items="userimportList"
                              :label="$t('user_import_type')"
                              item-text="text"
                              item-value="id"

                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('late_buffer')"
                              rules="required|min_value:0|max_value:99|integer"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.latebuffer"
                                :label="$t('late_buffer_min')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <v-col cols="6" class="pl-0">
                            <v-checkbox
                                class="mt-0"
                                hide-details
                                v-model="editedItem.activenewhirebuffer"
                                :label="$t('new_hire_buffer_is_enabled')"
                                @change="changeIsHireBufferActive"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                                v-slot="{ errors }"
                                :name="$t('new_hire')"
                                rules="required|min_value:0|integer"
                            >
                              <v-text-field
                                  :disabled="!editedItem.activenewhirebuffer"
                                  :error-messages="errors"
                                  v-model="editedItem.newhirebuffer"
                                  :label="$t('new_hire_buffer_days')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <v-checkbox
                              class="mt-0"
                              hide-details
                              v-model="editedItem.tcmenable"
                              :label="$t('enable_managed_controller_transfer')"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 1" color="blue darken-1" text
                    >{{ $t('back') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" class="cancel-button px-3" text>
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="invalid"
                        class="confirm-button px-5"
                        text
                        type="submit"
                    >
                      {{ $t('continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </validation-observer>


            </v-stepper-content>
            <v-stepper-content step="3">
              <validation-observer ref="observer2" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-card-text>{{ $t('please_leave_empty') }}</v-card-text>
                        <v-col cols="12" class="py-0">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('company_admin_template_id')"
                              rules="numeric|min_value:1"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.companyAdminTemplateId"
                                :label="$t('company_admin_template_id')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('manager_template_id')"
                              rules="numeric|min_value:1"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.managerTemplateId"
                                :label="$t('manager_template_id')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('supervisor_template_id')"
                              rules="numeric|min_value:1"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.supervisorTemplateId"
                                :label="$t('supervisor_template_id')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('driver_template_id')"
                              rules="numeric|min_value:1"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.driverTemplateId"
                                :label="$t('driver_template_id')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('reset_password_template_id')"
                              rules="numeric|min_value:1"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.resetPasswordTemplateId"
                                :label="$t('reset_password_template_id')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <validation-provider
                              v-slot="{ errors }"
                              :name="$t('reset_password_mobile_template_id')"
                              rules="numeric|min_value:1"
                          >
                            <v-text-field
                                :error-messages="errors"
                                v-model="editedItem.resetPasswordMobileTemplateId"
                                :label="$t('reset_password_mobile_template_id')"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 2" color="blue darken-1" text
                    >{{ $t('back') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" class="cancel-button px-3" text>
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="invalid"
                        class="confirm-button px-5"
                        text
                        type="submit"
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-stepper-content>
          </v-stepper-items>

        </v-stepper>
      </template>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>

