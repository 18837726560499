<template>
  <v-layout class="trips">
    <div class="col-12">
      <v-card-title
      >{{ $t('trip_data_date_range') }} : {{ dateTitle }}
        <v-spacer></v-spacer>
        <v-col cols="12" class="mx-0 px-0">
          <v-sheet
                  class="d-flex justify-center align-center note-class font-weight-normal text-subtitle-1"
                  color="#a4d6e8"
                  height="40"
                  width="250"
          >{{$t('note_time_format')}}</v-sheet
          >
        </v-col>
      </v-card-title>

      <v-container fluid>
        <v-col cols="12" class="d-flex ma-0 pa-0 flex-wrapper">
          <v-col class="py-4 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 my-2 col-12">
            <v-select
                    v-model="activeLocationID"
                    :items="locations"
                    :label="$t('home_location')"
                    item-text="name"
                    item-value="id"
                    @change="changeLocation"
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-sheet
                  min-width="120"
                  color="transparent"
                  class="d-flex flex-column align-center py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-1 my-2 col-12"
          >
            <span class="font-weight-bold text-subtitle-1">Color legend</span>
            <v-col class="d-flex justify-space-between">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge color="#fda80e"></v-badge>
                  </span>
                </template>
                <span>{{ $t('no_perfect_attendance') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge color="#008aff"></v-badge>
                  </span>
                </template>
                <span>{{ $t('late_arrival')}}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge color="#ffff00"></v-badge>
                  </span>
                </template>
                <span>{{ $t('no_show') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge color="#f00"></v-badge>
                  </span>
                </template>
                <span
                >{{ $t('error_data')}}</span
                >
              </v-tooltip>
            </v-col>
          </v-sheet>
        </v-col>
        <v-col cols="12" class="days-wrapper--desktop ma-0 pa-0">
          <!-- eslint-disable-next-line -->
          <v-btn
                  @click="setActiveDate(date.value)"
                  :class="{ primary: activeDay == date.value }"
                  elevation="0"
                  v-for="(date, i) in days"
                  :key="i"
          >
            {{ date.key }}
          </v-btn>
        </v-col>
        <v-col class="days-wrapper--mobile">
          <v-select
                  :value="activeDay"
                  :items="days"
                  :label="$t('day')"
                  item-text="key"
                  item-value="value"
                  @change="setActiveDate"
          ></v-select>
        </v-col>
        <template v-if="!isreleased">
          <v-data-table
                  class="active-data-table elevation-1"
                  :mobile-breakpoint="1400"
                  v-if="!isreleased"
                  :headers="headers"
                  :items="trips"
                  :loading="loading"
                  hide-default-footer
                  :options="options"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.number="{ item }">
              <div class="text-center">{{ trips.indexOf(item) + 1 }}</div>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.driver="{ item }">
              <div v-if="!item.isNew" class="text-center">{{ item.driver }}</div>
              <div class="new-driver-wrapper" v-else>
                <v-select

                        v-model="item.userid"
                        :items="driversList"
                        :label="$t('driver')"
                        item-text="value"
                        item-value="id"
                        @change="changeNewRow(item)"
                ></v-select>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.employeeID="{ item }">
              <div class="text-center">{{ item.employeeID }}</div>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.truck="{ item }">
              <v-text-field
                      class="truck-input"
                      required
                      outlined
                      v-model="item.truck"
                      hide-details="auto"
              ></v-text-field>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.visibleworkdate="{ item }">
              <v-dialog
                      v-model="item.datepickeractive"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                          class="workdate-input"
                          outlined
                          v-model="item.visibleworkdate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                        v-model="item.visibleworkdate"
                        @input="item.datepickeractive = false"
                ></v-date-picker>
              </v-dialog>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.sheduled="{ item }">
              <v-col class="d-flex" :class="getSheduledClasses(item)">
                <v-text-field
                        outlined
                        class="mx-1 part-year-input"
                        required
                        v-model.number="item.sheduledYear"
                        hide-details="auto"
                        maxlength="4"
                ></v-text-field>
                <span class="date-divider align-self-end">-</span>
                <v-text-field
                        maxlength="2"
                        class="mx-1 part-date-input"
                        required
                        outlined
                        v-model="item.sheduledMonth"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">-</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        required
                        class="mx-1 part-date-input"
                        v-model="item.sheduledDay"
                        hide-details="auto"
                ></v-text-field>
                <span class="mx-1 align-self-center font-weight-bold">{{$t('time')}}</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        required
                        class="mx-1 part-date-input"
                        v-model="item.sheduledHour"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">:</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        required
                        class="mx-1 part-date-input"
                        v-model="item.sheduledMin"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">:</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        required
                        class="mx-1 part-date-input"
                        v-model="item.sheduledSec"
                        hide-details="auto"
                ></v-text-field>
              </v-col>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actualLogin="{ item }">
              <v-col class="d-flex" :class="getLoginClasses(item)">
                <v-text-field
                        maxlength="4"
                        class="mx-1 part-year-input"
                        outlined
                        required
                        v-model="item.actualLoginYear"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">-</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        class="mx-1 part-date-input"
                        required
                        v-model="item.actualLoginMonth"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">-</span>
                <v-text-field
                        maxlength="2"
                        required
                        outlined
                        class="mx-1 part-date-input"
                        v-model="item.actualLoginDay"
                        hide-details="auto"
                ></v-text-field>
                <span class="mx-1 align-self-center font-weight-bold">{{$t('time')}}</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        required
                        class="mx-1 part-date-input"
                        v-model="item.actualLoginHour"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">:</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        required
                        class="mx-1 part-date-input"
                        v-model="item.actualLoginMin"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">:</span>
                <v-text-field
                        maxlength="2"
                        outlined
                        required
                        class="mx-1 part-date-input"
                        v-model="item.actualLoginSec"
                        hide-details="auto"
                ></v-text-field>
              </v-col>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actualLogout="{ item }">
              <v-col class="d-flex" :class="getLogoutClasses(item)">
                <v-text-field
                        maxlength="4"
                        class="mx-1 part-year-input"
                        outlined
                        required
                        v-model="item.actualLogoutYear"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">-</span>
                <v-text-field
                        maxlength="2"
                        class="mx-1 part-date-input"
                        outlined
                        required
                        v-model="item.actualLogoutMonth"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">-</span>
                <v-text-field
                        maxlength="2"
                        required
                        outlined
                        class="mx-1 part-date-input"
                        v-model="item.actualLogoutDay"
                        hide-details="auto"
                ></v-text-field>
                <span class="mx-1 align-self-center font-weight-bold">{{$t('time')}}</span>
                <v-text-field
                        maxlength="2"
                        required
                        outlined
                        class="mx-1 part-date-input"
                        v-model="item.actualLogoutHour"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">:</span>
                <v-text-field
                        maxlength="2"
                        required
                        outlined
                        class="mx-1 part-date-input"
                        v-model="item.actualLogoutMin"
                        hide-details="auto"
                ></v-text-field>
                <span class="date-divider align-self-end">:</span>
                <v-text-field
                        maxlength="2"
                        required
                        outlined
                        class="mx-1 part-date-input"
                        v-model="item.actualLogoutSec"
                        hide-details="auto"
                ></v-text-field>
              </v-col>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.numberoftrips="{ item }">
              <!-- eslint-disable-next-line -->
              <validation-provider v-slot="{ errors }"
                                   :name="$t('number_of_trips')"
                                   :ref="`validationProvider_${item.id}`"
                                   rules="required|numeric|min_value:0"
              >
                <v-text-field
                        class="numberoftrips-input"
                        required
                        outlined
                        v-model="item.numberoftrips"
                        :hide-details="true"
                        :error-messages="errors"
                        @change="validateNumberOfTrips(item)"
                ></v-text-field>
              </validation-provider>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-content-center">
                <v-icon
                        :title="$t('save')"
                        size="19"
                        class="mr-2"
                        @click="saveItem(item)"
                        :disabled="inuse || item.employeeID ===''"
                >
                  mdi-content-save
                </v-icon>
                <v-icon
                        :title="$t('restore')"
                        size="19"
                        class="mr-2"
                        :disabled="inuse || item.isNew"
                        @click="restoreItem(item)"
                >
                  mdi-file-restore
                </v-icon>
                <v-icon title="delete" size="19" @click="deleteItem(item)" :disabled="inuse">
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-if="!isreleased" v-slot:footer>
              <div class="pa-2">
                <v-btn @click="addItem" :disabled="inuse" class="px-3" color="primary" dark
                ><v-icon :title="$t('add_new_item')" color="white">
                  mdi-table-row-plus-after
                </v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table>
        </template>
        <template v-else>
          <v-data-table
                  :mobile-breakpoint="900"
                  :headers="headers"
                  :items="trips"
                  :loading="loading"
                  class="elevation-1"
                  hide-default-footer
                  :options="options"
          >
          </v-data-table>
        </template>
        <v-card elevation="0" v-if="!isreleased" class="my-3 transparent">
          <v-spacer></v-spacer>
          <v-card-actions class="px-0 gaped-actions">
            <v-spacer></v-spacer>
            <v-btn @click="saveItems" :disabled="!validNumberOfTrips || emptyDrivers(trips)" :loading="inuse" class="px-3" color="primary" dark
            ><v-icon title="save" color="white" class="mr-2">
              mdi-content-save
            </v-icon>
              {{$t('save_for_later')}}</v-btn
            >
            <v-btn
                    v-if="!issupervisor"
                    :disabled="emptyItems(trips) || !isvalid || hasNew(trips)"
                    class="px-3"
                    color="primary"
                    dark
                    @click="dialogRelease = true"
            >
              <v-icon title="save" color="white" class="mr-2">
                mdi-file-send
              </v-icon>
              {{$t('release_data')}}
            </v-btn>
          </v-card-actions>
        </v-card>
        <Confirmationdialog
                :visible="dialogRestore"
                @confirmationdialog:cancelClick="closeRestore"
                @confirmationdialog:confirmClick="restoreItemConfirm"
        >
          <template v-slot:cardText>{{ $t('are_you_sure_restore') }}?</template>
        </Confirmationdialog>
        <Confirmationdialog
                :visible="dialogDelete"
                @confirmationdialog:cancelClick="closeDelete"
                @confirmationdialog:confirmClick="deleteItemConfirm"
        >
          <template v-slot:cardText>{{ $t('are_you_sure_delete') }}</template>
        </Confirmationdialog>
        <Confirmationdialog
                :visible="dialogRelease"
                @confirmationdialog:cancelClick="closeRelease"
                @confirmationdialog:confirmClick="confirmRelease"
        >
          <template v-slot:cardText>{{ $t('release_warning', [dateWeek]) }} </template>
        </Confirmationdialog>
      </v-container>
    </div>
  </v-layout>
</template>
<script>
  import { apiCall } from "@/utils/api.js";
  import { mapActions, mapGetters } from "vuex";
  import {ValidationProvider } from "vee-validate";
  import Confirmationdialog from "@/components/ConfirmationDialog";


  export default {
    name: "Trips",
    components: {
      ValidationProvider,
      Confirmationdialog
    },
    props: {
      releaseid: {
        type: [String,Number],
        require: true,
      },
    },
    data(context) {
      return {
        options: {
          itemsPerPage: 10
        },
        newItemsCounter: 0,
        inuse: false,
        locked: false,
        dialogDelete: false,
        dialogRestore: false,
        dialogRelease: false,
        dialog: false,
        loading: false,
        trips: [],
        newTrips: [],
        days: [],
        activeDay: null,
        locations: [],
        activeLocationID: null,
        isreleased: true,
        isvalid: false,
        dateTitle: null,
        dateWeek: null,
        editedItem: {},
        newTripUser:0,
        driversList: [],
        headers: [
          { text: "#", value: "number", sortable: false },
          {
            text: context.$t('driver_name'),
            align: "start",
            value: "driver",
            sortable: false,
          },
          {
            text: context.$t('employee_id'),
            value: "employeeID",
            sortable: false,
            cellClass: "cell-class employee-cell",
          },
          {
            text: context.$t('truck'),
            value: "truck",
            sortable: false,
            cellClass: "cell-class truck-cell",
          },
          {
            text: context.$t('workdate'),
            value: "visibleworkdate",
            sortable: false,
            cellClass: "cell-class workdate-cell",
          },
          {
            text: context.$t('scheduled_time_in'),
            value: "sheduled",
            sortable: false,
            cellClass: "cell-class sheduled-cell inputs-cell",
          },
          {
            text: context.$t('actual_login'),
            value: "actualLogin",
            sortable: false,
            cellClass: "cell-class inputs-cell",
          },
          {
            text: context.$t('actual_logout'),
            value: "actualLogout",
            sortable: false,
            cellClass: "cell-class inputs-cell",
          },
          {
            text: context.$t('num_of_trips'),
            value: "numberoftrips",
            sortable: false,
            cellClass: "cell-class trips-cell",
          },
          {
            text: context.$t('actions'),
            value: "actions",
            sortable: false,
            cellClass: "cell-class actions-cell",
          },
        ],
        validNumberOfTrips: true,
      };
    },
    computed: {
      ...mapGetters("auth", ["getProfile"]),
      issupervisor() {
        return this.getProfile?.roles.includes("Supervisor");
      },
      hasInvalidTrips() {
        return this.trips.filter((item) => item.valid == false) > 0;
      },
    },
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
      dialogRelease(val) {
        val || this.closeRelease();
      },
    },
    mounted() {
      this.getDataFromApi();
    },
    methods: {
      addItem() {
        // eslint-disable-next-line no-unused-vars
        const newElem = {
          isNew: true,
          number: this.trips.length + 1,
          id: -Math.abs(this.newItemsCounter + 1),
          driver: "",
          locationid: this.activeLocationID,
          workdate: this.activeDay,
          visibleworkdate: this.activeDay,
          employeeID: "",
          truck: "1",
          isLateArrival: false,
          isperfectAttendance: false,
          hasErrors: true,
          isNoShow: false,
          sheduled: "",
          actualLogin: "",
          actualLogout: "",
          numberoftrips: 1,
          valid: true,
          datepickeractive: false,
          sheduledYear: "",
          sheduledMonth: "",
          sheduledDay: "",
          sheduledHour: "",
          sheduledMin: "",
          sheduledSec: "",
          actualLoginYear: "",
          actualLoginMonth: "",
          actualLoginDay: "",
          actualLoginHour: "",
          actualLoginMin: "",
          actualLoginSec:  "",
          actualLogoutYear: "",
          actualLogoutMonth: "",
          actualLogoutDay: "",
          actualLogoutHour: "",
          actualLogoutMin: "",
          actualLogoutSec: "",
        }

        this.options.itemsPerPage = this.trips.length + 1;
        this.trips.push(newElem);
        this.newItemsCounter++;
      },
      getLoginClasses(trip) {
        let result = "";

        result = trip.hasErrors ? " red-item " : "";

        result = result.concat(trip.isLateArrival ? " blue-item " : "");

        result = result.concat(trip.isNoShow ? " yellow-item " : "");

        return result;
      },
      getLogoutClasses(trip) {
        let result = "";

        result = trip.hasErrors ? " red-item " : "";

        result = result.concat(trip.isNoShow ? " yellow-item " : "");

        return result;
      },
      getSheduledClasses(trip) {
        let result = "";

        result = trip.hasErrors ? " red-item " : "";

        result = result.concat(trip.isperfectAttendance ? "" : " orange-item");

        return result;
      },
      emptyItems(trips) {
        let result = 0;

        trips.forEach((trip) => {
          if (this.emptyItem(trip)) {
            result++;
          }
        });

        return result > 0;
      },
      emptyItem(item) {
        return (
                item.employeeID.length == 0 ||
                item.truck.length == 0 ||
                item.sheduledYear.length == 0 ||
                item.sheduledMonth.length == 0 ||
                item.sheduledDay.length == 0 ||
                item.sheduledHour.length == 0 ||
                item.sheduledMin.length == 0 ||
                item.sheduledSec.length == 0 ||
                item.numberoftrips == null ||
                item.numberoftrips.length == 0
        );
      },
      ...mapActions("service", ["setSnackbar"]),
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, {});
        });
      },
      closeRestore() {
        this.dialogRestore = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, {});
        });
      },
      changeLocation() {
        this.activeDay = null;
        this.getDataFromApi();
      },
      setActiveDate(date) {
        this.activeDay = date;

        this.getDataFromApi();
      },
      getDataFromApi() {
        this.loading = true;

        let url = `gpsdatacsv/gettrips?releaseid=${this.releaseid}`;

        if (this.activeLocationID != null) {
          url = `${url}&locationid=${this.activeLocationID}`;
        }

        if (this.activeDay != null) {
          url = `${url}&date=${this.activeDay.split("T")[0]}`;
        }

        apiCall("get", `${url}`, true).then((response) => {
          this.trips = [];
          this.days = response.data.datesWithDays.map( el => {
            return {
              value: el.value,
              key: this.$t(el.key.toLowerCase())
            }
          });
          console.log(this.days);
          this.locations = response.data.locations;

          this.activeLocationID =
                  this.activeLocationID == null
                          ? this.locations[0].id
                          : this.activeLocationID;
          this.activeDay = this.activeDay == null ? this.days[0].value : this.activeDay;

          this.options.itemsPerPage = response.data.trips.length;

          response.data.trips.forEach((elem) => {
            let sheduleddate = !response.data.released
                    ? this.getDate(elem.scheduledtimein)
                    : elem.scheduledtimein?.replace("T", " ");
            let actuallogindate = !response.data.released
                    ? this.getDate(elem.actuallogin)
                    : elem.actuallogin?.replace("T", " ");
            let actuallogoutdate = !response.data.released
                    ? this.getDate(elem.actuallogout)
                    : elem.actuallogout?.replace("T", " ");

            let trip = {
              isNew: false,
              number: elem.num,
              id: elem.id,
              driver: elem.driver,
              locationid: elem.locationid,
              workdate: elem.workdate,
              visibleworkdate: `${elem.workdate.split("T")[0]}`,
              employeeID: elem.employeeID,
              truck: elem.truck ? elem.truck : "",
              isLateArrival: elem.lateArrival,
              isperfectAttendance: elem.perfectAttendance,
              hasErrors: !elem.valid,
              isNoShow: elem.noshow,
              sheduled: sheduleddate || "",
              actualLogin: actuallogindate || "",
              actualLogout: actuallogoutdate || "",
              numberoftrips: elem.numberoftrips,
              valid: true,
              datepickeractive: false,
              sheduledYear:
                      (!response.data.released && sheduleddate?.getFullYear()) || "",
              sheduledMonth:
                      !response.data.released && sheduleddate != null
                              ? String(sheduleddate.getMonth() + 1).length == 1
                              ? "0".concat(sheduleddate.getMonth() + 1)
                              : String(sheduleddate.getMonth() + 1)
                              : "",
              sheduledDay:
                      !response.data.released && sheduleddate != null
                              ? String(sheduleddate?.getDate()).length == 1
                              ? "0".concat(sheduleddate.getDate())
                              : String(sheduleddate.getDate())
                              : "",
              sheduledHour:
                      !response.data.released && sheduleddate != null
                              ? String(sheduleddate?.getHours()).length == 1
                              ? "0".concat(sheduleddate.getHours())
                              : String(sheduleddate.getHours())
                              : "",
              sheduledMin:
                      !response.data.released && sheduleddate != null
                              ? String(sheduleddate?.getMinutes()).length == 1
                              ? "0".concat(sheduleddate.getMinutes())
                              : String(sheduleddate.getMinutes())
                              : "",
              sheduledSec:
                      !response.data.released && sheduleddate != null
                              ? String(sheduleddate?.getSeconds()).length == 1
                              ? "0".concat(sheduleddate.getSeconds())
                              : String(sheduleddate.getSeconds())
                              : "",
              actualLoginYear:
                      (!response.data.released && actuallogindate?.getFullYear()) || "",
              actualLoginMonth:
                      !response.data.released && actuallogindate != null
                              ? String(actuallogindate?.getMonth() + 1).length == 1
                              ? "0".concat(actuallogindate.getMonth() + 1)
                              : String(actuallogindate.getMonth() + 1)
                              : "",
              actualLoginDay:
                      !response.data.released && actuallogindate != null
                              ? String(actuallogindate?.getDate()).length == 1
                              ? "0".concat(actuallogindate.getDate())
                              : String(actuallogindate.getDate())
                              : "",
              actualLoginHour:
                      !response.data.released && actuallogindate != null
                              ? String(actuallogindate?.getHours()).length == 1
                              ? "0".concat(actuallogindate.getHours())
                              : String(actuallogindate.getHours())
                              : "",
              actualLoginMin:
                      !response.data.released && actuallogindate != null
                              ? String(actuallogindate?.getMinutes()).length == 1
                              ? "0".concat(actuallogindate.getMinutes())
                              : String(actuallogindate.getMinutes())
                              : "",
              actualLoginSec:
                      !response.data.released && actuallogindate != null
                              ? String(actuallogindate?.getSeconds()).length == 1
                              ? "0".concat(actuallogindate.getSeconds())
                              : String(actuallogindate.getSeconds())
                              : "",
              actualLogoutYear:
                      (!response.data.released && actuallogoutdate?.getFullYear()) ||
                      "",
              actualLogoutMonth:
                      !response.data.released && actuallogoutdate != null
                              ? String(actuallogoutdate?.getMonth() + 1).length == 1
                              ? "0".concat(actuallogoutdate.getMonth() + 1)
                              : String(actuallogoutdate.getMonth() + 1)
                              : "",
              actualLogoutDay:
                      !response.data.released && actuallogoutdate != null
                              ? String(actuallogoutdate?.getDate()).length == 1
                              ? "0".concat(actuallogoutdate.getDate())
                              : String(actuallogoutdate.getDate())
                              : "",
              actualLogoutHour:
                      !response.data.released && actuallogoutdate != null
                              ? String(actuallogoutdate?.getHours()).length == 1
                              ? "0".concat(actuallogoutdate.getHours())
                              : String(actuallogoutdate.getHours())
                              : "",
              actualLogoutMin:
                      !response.data.released && actuallogoutdate != null
                              ? String(actuallogoutdate?.getMinutes()).length == 1
                              ? "0".concat(actuallogoutdate.getMinutes())
                              : String(actuallogoutdate.getMinutes())
                              : "",
              actualLogoutSec:
                      !response.data.released && actuallogoutdate != null
                              ? String(actuallogoutdate?.getSeconds()).length == 1
                              ? "0".concat(actuallogoutdate.getSeconds())
                              : String(actuallogoutdate.getSeconds())
                              : "",
            };

            this.trips.push(trip);
          });

          this.dateTitle = `${response.data.startDate} - ${response.data.endDate}`;

          this.dateWeek = `${response.data.startDate.split("/")[0]}/${
                  response.data.startDate.split("/")[1]
          } - ${response.data.endDate.split("/")[0]}/${
                  response.data.endDate.split("/")[1]
          }`;

          this.isreleased = response.data.released;

          this.isvalid = response.data.valid;

          this.loading = false;

          this.validNumberOfTrips = true;

          this.locked = false;

          this.driversList = response.data.users;
        });
      },
      deleteTrip(id) {
        this.inuse = true;
        let params = {
          id: id,
        };

        apiCall(
                "post",
                "trips/delete",
                {
                  params: params,
                },
                true
        ).then(() => {
          this.inuse = false;
          this.setSnackbar({
            color: "success",
            text: this.$t('trip_deleted'),
            toggle: true,
          });
          this.closeDelete();
          if (this.trips.length == 1) {
            this.activeDay = this.days[0].value;
          }
          this.getDataFromApi();
        }).catch(() =>{
          this.inuse = false;
        });
      },
      restoreTrip(id) {
        this.inuse = true;
        let params = {
          id: id,
        };

        apiCall(
                "post",
                "trips/restore",
                {
                  params: params,
                },
                true
        ).then(() => {
          this.inuse = false;
          this.setSnackbar({
            color: "success",
            text: this.$t('trip_data_restored'),
            toggle: true,
          }).catch(()=>{

            this.inuse = false;
          });
          this.closeRestore();
          this.getDataFromApi();
        }).catch(() =>{
          this.inuse = false;
        });
      },
      updateTrip(data) {
        this.inuse = true;
        let params = {
          id: data.id,
          workdate: data.workdate,
          employeeid: data.employeeID,
          truck: data.truck,
          numberoftrips: data.numberoftrips,
          sheduledtimeinYear: data.sheduledYear,
          sheduledtimeinMonth: data.sheduledMonth,
          sheduledtimeinDay: data.sheduledDay,
          sheduledtimeinHour: data.sheduledHour,
          sheduledtimeinMin: data.sheduledMin,
          sheduledtimeinSec: data.sheduledSec,
          actualLoginYear: data.actualLoginYear,
          actualLoginMonth: data.actualLoginMonth,
          actualLoginDay: data.actualLoginDay,
          actualLoginHour: data.actualLoginHour,
          actualLoginMin: data.actualLoginMin,
          actualLoginSec: data.actualLoginSec,
          actualLogoutYear: data.actualLogoutYear,
          actualLogoutMonth: data.actualLogoutMonth,
          actualLogoutDay: data.actualLogoutDay,
          actualLogoutHour: data.actualLogoutHour,
          actualLogoutMin: data.actualLogoutMin,
          actualLogoutSec: data.actualLogoutSec,
          releaseId:  this.releaseid,
          locationId: this.activeLocationID,
        };

        apiCall(
                "post",
                "trips/update",
                {
                  data: params,
                },
                true
        ).then(() => {
          this.getDataFromApi();
          this.inuse = false;
          this.setSnackbar({
            color: "success",
            text: this.$t('trip_updated'),
            toggle: true,
          }).catch(() =>{
            this.inuse = false;

          });
        });
      },
      updateTrips(data) {

        this.inuse = true;

        let params = [];

        data.forEach((elem) => {
          let el = {
            id: elem.id,
            workdate: elem.workdate,
            employeeid: elem.employeeID,
            truck: elem.truck,
            sheduledtimein: elem.scheduledtimein,
            actuallogin: elem.actuallogin,
            actuallogout: elem.actuallogout,
            numberoftrips: elem.numberoftrips,
            sheduledtimeinYear: elem.sheduledYear,
            sheduledtimeinMonth: elem.sheduledMonth,
            sheduledtimeinDay: elem.sheduledDay,
            sheduledtimeinHour: elem.sheduledHour,
            sheduledtimeinMin: elem.sheduledMin,
            sheduledtimeinSec: elem.sheduledSec,
            actualLoginYear: elem.actualLoginYear,
            actualLoginMonth: elem.actualLoginMonth,
            actualLoginDay: elem.actualLoginDay,
            actualLoginHour: elem.actualLoginHour,
            actualLoginMin: elem.actualLoginMin,
            actualLoginSec: elem.actualLoginSec,
            actualLogoutYear: elem.actualLogoutYear,
            actualLogoutMonth: elem.actualLogoutMonth,
            actualLogoutDay: elem.actualLogoutDay,
            actualLogoutHour: elem.actualLogoutHour,
            actualLogoutMin: elem.actualLogoutMin,
            actualLogoutSec: elem.actualLogoutSec,
          };

          params.push(el);
        });

        apiCall(
                "post",
                "trips/updateall",
                {
                  data: { trips: params },
                },
                true
        ).then(() => {
          this.getDataFromApi();
          this.setSnackbar({
            color: "success",
            text: this.$t('trip_updated'),
            toggle: true,
          });
          this.inuse = false;
        }).catch(() =>{
          this.inuse = false;
        });
      },
      release() {
        this.locked = true;
        this.inuse = true;
        let params = {
          releaseid: this.releaseid,
        };

        apiCall(
                "post",
                "trips/release",
                {
                  params: params,
                },
                true
        ).then((response) => {
          if (response.data) {
            this.setSnackbar({
              color: response.data.status ? "success" : "error",
              text: response.data.result,
              toggle: true,
            });
          }
          this.inuse = false;
          this.getDataFromApi();

          this.closeRelease();
        }).catch(() => {
          this.inuse = false;
          this.setSnackbar({
            color: "error",
            text: this.$t('unknown_error'),
            toggle: true,
          });
        })
      },
      close() {
        this.dialog = false;
      },
      closeRelease() {
        this.dialogRelease = false;
      },
      confirmRelease() {
        if (!this.locked) {
          this.release();
        }
      },
      saveItem(item) {
        if (item.valid) {
          let data = { ...item };
          data.scheduledtimein = new Date(
                  data.sheduledYear,
                  data.sheduledMonth - 1,
                  data.sheduledDay,
                  data.sheduledHour,
                  data.sheduledMin,
                  data.sheduledSec
          );
          data.actuallogin = new Date(
                  data.actualLoginYear,
                  data.actualLoginMonth - 1,
                  data.actualLoginDay,
                  data.actualLoginHour,
                  data.actualLoginMin,
                  data.actualLoginSec
          );
          data.actuallogout = new Date(
                  data.actualLogoutYear,
                  data.actualLogoutMonth - 1,
                  data.actualLogoutDay,
                  data.actualLogoutHour,
                  data.actualLogoutMin,
                  data.actualLogoutSec
          );

          data.workdate = data.visibleworkdate;

          this.updateTrip(data);
        }
      },
      saveItems() {
        let data = [...this.trips];

        data.forEach((elem) => {
          elem.scheduledtimein = new Date(
                  elem.sheduledYear,
                  elem.sheduledMonth - 1,
                  elem.sheduledDay,
                  elem.sheduledHour,
                  elem.sheduledMin,
                  elem.sheduledSec
          );
          elem.actuallogin = new Date(
                  elem.actualLoginYear,
                  elem.actualLoginMonth - 1,
                  elem.actualLoginDay,
                  elem.actualLoginHour,
                  elem.actualLoginMin,
                  elem.actualLoginSec
          );
          elem.actuallogout = new Date(
                  elem.actualLogoutYear,
                  elem.actualLogoutMonth - 1,
                  elem.actualLogoutDay,
                  elem.actualLogoutHour,
                  elem.actualLogoutMin,
                  elem.actualLogoutSec
          );

          elem.workdate = elem.visibleworkdate;
        });

        this.updateTrips(data);
      },
      deleteItem(item) {
        if (item.isNew === true) {
          this.deleteNewItem(item);
        }else {
          this.editedItem = Object.assign({}, item);
          this.dialogDelete = true;
        }
      },
      deleteItemConfirm() {
        this.deleteTrip(this.editedItem.id);
      },
      restoreItem(item) {
        this.editedItem = Object.assign({}, item);
        this.dialogRestore = true;
      },
      restoreItemConfirm() {
        this.restoreTrip(this.editedItem.id);
      },
      getDate(stringdate) {
        try {
          let result = new Date(String(stringdate));
          return result != "Invalid Date" ? result : null;
        } catch {
          return null;
        }
      },
      async validateNumberOfTrips(item) {
        const resultObj = await this.$refs[`validationProvider_${item.id}`].validate();

        item.valid = resultObj.valid;

        const totalValidationResult = this.trips.filter(e => !e.valid).length == 0;

        this.validNumberOfTrips = totalValidationResult;

      },
      changeNewRow(item) {
        const userId = item.userid;
        const empId = this.driversList.filter(e => e.id === userId)[0].employerId;
        let editedItem = item;
        editedItem.employeeID = empId;
        this.trips = this.trips.map(u => u.number !== editedItem.number ? u : editedItem);
      },
      emptyDrivers(trips) {
        return trips.filter(t => t.employeeID ==="").length > 0;
      },
      hasNew(trips) {
        return trips.filter(t => t.isNew === true) > 0;
      },
      deleteNewItem(item) {
        this.trips = this.trips.filter(t=> t.id != item.id);
      }
    },
  };
</script>
<style scoped>
  .truck-input, .workdate-input, .numberoftrips-input{
    width: 120px;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell{
    margin: 4px 0;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex{
    padding: 0;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .gaped-actions {
    column-gap: 2rem;
    row-gap: 1rem;
  }

  @media (max-width: 480px) {
    .gaped-actions{
      flex-direction: column;
    }

  }
  .active-data-table >>> .v-data-table__mobile-row__cell .v-input__append-inner {
    margin-top: 4px;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .v-input__slot{
    min-height: auto;
    padding: 5px 1px !important;
  }

  @media (max-width: 920px) {
    .days-wrapper--desktop{
      display: none;
    }
    .days-wrapper--mobile{
      display: block;
    }
  }
  @media (min-width: 921px) {
    .days-wrapper--mobile{
      display: none;
    }
  }

  @media (max-width: 980px) {
    .flex-wrapper {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-end;
    }

  }
  .note-class {
    border: 1px solid #7fa6f4 !important;
  }
  .trips /deep/ .cell-class {
    max-width: 290px;
    padding: 4px;
  }
  .trips /deep/ .cell-class .v-input {
    padding: 0;
  }
  .trips /deep/ .cell-class .v-input input {
    text-align: center;
  }
  .trips /deep/ .cell-class .v-input__append-inner {
    margin: auto;
  }
  .trips /deep/ .cell-class .v-input__slot {
    padding: 2px !important;
    min-height: auto;
  }
  .trips /deep/ .employee-cell {
    max-width: 60px;
    width: 60px;
  }
  .trips /deep/ .truck-cell {
    max-width: 60px;
    width: 60px;
  }
  .trips /deep/ .workdate-cell {
    max-width: 120px;
  }
  .trips /deep/ .inputs-cell {
    max-width: 290px;
  }
  .trips /deep/ .trips-cell {
    max-width: 60px;
    width: 60px;
  }
  .date-divider {
    display: flex;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 5px;
  }
  .trips /deep/ .cell-class .col {
    padding-left: 4px;
    padding-right: 4px;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.orange-item fieldset, .trips /deep/.cell-class > .col.orange-item fieldset {
    color: #fda80e !important;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.orange-item .v-input__slot, .trips /deep/.cell-class > .col.orange-item .v-input__slot {
    background-color: #fdab1726 !important;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.blue-item fieldset, .trips /deep/.cell-class > .col.blue-item fieldset {
    color: #008aff !important;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.blue-item fieldset, .trips /deep/.cell-class > .col.blue-item .v-input__slot {
    background-color: #008aff26 !important;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.yellow-item fieldset, .trips /deep/.cell-class > .col.yellow-item fieldset {
    color: #ffff00 !important;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.yellow-item .v-input__slot, .trips /deep/.cell-class > .col.yellow-item .v-input__slot {
    background-color: #ffff0047 !important;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.red-item fieldset, .trips /deep/.cell-class > .col.red-item fieldset {
    color: red !important;
  }
  .active-data-table >>> .v-data-table__mobile-row__cell .d-flex.red-item .v-input__slot, .trips /deep/.cell-class > .col.red-item .v-input__slot {
    background-color: #ff00001a !important;
  }

  .trips /deep/ .part-date-input {
    width: 20px;
  }
  .trips /deep/ .part-year-input {
    width: 40px;
  }
  .trips /deep/ .date-part-divider {
    border: none;
  }

  .trips
  /deep/
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(:last-child) {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  .trips
  /deep/
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:not(:last-child) {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  .trips
  /deep/
  .v-data-table
  > .v-data-table__wrapper >
  table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  .new-driver-wrapper{
    max-width: 230px;
  }
</style>