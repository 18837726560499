<template>
    <v-dialog
            @click:outside="$emit('lightbox:outsideclick')"
            v-model="visible"
            class="v-dialog"
    >
                <v-carousel
                    :hide-delimiters="true"
                    class="v-carousel__elem"
                    v-model="activeSlide"
                    @change="(val) => $emit('lightbox:changeslide', val)"
                >
                    <v-carousel-item
                            class="v-carousel__item-wrapper"
                            contain
                        v-for="(item,i) in images"
                        :key="i"
                        :src="item"
                        reverse-transition="fade-transition"
                        transition="fade-transition"

                    />
                </v-carousel>
    </v-dialog>
</template>
<script>
    export default {
        name: "lightbox",
        props: {
            visible: {
                type: Boolean,
                required: true,
                default: false
            },
            images: {
                type: Array,
                required: true,
                default: () => []
            },
            activeimage: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data: () => ({
            activeSlide: 0,
        }),
        watch: {
            activeimage(newVal){
                this.activeSlide = newVal;
            }
        }
    }
</script>
<style scoped>
    .v-dialog{
        max-height: 80vh;
    }
    .v-carousel__elem{
        height: 80vh;
    }
</style>