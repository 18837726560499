<template>
    <v-dialog persistent v-model="visible" max-width="500px">
        <v-card>
            <v-card-title class="dialog-title">
                <span class="headline">{{title}}</span>
            </v-card-title>
            <v-card-text class="text-h6 black--text py-2">{{text}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" dark @click="$emit('informationdialog:buttonClick')"
                >{{buttonText}}</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "informationdialog",
        props: {
            visible: {
              type: Boolean,
              required: true,
              default: false
            },
            title: {
                type: String,
                default: "",
                required: false

            },
            text: {
                type: String,
                default: "",
                required: false
            },
            buttonText: {
                type: String,
                default: "Ok",
                required: false
            }
        }
    }
</script>