import { apiCall } from "@/utils/api.js";
import {closeSession} from "../utils/session";

const SESSION_TIMEOUT = Number.parseInt(process.env.VUE_APP_SESSION_TIMEOUT);

let closeSessionTimeout = setTimeout(closeSession, SESSION_TIMEOUT);


const userModule = {
    namespaced: true,
    state: {
        profile: null,
        token: null,
        loading: false,
        hasSecondToken: false
    },
    getters: {
        getRolesArray: state => {
            return state.profile?.roles;
        },
        getProfile: state => {
            return state.profile;
        },
        getLoading: state => {
            return state.loading;
        },
        getSecondTokenStatus: state => {
            return state.hasSecondToken;
        }
    },
    mutations: {
        setProfile(state, profile) {
            if (profile) {
                state.profile = profile;
            } else {
                state.profile = null;
            }
        },
        setSuggestions(state, suggestions) {
          state.profile.suggestions = suggestions;
        },
        setToken(state, token) {
            if (token) {
                state.token = token;
            } else {
                state.token = null;
            }
        },
        setLoading(state, value){
            state.loading = value;
        },
        setSecondTokenStatus(state, value) {
            state.hasSecondToken = value;
        },
        setSuggestionsCount(state, value){
            const currentCount = Number.parseInt(value);
            state.profile.suggestionsCount = currentCount;
        }
    },
    actions: {
        async setProfile(context, payload) {
            context.commit("setProfile", payload);
        },
        async setSuggestions(context, payload) {
            context.commit("setSuggestions", payload);
        },
        async setToken(context, payload) {
            clearTimeout(closeSessionTimeout);
            closeSessionTimeout = setTimeout(closeSession, SESSION_TIMEOUT);

            context.commit("setToken", payload);
        },
        async setLoading(context, payload){
            context.commit("setLoading", payload);
        },
        async setSecondTokenStatus(context, payload){
            context.commit("setSecondTokenStatus", payload);
        },
        // eslint-disable-next-line no-unused-vars
        async sendLoginData({ dispatch }, payload) {
            // eslint-disable-next-line no-unused-vars
            const res = await apiCall(
                "post",
                "auth/signin",
                {
                    data: payload
                },
                false
            )
            if (res.data.token) {

                localStorage.setItem("sessionToken", res.data.token);
                await dispatch("setToken", res.data.token);
                await dispatch("setProfile", res.data);

                return res.data;
            }
            return null;
        },
        async loadProfile({ dispatch }) {
            
            const oldToken = localStorage.getItem("oldToken");

            const res = await apiCall(
                "get",
                "auth/loaduser",
                true
            )
            if (res.data.token) {
                localStorage.setItem("sessionToken", res.data.token);
                await dispatch("setToken", res.data.token);
                await dispatch("setProfile", res.data);
                await dispatch("setLoading", false);
                if (oldToken != null && oldToken !== undefined){
                    await dispatch("setSecondTokenStatus", true);
                }else{
                    await dispatch("setSecondTokenStatus", false);
                }

                return res.data;
            }else{
                await dispatch("logout");
            }
        },
        async logBack({ dispatch}) {
            const token = localStorage.getItem("oldToken");

            localStorage.removeItem("oldToken");
            localStorage.setItem("sessionToken", token);

            await dispatch("loadProfile");

        },
        async loginAs({ dispatch }, payload) {
            let oldToken = localStorage.getItem("sessionToken");

            localStorage.setItem("oldToken", oldToken);

            const res = await apiCall(
                "post",
                "auth/loginas",
                {
                    data: payload
                },
                true
            )
            if (res.data.token) {

                await dispatch("setSecondTokenStatus", true);

                localStorage.setItem("sessionToken", res.data.token);
                await dispatch("setToken", res.data.token);
                await dispatch("setProfile", res.data);
                await dispatch("setLoading", false);
                return res.data;
            }else{
                return res.data;
            }
        },
        async login({ dispatch }) {
            
            await dispatch("setLoading", true);

            const token = localStorage.getItem("sessionToken");


            if (token) {
                await dispatch("setToken", token);
                return await dispatch("loadProfile", token);
            }else{
                await dispatch("logout");
            }
        },
        async logout(context) {
            localStorage.removeItem("sessionToken");
            localStorage.removeItem("oldToken");
            context.commit("setToken", null);
            context.commit("setProfile", null);
            context.commit("setLoading", false);

        },
        async setSuggestionsCount(context, payload) {
            context.commit("setSuggestionsCount", payload);
        },
        // eslint-disable-next-line no-unused-vars
        async updatelang(context, payload) {
            const params = {
                lang: localStorage.getItem("lang")
            }
            await apiCall(
                "post",
                "users/updatelang",
                {
                    data: params
                },
                true
            )
        }
    }
};
export default userModule;