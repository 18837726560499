<template>
  <v-col>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="confirmEmail">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('password')"
          rules="required"
          vid="confirmation"
        >
          <v-text-field
            v-model="password"
            :error-messages="errors"
            :label="$t('password')"
            required
            :append-icon="invisiblepassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (invisiblepassword = !invisiblepassword)"
            :type="invisiblepassword ? 'password' : 'text'"
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="Field"
          rules="required|confirmed:confirmation"
        >
          <v-text-field
            v-model="repeatedpassword"
            :error-messages="errors"
            :label="$t('confirm_password')"
            required
            :append-icon="invisiblerepeatedpassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="
              () => (invisiblerepeatedpassword = !invisiblerepeatedpassword)
            "
            :type="invisiblerepeatedpassword ? 'password' : 'text'"
          ></v-text-field>
        </validation-provider>
        <v-btn
          height="70"
          class="rounded-0 my-6 text-h6"
          block
          color="secondary"
          depressed
          type="submit"
          :disabled="invalid || sending"
          >{{ $t('send') }}</v-btn
        >
        <v-btn
          color="transparent"
          class="primary--text"
          depressed
          @click="backtologin"
          >{{ $t('to_login') }}</v-btn
        >
      </v-form>
    </validation-observer>
  </v-col>
</template>
<script>
// eslint-disable-next-line
import { mapActions } from "vuex";
import { apiCall } from "@/utils/api";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "EmailConfirm",
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line
  },
  props: {
    token: {
      require: true,
      default: null,
    },
  },
  data: () => ({
    tokenconfirmed: false,
    invisiblepassword: true,
    invisiblerepeatedpassword: true,
    password: null,
    repeatedpassword: null,
    sending: false,
  }),
  created() {
    if (this.token == null || this.token == "") {
      this.setSnackbar({
        color: "error",
        text: this.$('token_not_found'),
        toggle: true,
      });
    }
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    backtologin() {
      this.$emit("changeform", "login");
      this.$router.replace("/");
    },
    confirmEmail() {
      let param = {
        token: this.token,
        password: this.password,
        repeastedpassword: this.repeatedpassword,
      };

      apiCall(
        "post",
        "auth/emailconfirm",
        {
          data: param,
        },
        false
      )
        .then((response) => {
          this.setSnackbar({
            color: response?.data?.status == true ? "success" : "error",
            text: response.data.message || this.$t("unknown_error"),
            toggle: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setSnackbar({
            color: "error",
            text: this.$t('token_or_user_not_found'),
            toggle: true,
          });
        });
    }
  },
};
</script>