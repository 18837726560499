import { apiCall } from "@/utils/api"

export function getNotifications() {
    apiCall("get", "notifications", {},true)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch((e) => {
            return Promise.reject(e);
        })
}

export async function getFileFromUrl(url, name, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
        type: defaultType,
    });
}
