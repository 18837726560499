import validationMessages from 'vee-validate/dist/locale/en';
import { en as vuetifymessages }  from 'vuetify/lib/locale';

const en = {
  $vuetify: {
    ...vuetifymessages,
    dataTable: {
      itemsPerPageText: "Items per page:",
    },
    dataFooter: {
      pageText: '{0}-{1} of {2}',
      itemsPerPageAll: "All",
    }
  },
  validations: {
    ...validationMessages.messages,
    required: '{_field_} field is required',
    max: "{_field_} field is too long",
    ext: "Incorrect file format",
    ext1: "Only .jpg, .jpeg, .png files can be uploaded",
    confirmed: "Password doesn't match",
    size: "Image size must not exceed 1 MB",
    image: "File must be a image"
  },
  email: "Email",
  password: "Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  login: "login",
  password_forgot: "Forgot password?",
  warning: 'Warning!',
  are_you_sure: 'Are you sure?',
  cancel: 'Cancel',
  confirm: 'Confirm',
  save: "Save",
  reset: "Reset",
  continue: "Continue",
  back: "Back",
  send: "Send",
  edit: "Edit",
  delete: "Delete",
  create: "Create",
  completed: "Completed",
  ok: "OK",
  to_login: "To login",
  are_you_sure_delete: "Are you sure you want to delete this item?",
  new_user: "New user",
  edit_user: "Edit user",
  user_info: "User info",
  badges: "Badges",
  firstname: "First Name",
  lastname: "Last Name",
  employeeid: 'EmployeeID',
  secondaryid: "SecondaryId",
  country : "Country",
  role: "Role",
  roles: "Roles",
  company: "Company",
  division: "Division",
  location: "Location",
  birthdate: "Birth Date",
  hiredate: "Hire Date",
  isnewhire: "Is new hire?",
  badgetype: 'Type: {0}',
  badgepoints: "Points: {0}",
  badgecoins: "Coins: {0}",
  token_not_found: "Token not found",
  token_or_user_not_found: "Token or user not found",
  unknown_error: "Unknown error",
  image: "Image",
  dragndrop: "Drag&Drop file here or click to upload",
  send_password_reset: "SEND RESET PASSWORD EMAIL",
  invalid_credentials: "Invalid credentials",
  notifications: "Notifications",
  see_all: "See All",
  sent_a_suggestion: "sent a suggestion.",
  return_to_admin: "Return to Admin",
  profile: "Profile",
  logout: "Logout",
  reset_data: "Reset Data",
  you_are_about_reset: "You are about to reset data. Would you like to continue?",
  companies: "Companies",
  users: "Users",
  divisions_locations: "Divisions/Locations",
  divisions: "Divisions",
  locations: "Locations",
  leaderboard: "LeaderBoard",
  reports: "Reports",
  transfer_coins: "Transfer coins",
  settings: "Settings",
  programs: "Programs",
  home_locations: "Home locations",
  gps: "GPS System Data",
  quizzes: "Quizzes",
  data_reset_successfully: "Data reset succesfully",
  company_ids: "Company IDs",
  role_ids: "Role IDs",
  exclusion_user_id_list: "Exclusion user ID list",
  inclusion_user_id_list: "Inclusion user ID list",
  upload_users_to_awardco: "Upload users to Awardco",
  confirmation: "Confirmation",
  you_are_about_save_data: "You are about save this data",
  companies_id: "Companies id",
  roles_id: "Roles id",
  exclusion_user_id: "Exclusion user ID",
  inclusion_user_id: "Inclusion user ID",
  are_you_sure_upload_to_awardco: "Are you sure you want to upload users to Awardco?",
  at_least_one_company_required: "At least one companyId is required",
  at_least_one_role_required: "At least one roleId is required",
  something_went_wrong: "Something went wrong",
  users_are_uploaded_to_awardco: "Users are uploaded to Awardco",
  new_badge: "New Badge",
  edit_badge: "Edit Badge",
  additional_settings: "Additional settings",
  choose_from_library: "Choose from library",
  badge_name: "Badge name",
  points: "Points",
  coins: "Coins",
  coin: "Coin",
  description: "Description",
  feed_type: "Feed Type",
  allow_on_recognition: "Allow on recognition feed",
  monthly_limit_per_division_is_enabled: "Monthly limit per division is enabled",
  monthly_division_limit: "Monthly/division limit",
  monthly_limit_per_driver_is_enabled: "Monthly limit per driver is enabled",
  select_all: 'select all',
  monthly_driver_limit: "Monthly/driver limit",
  monthly_limit_per_driver: "Monthly limit per driver",
  monthly_limit_per_division: "Monthly limit per division",
  select_icon: "Select icon",
  default: "Default",
  none: "None",
  global: "Global",
  within_division: "Within division",
  within_home_location: "Within Home Location",
  assign_badges: "Assign badges",
  badges_data: "Badges data",
  users_data: "Users data",
  info: "Info",
  reached_monthly_limit: "Reached monthly limit",
  search: "Search",
  comment: "Comment",
  badge: "Badge",
  date: "Date",
  no_comment: "No comment",
  assign: "Assign",
  assign_without_comment: "Assign badge(s) without a comment?",
  unable_to_fetch_icon: "Unable to fetch icon",
  badge_assigned_successfully: "Badge assigned successfully",
  all_locations: "All locations",
  new_company: "New company",
  company_name: "Company name",
  company_key: "Company key",
  driver_limit: "Driver limit",
  non_driver_limit: "Non-Driver limit",
  show_awardco_link_in_app: "Show Awardco link in app",
  allow_user_point_redemption: "Allow user point redemption",
  suggestion_box: "Suggestion box",
  show_suggestion_box: "Show Suggestion box",
  user_import_type: "User import",
  show_badges_feed: "Show Badges feed",
  late_buffer: "Late buffer",
  late_buffer_min: "Late buffer (minutes)",
  new_hire_buffer_is_enabled: "New Hire buffer is enabled",
  new_hire: "New hire",
  new_hire_buffer_days: "New hire (days)",
  enable_managed_controller_transfer: "Enable Managed controlled transfer",
  name: "Name",
  driver_count: "Driver count",
  non_driver_count: "Non-driver count",
  date_created: "Date created",
  actions: "Actions",
  edit_company: "Edit company",
  transfer_coins_to_redeem_partner: "Transfer coins to redeem partner",
  transfer_queue: "Transfer queue",
  active: "Active",
  inactive: "Inactive",
  deleted: "Deleted",
  batch_user_import: "Batch user import",
  upload_users: "Upload users",
  expected_csv: "Expected CSV row format",
  csv_headers: "EmployeeNo,DriverFirstName,DriverLastName,Email,HomeLocationName,HireDate,BirthDate,SecondaryId",
  file: "File",
  sheduled_invitation: "Scheduled invitation",
  campaign_name: "Campaign name",
  scheduled_at: "Scheduled at",
  select_datetime: "Select Datetime",
  template_id: "Template id",
  upload: "Upload",
  make_active: "Make active",
  make_inactive: "Make inactive",
  restore: "Restore",
  permanent_delete: "Permanent delete",
  oops: "OOPS",
  restore_user: "Restore user?",
  delete_user_permanently: "Delete user permanently?",
  are_you_sure_delete_user: "Are you sure you want to delete this user?",
  are_you_sure_change_status: "Are you sure you want to change status for this user?",
  loginas_error: "Looks like this users hasn’t finished authorization or\n reset password process and is inactive at the moment.",
  newhire: "newhire",
  all: "All",
  status_changed: "Status changed",
  super_admin: "Super admin",
  company_admin: "Company admin",
  manager: "Manager",
  supervisor: "Supervisor",
  non_driver_user: "Non-Driver user",
  status: "Status",
  date_range: "Date range",
  transfer_current: "Transfer current",
  transfer_by_date: "Transfer by date",
  amount: "Amount",
  redeem: "Redeem",
  confirm_transfer: "Confirm transfer",
  select_transfer_date: "Select transfer date",
  fullname: "Full name",
  current_coins: "Current coins",
  coins_by_date_range: "Coins by date range",
  pending_transfer: "Pending transfer",
  transfer_amount: "Transfer amount (full coins only)",
  alltime: "Alltime",
  new_division: "New division",
  edit_division: "Edit division",
  division_data: "Division data",
  division_badges: "Division badges",
  division_settings: "Division settings",
  division_name: "Division name",
  division_has_leaderboard: "Division has leaderboard",
  division_has_gps_data_page: "Division has GpsData page",
  default_badges: "Default badges",
  division_deleted: "Division deleted",
  change_password: "Change password",
  old_password: "Old password",
  image_successfully_updated: "Image successfully updated",
  password_updated: "Password updated",
  suggestion: "Suggestion",
  mark_as_read: "Mark as read",
  are_you_sure_mark_as_completed: "Are you sure you want to mark this suggestion as Completed?",
  success: "Success",
  employee_score_card: "EMPLOYEE score card",
  score: "Score",
  performance_history: "Performance history",
  complete_badge_history: "Complete badge history",
  week: "Week",
  month: "Month",
  year: "Year",
  export_data: "Export data",
  earned: "Earned",
  average: "Average",
  median: "Median",
  mode: "Mode",
  low: "Low",
  high: "High",
  period: "Period",
  ranking: "Ranking",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  rewardedby: "Rewarded by",
  awardedby: "Awarder by",
  prev_three_months: "Previous 3 Months",
  prev_sixst_months: "Previous 6 Months",
  prev_twelw_months: "Previous 12 Months",
  remark: "remark",
  upload_file: "Upload file",
  sheduled_time_file: "Scheduled time file",
  time_card_file: "Time card file",
  report_shedule_access_file: "Report Schedule Access file",
  import_results: "Import results",
  trips_upload_successfully: "Trips uploaded successfully",
  acknowledged: "Acknowledged",
  released_status: "Released Status",
  released_by: "Released By",
  released: "Released",
  pending: "Pending",
  cant_find_import_system: "Can't find import system",
  delete_gps_title: "You are about to permanently delete all uploaded data for the period {0}. Are you sure?",
  data_successfully_deleted: "Data is successfully deleted",
  show_board_data: "Show board data",
  driver: "Driver",
  badge_count: "Badge count",
  no_data_available: "NO DATA AVAILABLE",
  new_location: "New location",
  edit_location: "Edit location",
  address: "Address",
  city: "City",
  state: "State",
  zipcode: "Zipcode",
  location_successfully_updated: "Location successfully updated",
  location_successfully_created: "Location successfully created",
  location_successfully_deleted: "Location successfully deleted",
  trip_data_date_range: "Trip data date range",
  note_time_format: "Note! We use 24h time format",
  color_legend: "Color legend",
  no_perfect_attendance: "No perfect attendance badge",
  late_arrival: "late_arrival",
  no_show: "No show",
  error_data: "Mandatory fields/Duplicates/Schedule Time In greater than WorkDate/Actual Logout before Actual Login",
  day: "Day",
  time: "Time",
  number_of_trips: "Number of trips",
  add_new_item: "Add new item",
  save_for_later: "Save for later",
  release_data: "Release data",
  are_you_sure_restore: "Are you sure you want to restore this?",
  release_warning: "Warning: You are about to release data for {0}, would you like to continue?",
  driver_name: "Driver name",
  employee_id: 'Employee ID',
  truck: "Truck",
  workdate: "Work date",
  scheduled_time_in: "Scheduled time in",
  actual_login: "Actual login",
  actual_logout: "Actual logout",
  num_of_trips: "№ of trips",
  trip_deleted: "Trip deleted",
  trip_data_restored: "Trip data restored",
  trip_updated: "Trip updated",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  sunday: "sunday",
  restart_queue: "Restart queue",
  remove_user_from_queue: "Remove user from queue?",
  employee_name: "Employee name",
  transfer_am: "Transfer amount",
  scheduled_date: "Sheduled date",
  processed_date: "Processed date",
  global_user_report: "Global user report",
  load_next_rows: "Load next 50 rows",
  show_data: "Show data",
  terms_of_use: "Terms of use",
  privacy_policy: "Privacy policy",
  eula: "EULA",
  lang: "Language",
  notifications_center: "Notifications center (beta)",
  notification: "Notification",
  notification_title: "Notification title",
  notification_text: "Notification text",
  new_notification: "New notification",
  send_notification: "Send notification",
  select_recipients: "Select recipients",
  general_data: "General data",
  company_settings: "Company settings",
  email_settings: "Email settings",
  please_leave_empty: "Please leave the fields empty if you want to use our default system emails",
  company_admin_template_id: "Company Admin template ID",
  manager_template_id: "Manager template ID",
  supervisor_template_id: "Supervisor template ID",
  driver_template_id: "Driver template ID",
  reset_password_template_id: "Reset password template ID",
  reset_password_mobile_template_id: "Reset password (mobile) template ID",
  reset_password_email_is_sent: "Reset password email is sent",
  are_you_sure_request_password_reset: "Are you sure you want to reset password?",
  are_you_sure_password_change: "Are you sure you want to change password?",
  request_password_reset: "Reset password",
  password_changed: "Password changed",
  queueStatuses : {
    cancelled: "Cancelled",
    error: "Error",
    pending: "Pending",
    success: "Success"
  },
  show_code: "Show verification code",
  code: "Verification code"
};

export default en
