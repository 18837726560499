<template>
    <v-layout>
        <div class="col-12 leaderboard">
            <v-card-title>{{$t('global_user_report')}}</v-card-title>
            <v-card>
                <v-card-title>
                    <v-dialog
                            ref="menu"
                            v-model="menu"
                            :return-value.sync="selectedDates"
                            persistent
                            width="430px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    :value="dateRangeText"
                                    :label="$t('date_range')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    @click:clear="clearDates"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="selectedDates"
                                no-title
                                scrollable
                                range
                                width="auto"
                                show-adjacent-months
                                :max="new Date().toISOString().substr(0, 10)"
                        >
                            <v-col
                            ><v-col class="leaderboard-calendar-btn--wrapper" cols="12">
                                <v-btn
                                        :class="{ 'active primary': activePeriod == 'week' }"
                                        @click="setDates('week')"
                                        class="interval-btn"
                                >{{$t('week')}}</v-btn
                                >
                                <v-btn
                                        :class="{ 'active primary': activePeriod == 'month' }"
                                        @click="setDates('month')"
                                        class="interval-btn"
                                >{{$t('month')}}</v-btn
                                >
                                <v-btn
                                        :class="{ 'active primary': activePeriod == 'year' }"
                                        @click="setDates('year')"
                                        class="interval-btn"
                                >{{$t('year')}}</v-btn
                                >
                                <v-btn
                                        :class="{ 'active primary': activePeriod == 'all' }"
                                        @click="setDates('all')"
                                        class="interval-btn"
                                >{{$t('alltime')}}</v-btn
                                >
                            </v-col>

                                <v-col class="d-flex justify-center" cols="12">
                                    <v-btn text color="primary" @click="menu = false">
                                        {{$t('cancel')}}
                                    </v-btn>
                                    <v-btn text color="primary" @click="saveDates(selectedDates)">
                                        {{$t('ok')}}
                                    </v-btn>
                                </v-col></v-col
                            >
                        </v-date-picker>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
                        <v-select
                                v-if="superadmin"
                                v-model="selectedCompany"
                                :items="companies"
                                :label="$t('company')"
                                item-text="name"
                                item-value="id"
                                hide-details
                        ></v-select>
                    </v-col>
                    <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
                        <v-select
                                v-model="selectedDivision"
                                :items="divisions"
                                :label="$t('division')"
                                item-text="value"
                                item-value="id"
                                hide-details
                        ></v-select>
                    </v-col>
                    <v-col class="py-4 col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 col-12">
                        <v-select
                                v-model="selectedLocation"
                                :items="locations"
                                :label="$t('location')"
                                item-text="value"
                                item-value="id"
                                hide-details
                        ></v-select>
                    </v-col>
                    <div class="d-flex">
                        <v-btn
                                class="primary ml-2 export-data-btn"
                                :outlined="false"
                                color="white"
                                :loading="loading"
                                @click="getCsvReportData"
                        >
                            <v-icon class="mr-1">mdi-file-export</v-icon>{{$t('export_data')}}
                        </v-btn
                        >
                    </div>
                    <v-col cols="12" class="d-flex justify-center leaderboard-btn--wrapper">
                        <v-btn
                                class="primary show-data-btn"
                                :outlined="false"
                                color="white"
                                :loading="loading"
                                @click="reloadData"
                        >{{$t('show_data')}}</v-btn
                        >
                        </v-col>
                </v-card-title>
                <v-card>
                    <v-data-table
                            class="active-data-table elevation-0"
                            :mobile-breakpoint="1400"
                            :headers="tableHeaders"
                            :items="data"
                            :loading="loading"
                            disable-sort
                            fixed-header
                            :hide-default-footer="true"
                            :items-per-page="reportTableItemsPerPage"
                    >
                        <template v-slot:item.fullname="{ item }">
                            <router-link :to="{ name: 'Driverinfo', params: { driverid: item.userid } }">{{item.fullname}}</router-link>
                        </template>
                        <template v-slot:footer>
                            <v-card>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
<!--                                    <v-btn @click="setTablePage(page - 1)" :disabled="!hasPrev">Prev 50 rows</v-btn>-->
                                    <v-btn @click="setTablePage(page + 1)" :disabled="!hasNext">{{$t('load_next_rows')}}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-data-table>
                </v-card>
            </v-card>
        </div>
    </v-layout>
</template>
<script>
    import { apiCall } from "@/utils/api.js";
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: "Reports",
        data: (context) => ({
            activePeriod: null,
            companies: [],
            divisions: [],
            locations: [],
            selectedDates: [],
            menu: false,
            selectedCompany: 0,
            selectedDivision: 0,
            selectedLocation: 0,
            selectedDriver: 0,
            loading: false,
            tableHeaders: [
                {
                    text: context.$t('employee_id'),
                    value:"employeeid",
                    align: 'center',
                },
                {
                    text: context.$t('fullname'),
                    value: "fullname",
                    align: 'center',
                },
                {
                    text:  context.$t('score'),
                    value: "points",
                    align: 'center',
                },
                {
                    text: context.$t('coins'),
                    value: "coins",
                    align: 'center',
                },
            ],
            data: [],
            page: 1,
            hasNext: false,
            reportTableItemsPerPage: 50,
        }),
        async created() {

            this.setDates('year');

            if (this.superadmin) {
                await this.getCompanies();
            } else {
                await this.getReportFilters();
            }
        },
        watch: {
            selectedCompany(newVal) {
                if (newVal != 0) {
                    this.selectedLocation = 0;
                    this.selectedDivision = 0;
                    this.getReportFilters();
                }
            },
            selectedDivision(newVal) {
                this.getReportFilters();
                this.selectedLocation = 0;
                if(newVal === 0){
                    this.selectedLocation = 0;
                }
            },
        },
        computed: {
            ...mapGetters("auth", ["getProfile"]),
            superadmin() {
                return this.getProfile.roles.includes("Admin");
            },
            dateRangeText() {
                const endDate = new Date();
                const firstDate = new Date(2020, 0, 1);
                const arr  = [
                    firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                    endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                ];

                if (this.selectedDates[0] == arr[0] && this.selectedDates[1] == arr[1]) {
                    return this.$t('alltime');
                } else {
                    return this.selectedDates.join(" ~ ");
                }
            },
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            setTablePage(val){
                this.page = val;
                this.reportTableItemsPerPage = 50 * val;
                this.getDataFromApi(true);
            },
            clearDates() {
                const endDate = new Date();
                const firstDate = new Date(2020, 0, 1);
                this.selectedDates = [
                    firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                    endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                ];
                this.activePeriod = "all";
            },
            saveDates(dates) {
                if (dates.length != 2) {
                    this.$refs.menu.save([]);
                } else {
                    this.$refs.menu.save(this.selectedDates);
                }
            },
            setDates(period) {
                this.activePeriod = period == this.activePeriod ? null : period;

                const endDate = new Date();

                let temp = new Date();

                let firstDate = null;

                switch (period) {
                    case "week":
                        firstDate = new Date(temp.setDate(temp.getDate() - temp.getDay()));
                        this.selectedDates = [
                            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                        ];
                        break;
                    case "month":
                        firstDate = new Date(temp.getFullYear(), temp.getMonth());
                        this.selectedDates = [
                            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                        ];
                        break;
                    case "year":
                        firstDate = new Date(temp.getFullYear(), 0);
                        this.selectedDates = [
                            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                        ];
                        break;
                    default:
                        firstDate = new Date(2020, 0, 1);
                        this.selectedDates = [
                            firstDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                            endDate.toLocaleDateString('en-GB').split("/").reverse().join("-"),
                        ];
                        break;
                }
            },
            getReportFilters() {
                this.loading = true;

                let params = {
                    companyid: this.selectedCompany,
                    divisionid: this.selectedDivision
                };

                apiCall(
                    "post",
                    "reports/getglobaluserreportfilters",
                    {
                        data: params,
                    },
                    true
                ).then(response => {
                    this.locations = [{ id: 0, value: this.$t('all') }];
                    this.divisions = [{ id: 0, value: this.$t('all') }];
                    this.divisions = [...this.divisions,...response.data.result.divisions];
                    this.locations = [...this.locations, ...response.data.result.locations];
                    this.page = 1;
                    this.reportTableItemsPerPage = 50;

                }).finally(() => this.loading = false);

            },
            getDataFromApi(addDataToCurrent = false) {
                if (!addDataToCurrent) {
                    this.data = [];
                }

                this.loading = true;

                let params = {
                    selectedDates: this.selectedDates,
                    page: this.page
                };

                if (this.selectedCompany !== 0) {
                    params.companyid = this.selectedCompany;
                }

                if (this.selectedDivision !== 0) {
                    params.divisionid = this.selectedDivision;
                }

                if (this.selectedLocation !== 0) {
                    params.locationid = this.selectedLocation;
                }

                apiCall(
                    "post",
                    "reports/getglobaluserreport",
                    {
                        data: params,
                    },
                    true
                )
                    .then((response) => {
                        if (response.data.status == true) {
                            response.data.result.globalUserReportDataRecords.content.forEach( val => {
                               let badgeInfos = val.badgeInfos;
                               let elem = {...val};
                                badgeInfos.forEach((badge, i) => {
                                    elem[`badge_${i}`] = badge.count;
                                });
                                this.data.push(elem);
                            });

                            this.hasNext = response.data.result.globalUserReportDataRecords.last === false;
                            // this.hasPrev = response.data.result.globalUserReportDataRecords.first === false;

                            this.tableHeaders = [
                                {
                                    text: this.$t('employee_id'),
                                    value:"employeeid",
                                    align: 'center',
                                },
                                {
                                    text: this.$t('fullname'),
                                    value: "fullname",
                                    align: 'center',
                                },
                                {
                                    text:  this.$t('score'),
                                    value: "points",
                                    align: 'center',
                                },
                                {
                                    text: this.$t('coins'),
                                    value: "coins",
                                    align: 'center',
                                },
                            ],

                            response.data.result.globalUserReportDataTableHeaderList.forEach(e => {
                                let val = {
                                    text: e.title,
                                    value: e.value,
                                    align: 'center'
                                };
                                this.tableHeaders.push(val);
                            });

                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: this.$t('unknown_error'),
                                toggle: true,
                            });
                        }
                    })
                    .catch(() => {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    }).finally(() => this.loading = false);
            },
            async getCompanies() {
                await apiCall("get", "companies/get", true).then((response) => {
                    this.companies = response.data;
                    this.selectedCompany =
                        this.companies.length > 0 ? this.companies[0].id : 0;
                });
            },
            reloadData() {
                this.page = 1;
                this.getDataFromApi();
            },
            getCsvReportData() {
                this.loading = true;

                let params = {
                    selectedDates: this.selectedDates,
                    page: this.page
                };

                if (this.selectedCompany !== 0) {
                    params.companyid = this.selectedCompany;
                }

                if (this.selectedDivision !== 0) {
                    params.divisionid = this.selectedDivision;
                }

                if (this.selectedLocation !== 0) {
                    params.locationid = this.selectedLocation;
                }

                apiCall(
                    "post",
                    "reports/exportglobaluserreport",
                    {
                        data: params,
                    },
                    true,
                    "blob"
                )
                    .then((response) => {
                        const url = window.URL.createObjectURL(response.data);

                        const dates = this.period =='Alltime' ? 'Alltime' : this.dateRangeText;

                        const filename = dates.concat(" - ", `globalreport`,".csv");

                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        // the filename you want
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.loading = false;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.loading = false;
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    });
            }
        },
    };
</script>
<style scoped>
    .leaderboard-calendar-btn--wrapper{
        padding: 0;
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 480px) {
        .leaderboard-calendar-btn--wrapper{
            display: flex;
            flex-wrap: wrap;
            row-gap: 1rem;
            justify-content: space-between;
        }
        .leaderboard-btn--wrapper{
            flex-direction: column;
        }
        .show-data-btn,.export-data-btn{
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100%;
            margin-top: 4px;
            margin-bottom: 4px;
        }

    }
    .v-expansion-panel-header{
        padding: 0;
    }
    @media only screen and (max-width: 959px) {
        .badge-list-items-card{
            padding: 0;
        }
        .badge-list-items{
            padding: 0;
        }
        .badge-list-items .name--wrapper,
        .badge-list-items .name--content,
        .badge-list-items .earnedbadge--wrapper,
        .badge-list-items .earnedbadge--content{
            transform: none;
            border: none;
        }
    }
    .heading--item {
        padding-left: 0;
    }
    .position {
        font-size: 24px;
        font-weight: 500;
        min-width: 50px;
        width: auto;
        padding: 0;
        text-align: center;
    }
    .v-list-item__avatar {
        z-index: 2;
    }
    .name--wrapper {
        background: white;
        border: 1px solid gray;
        transform: skew(-30deg);
        padding-left: 30px;
        margin-left: -20px !important;
        z-index: 1;
    }
    .name--content {
        transform: skew(30deg);
    }
    .earnedbadge--wrapper {
        background-color: #a0ce5f;
        transform: skew(-30deg);
        display: flex;
        /* padding: 0; */
        justify-content: center;
        /* height: 48px; */
    }
    .earnedbadge--content {
        text-align: center;
        /* font-size: 24px; */
        transform: skew(30deg);
    }
    .currentscore--wrapper {
        display: flex;
        /* padding: 0; */
        justify-content: center;
        transform: skew(-30deg);
        /* height: 48px; */
    }
    .currentscore--content {
        color: white;
        text-align: center;
        transform: skew(30deg);
    }
    .leaderboard /deep/ .v-virtual-scroll__item:hover {
        background: #ff1493;
        cursor: pointer;
    }
    .leaderboard /deep/ .v-virtual-scroll__item.item--active {
        background: #ff1493;
    }
    .v-list-item.item--active .name--wrapper,
    .v-list-item.item--active .earnedbadge--wrapper,
    .v-list-item.item--active .currentscore--wrapper {
        border-top: 2px solid #ff1493 !important;
        border-bottom: 2px solid #ff1493 !important;
    }
</style>

