import axios from "axios"
import { closeSession, updateVuexToken} from "./session";

const API_URL = process.env.VUE_APP_API_URL;

const isNetworkError = err => {
  return !!err.isAxiosError && !err.response;
}

axios.interceptors.response.use(
  response => {

      if (response.headers.token) {
          localStorage.setItem("sessionToken", response.headers.token);
          updateVuexToken(response.headers.token);
      }

      if (response.headers.oldtoken) {
          localStorage.setItem("oldToken", response.headers.oldtoken);
      }
    return response
  },
  error => {
    if (isNetworkError(error)) {
        // network error
      return Promise.reject();
    }

    if (error.response) {
      if (error.response.status === 401) {
        //session expired
          closeSession();

      }
    }
    return Promise.reject(error)
  }
)

export function startSessionTimeout() {
}

export function apiHeaders(headers, authenticated = true) {
    let result = {}

    if (Object.keys(headers).length !== 0) {
        result = headers
    }else {
        result = { "Content-Type": "application/json" }
    }
  if (authenticated) {
    let jwt = localStorage.getItem("sessionToken");

    let oldJwt = localStorage.getItem("oldToken");

    if (jwt !== undefined) {
        result["Authorization"] = `Bearer ${jwt}`
    }

    if (oldJwt !== undefined && oldJwt != null) {
        result["oldtoken"] = oldJwt;
    }
  }
  return result
}

export function apiCall(
  method,
  path,
  options = {},
  authenticated = true,
  responseType = 'text',
  headers = {},
  iStringifyOptions = true
  
) {
  return axios
    .request({
      method: method,
      url: `${API_URL}/${path}`,
      headers: apiHeaders(headers, authenticated),
      params: options.params,
      data:  iStringifyOptions ? JSON.stringify(options.data) : options,
      responseType: responseType
    })
    .catch(error => {
      if (error?.response) {
        if (error.response.status !== 401 && error.response.status !== 400) {
          // show error
        }
      } else {
          // show error
      }

      return Promise.reject(error?.response)
    })
}

