<template>
    <v-dialog v-model="isVisible" max-width="500px"
              @click:outside="close"
    >
        <v-card class="useredit--card">
            <v-card-title class="dialog-title">
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-stepper v-model="e1">
                <v-stepper-header
                        v-if="steps == 2"
                >
                    <template v-for="n in steps">
                        <v-stepper-step

                                :key="`${n}-step`"
                                :complete="e1 > n"
                                :step="n"
                        >
                            {{ n == 1 ? $t('user_info') : $t('badges') }}
                        </v-stepper-step>

                        <v-divider
                                v-if="n !== steps"
                                :key="n"
                        ></v-divider>
                    </template>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content key="1" step="1" class="pa-0">
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form @submit.prevent="save">
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col class="pa-0" cols="12">
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('email')"
                                                        rules="required|email"
                                                >
                                                    <v-text-field
                                                            :error-messages="errors"
                                                            v-model="editedItem.username"
                                                            :label="$t('email')"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" class="pa-0 pr-1 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-12">
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('firstname')"
                                                        rules="required"
                                                >
                                                    <v-text-field
                                                            v-model="editedItem.firstname"
                                                            :error-messages="errors"
                                                            :label="$t('firstname')"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" class="pa-0 pl-1 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-12">
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('lastname')"
                                                        rules="required"
                                                >
                                                    <v-text-field
                                                            v-model="editedItem.lastname"
                                                            :label="$t('lastname')"
                                                            :error-messages="errors"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col class="pa-0 pr-1 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-12">
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('employeeid')"
                                                        rules="required|max:20"
                                                >
                                                    <v-text-field
                                                            v-model="editedItem.employeeid"
                                                            :error-messages="errors"
                                                            :label="$t('employeeid')"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col class="pa-0 pl-1 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-12">
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('secondaryid')"
                                                        rules="max:20"
                                                >
                                                    <v-text-field
                                                            :error-messages="errors"
                                                            v-model="editedItem.secondaryid"
                                                            :label="$t('secondaryid')"
                                                    />
                                                </validation-provider>

                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    class="pa-0"
                                            >
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('country')"
                                                        rules="required"
                                                >
                                                    <v-select
                                                            :error-messages="errors"
                                                            v-model="selectedCountry"
                                                            :items="countries"
                                                            :label="$t('country')"
                                                            item-text="value"
                                                            item-value="value"
                                                    ></v-select>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                    v-if="editedIndex === -1"
                                                    cols="12"
                                                    class="pa-0"
                                            >
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('lang')"
                                                        rules="required"
                                                >
                                                    <v-select
                                                            :error-messages="errors"
                                                            v-model="selectedLang"
                                                            :items="languages"
                                                            :label="$t('lang')"
                                                            item-text="text"
                                                            item-value="value"
                                                    ></v-select>
                                                </validation-provider>
                                            </v-col>
                                            <template
                                                    v-if="editedIndex === -1"

                                            >
                                                <v-col
                                                        cols="12"
                                                        class="pa-0"
                                                >
                                                    <validation-provider
                                                            v-slot="{ errors }"
                                                            :name="$t('role')"
                                                            rules="required|min_value:1"
                                                    >
                                                        <v-select
                                                                :error-messages="errors"
                                                                v-model="selectedRole"
                                                                :items="rolesforEdit"
                                                                :label="$t('role')"
                                                                item-text="roletext"
                                                                item-value="id"
                                                        ></v-select>
                                                    </validation-provider>
                                                </v-col>
                                            </template>
                                            <template v-else>
                                                <v-col cols="12"
                                                       class="pa-0" v-if="selectedRole != 4
                                                       && selectedRole != 6
                                                ">
                                                    <validation-provider
                                                            v-slot="{ errors }"
                                                            :name="$t('role')"
                                                            rules="required|min_value:1"
                                                    >
                                                        <v-select
                                                                :error-messages="errors"
                                                                v-model="selectedRole"
                                                                :items="rolesforEdit"
                                                                :label="$t('role')"
                                                                item-text="roletext"
                                                                item-value="id"
                                                        ></v-select>
                                                    </validation-provider>
                                                </v-col>
                                            </template>
                                            <template
                                                    v-if="editedIndex === -1"
                                            >
                                                <v-col
                                                        v-if="
                              selectedRole != 0 &&
                              selectedRole != 1 &&
                              companies.length > 0
                            "
                                                        cols="12"
                                                        class="pa-0"
                                                >
                                                    <validation-provider
                                                            v-slot="{ errors }"
                                                            :name="$t('company')"
                                                            rules="required|min_value:1"
                                                    >
                                                        <v-select
                                                                :error-messages="errors"
                                                                v-model="selectedCompany"
                                                                :items="companies"
                                                                :label="$t('company')"
                                                                item-text="name"
                                                                item-value="id"
                                                                @change="newHireCheck"
                                                        ></v-select>
                                                    </validation-provider>
                                                </v-col>
                                            </template>
                                            <template v-else>
                                                <v-col
                                                        v-if="
                              selectedRole != 0 &&
                              selectedRole != 1 &&
                              selectedRole != 4 &&
                              selectedRole != 6 &&
                              companies.length > 0
                            "
                                                        cols="12"
                                                        class="pa-0"
                                                >
                                                    <validation-provider
                                                            v-slot="{ errors }"
                                                            :name="$t('company')"
                                                            rules="required|min_value:1"
                                                    >
                                                        <v-select
                                                                :error-messages="errors"
                                                                v-model="selectedCompany"
                                                                :items="companies"
                                                                :label="$t('company')"
                                                                item-text="name"
                                                                item-value="id"
                                                        ></v-select>
                                                    </validation-provider>
                                                </v-col>
                                            </template>
                                            <v-col
                                                    v-if="
                              (selectedRole == 3 || selectedRole == 5)
                            "
                                                    cols="12"
                                                    class="pa-0"
                                            >
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('division')"
                                                        rules="required|min_value:1"
                                                >
                                                    <v-select
                                                            :error-messages="errors"
                                                            v-model="selectedDivisions"
                                                            :items="divisions"
                                                            multiple
                                                            :label="$t('division')"
                                                            item-text="name"
                                                            item-value="id"
                                                    ></v-select>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                    v-if="selectedRole == 4 || selectedRole == 6"
                                                    cols="12"
                                                    class="pa-0"
                                            >
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        name="Division"
                                                        rules="required|min_value:1"
                                                >
                                                    <v-select
                                                            :error-messages="errors"
                                                            v-model="selectedDivision"
                                                            :items="divisions"
                                                            :label="$t('division')"
                                                            item-text="name"
                                                            item-value="id"
                                                    ></v-select>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                    v-if="selectedRole == 4 || selectedRole == 6"
                                                    cols="12"
                                                    class="pa-0"
                                            >
                                                <validation-provider
                                                        v-slot="{ errors }"
                                                        :name="$t('location')"
                                                        rules="required|min_value:1"
                                                >
                                                    <v-select
                                                            v-model="selectedLocation"
                                                            :items="locations"
                                                            :error-messages="errors"
                                                            :label="$t('location')"
                                                            item-text="name"
                                                            item-value="id"
                                                    ></v-select>
                                                </validation-provider>
                                            </v-col>
                                            <v-col class="pl-0 pl-0 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-12">
                                                <v-text-field
                                                        @keypress="formatInput($event)"
                                                        @paste="formatInput($event)"
                                                        @change="formatDate('birthDate')"
                                                        maxlength="10"
                                                        v-model="birthDate"
                                                        :label="$t('birthdate')"
                                                        prepend-icon="mdi-calendar"
                                                        hint="MM/DD/YYYY"
                                                        clearable
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="pl-0 pl-0 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-12">
                                                <v-text-field
                                                        @keypress="formatInput($event)"
                                                        @paste="formatInput($event)"
                                                        @change="formatDate('hireDate')"
                                                        maxlength="10"
                                                        v-model="hireDate"
                                                        :label="$t('hiredate')"
                                                        prepend-icon="mdi-calendar"
                                                        hint="MM/DD/YYYY"
                                                        clearable
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="pl-0">
                                                <v-checkbox
                                                        :disabled="!editedItem.activenewhire"
                                                        class="mt-0"
                                                        hide-details
                                                        v-model="editedItem.newhire"
                                                        :label="$t('isnewhire')"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions v-if="selectedRole != 3">
                                    <v-spacer></v-spacer>
                                    <v-btn @click="close" class="cancel-button px-3" text>
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-btn
                                            :class="{
                          'confirm-button px-5': !invalid,
                          'disabled px-5': invalid,
                        }"
                                            :disabled="invalid"
                                            :loading="inuse"
                                            text
                                            type="submit"
                                    >
                                        {{ $t('save') }}
                                    </v-btn>
                                </v-card-actions>
                                <v-card-actions class="py-1" v-else>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            class="cancel-button px-3"
                                            text
                                            @click="close"
                                    >
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-btn
                                            :class="{
                          'confirm-button px-5': !invalid,
                          'disabled px-5': invalid,
                        }"
                                            :disabled="invalid"
                                            text
                                            @click="nextStep"
                                    >
                                        {{ $t('continue') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </validation-observer>
                    </v-stepper-content>
                    <v-stepper-content step="2" key="2">
                        <v-card>
                            <v-list-group
                                    v-for="item in divisionsForSelect"
                                    :key="item.id"

                            >
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item
                                        multiple
                                        v-for="badge in managementBadges(item.badges)"
                                        :key="badge.id"
                                        :value="item.id"
                                        :class="{'v-list-item--active' : isBadgeSelected(badge.id)}"
                                        @click="addBadge(badge)"

                                >
                                    <v-list-item-avatar>
                                        <v-img :src="badge.smallimage"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                                v-text="badge.name"
                                        ></v-list-item-title>

                                        <v-list-item-subtitle
                                                class="text--primary"
                                                :v-text="$t('badgetype', [badge.type])"
                                        ></v-list-item-subtitle>

                                        <v-list-item-subtitle
                                                :v-text="$t('badgepoints', [badge.points])"
                                        ></v-list-item-subtitle>
                                        <v-list-item-subtitle
                                                :v-text="$t('badgecoins', [badge.coins.toFixed(2)])"
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <v-card-actions>
                                <v-btn color="blue darken-1" text @click="e1 = 1"
                                >{{ $t('back') }}</v-btn
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                        class="cancel-button px-3"
                                        text
                                        @click="close"
                                >
                                    {{ $t('cancel') }}
                                </v-btn>
                                <v-btn
                                        class="confirm-button px-5"
                                        text
                                        @click="save"
                                >
                                    {{ $t('save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>
<script>
    // eslint-disable-next-line no-unused-vars
    import {apiCall} from "@/utils/api.js";
    import {mapActions, mapGetters} from "vuex";
    import {ValidationObserver, ValidationProvider, } from "vee-validate";

    export default {
        name: 'EditUserDialog',
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        props: {
            editedIndex: {
                default: -1,
                required: true
            },
            selectableroles: {
                required: true
            },
            user: {
                required: true
            },
            visible: {
                type: Boolean,
                default: false,
            },
            companies: {
                required: true
            },
        },
        data: () => ({
            inuse: false,
            loading: false,
            divisions: [],
            locations: [],
            e1: 1,
            steps: 1,
            editedItem: {
                id: -1,
                username: "",
                firstname: "",
                lastname: "",
                employeeid: "",
                secondaryid: "",
                country: null,
                role: 0,
                companyid: 0,
                divisionid: 0,
                locationid: 0,
                selectedDivisions: [],
                hireDate: null,
                birthDate: null,
                allowBadges: null,
                activenewhire: false,
                newhire: false,
                lang: null,
            },
            defaultItem: {
                username: "",
                firstname: "",
                lastname: "",
                employeeid: "",
                secondaryid: "",
                country: null,
                role: 0,
                companyid: 0,
                divisionid: 0,
                locationid: 0,
                selectedDivisions: [],
                birthDate: null,
                hireDate: null,
                allowBadges: null,
                activenewhire: false,
                newhire: false,
                lang: null,
            },
            selectedRole: 0,
            selectedCompany: 0,
            selectedDivision: 0,
            selectedDivisions: [],
            selectedLocation: 0,
            selectedCountry: null,
            selectedLang: null,
            hireDate: null,
            birthDate: null,
            allowBadges: [],
            countries: [
                {
                    id: 1,
                    value: "CANADA"
                },
                {
                    id: 2,
                    value: "USA"
                }
            ],
            languages: [
                {
                    id: 1,
                    value: "en",
                    text: "English"
                },
                {
                    id: 2,
                    value: "fr",
                    text: "Français"
                }
            ]

        }),
        computed: {
            ...mapGetters("auth", ["getProfile"]),
            formTitle() {

                return this.user.id === -1 ? this.$t('new_user') : this.$t('edit_user');
            },
            isVisible : {
                get: function () { return this.visible; },
                set: function () { this.$emit('edituserdialog:cancel'); }

            },
            rolesforEdit() {
                if (this.editedIndex === -1) {
                    return this.selectableroles.filter(item => item.id != 1)
                } else {
                    return this.getProfile.roles.includes("Admin") ?
                        this.selectableroles.filter(item => item.id != 4 && item.id != 6) :
                        this.selectableroles.filter(item => item.id != 4 && item.id != 6 && item.id != 1)
                }

            },
            divisionsForSelect() {
                return this.divisions.filter(e => this.selectedDivisions.includes(e.id));
            }
        },
        watch: {
            isVisible(newVal){
                if (newVal === true) {
                    if (this.companies.length === 0){
                        this.getDivisions();
                    }
                    if (this.editedIndex === -1 && this.getProfile.companyid !== -1) {
                        this.editedItem.activenewhire = !this.getProfile.companyHasNewHireBuffer;
                        this.editedItem.newhire = this.getProfile.companyHasNewHireBuffer;
                    }
                }
            },
            editedIndex(newVal){
                if (newVal != -1 ){
                    this.updateData();
                }
            },
            selectedRole(newVal) {
                if (newVal == 3) {
                    this.steps = 2;
                }else {
                    this.steps = 1;
                }
            },
            selectedCompany(newVal, oldVal) {
                this.editedItem.companyid = newVal;
                newVal != 0 && this.getDivisions();
                if (newVal != 0 && this.editedItem.id === -1) {

                    this.selectedDivision = 0;
                    this.selectedDivisions = [];
                    this.selectedLocation = 0;
                    this.$refs.observer?.reset();
                } else {
                    if (oldVal !== 0) {
                        this.selectedDivision = 0;
                        this.selectedDivisions = [];
                        this.selectedLocation = 0;

                        this.locations = [];
                    }

                }
            },
            selectedDivisions(newVal) {
                this.editedItem.selectedDivisions = newVal;
                this.$refs.observer?.reset();
            },
            selectedDivision(newVal, oldVal) {
                if (oldVal != 0) {
                    this.selectedLocation = 0;
                    this.selectedLocations = [];
                }
                this.editedItem.divisionid = newVal;
                if (newVal != 0) {
                    this.getLocations();
                    this.$refs.observer?.reset();
                }


            },
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            nextStep () {
                this.e1 = 2;

                if (this.editedIndex == -1) {
                    let arr = [];

                    this.divisions
                        .filter( e => this.selectedDivisions.includes(e.id))
                        .map(elem => {
                            elem.badges
                                .filter(e => e.type === "MANAGEMENT")
                                .forEach( b => {
                                    arr.push(b.id)
                                })
                        });

                    this.allowBadges = arr;

                } else{
                    const oldDivisions = this.user.permissions.map( e=>  e.division?.id);
                    const newDivisions = this.divisions.filter(e =>
                        (!oldDivisions.includes(e.id) &&
                            this.selectedDivisions.includes(e.id))
                    ).map(e=> e.id);
                    let arr = [];

                    this.divisions
                        .filter( e => newDivisions.includes(e.id))
                        .map(elem => {
                            elem.badges
                                .filter(e => e.type === "MANAGEMENT")
                                .forEach( b => {
                                    arr.push(b.id)
                                })
                        });

                    this.allowBadges = [...this.editedItem.allowBadges, ...arr];
                }

            },
            managementBadges(badges) {
                const management = "MANAGEMENT";

                return badges.filter((badge) => badge.type == management).sort(
                    (a,b) => (a.id-b.id)
                );
            },
            getDivisions() {
                if( (this.selectedCompany === undefined || this.selectedCompany === 0)  && this.companies.length === 0) {
                    apiCall("get", `divisions/getcompanydivisions`, true).then((response) => {
                        this.divisions = response.data;
                    });
                }else {
                    apiCall(
                        "get",
                        `divisions/get?companyid=${this.selectedCompany}`,
                        true
                    ).then((response) => {
                        this.divisions = response.data;


                        if (this.editedItem.id === -1) {
                            this.selectedDivisions = [];
                            this.selectedDivision = 0;
                        }
                    });
                }
            },
            getLocations() {
                apiCall(
                    "get",
                    `locations/get?divisionid=${this.selectedDivision}`,
                    true
                ).then((response) => {
                    this.locations = response.data;

                    if (this.editedItem.id === -1) {
                        this.selectedLocations = [];
                        this.selectedLocation = 0;
                    }

                });
            },
            updateUser() {
                this.inuse = true;
                this.loading = true;


                let params = this.editedItem;

                apiCall(
                    "post",
                    "users/update",
                    {
                        data: params,
                    },
                    true
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,
                            });
                            this.$emit('edituserdialog:create');
                            this.close();
                        } else {
                            this.$emit('edituserdialog:showError', response.data.result);
                        }
                    })
                    .catch(e => {
                        this.inuse = false;
                        this.setSnackbar({
                            color: "error",
                            text: e?.data?.result || "Unknown error",
                            toggle: true,
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            createUser() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "users/create",
                    {
                        data: params,
                    },
                    true
                )
                    .then((response) => {
                        this.inuse = false;
                        if (response?.data?.status) {
                            this.setSnackbar({
                                color: "success",
                                text: response.data.result,
                                toggle: true,

                            });
                            this.$emit('edituserdialog:create');
                            this.close();
                        } else {
                            this.setSnackbar({
                                color: "error",
                                text: response.data.result,
                                toggle: true,
                                timeout: 5000,
                            });
                        }
                    })
                    .catch(e => {
                        this.inuse = false;
                        this.setSnackbar({
                            color: "error",
                            text: e?.data?.result || "Unknown error",
                            toggle: true,
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            save() {
                if (this.editedIndex > -1) {
                    // edit
                    this.editedItem.id = this.editedIndex;
                    this.defaultItem.id = this.editedIndex;

                    this.defaultItem.roleid = this.selectedRole;
                    this.editedItem.roleid = this.selectedRole;

                    if (this.selectedRole == 1) {
                        this.selectedCompany = -1;
                    }

                    this.defaultItem.companyid = this.selectedCompany;
                    this.editedItem.companyid = this.selectedCompany;

                    this.editedItem.divisionid = this.selectedDivision;
                    this.editedItem.locationid = this.selectedLocation;

                    this.editedItem.hireDate = this.hireDate;
                    this.editedItem.birthDate = this.birthDate;

                    this.editedItem.allowBadges = this.allowBadges;

                    this.editedItem.country = this.selectedCountry;


                    this.updateUser();
                } else {
                    // create
                    this.defaultItem.roleid = this.selectedRole;
                    this.editedItem.roleid = this.selectedRole;

                    if (this.selectedRole == 1) {
                        this.selectedCompany = -1;
                    }

                    this.defaultItem.companyid = this.selectedCompany;
                    this.editedItem.companyid = this.selectedCompany;

                    this.editedItem.locationid = this.selectedLocation;

                    this.editedItem.hireDate = this.hireDate;
                    this.editedItem.birthDate = this.birthDate;

                    this.editedItem.allowBadges = this.allowBadges;

                    this.editedItem.country = this.selectedCountry;
                    this.editedItem.lang = this.selectedLang

                    this.createUser();
                }
            },
            close(){
                this.$refs.observer.reset();
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.selectedCompany = 0;
                    this.selectedDivision = 0;
                    this.selectedRole = 0;
                    this.selectedDivisions = [];
                    this.selectedLocation = 0;
                    this.divisions = [];
                    this.locations = [];
                    this.birthDate = null;
                    this.hireDate = null;
                    this.allowBadges = [];
                    this.e1 = 1;
                });

                this.$emit('edituserdialog:cancel');
            },
            formatInput(e) {
                switch (e.type) {
                    case 'paste':
                        e.preventDefault();
                        break;
                    default:
                        // eslint-disable-next-line no-case-declarations
                        const char = String.fromCharCode(e.keyCode); // Get the character
                        if(/^[0-9/]+$/.test(char)) return true; // Match with regex
                        else e.preventDefault(); // If not match, don't add to input text
                        break;
                }
            },
            formatDate(dateType) {
                switch (dateType) {
                    case "birthDate":
                        try {
                            // eslint-disable-next-line no-case-declarations
                            let day = this.birthDate.split("/")[1].length === 1 ? "0".concat(this.birthDate.split("/")[1]) : this.birthDate.split("/")[1];
                            // eslint-disable-next-line no-case-declarations
                            let month = this.birthDate.split("/")[0].length === 1 ? "0".concat(this.birthDate.split("/")[0]) : this.birthDate.split("/")[0];
                            // eslint-disable-next-line no-case-declarations
                            let year = this.birthDate.split("/")[2].length === 2 ? ( this.birthDate.split("/")[2] > 41 ? "19".concat(this.birthDate.split("/")[2]) : "20".concat(this.birthDate.split("/")[2])) : this.birthDate.split("/")[2];
                            // eslint-disable-next-line no-unused-vars
                            let res = new Date(month.concat("/").concat(day).concat("/").concat(year));
                            if (res.toString() !== "Invalid Date"){
                                this.birthDate = month.concat("/").concat(day).concat("/").concat(year);
                            }else {
                                this.birthDate = null;}
                        }catch (e) {
                            this.birthDate = null;
                        }
                        break;
                    case "hireDate":
                        try {
                            // eslint-disable-next-line no-case-declarations
                            let day = this.hireDate.split("/")[1].length === 1 ? "0".concat(this.hireDate.split("/")[1]) : this.hireDate.split("/")[1];
                            // eslint-disable-next-line no-case-declarations
                            let month = this.hireDate.split("/")[0].length === 1 ? "0".concat(this.hireDate.split("/")[0]) : this.hireDate.split("/")[0];
                            // eslint-disable-next-line no-case-declarations
                            let year = this.hireDate.split("/")[2].length === 2 ? ( this.hireDate.split("/")[2] > 41 ? "19".concat(this.hireDate.split("/")[2]) : "20".concat(this.hireDate.split("/")[2])) : this.hireDate.split("/")[2];
                            // eslint-disable-next-line no-unused-vars
                            let res = new Date(month.concat("/").concat(day).concat("/").concat(year));
                            if (res.toString() !== "Invalid Date"){
                                this.hireDate = month.concat("/").concat(day).concat("/").concat(year);
                            }else {
                                this.hireDate = null;}
                        }catch (e) {
                            this.hireDate = null;
                        }
                        break;
                }
            },
            isBadgeSelected(id) {
                return this.allowBadges.includes(id);
            },
            addBadge(item) {
                if (this.isBadgeSelected(item.id)) {
                    this.allowBadges = [...this.allowBadges.filter(el => el !== item.id)];
                }else {
                    this.allowBadges = [...this.allowBadges, item.id];
                }
            },
            updateData() {
                this.editedItem = Object.assign({}, this.user);

                this.birthDate = this.user.birthdate != null ? this.user.birthdate.split("-").reverse()[1].concat("/").concat(this.user.birthdate.split("-").reverse()[0]).concat("/").concat(this.user.birthdate.split("-").reverse()[2]): null;
                this.hireDate = this.user.hiredate != null ? this.user.hiredate.split("-").reverse()[1].concat("/").concat(this.user.hiredate.split("-").reverse()[0]).concat("/").concat(this.user.hiredate.split("-").reverse()[2]): null;

                this.selectedRole = this.selectableroles.filter(
                    (role) => role.name === this.user.permissions[0].role.name
                )[0].id;

                this.selectedCompany = this.user.companyid;

                this.selectedCountry = this.user.country;

                if (this.user.permissions[0].role.id === 3 || this.user.permissions[0].role.id === 5) {

                    const arr = [];
                    this.user.permissions.forEach(e => {
                        arr.push(e.division.id);
                    })

                    this.selectedDivisions = arr;
                    this.editedItem.selectedDivisions = arr;
                }

                this.selectedLocation = this.user.selectedLocation;

                if (this.user.permissions[0].role.id === 4 || this.user.permissions[0].role.id === 6) {

                    this.selectedDivision = this.user.permissions[0].division.id;
                    this.editedItem.selectedDivision = this.selectedDivision;

                    this.selectedLocation = this.user.permissions[0].location.id;
                    this.editedItem.selectedLocation = this.selectedLocation;
                }
            },
            newHireCheck(value) {
                const company  = this.companies.find(e=> e.id === value);
                if (company != null) {
                    this.editedItem.activenewhire = !company.activenewhirebuffer;
                    this.editedItem.newhire = company.activenewhirebuffer;
                }
            }
        },
    }
</script>
<style scoped>
</style>