<template>
  <v-layout fill-height wrap>
    <router-view v-if="isAuth" />
    <v-layout class="auth-layout" v-else fill-height wrap align-center>
      <v-col class="col-xl-4 col-lg-4 col-md-5 col-sm-8 offset-md-1">
        <auth
          @changeform="changeactiveform"
          v-if="isActiveComponentAuth"
        ></auth>
        <reset
          @changeform="changeactiveform"
          v-if="isActiveComponentReset"
        ></reset>
        <passwordresetconfirm
          :token="token"
          @changeform="changeactiveform"
          v-if="isActiveComponentResetConfirmation"
        ></passwordresetconfirm>
        <emailconirm
          :token="token"
          @changeform="changeactiveform"
          v-if="isActiveComponentEmailConfirm"
        ></emailconirm>
      </v-col>
    </v-layout>
  </v-layout>
</template>
<script>
import auth from "@/components/Authorization.vue";
import reset from "@/components/ResetPassword.vue";

import emailconirm from "@/components/EmailConfirm";
import passwordresetconfirm from "@/components/PasswordResetConfirm";

import { mapGetters } from "vuex";
export default {
  // eslint-disable-next-line
  components: { auth, reset, emailconirm, passwordresetconfirm },
  props: {
    token: {
      require: true,
      default: null,
    },
  },
  data: () => ({
    passwordconfirmation: false,
    isActiveComponentAuth: true,
    isActiveComponentReset: false,
    isActiveComponentResetConfirmation: false,
    isActiveComponentEmailConfirm: false,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name == "PasswordResetConfirm") {
        vm.isActiveComponentAuth = false;
        vm.isActiveComponentReset = false;
        vm.isActiveComponentEmailConfirm = false;
        vm.isActiveComponentResetConfirmation = true;
      }
      if (to.name == "EmailConfirm") {
        vm.isActiveComponentAuth = false;
        vm.isActiveComponentReset = false;
        vm.isActiveComponentResetConfirmation = false;
        vm.isActiveComponentEmailConfirm = true;
      }
    });
  },
  methods: {
    returntologin() {
      this.passwordconfirmation = false;
      this.$router.push("/");
    },
    changeactiveform(val) {
      if (val == "reset") {
        this.isActiveComponentReset = true;
        this.isActiveComponentAuth = false;
        this.isActiveComponentResetConfirmation = false;
        this.isActiveComponentEmailConfirm = false;
      } else {
        this.isActiveComponentAuth = true;
        this.isActiveComponentReset = false;
        this.isActiveComponentResetConfirmation = false;
        this.isActiveComponentEmailConfirm = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getProfile"]),
    isAuth() {
      return this.getProfile != null;
    },
  },
};
</script>
<style scoped>
  @media (max-width: 1024px) {
    .auth-layout {
       background-size: 160% !important;
    }
  }
  @media (max-width: 600px) {
    .auth-layout {
      background-image: none !important;
    }
  }
.auth-layout {
  background-image: url("~@/assets/login-bg.png");
  background-color: white;
  background-position: bottom;
  background-size: contain;
}
</style>