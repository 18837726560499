<template>
    <v-layout>
        <div class="col-12">
            <v-toolbar
                    flat
            >

                <v-toolbar-title>Notifications</v-toolbar-title>

                <template v-slot:extension>
                    <v-tabs
                            v-model="tab"
                            align-with-title
                    >
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab
                                :disabled="true"
                        >
                            {{ $t('notifications') }}
                        </v-tab>
                        <v-tab
                        >
                            {{ $t('suggestion_box') }}
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>

            <v-tabs-items v-model="tab">
                <v-tab-item
                >
                    <v-card>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                >
                    <v-card>
                        <v-expansion-panels focusable>
                            <v-expansion-panel
                                    v-for="item in suggestions"
                                    :key="item.id"
                            >
                                <v-expansion-panel-header :color="(item.notificationseen || item.completed) ?  'transparent' : '#d9edf7'">
                                    {{$t('suggestion')}} #{{item.id}} {{item.username}} ({{item.location}}) - {{item.title}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row no-gutters>

                                    </v-row>
                                    <v-card class="pl-0" elevation="0">
                                        <v-card-text class="pl-0">
                                            {{item.text}}
                                        </v-card-text>
                                        <v-card-text class="pl-0">
                                            <v-col
                                                    class="d-flex flex-wrap"
                                                    cols="12"
                                            >
                                                <v-img
                                                        class="px-1 mx-2 my-1"
                                                        v-for="(image, i) in item.images"
                                                        :key="image"
                                                        max-width="90"
                                                        min-width="90"
                                                        max-height="90"
                                                        :src="image"
                                                        aspect-ratio="1"
                                                        @click="showGallery(item, i)"
                                                >
                                                    <template v-slot:placeholder>
                                                        <v-sheet>
                                                            <v-skeleton-loader type="image" />
                                                        </v-sheet>
                                                    </template>
                                                </v-img>
                                            </v-col>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    v-if="!item.notificationseen && !item.completed"
                                                    color="secondary"
                                                    :loading="loading"
                                                    @click="markAsRead(item.id)"
                                            >
                                                {{$t('mark_as_read')}}
                                            </v-btn>
                                            <v-btn
                                                    v-if="!item.completed"
                                                    color="primary"
                                                    :loading="loading"
                                                    @click="completeItem(item.id)"
                                            >
                                                {{$t('completed')}}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            <ConfirmationDialog
                    :visible="dialogCompleted"
                    @confirmationdialog:cancelClick="closeCompleted"
                    @confirmationdialog:confirmClick="completeItemConfirm"
            >
                <template v-slot:cardText>{{ $t('are_you_sure_mark_as_completed') }}</template>
            </ConfirmationDialog>
        </div>
        <lightbox
        :visible="visibleLightbox"
        :images="activeImages"
        :activeimage="activeImage"
        @lightbox:outsideclick="closeLightbox"
        @lightbox:changeslide="changeSlide"

        />
    </v-layout>
</template>
<script>
    import {apiCall} from "../utils/api";
    import {mapActions} from "vuex";
    import LightboxCarousel from "@/components/LightboxCarousel";
    import ConfirmationDialog from "@/components/ConfirmationDialog";

    export default {
        name: "Notifications",
        components: {
            lightbox: LightboxCarousel,
            ConfirmationDialog,
        },
        data:() => ({
            dialogCompleted: false,
            notificationsTabActive: false,
            suggestionTabActive: true,
            loading: false,
            notifications: [],
            suggestions: [],
            tab: null,
            selecteditem: null,
            activeImages: [],
            visibleLightbox: false,
            activeImage: 0,
        }),
        created() {
            this.getDataFromApi()
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            ...mapActions("auth", ["setSuggestions", "setSuggestionsCount"]),
            closeCompleted(){
                this.selecteditem = null;
                this.dialogCompleted = false;
            },
            completeItem(id) {
                this.selecteditem = id;
                this.dialogCompleted = true;
            },
            completeItemConfirm(){
                this.completeSuggestion();
            },
            getDataFromApi(){
                this.loading = true;

                apiCall("get", "notifications", {},true)
                .then(response => {

                    this.suggestions = response.data.result.suggestions;
                    this.notifications = response.data.notifications;

                    const newSuggestions =this.suggestions.filter(e =>
                        !e.notificationseen && !e.completed
                    );

                    this.setSuggestions(newSuggestions);
                    this.setSuggestionsCount(newSuggestions.length);

                })
                .catch(() => {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('something_went_wrong'),
                        toggle: true,
                    })
                })
                .finally(() => {
                    this.loading = false;
                })
            },
            markAsRead(id) {
                this.loading = true;

                apiCall("post", `notifications/read/${id}`, {}, true)
                .then( response => {
                        this.setSnackbar({
                            color: response?.data?.status == true ? "success" : "error",
                            text: response.data?.status==true ? this.$t('success'): this.$t('something_went_wrong'),
                            toggle: true,
                        })
                    this.getDataFromApi();
                })
                .catch(() => {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('something_went_wrong'),
                        toggle: true,
                    })
                })
                .finally(() => this.loading = false);

            },
            completeSuggestion() {
                this.loading = true;

                apiCall("post", `notifications/complete/${this.selecteditem}`)
                    .then( response => {
                        this.setSnackbar({
                            color: response?.data?.status == true ? "success" : "error",
                            text: response.data?.status==true ? this.$t('success'): this.$t('something_went_wrong'),
                            toggle: true,
                        })
                        this.getDataFromApi();
                    })
                    .catch(() => {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('something_went_wrong'),
                            toggle: true,
                        })
                    })
                    .finally(() => {
                        this.loading = false;
                        this.selecteditem = null;
                        this.dialogCompleted = false;
                    });

            },
            showGallery(item, num) {
                const images = [...item.images];
                this.activeImage = num;
                this.activeImages = [...images];
                this.visibleLightbox = true;

            },
            closeLightbox() {
                this.visibleLightbox = false;
                this.activeImage = 0;
                this.activeImages = [];
            },
            changeSlide(val) {
                this.activeImage = val;
            }
        }
    }
</script>
