<template>
    <v-layout>
        <template v-if="isCompanyAdmin">
            <v-layout>
                <div class="col-12">
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('users') }}</v-toolbar-title>
                        <template v-slot:extension>
                            <v-tabs
                                    v-model="tab"
                                    align-with-title
                            >
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab>{{ $t('active') }}</v-tab>
                                <v-tab>{{ $t('inactive') }}</v-tab>
                                <v-tab>{{ $t('deleted') }}</v-tab>
                            </v-tabs>
                        </template>
                    </v-toolbar>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card>
                                <drivers :userStatus="'Active'" :fullpage="false" v-if="tab==0"/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <drivers :userStatus="'Inactive'" :fullpage="false" v-if="tab==1"/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <drivers :userStatus="'Removed'" :fullpage="false" v-if="tab==2"/>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </v-layout>
        </template>
        <template v-else>
            <drivers :userStatus="'Active'" :fullpage="true"/>
        </template>
    </v-layout>
</template>
<script>
    import Drivers from "@/views/Drivers";
    import {mapGetters} from "vuex";

    export default {
        components: {
            drivers: Drivers,
        },
        name: "DriverListWrapper",
        data: () => ({
            loading: false,
            tab: null,
        }),
        computed: {
            ...mapGetters("auth", ["getProfile",]),
            isCompanyAdmin() {
                return this.getProfile.roles.includes("CompanyAdmin");
            }
        }
    }
</script>
