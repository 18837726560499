import { apiCall } from "@/utils/api"
import store  from '@/store'

export function createSession(params) {
    let queryParams = Object.assign({}, params)
    for (const param of Object.keys(queryParams)) {
        if (param === "parsedParams") {
            delete queryParams[param]
        }
    }

    return apiCall(
        "post",
        `authorization/login`,
        { data: queryParams },
        false
    )
        .then(response => {
            /*eslint no-throw-literal: "off"*/
            if (!response) throw { name: "Network Error", message: "Offline" }

            localStorage.setItem("sessionToken", response.data.token)
            return response
        })
        .catch(error => {
            clearLocalStorage()
            const errorMessage = "Invalid Credentials"
            //error message
            return error && error.name === "Network Error" ? error.message : errorMessage 

        })
}

export function clearLocalStorage() {
    localStorage.removeItem("sessionToken")
}

export function closeSession() {
    store.dispatch("auth/logout");
}
export function updateVuexToken(token) {
    store.dispatch("auth/setToken", token);
}