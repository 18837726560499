<template>
    <v-layout>
        <div class="col-12">
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('users') }}</v-toolbar-title>
                <template v-slot:extension>
                    <v-tabs
                            v-model="tab"
                            align-with-title
                    >
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab>{{ $t('active') }}</v-tab>
                        <v-tab>{{ $t('inactive') }}</v-tab>
                        <v-tab>{{ $t('deleted') }}</v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card>
                        <users :userStatus="'Active'" v-if="tab==0"/>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <users :userStatus="'Inactive'" v-if="tab==1"/>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <users :userStatus="'Removed'" v-if="tab==2"/>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-layout>
</template>
<script>
    import Users from "@/views/Users";
    export default {
        components: {
            users: Users,
        },
        name: "SAUserlistWrapper",
        data: () => ({
            loading: false,
            tab: null,
        })
    }
</script>
