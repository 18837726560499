<template>
  <v-app>
    <v-app-bar clipped-left app color="primary" dark class="application-header" >
      <v-app-bar-nav-icon
        v-if="isAuth"
        @click.stop="showMenu"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Rocket Start Logo"
          class="shrink mr-2"
          contain
          src="~@/assets/Logo.png"
          transition="scale-transition"
          width="40"
        />
        Rocket Start
      </div>

      <v-spacer></v-spacer>
      <div v-if="isAuth">
        <v-menu left bottom :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-badge
                :content="getProfile.suggestionsCount"
                :value="getProfile.suggestionsCount"
                color="red"
                overlap
                class="mr-4"
              >
                <v-icon color="#a3cc56" size="29">mdi-bell-ring</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-list v-if="isAuth && (getProfile.roles.includes('Manager') || getProfile.roles.includes('CompanyAdmin'))" class="pb-0">
            <v-subheader class="suggestionList__header">
              <v-list-item-action-text class="suggestionList__title">{{ $t('notifications') }}</v-list-item-action-text>
              <v-list-item-action>
                <router-link class="suggestionList__link" :to="{ name: 'Notifications'}">{{ $t('see_all') }}</router-link>
              </v-list-item-action>
            </v-subheader>
            <v-list-item class="px-0 mx-4 suggestion__item--wrapper" v-for="item in getProfile.suggestions" :key="item.id">
              <v-list-item-icon class="mr-0 my-2">
                <v-icon color="primary" size="29">mdi-lightbulb-on</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="suggestion__item pl-4 pr-2">
                <v-list-item-title class="suggestionTitle"> <span class="suggestionTitle__user">{{item.username}} ({{item.location}}) </span> {{ $t('sent_a_guggestion')}}</v-list-item-title>
                <v-list-item-subtitle class="suggestionDate">{{item.date}}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>

        </v-menu>

        <v-avatar color="brown" size="45">
          <img v-if="avatar" :src="avatar" alt="userInitials" />
          <span v-else class="white--text headline">{{ userInitials }}</span>
        </v-avatar>
        <span  class="ml-5 hidden-md-and-down">{{ fullName }}</span>
        <v-menu left bottom :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="user-menu-button" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-title class="hidden-md-and-up pl-4 pr-4 py-2 text-body-1 font-weight-bold">
              {{fullName}}
            </v-list-item-title>
            <v-divider class="hidden-md-and-up"></v-divider>
            <v-list-item v-if="oldTokenFound" @click="returnToAdmin">
              <v-icon class="mr-2">mdi-account-arrow-left</v-icon>
              <v-list-item-title>{{ $t('return_to_admin') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="loadProfilePage">
              <v-icon class="mr-2">mdi-account</v-icon>
              <v-list-item-title>{{ $t('profile') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-icon class="mr-2">mdi-logout</v-icon>
              <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div else>
        <v-menu left bottom :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="width: 32px; height: 32px" class="user-menu-button" icon v-bind="attrs" v-on="on">
              <v-img
                      :alt="$i18n.locale"
                      width="32"
                      :src="require(`@/assets/${$i18n.locale}.png`)"
                      transition="scale-transition"
              />
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="setLang(lang)" :key="lang" v-for="lang in $i18n.availableLocales">
              <v-img
                      alt="lang"
                      width="32"
                      :src="require(`@/assets/${lang}.png`)"
                      transition="scale-transition"
              />
              <v-list-item-title class="ml-2">{{lang == 'en' ? "English" : 'Français'}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isAuth && !getLoading"
      :permanent="isDesktop"
      v-resize="resizeWindow"
      clipped
      app
      :mini-variant="mini && isDesktop"
      :temporary="!isDesktop"
      v-model="visibleMenu"
    >
      <v-list nav dense>
        <template v-for="item in items">
          <v-list-item link :key="item.text" :to="item.link" v-if="!item.subLinks">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >{{ $t(item.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
                  v-else
                  :key="item.text"
                  no-action
          >
            <template v-slot:activator>
              <v-list-item class="px-0"  link :key="item.text" >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title >{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-for="item in item.subLinks">
              <v-list-item class="pl-8" link :key="item.text" :to="item.link" v-if="!item.subLinks">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.toggle"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      right="right"
      top="top"
      class="snackbar-wrapper"
    >
      {{ snackbar.text }}
    </v-snackbar>
    <v-main class="grey-background">
      <template v-if="!getLoading" >
        <router-view :class="{'pb-8': isAuth}" />
        <template>
          <v-footer v-if="!isAuth"

              color="primary"
              padless
          >
            <v-row
                class="py-2"
                justify="center"
                align="center"
                no-gutters
            >
              <div
                  class="primary text-center white--text"
              >
                © {{ new Date().getFullYear() }} Rocket Start, LLC. All Rights Reserved
              </div>
              <a color="white" href="/tos" target="_blank">
              <v-btn
                  color="white"
                  text
                  rounded
                  class="my-0"
              >
                {{  $t('terms_of_use') }}
              </v-btn>
              </a>
              <a color="white" href="/privacy" target="_blank">
              <v-btn
                  color="white"
                  text
                  rounded
                  class="my-0"
              >
                {{  $t('privacy_policy') }}
              </v-btn>
              </a>
              <a color="white" href="/eula" target="_blank">
                <v-btn
                    color="white"
                    text
                    rounded
                    class="my-0"
                >
                  {{  $t('eula') }}
                </v-btn>
              </a>
            </v-row>
          </v-footer>
        </template>
      </template>

      <v-layout align-center justify-center column fill-height v-else>
        <v-progress-circular :size="250" color="primary" indeterminate>
          <v-img
            alt="RocketStart Logo"
            class="shrink mr-2"
            contain
            src="~@/assets/Logo.png"
            transition="scale-transition"
            width="200"
            position="center -10px"
          />
        </v-progress-circular>
      </v-layout>
    </v-main>

  </v-app>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data: (context) => ({
    dialogReset: false,
    drawer: false,
    mini: false,
    adminItems: [
      {
        link: "/companies",
        icon: "mdi-view-dashboard",
        // eslint-disable-next-line no-undef
        text: 'companies'
      },
      {
        link: "/users",
        icon: "mdi-account-group",
        text: 'users'
      },
      {
        link: "/divisions",
        icon: "mdi-domain",
        text: 'divisions_locations'
      },
      {
        link: "/defaultbadges",
        icon: "mdi-shield-star",
        text: 'badges'

      },
      {
        link: "/leaderboard",
        icon: "mdi-star-shooting",
        text: 'leaderboard'
      },
      {
        link: "/reports",
        icon: "mdi-finance",
        text: 'reports'
      },
      {
        link: "/transfer-page",
        icon: "mdi-hand-coin",
        text: 'transfer_coins'
      },
      {
        link: "/notifications-center",
        icon: "mdi-chat",
        text: 'notifications_center'
      },
      {
        icon: "mdi-cogs",
        text: 'settings',
        subLinks: [
          {
            text: 'programs',
            link : '/settings-programs',
            icon : 'mdi-widgets'
          },
        ]
      }
    ],
    companyItems: [
      {
        link: "/drivers",
        icon: "mdi-account-group",
        text: context.$t('users'),
      },
      {
        link: "/locations",
        icon: "mdi-map-marker",
        text: context.$t('home_locations'),
      },
      {
        link: "/badges",
        icon: "mdi-shield-star",
        text: context.$t('badges'),
      },
      {
        link: "/gps-data",
        icon: "mdi-crosshairs-gps",
        text: context.$t('gps'),
        hideBy: "gps",
      },
      {
        link: "/leaderboard",
        icon: "mdi-star-shooting",
        text: context.$t('leaderboard'),
        hideBy: "leaderboard",
      },
      {
        link: "/reports",
        icon: "mdi-finance",
        text: context.$t('reports'),
      },
      {
        link: "/redeems",
        icon: "mdi-hand-coin",
        text: context.$t('transfer_coins'),
        hideBy: 'tcmenable',
      },
      {
        link: "/quizzes",
        icon: "mdi-chat-question",
        text: context.$t('quizzes'),
      },
      {
        link: "/notifications-center",
        icon: "mdi-chat",
        text: 'notifications_center'
      },
    ],
    supervisorItems: [
      {
        link: "/gps-data",
        icon: "mdi-crosshairs-gps",
        text: context.$t('gps'),
        hideBy: "gps",
      },
      {
        link: "/leaderboard",
        icon: "mdi-star-shooting",
        text: context.$t('leaderboard'),
        hideBy: "leaderboard",
      },
      {
        link: "/reports",
        icon: "mdi-finance",
        text: context.$t('reports'),
      },
      {
        link: "/quizzes",
        icon: "mdi-chat-question",
        text: context.$t('quizzes'),
      },
      {
        link: "/notifications-center",
        icon: "mdi-chat",
        text: 'notifications_center'
      },
    ],
    isDesktop: false,
    visibleMenu: false,
  }),
  watch: {
    dialogReset(val) {
      val || this.closeReset();
    },
  },
  computed: {
    ...mapState("service", ["snackbar"]),
    ...mapGetters("auth", ["getProfile", "getLoading", "getSecondTokenStatus"]),
    oldTokenFound() {
      return this.getSecondTokenStatus == true;
    },
    isAuth() {
      return this.getProfile != null;
    },
    userInitials() {
      return this.getProfile != null
        ? this.getProfile.firstname[0] + this.getProfile.lastname[0]
        : "u";
    },
    avatar() {
      return this.getProfile.profilephotourl;
    },
    fullName() {
      return this.getProfile != null
        ? this.getProfile.firstname + " " + this.getProfile.lastname
        : "user";
    },
    items() {
      const hasGpsDataPage = this.getProfile?.hasGpsDataPage;
      const hasLeaderboard = this.getProfile?.hasLeaderboard;
      const tcmenable = this.getProfile?.tcmenable;

      if (
        this.getProfile?.roles.includes("CompanyAdmin") ||
        this.getProfile?.roles.includes("Manager")
      ) {
        return this.companyItems
                .filter(e => !(e.hideBy==='gps' && !hasGpsDataPage))
                .filter(e => !(e.hideBy==='leaderboard' && !hasLeaderboard))
                .filter(e=> !(e.hideBy==='tcmenable' && !tcmenable));
      }

      if (this.getProfile?.roles.includes("Admin")) {
        return this.adminItems;
      }
      if (this.getProfile?.roles.includes("Supervisor")) {
        return this.supervisorItems
                .filter(e => !(e.hideBy==='gps' && !hasGpsDataPage))
                .filter(e => !(e.hideBy==='leaderboard' && !hasLeaderboard));
      }
      return null;
    },
  },
  methods: {
    ...mapActions("service", ["setSnackbar"]),
    ...mapActions("auth", ["logout", "logBack", "updatelang" ]),
    setLang(lang){
      if (lang !== this.$i18n.locale){
        this.selectedLang = lang;
        this.$i18n.locale = lang;
        localStorage.setItem("lang", lang);
        if (this.isAuth){
          this.updatelang();
        }
        location.reload();
      }
    },
    resizeWindow(){
      const x = window.innerWidth;
      this.isDesktop = x > 1024 || false;
    },
    async returnToAdmin() {
      await this.logBack();
      this.$router.push("/users");
    },
    loadProfilePage() {
      this.$router.push("/profile");
    },
    showMenu() {
      if (this.isDesktop) {
        this.mini = ! this.mini;
      }else{
        this.visibleMenu = true;
      }
    }
  },
};
</script>
<style scoped>
.grey-background {
  background-color: #eceef0;
}

.v-main /deep/ .v-main__wrap {
  display: flex;
  flex-direction: column;
}
  @media (max-width: 720px) {
    .application-header /deep/ .v-toolbar__content {
      padding-left: 8px;
      padding-right: 8px;
    }
    .application-header /deep/ .user-menu-button {
      width: auto !important;
      height: auto !important;
    }
  }
</style>
<style>
  /* snackbar */
  .v-snack__wrapper{
    min-width: 306px !important;
  }
/* dialog */
.dialog-title {
  color: white;
  background-color: var(--v-primary-base);
}

.cancel-button {
  background-color: #f14746;
  color: white !important;
}
.confirm-button {
  background-color: #a2cd63;
  color: white !important;
}
.suggestionList__header {
  display: flex;
  justify-content: space-between;
}
.suggestionList__title {
  font-weight: bold;
  font-size: 1rem !important;
  color: black;
}
.suggestionList__link {
  text-decoration: none;
}
.suggestion__item--wrapper{
  border-top: 1px solid var(--v-primary-base);
}
.suggestionTitle {
  font-size: 13px !important;
}
.suggestionTitle__user {
  font-weight: bold;
}
  .suggestionDate {
    font-size: 11px !important;
    text-align: right;
  }
</style>
